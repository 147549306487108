import styles from "./SubmitFormBtn.module.scss";

/* eslint-disable */
const SubmitFormBtn = ({ text, clickFunc, refBtn, disabled = false, ...rest }) => (
  <>
    {clickFunc ? (
      <button disabled={disabled} onClick={clickFunc} type="submit" ref={refBtn} {...rest} className={styles.submit_form_btn}>
        {text}
      </button>
    ) : (
      <button disabled={disabled} type="submit" ref={refBtn} {...rest} className={styles.submit_form_btn}>
        {text}
      </button>
    )}
  </>
);

export default SubmitFormBtn;
