import { t } from "i18next";

import * as Yup from "yup";
import { useFormik } from "formik";

import axios from "axios";

import { useLayoutEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  selectContractDataFYC,
  patchContractDataThunk,
} from "app/slices/followYourContracts/followYourContracts.slice";

import { changeAucunToNull, changeEmptyStringToNull, changeOuiOrNonToBoolean } from "utils/tools";

import { toastError } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";
import SubmitBtn from "components/SubmitBtn/SubmitBtn";
// import BlueBanner from "../../../components/BlueBanner/BlueBanner";
import Form10SubTitle from "pages/ENT/FollowYourContracts/components/formFields/Form10SubTitle/Form10SubTitle";
import DropdownSection from "../../../components/DropdownSection/DropdownSection";
import StudentInformationsSection from "../../../components/StudentInformationsSection/StudentInformationsSection";

import Form1 from "./forms/Form1/Form1";
import Form2 from "./forms/Form2/Form2";
import Form4 from "./forms/Form4/Form4";
import Form6 from "./forms/Form6/Form6";
import Form7 from "./forms/Form7/Form7";
import Form8 from "./forms/Form8/Form8";
import Form9 from "./forms/Form9/Form9";
import Form10 from "./forms/Form10/Form10";

import styles from "./ManualEntrySection.module.scss";

const ManualEntrySection = () => {
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const dispatch = useDispatch();

  const contractData = useSelector(selectContractDataFYC);

  const [submittingAllSections, setSubmittingAllSections] = useState(false);
  const [currentSectionBeingSubmitted, setCurrentSectionBeingSubmitted] = useState(0);

  const [form1IsValid, setForm1IsValid] = useState(false);
  const [form2IsValid, setForm2IsValid] = useState(false);
  const [form4IsValid, setForm4IsValid] = useState(false);
  const [form6IsValid, setForm6IsValid] = useState(false);
  const [form7IsValid, setForm7IsValid] = useState(false);
  const [form8IsValid, setForm8IsValid] = useState(false);
  const [form9IsValid, setForm9IsValid] = useState(false);
  const [form10IsValid, setForm10IsValid] = useState(false);

  const manualEntrySectionSchema = Yup.object().shape({
    electronicSignCheckbox: Yup.boolean(),
    certifiedDataCheckbox: Yup.bool().oneOf([true], t("app.errorMessages.RemplirChamp")),
  });

  const onSubmit = async (values) => {
    const valuesCopy = structuredClone(values);
    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);

    const dataToSend = {
      status: "Soumis",
      contract: {
        apprenticeshipType: contractData?.contract?.apprenticeshipType?.key,
        financing: {
          certifiedData: valuesCopy.certifiedDataCheckbox,
        },
      },
      electronicSignature: valuesCopy.electronicSignCheckbox,
    };

    try {
      await dispatch(
        patchContractDataThunk({
          dataToSend,
          id: contractData.id,
          axiosCancelToken,
        }),
      ).unwrap();
      console.log("Final patchContractDataThunk success !", dataToSend);
    } catch (error) {
      cancelSubmitAllSections();
      toastError(
        <ToastMessageStructure firstMessage={t("app.toastMessages.PreContraEntLastSubmitError")} secondMessage={error.message} />,
      );
      console.log("Final patchContractDataThunk fail !", error);
    }
  };

  const { values, errors, touched, setFieldValue, handleSubmit, isValid, validateForm } = useFormik({
    initialValues: {
      electronicSignCheckbox: contractData?.electronicSignature ? contractData?.electronicSignature : false,
      certifiedDataCheckbox: contractData?.contract?.financing?.certifiedData
        ? contractData?.contract?.financing?.certifiedData
        : false,
    },
    validationSchema: manualEntrySectionSchema,
    onSubmit,
  });

  const handleSubmitAllSections = (e) => {
    e.preventDefault();

    if (!isValid) {
      handleSubmit();
    } else if (
      !submittingAllSections &&
      form1IsValid &&
      form2IsValid &&
      form4IsValid &&
      form6IsValid &&
      form7IsValid &&
      form8IsValid &&
      form9IsValid &&
      form10IsValid
    ) {
      setSubmittingAllSections(true);
      setCurrentSectionBeingSubmitted(1);
    } else {
      toastError(<ToastMessageStructure firstMessage={t("app.toastMessages.PreContraEntSectionNonRemplieError")} />);
    }
  };

  const submitNextSectionIfSubmitAllTrue = (nextStep) => {
    if (submittingAllSections) {
      setCurrentSectionBeingSubmitted(nextStep);
    }
  };

  const cancelSubmitAllSections = () => {
    setSubmittingAllSections(false);
    setCurrentSectionBeingSubmitted(0);
    if (submittingAllSections) {
      toastError(<ToastMessageStructure firstMessage={t("app.toastMessages.PreContraEntLastSubmitAllError")} />);
    }
  };

  // useLayoutEffect to force Formik's isValid check, the first time the component is mounted
  useLayoutEffect(() => {
    validateForm();
  }, []);

  // useLayoutEffect to manage the trigger at the right time for sending this form, during global submission.
  useLayoutEffect(() => {
    const thisFormNumber = 11;

    if (isValid && submittingAllSections && currentSectionBeingSubmitted === thisFormNumber) {
      handleSubmit();
    } else if (!isValid && submittingAllSections && currentSectionBeingSubmitted === thisFormNumber) {
      handleSubmit();
      cancelSubmitAllSections();
      toastError(<ToastMessageStructure firstMessage={t("app.toastMessages.PreContraEntLastSubmitError")} />);
    }
  }, [isValid, submittingAllSections, currentSectionBeingSubmitted]);

  // For cleanup function only
  useLayoutEffect(
    () => () => {
      axiosCancelToken.cancel();
      console.log("Cancel API calls : ManualEntrySection");
    },
    [],
  );

  return (
    <>

      {/* <div className={styles.blue_banner_container}>
        <BlueBanner
          text={t("app.suivezVosContratsPartner.CerfaWarning")}
          text2={t("app.suivezVosContratsPartner.CerfaWarning2")}
          text3={t("app.suivezVosContratsPartner.CerfaWarning3")}
          text4={t("app.suivezVosContratsPartner.CerfaWarning4")}
        />
      </div> */}

      <section className={styles.main_container}>
        <div className={styles.static_part}>
          <StudentInformationsSection />
        </div>

        <DropdownSection
          title={`${t("app.suivezVosContratsPartner.InformationsEntreprise")} -`}
          formIsValid={form1IsValid}
          Form={(
            <Form1
              axiosCancelToken={axiosCancelToken}
              submittingAllSections={submittingAllSections}
              submitNextSectionIfSubmitAllTrue={submitNextSectionIfSubmitAllTrue}
              cancelSubmitAllSections={cancelSubmitAllSections}
              currentSectionBeingSubmitted={currentSectionBeingSubmitted}
              setFormIsValid={setForm1IsValid}
            />
          )}
        />

        <DropdownSection
          title={`${t("app.suivezVosContratsPartner.SignataireDeLaConvention")} -`}
          formIsValid={form2IsValid}
          isSubTitle
          subTitle={t("app.suivezVosContratsPartner.infoTextSignaCerfa")}
          Form={(
            <Form2
              axiosCancelToken={axiosCancelToken}
              submittingAllSections={submittingAllSections}
              submitNextSectionIfSubmitAllTrue={submitNextSectionIfSubmitAllTrue}
              cancelSubmitAllSections={cancelSubmitAllSections}
              currentSectionBeingSubmitted={currentSectionBeingSubmitted}
              setFormIsValid={setForm2IsValid}
            />
          )}
        />

        <DropdownSection
          title={`${t("app.suivezVosContratsPartner.RHTitle")} -`}
          isSubTitle
          subTitle={t("app.suivezVosContratsPartner.RhInfoText")}
          formIsValid={form4IsValid}
          Form={(
            <Form4
              axiosCancelToken={axiosCancelToken}
              submittingAllSections={submittingAllSections}
              submitNextSectionIfSubmitAllTrue={submitNextSectionIfSubmitAllTrue}
              cancelSubmitAllSections={cancelSubmitAllSections}
              currentSectionBeingSubmitted={currentSectionBeingSubmitted}
              setFormIsValid={setForm4IsValid}
            />
          )}
        />

        <DropdownSection
          title={`${t("app.suivezVosContratsPartner.ContactTuteurEtMaitreDapprentissage")} -`}
          formIsValid={form6IsValid}
          Form={(
            <Form6
              axiosCancelToken={axiosCancelToken}
              submittingAllSections={submittingAllSections}
              submitNextSectionIfSubmitAllTrue={submitNextSectionIfSubmitAllTrue}
              cancelSubmitAllSections={cancelSubmitAllSections}
              currentSectionBeingSubmitted={currentSectionBeingSubmitted}
              setFormIsValid={setForm6IsValid}
            />
          )}
        />

        <DropdownSection
          title={`${t("app.suivezVosContratsPartner.ContratRenseignementsGeneraux")} -`}
          formIsValid={form7IsValid}
          Form={(
            <Form7
              axiosCancelToken={axiosCancelToken}
              submittingAllSections={submittingAllSections}
              submitNextSectionIfSubmitAllTrue={submitNextSectionIfSubmitAllTrue}
              cancelSubmitAllSections={cancelSubmitAllSections}
              currentSectionBeingSubmitted={currentSectionBeingSubmitted}
              setFormIsValid={setForm7IsValid}
            />
          )}
        />

        <DropdownSection
          title={`${t("app.suivezVosContratsPartner.RemunerationContratDapprentissage")} -`}
          formIsValid={form8IsValid}
          Form={(
            <Form8
              axiosCancelToken={axiosCancelToken}
              submittingAllSections={submittingAllSections}
              submitNextSectionIfSubmitAllTrue={submitNextSectionIfSubmitAllTrue}
              cancelSubmitAllSections={cancelSubmitAllSections}
              currentSectionBeingSubmitted={currentSectionBeingSubmitted}
              setFormIsValid={setForm8IsValid}
            />
          )}
        />

        <DropdownSection
          title={`${t("app.suivezVosContratsPartner.FinancementDuContrat")} -`}
          formIsValid={form9IsValid}
          Form={(
            <Form9
              axiosCancelToken={axiosCancelToken}
              submittingAllSections={submittingAllSections}
              submitNextSectionIfSubmitAllTrue={submitNextSectionIfSubmitAllTrue}
              cancelSubmitAllSections={cancelSubmitAllSections}
              currentSectionBeingSubmitted={currentSectionBeingSubmitted}
              setFormIsValid={setForm9IsValid}
            />
          )}
        />

        <DropdownSection
          title={`${t("app.suivezVosContratsPartner.FinancementDuContratOpco")} -`}
          formIsValid={form10IsValid}
          isSubTitle
          subTitle={<Form10SubTitle />}
          Form={(
            <Form10
              axiosCancelToken={axiosCancelToken}
              submittingAllSections={submittingAllSections}
              submitNextSectionIfSubmitAllTrue={submitNextSectionIfSubmitAllTrue}
              cancelSubmitAllSections={cancelSubmitAllSections}
              currentSectionBeingSubmitted={currentSectionBeingSubmitted}
              setFormIsValid={setForm10IsValid}
            />
          )}
        />

        <form className={styles.checkbox_part} onSubmit={(e) => handleSubmitAllSections(e)}>
          <div className={styles.checkbox_row}>
            <div className={` ${styles.checkbox_container} input-like-rw`} style={{ marginBottom: "20px" }}>
              <div>
                <input
                  style={
                    errors.electronicSignCheckbox &&
                    touched.electronicSignCheckbox && {
                      border: "2px solid #EA001E",
                    }
                  }
                  id="electronicSignCheckbox"
                  type="checkbox"
                  checked={values.electronicSignCheckbox}
                  onChange={(e) => setFieldValue("electronicSignCheckbox", e.target.checked)}
                />
                <div className={styles.checkbox_text}>{t("app.suivezVosContratsPartner.SignatureElectronique")}</div>
              </div>
              <InputErrorMessage message={errors.electronicSignCheckbox} touched={touched.electronicSignCheckbox} />
            </div>
            <div className={` ${styles.checkbox_container} input-like-rw`}>
              <div>
                <input
                  style={
                    errors.certifiedDataCheckbox &&
                    touched.certifiedDataCheckbox && {
                      border: "2px solid #EA001E",
                    }
                  }
                  id="certifiedDataCheckbox"
                  type="checkbox"
                  checked={values.certifiedDataCheckbox}
                  onChange={(e) => setFieldValue("certifiedDataCheckbox", e.target.checked)}
                />
                <div className={styles.checkbox_text}>{t("app.suivezVosContratsPartner.checkbox2Opco")}</div>
              </div>
              <InputErrorMessage message={errors.certifiedDataCheckbox} touched={touched.certifiedDataCheckbox} />
            </div>
          </div>

          <div className={styles.submit_brn}>
            <SubmitBtn disabled={submittingAllSections} text="Validez votre contrat" />
          </div>
        </form>
      </section>
    </>
  );
};

export default ManualEntrySection;
