import { t } from "i18next";

import { useSelector } from "react-redux";
import { selectContractDataFYC } from "app/slices/followYourContracts/followYourContracts.slice";

import FormInformationField from "../formFields/FormInformationField/FormInformationField";

import styles from "./StudentInformationsSection.module.scss";

const StudentInformationsSection = () => {
  const contractData = useSelector(selectContractDataFYC);

  const formatDate = (input) => {
    if (input) {
      const datePart = input?.match(/\d+/g);
      const year = datePart[0]; // get only two digits
      const month = datePart[1];
      const day = datePart[2];

      return `${day}/${month}/${year}`;
    }

    return "ERROR";
  };

  return (
    <>
      <p className={styles.user_name}>
        {contractData?.student?.person?.firstname} {contractData?.student?.person?.name}
      </p>

      <div className={styles.user_info_container}>
        <FormInformationField
          label={t("app.suivezVosContratsPartner.DateDeNaissance")}
          information={
            contractData?.student?.person?.birthdate === undefined
              ? "//////////"
              : formatDate(contractData?.student?.person?.birthdate)
          }
        />
        <FormInformationField
          label={t("app.suivezVosContratsPartner.EcoleDeFormation")}
          information={
            contractData?.student?.schoolProgramEnrollment?.product?.trainingSchool?.name === undefined
              ? "//////////"
              : contractData?.student?.schoolProgramEnrollment?.product?.trainingSchool?.name
          }
        />
        <FormInformationField
          label={t("app.suivezVosContratsPartner.Uai")}
          information={
            contractData?.student?.schoolProgramEnrollment?.educationalEstablishmentRegistrationNumber === undefined
              ? "//////////"
              : contractData?.student?.schoolProgramEnrollment?.educationalEstablishmentRegistrationNumber
          }
        />
      </div>

      <div className={styles.user_info_container}>
        <FormInformationField
          label={t("app.suivezVosContratsPartner.AnneeUniversitaire")}
          information={
            contractData?.student?.schoolProgramEnrollment?.schoolYear === undefined
              ? "//////////"
              : contractData?.student?.schoolProgramEnrollment?.schoolYear
          }
        />
        <FormInformationField
          label={t("app.suivezVosContratsPartner.Session")}
          information={
            contractData?.student?.schoolProgramEnrollment?.session?.label === undefined
              ? "//////////"
              : contractData?.student?.schoolProgramEnrollment?.session?.label
          }
        />
        <FormInformationField
          label={t("app.suivezVosContratsPartner.DateDeDebutDeFormation")}
          information={
            contractData?.student?.schoolProgramEnrollment?.startDate === undefined
              ? "//////////"
              : formatDate(contractData?.student?.schoolProgramEnrollment?.startDate)
          }
        />
        <FormInformationField
          label={t("app.suivezVosContratsPartner.Annee")}
          information={
            contractData?.student?.schoolProgramEnrollment?.year === undefined
              ? "//////////"
              : contractData?.student?.schoolProgramEnrollment?.year
          }
        />
      </div>

      <div className={styles.user_info_container}>
        <FormInformationField
          label={t("app.suivezVosContratsPartner.Promotion")}
          information={
            contractData?.student?.schoolProgramEnrollment?.promotion === undefined
              ? "//////////"
              : contractData?.student?.schoolProgramEnrollment?.promotion
          }
        />
        <FormInformationField
          label={t("app.suivezVosContratsPartner.SousPromotion")}
          information={
            contractData?.student?.schoolProgramEnrollment?.subPromotion === undefined
              ? "//////////"
              : contractData?.student?.schoolProgramEnrollment?.subPromotion
          }
        />
        <FormInformationField
          label={t("app.suivezVosContratsPartner.SousPromotionEnvisagee")}
          information={
            contractData?.student?.schoolProgramEnrollment?.wantedSubPromotion === undefined
              ? "//////////"
              : contractData?.student?.schoolProgramEnrollment?.wantedSubPromotion
          }
        />
      </div>
      <div className={styles.user_info_container}>
        <FormInformationField
          label={t("app.suivezVosContratsPartner.NomDiplome")}
          information={
            contractData?.student?.schoolProgramEnrollment?.diploma?.name === undefined
              ? "//////////"
              : contractData?.student?.schoolProgramEnrollment?.diploma?.name
          }
        />
        <FormInformationField
          label={t("app.suivezVosContratsPartner.CodeDiplome")}
          information={
            contractData?.student?.schoolProgramEnrollment?.diploma?.code === undefined
              ? "//////////"
              : contractData?.student?.schoolProgramEnrollment?.diploma?.code
          }
        />
      </div>
    </>
  );
};

export default StudentInformationsSection;
