import { t } from "i18next";

// import { useFormik } from "formik";

import { useDispatch, useSelector } from "react-redux";
import {
  patchStepThunk,
  handlePreviousStep,
  getDeclareARecruitmentPreContractualizationInformations,
  getDeclareARecruitmentPending,
} from "app/slices/declareARecruitment/declareARecruitment.slice";

import SubmitContractBtn from "pages/ETU/DeclareARecruitment/components/SubmitContractBtn/SubmitContractBtn";
import PreviousFormBtn from "components/PreviousFormBtn/PreviousFormBtn";
import BlueBanner from "../components/BlueBanner";

import styles from "../Step2.module.scss";

const ContinuingEducationForm = ({ axiosCancelToken }) => {
  const dispatch = useDispatch();

  const preContractualizationInformations = useSelector(getDeclareARecruitmentPreContractualizationInformations);
  const pending = useSelector(getDeclareARecruitmentPending);

  // onSubmit form function
  const onSubmit = () => {
    // Data to send object initialization
    const dataToSend = {
      stage: "Etape3",
      contractType: preContractualizationInformations?.contractType?.key,
      student: {
        id: preContractualizationInformations?.student?.id,
      },
    };
    console.log("dataToSend : ", dataToSend);

    (async () => {
      if (!pending) {
        (async () => {
          try {
            await dispatch(
              patchStepThunk({
                dataToSend,
                id: preContractualizationInformations.id,
                axiosCancelToken,
              }),
            ).unwrap();
            console.log("patch success !");
          } catch (error) {
            console.log("patch fail !", error.message);
          }
        })();
      }
    })();
  };

  return (
    <>
      <BlueBanner />
      <div className={styles.btns_container}>
        <SubmitContractBtn text={t("app.declarezUnRecrutement.ValidezEtapeSuivante")} handleNextStep={onSubmit} />
        <PreviousFormBtn
          text={t("app.declarezUnRecrutement.EtapePrecedente")}
          handlePreviousStep={() => dispatch(handlePreviousStep("Etape1"))}
        />
      </div>
    </>
  );
};

export default ContinuingEducationForm;
