import codeNafSvg from "../assets/codeNaf.svg";
import styles from "../CompanyProfile.module.scss";

const IdccDropdownItem = ({ result, handleClick, handleKeyDown }) => (
  <div>
    <div key={result.code} className={styles.output_plus_image_container}>
      <img src={codeNafSvg} style={{ width: "1.8rem" }} alt="test"></img>
      <div className={styles.outputKeyLabel} onClick={handleClick} onKeyDown={handleKeyDown} role="button" tabIndex={0}>
        <li>{result.code}</li>
        <li> {result.label}</li>
      </div>
    </div>
  </div>
);

export default IdccDropdownItem;
