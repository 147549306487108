import { useState, useEffect, useLayoutEffect } from "react";
import { t } from "i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { DropdownList } from "react-widgets";
import { nanoid } from "@reduxjs/toolkit";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { createObjAucun } from "utils/localReferentials";
import axios from "axios";
import {
  getAllCompaniesWithoutToken,
  getSiretThunkWithoutToken,
  getDunsThunkWithoutToken,
  postCompanyThunkWithoutToken,
  postEmployeeThunkWithoutToken,
  activateEmployeeThunkWithoutToken,
  getAllCompaniesWithoutTokenPostCompanyPending,
  getAllCompaniesWithoutTokenPostEmployeePending,
  selectAllCompaniesWithoutTokenPending,
} from "app/slices/checkDetailsCompanyWithoutToken/getAllCompaniesWithoutToken.slice";
import {
  getEmployeeDetailsWithoutToken,
  getEmployeesThunkWithoutToken,
} from "app/slices/checkDetailsCompanyWithoutToken/checkDetailsCompanyWithoutToken.slice";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";
import { useNavigate } from "react-router-dom";
import Loader from "components/Loader/Loader";
import { removeEverythingExceptNumbers } from "utils/tools";
import HeaderContract from "./components/HeaderContract/HeaderContract";
import ButtonSubmit from "./components/ButtonSubmit/ButtonSubmit";

import codeNafSvg from "./assets/codeNaf.svg";
import { ReactComponent as Pencil } from "./assets/search.svg";

import styles from "./Register.module.scss";

const RegisterENTNotAuthenticated = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const objAucun = createObjAucun();
  const referencialData = useSelector(getReferentialsData);
  const companyEmployees = useSelector(getEmployeeDetailsWithoutToken);
  const allCompanies = useSelector(getAllCompaniesWithoutToken);
  const pendingPostCompany = useSelector(getAllCompaniesWithoutTokenPostCompanyPending);
  const pendingPostEmployee = useSelector(getAllCompaniesWithoutTokenPostEmployeePending);
  const pending = useSelector(selectAllCompaniesWithoutTokenPending);
  const [employeeInAd, setEmployeeInAd] = useState(false);
  const [firstCall, setFirstCall] = useState(false);
  const [secondCall, setSecondCall] = useState(false);
  const [companyActive, setCompanyActive] = useState(true);
  const [errorCode, setErrorCode] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [inputValue3, setInputValue3] = useState("");
  const [country, setCountry] = useState("FR");
  const [registerToken] = useState(axios.CancelToken.source());
  const [apiResponse, setApiResponse] = useState([]);
  const [apiResponse3, setApiResponse3] = useState([]);
  const [apiResponse2, setApiResponse2] = useState([]);
  const [dunsResponse, setDunsResponse] = useState([]);
  const [siretResponse, setSiretResponse] = useState([]);
  const [goToNewContactConfirmation, setGoToNewContactConfirmation] = useState(false);
  const [goToExistingContactConfirmation] = useState(false);
  const [companyActiveDuns, setCompanyActiveDuns] = useState(true);

  useEffect(() => {
    setFirstCall(false);
    setSecondCall(false);
    setCountry("FR");
  }, []);
  useEffect(() => {
    if (goToNewContactConfirmation) navigate("/inscription-confirmation");
  }, [goToNewContactConfirmation]);
  useEffect(() => {
    if (goToExistingContactConfirmation) navigate("/inscription-dejainscrit");
  }, [goToExistingContactConfirmation]);
  const HandleInput3Change = async (event) => {
    const { value } = event.target;
    setInputValue3(value);
    if (value.length > 2) {
      const response = await axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_COMPANY}/v1/companies/activities?code=*${value}*`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          // cancelToken: axiosCancelToken.token,
        },
      );
      setApiResponse3(response.data);
      console.log("this is the DAta", response.data);
    } else {
      setApiResponse2([]);
    }
  };
  const HandleInput2Change = async (event) => {
    const { value } = event.target;
    setInputValue2(value);
    if (value.length > 2) {
      const response = await axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_COMPANY}/v1/companies/collective-agreements?code=*${value}*`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          // cancelToken: axiosCancelToken.token,
        },
      );
      setApiResponse2(response.data);
      console.log("this is the DAta", response.data);
    } else {
      setApiResponse2([]);
    }
  };
  const handleInputChange = async (event) => {
    const { value } = event.target;
    setInputValue(value);
    if (value.length > 2) {
      const response = await axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_COMPANY}/v1/companies/legal-forms?code=*${value}*`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          // cancelToken: axiosCancelToken.token,
        },
      );
      setApiResponse(response.data);
      console.log("this is the DAta", response.data);
    } else {
      setApiResponse([]);
    }
  };
  const handleOptionSelect = (option) => {
    setFieldValue("catJuridique", option.code);
    setInputValue(option.code);
    console.log(values.catJuridique);
    setApiResponse([]);
  };
  const handleOption2Select = (option) => {
    setFieldValue("codeIdcc", option.code);
    setInputValue2(option.code);
    console.log(values.codeIdcc);
    setApiResponse2([]);
  };
  const handleOption3Select = (option) => {
    setFieldValue("codeNaf", option.code);
    setFieldValue("descriptionNaf", option.label);
    setInputValue3(option.code);
    console.log(values.codeNaf);
    setApiResponse3([]);
  };
  const schemaSiret = Yup.string().when([], {
    is: () => values.country.key === "FR",
    then: (schema) =>
      schema
        .matches(/^[0-9]+$/, `${t("app.errorMessages.siretFormatNumber")}`)
        .min(14, `${t("app.errorMessages.siretFormatMinimum")}`)
        .max(14, `${t("app.errorMessages.siretFormatMaximum")}`)
        .required(`${t("app.errorMessages.RemplirChamp")}`),
  });

  const schemaDuns = Yup.string().when([], {
    is: () => values.country.key !== "FR",
    then: (schema) =>
      schema
        .matches(/^[0-9]+$/, `${t("app.errorMessages.dunsFormatNumber")}`)
        .min(9, `${t("app.errorMessages.dunsFormatMinimum")}`)
        .max(9, `${t("app.errorMessages.dunsFormatMaximum")}`)
        .required(`${t("app.errorMessages.RemplirChamp")}`),
  });
  const contactSchema = Yup.object().shape({
    isValidPhone: Yup.boolean(),
    catJuridique: Yup.string().when("notMandatory", {
      is: errorCode === "503" || true,
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    codeNaf: Yup.string().when("notMandatory", {
      is: errorCode === "503" || true,
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    descriptionNaf: Yup.string().when("notMandatory", {
      is: errorCode === "503" || true,
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    companyFrName: Yup.string().when("notMandatory", {
      is: errorCode === "503" || true,
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    typeOrganisation: Yup.object().when("notMandatory", {
      is: errorCode === "503" || true,
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    companyFrAdresse: Yup.string().when("notMandatory", {
      is: errorCode === "503" || true,
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    companyFrZipCode: Yup.string().when("notMandatory", {
      is: errorCode === "503" || true,
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    companyFrCity: Yup.string().when("notMandatory", {
      is: errorCode === "503" || true,
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    country: Yup.object({
      key: Yup.string(),
      label: Yup.string(),
    }).test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    siret: schemaSiret,
    duns: schemaDuns,
    companyName: Yup.string().when([], {
      is: () => values.country.key !== "FR",
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    companyAdress: Yup.string().when([], {
      is: () => values.country.key !== "FR",
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    companyZipCode: Yup.string().when([], {
      is: () => values.country.key !== "FR",
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    companyCity: Yup.string().when([], {
      is: () => values.country.key !== "FR",
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    email: Yup.string()
      .email(`${t("app.errorMessages.EmailInvalid")}`)
      .required(`${t("app.errorMessages.RemplirChamp")}`),
    civility: Yup.object().when([], {
      is: () => companyEmployees?.length < 1,
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    lastname: Yup.string().when([], {
      is: () => companyEmployees?.length < 1,
      then: Yup.string()
        .max(80)
        .required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    name: Yup.string().when([], {
      is: () => companyEmployees?.length < 1,
      then: Yup.string()
        .max(80)
        .required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    phone: Yup.string().when([], {
      is: () =>
        // values.phone !== undefined &&
        // values.phone !== "" &&
        !isValidPhoneNumber(values.phone) && companyEmployees?.length === 0,
      then: (schema) =>
        schema
          .test(
            "if-is-aucun",
            t("app.errorMessages.PhoneFormat"),
            (value) => value !== undefined && isValidPhoneNumber(value) !== false,
          )
          .required(t("app.errorMessages.RemplirChamp")),
      // otherwise: (schema) => schema.notRequired(),
    }),
    // .required(t("app.errorMessages.RemplirChamp")),
  });

  console.log("ALL COMPANIES", allCompanies);
  const onSubmit = (values) => {
    if (!companyActive) return;
    if (!companyActiveDuns) return;
    const DataToPost =
      errorCode !== "503"
        ? {
            siret: values.country?.key === "FR" ? values.siret : null,
            duns: values.country?.key === "FR" ? null : parseInt(values.duns, 10),
            name: values.country?.key === "FR" ? values.companyFrName : values.companyName,
            addresses: {
              headquarters: {
                street: values.country?.key === "FR" ? values.companyFrAdresse : values.companyAdress,
                additionalAddress: null,
                zipCode: values.country?.key === "FR" ? values.companyFrZipCode : values.companyZipCode,
                city: values.country?.key === "FR" ? values.companyFrCity : values.companyCity,
                country: values.country?.key,
              },
            },
            activities: values.country?.key === "FR" ? [values.codeNaf] : null,
            collectiveAgreement: values.country?.key === "FR" ? values.codeIdcc : null,
            legalForm: values.country?.key === "FR" ? values.catJuridique : null,
            active:
              allCompanies?.map((e) => e.active).join("") !== ""
                ? allCompanies?.map((e) => e.active).join() === "true"
                  ? false
                  : true
                : false,
          }
        : {
            siret: values.country?.key === "FR" ? values.siret : null,
            duns: values.country?.key === "FR" ? null : parseInt(values.duns, 10),
            name: values.country?.key === "FR" ? values.companyFrName : values.companyName,
            addresses: {
              headquarters: {
                street: values.country?.key === "FR" ? values.companyFrAdresse : values.companyAdress,
                additionalAddress: null,
                zipCode: values.country?.key === "FR" ? values.companyFrZipCode : values.companyZipCode,
                city: values.country?.key === "FR" ? values.companyFrCity : values.companyCity,
                country: values.country?.key,
              },
            },
            activities: values.country?.key === "FR" ? [values.codeNaf] : null,
            collectiveAgreement: values.country?.key === "FR" ? values.codeIdcc : null,
            legalForm: values.country?.key === "FR" ? values.catJuridique : null,
            active:
              allCompanies?.map((e) => e.active).join("") !== ""
                ? allCompanies?.map((e) => e.active).join() === "true"
                  ? false
                  : true
                : false,
          };
    const employeeDataToPostWithOnlineAccess = {
      civility: values.civility?.key,
      email: values.email,
      name: values.name,
      firstname: values.lastname,
      phones: {
        office: values.phone,
        cellular: values.phone,
      },
      address: {
        street: values.country?.key === "FR" ? values.companyFrAdresse : values.companyAdress,
        additionalAddress: null,
        zipCode: values.country?.key === "FR" ? values.companyFrZipCode : values.companyZipCode,
        city: values.country?.key === "FR" ? values.companyFrCity : values.companyCity,
        country: values.country?.key,
      },
      withOnlineAccess: true,
    };

    (async () => {
      let newCompanyIdForEmployee = null;
      let newEmployeeId = null;
      try {
        if (siretNotFound || !companyActive) return;

        // FRENCH COMPANY
        if (values.country.key === "FR" || country === "FR") {
          if ((allCompanies?.map((e) => e.id).join("") === "" || errorCode === "503") && !pendingPostCompany) {
            await dispatch(
              postCompanyThunkWithoutToken({
                dataToSend: DataToPost,
                axiosCancelToken: registerToken,
              }),
            )
              .unwrap()
              .then((res) => {
                newCompanyIdForEmployee = res.id;
              });
          }
          if (
            companyEmployees?.length === 0 &&
            (!employeeInAd || employeeInAd === "false") &&
            (allCompanies?.map((e) => e.id).join() !== "" || newCompanyIdForEmployee) &&
            !pendingPostEmployee
          ) {
            await dispatch(
              postEmployeeThunkWithoutToken({
                dataToSend: employeeDataToPostWithOnlineAccess,
                id: newCompanyIdForEmployee
                  ? newCompanyIdForEmployee
                  : parseInt(
                      allCompanies.map((e) => e.id),
                      10,
                    ),
                axiosCancelToken: registerToken,
              }),
            )
              .unwrap()
              .then((res) => {
                newEmployeeId = res.id;
              });
            setGoToNewContactConfirmation(true);
          }

          if (
            companyEmployees?.length > 0 &&
            companyEmployees?.length < 2 &&
            (!employeeInAd || employeeInAd === "false") &&
            (allCompanies?.map((e) => e.id).join() !== "" || newCompanyIdForEmployee)
          ) {
            await dispatch(
              activateEmployeeThunkWithoutToken({
                companyId: newCompanyIdForEmployee || allCompanies?.map((e) => e.id).join(""),
                companyEmployeeId: companyEmployees?.map((e) => e.id).join(""),
                axiosCancelToken: registerToken,
              }),
            ).unwrap();
            setGoToNewContactConfirmation(true);
          } else if (
            companyEmployees?.length > 1 &&
            (!employeeInAd || employeeInAd === "false") &&
            (allCompanies?.map((e) => e.id).join() !== "" || newCompanyIdForEmployee)
          ) {
            await dispatch(
              activateEmployeeThunkWithoutToken({
                companyId: newCompanyIdForEmployee || allCompanies?.map((e) => e.id).join(""),
                companyEmployeeId: companyEmployees[0].id,
                axiosCancelToken: registerToken,
              }),
            ).unwrap();
            setGoToNewContactConfirmation(true);
          }

          if (newEmployeeId && newCompanyIdForEmployee) {
            setGoToNewContactConfirmation(true);
          } else if (newEmployeeId && allCompanies?.map((e) => e.id).join("") !== "") {
            setGoToNewContactConfirmation(true);
          } else if (newCompanyIdForEmployee && companyEmployees?.map((e) => e.id).join("") !== "") {
            setGoToNewContactConfirmation(true);
          } else if (
            allCompanies?.map((e) => e.id).join("") !== "" &&
            companyEmployees?.map((e) => e.id).join("") !== "" &&
            employeeInAd === "false"
          ) {
            await dispatch(
              activateEmployeeThunkWithoutToken({
                companyId: newCompanyIdForEmployee || allCompanies?.map((e) => e.id).join(""),
                companyEmployeeId: companyEmployees?.map((e) => e.id).join(""),
                axiosCancelToken: registerToken,
              }),
            ).unwrap();
            setGoToNewContactConfirmation(true);
          } else if (
            (employeeInAd === "true" || employeeInAd) &&
            allCompanies?.map((e) => e.id).join("") !== "" &&
            companyEmployees?.map((e) => e.id).join("") !== ""
          ) {
            setGoToNewContactConfirmation(true);
          }
        }

        // NOT A FRENCH COMPANY
        if (values.country.key !== "FR" || country !== "FR") {
          if (dunsResponse?.length === 0 && !pendingPostCompany) {
            await dispatch(
              postCompanyThunkWithoutToken({
                dataToSend: DataToPost,
                axiosCancelToken: registerToken,
              }),
            )
              .unwrap()
              .then((res) => {
                newCompanyIdForEmployee = res.id;
              });
          }

          if (
            companyEmployees?.length === 0 &&
            (!employeeInAd || employeeInAd === "false") &&
            (allCompanies?.map((e) => e.id).join() !== "" || newCompanyIdForEmployee) &&
            !pendingPostEmployee
          ) {
            await dispatch(
              postEmployeeThunkWithoutToken({
                dataToSend: employeeDataToPostWithOnlineAccess,
                id: newCompanyIdForEmployee
                  ? newCompanyIdForEmployee
                  : parseInt(
                      allCompanies.map((e) => e.id),
                      10,
                    ),
                axiosCancelToken: registerToken,
              }),
            )
              .unwrap()
              .then((res) => {
                newEmployeeId = res.id;
              });
            setGoToNewContactConfirmation(true);
          }

          if (
            companyEmployees?.length > 0 &&
            (!employeeInAd || employeeInAd === "false") &&
            (allCompanies?.map((e) => e.id).join() !== "" || newCompanyIdForEmployee)
          ) {
            await dispatch(
              activateEmployeeThunkWithoutToken({
                companyId: newCompanyIdForEmployee || allCompanies?.map((e) => e.id).join(""),
                companyEmployeeId: companyEmployees?.map((e) => e.id).join(""),
                axiosCancelToken: registerToken,
              }),
            ).unwrap();
            setGoToNewContactConfirmation(true);
          }

          if (newEmployeeId && newCompanyIdForEmployee) {
            setGoToNewContactConfirmation(true);
          } else if (newEmployeeId && allCompanies?.map((e) => e.id).join("") !== "") {
            setGoToNewContactConfirmation(true);
          } else if (newCompanyIdForEmployee && companyEmployees?.map((e) => e.id).join("") !== "") {
            setGoToNewContactConfirmation(true);
          } else if (
            allCompanies?.map((e) => e.id).join("") !== "" &&
            companyEmployees?.map((e) => e.id).join("") !== "" &&
            employeeInAd === "false"
          ) {
            await dispatch(
              activateEmployeeThunkWithoutToken({
                companyId: newCompanyIdForEmployee || allCompanies?.map((e) => e.id).join(""),
                companyEmployeeId: companyEmployees?.map((e) => e.id).join(""),
                axiosCancelToken: registerToken,
              }),
            ).unwrap();
            setGoToNewContactConfirmation(true);
          } else if (
            (employeeInAd === "true" || employeeInAd) &&
            allCompanies?.map((e) => e.id).join("") !== "" &&
            companyEmployees?.map((e) => e.id).join("") !== ""
          ) {
            setGoToNewContactConfirmation(true);
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    })();
  };

  console.log("siretResponse", siretResponse);
  console.log("dunsResponse", dunsResponse);
  console.log("companyEmployees", companyEmployees);
  const setFieldHandlerSiret = (value) => {
    if (value.length > 0) {
      setFieldValue("catJuridique", value.map((e) => e?.legalForm?.code).join());
      setFieldValue(
        "codeIdcc",
        value.map((e) => e?.id).join() === "" ? "" : value.map((e) => e?.collectiveAgreement?.code).join(),
      );
      setFieldValue(
        "codeNaf",
        value.map((e) => e?.activities?.map((a) => a?.code).join()).join()
          ? value.map((e) => e?.activities?.map((a) => a?.code).join()).join()
          : null,
      );
      setFieldValue("companyFrCity", value.map((e) => e?.addresses?.headquarters?.city).join());
      setFieldValue("companyFrAdresse", value.map((e) => e?.addresses?.headquarters?.street).join());
      setFieldValue("companyFrName", value.map((e) => e?.name).join());
      setFieldValue("companyFrZipCode", value.map((e) => e?.addresses?.headquarters?.zipCode).join());
      setFieldValue("descriptionNaf", value.map((e) => e?.activities?.map((a) => a?.label).join()).join());
      setFieldValue("active", value.map((e) => e?.active).join());
      setFieldValue("companyName", "");
      setFieldValue("companyAdress", "");
      setFieldValue("companyZipCode", "");
      setFieldValue("duns", "");
      setFieldValue("notMandatory", false);
    } else {
      setFieldValue("catJuridique", "");
      setFieldValue("codeIdcc", "");
      setFieldValue("codeNaf", "");
      setFieldValue("companyFrAdresse", "");
      setFieldValue("companyFrName", "");
      setFieldValue("companyFrZipCode", "");
      setFieldValue("companyFrCity", "");
      setFieldValue("descriptionNaf", "");
      setFieldValue("companyName", "");
      setFieldValue("companyAdress", "");
      setFieldValue("companyCity", "");
      setFieldValue("companyZipCode", "");
      setFieldValue("duns", "");
      setFieldValue("notMandatory", true);
    }
  };
  const setFieldHandlerDuns = (value) => {
    if (value.length > 0) {
      setFieldValue("companyName", value.map((e) => e?.name).join());
      setFieldValue("companyAdress", value.map((e) => e?.addresses?.headquarters?.street).join());
      setFieldValue("companyCity", value.map((e) => e?.addresses?.headquarters?.city).join());
      setFieldValue("companyZipCode", value.map((e) => e?.addresses?.headquarters?.zipCode).join());
      setFieldValue("catJuridique", "");
      setFieldValue("codeNaf", "");
      setFieldValue("companyFrAdresse", "");
      setFieldValue("companyFrName", "");
      setFieldValue("companyFrZipCode", "");
      setFieldValue("companyFrCity", "");
      setFieldValue("descriptionNaf", "");
      setFieldValue("codeIdcc", "");
      setFieldValue("siret", "");
      setFieldValue("notMandatory", false);
    } else {
      setFieldValue("companyName", "");
      setFieldValue("companyAdress", "");
      setFieldValue("companyCity", "");
      setFieldValue("companyZipCode", "");
      setFieldValue("notMandatory", false);
      setFieldValue("catJuridique", "");
      setFieldValue("codeNaf", "");
      setFieldValue("companyFrAdresse", "");
      setFieldValue("companyFrName", "");
      setFieldValue("companyFrCity", "");
      setFieldValue("companyFrZipCode", "");
      setFieldValue("descriptionNaf", "");
      setFieldValue("codeIdcc", "");
      setFieldValue("siret", "");
    }
  };
  const setFieldHandlerMail = (value) => {
    if (value.length > 0 && value.length < 2) {
      setFieldValue("name", value.map((e) => e?.name).join());
    } else if (value.length > 1) {
      setFieldValue("name", value[0].firstname);
      setFieldValue("lastname", value[0].name);
    } else {
      setFieldValue("name", "");
    }
  };
  const { values, errors, touched, setFieldValue, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      country: { key: "FR", label: "France" },
      siret: "",
      duns: "",
      companyName: "",
      companyAdress: "",
      companyZipCode: "",
      companyCity: "",
      email: "",
      civility: { ...objAucun },
      name: "",
      lastname: "",
      phone: "",
      startDate: null,
      contract: { ...objAucun },
      catJuridique: "",
      codeNaf: "",
      descriptionNaf: "",
      companyFrName: "",
      codeIdcc: "",
      typeOrganisation: { ...objAucun },
      companyFrAdresse: "",
      companyFrZipCode: "",
      companyFrCity: "",
    },
    validationSchema: contactSchema,
    onSubmit,
  });
  const validCountry = touched.country && errors.country;
  const validSiret = touched.siret && errors.siret;
  const siretNotFound = siretResponse?.length === 0 && firstCall;
  const validEmail = touched.email && errors.email;
  const validName = touched.name && errors.name;
  const validLastname = touched.lastname && errors.lastname;
  const validCivility = touched.civility && errors.civility;
  const validPhone = touched.phone && errors.phone;
  const validDuns = touched.duns && errors.duns;
  const validCompanyName = touched.companyName && errors.companyName;
  const validCompanyAdress = touched.companyAdress && errors.companyAdress;
  const validCompanyZipCode = touched.companyZipCode && errors.companyZipCode;
  const validCompanyCity = touched.companyCity && errors.companyCity;
  const validOrganisation = touched.typeOrganisation && errors.typeOrganisation;
  const validCompanyFrName = touched.companyFrName && errors.companyFrName;
  const validcompanyFrAdresse = touched.companyFrAdresse && errors.companyFrAdresse;
  const validcompanyFrZipCode = touched.companyFrZipCode && errors.companyFrZipCode;
  const validcompanyFrCity = touched.companyFrCity && errors.companyFrCity;
  const validcatJuridique = touched.catJuridique && errors.catJuridique;
  const validcodeNaf = touched.codeNaf && errors.codeNaf;
  const validDescriptionNaf = touched.descriptionNaf && errors.descriptionNaf;
  const validcodeIdcc = touched.codeIdcc && errors.codeIdcc;

  const handleEmployeesCalls = (email) => {
    if (errors.email) {
      return;
    }
    (async () => {
      let responseEmployees = null;
      try {
        if (allCompanies !== undefined) {
          await dispatch(
            getEmployeesThunkWithoutToken({
              email,
              axiosCancelToken: registerToken,
            }),
          )
            .unwrap()
            .then((res) => {
              console.log("resgros", res);
              if (
                res?.length < 2 &&
                res.find(({ withOnlineAccess }) => withOnlineAccess === true || withOnlineAccess === false) ===
                  undefined
              ) {
                setEmployeeInAd(false);
              } else if (
                (res?.length > 1 && res[0]?.withOnlineAccess === false) ||
                res[0]?.withOnlineAccess === undefined
              ) {
                setEmployeeInAd(false);
              } else {
                setEmployeeInAd(res.map((e) => e?.withOnlineAccess).join());
              }
              responseEmployees = res;
              setFieldHandlerMail(responseEmployees);
            });
          console.log("get success Employees !");
        }
      } catch (error) {
        console.log("get fail Employees !", error.message);
      }
    })();
  };

  const setDunsResponseToNull = (value) => {
    if (value === "FR") {
      setDunsResponse([]);
    }
  };
  const setSiretResponseToNull = (value) => {
    if (value !== "FR") {
      setSiretResponse([]);
    }
  };
  const handleSiretCalls = (siret) => {
    (async () => {
      // const isSiret = true;
      let responseForSiretTrue = null;
      try {
        await dispatch(
          getSiretThunkWithoutToken({
            siret,
            axiosCancelToken: registerToken,
          }),
        )
          .unwrap()
          .then((res) => {
            responseForSiretTrue = res;
            setCompanyActive(JSON.parse(res.map((e) => e.active).join()));
            setSiretResponse(responseForSiretTrue);
            setFieldHandlerSiret(responseForSiretTrue);
          })
          .catch((err) => setErrorCode(err.code));
        setFirstCall(true);
        if (allCompanies?.length === 0) {
          await dispatch(
            getSiretThunkWithoutToken({
              siret,
              axiosCancelToken: registerToken,
            }),
          )
            .unwrap()
            .then((res) => {
              responseForSiretTrue = res;
              setCompanyActive(JSON.parse(res.map((e) => e.active).join()));
              setSiretResponse(responseForSiretTrue);
              setFieldHandlerSiret(responseForSiretTrue);
            })
            .catch((err) => setErrorCode(err.code));
        }
      } catch (error) {
        console.log(error.message);
        setErrorCode(error.message.split(" ")[5]);
        if (errorCode === "503") {
          try {
            dispatch(
              getSiretThunkWithoutToken({
                siret,
                axiosCancelToken: registerToken,
              }),
            )
              .unwrap()
              .then((res) => setFieldHandlerSiret(res));
            console.log("get success SIRET !");
          } catch (err) {
            console.log("get siret failed ", err.message);
          }
        }
      }
    })();
  };
  const handleDunsCalls = (duns) => {
    let responseDuns = null;
    setCompanyActiveDuns(true);
    (async () => {
      try {
        await dispatch(
          getDunsThunkWithoutToken({
            duns,
            axiosCancelToken: registerToken,
          }),
        )
          .unwrap()
          .then((res) => {
            responseDuns = res;
            console.log("responseDuns", responseDuns);
            if (!responseDuns?.[0]?.active && responseDuns.length > 0) {
              setCompanyActiveDuns(false);
            }
          });
        setDunsResponse(responseDuns);
        setFieldHandlerDuns(responseDuns);
        if (responseDuns?.length > 0) {
          setSecondCall(false);
        } else {
          setSecondCall(true);
        }
        console.log("get success DUNS !");
      } catch (error) {
        console.log("get fail DUNS !", error.message);
      }
    })();
  };

  useLayoutEffect(() => {
    if (values.siret.length === 14 && !errors.siret) {
      handleSiretCalls(values.siret);
    }
  }, [values.siret, errors.siret]);

  useLayoutEffect(() => {
    if (values.duns.length === 9 && !errors.duns) {
      handleDunsCalls(values.duns);
    }
  }, [values.duns, errors.duns]);

  return (
    referencialData && (
      <div className={styles.container}>
        {(pending || pendingPostCompany || pendingPostEmployee) && <Loader />}
        <HeaderContract />
        <div className={styles.wrapper}>
          <div className={styles.wrapper__colLeft}>
            <form onSubmit={handleSubmit}>
              <div className={styles.header}>
                <h2 className={styles.headerTitle}>{t("app.autoInscription.InformationSurVotreOrga")}</h2>
              </div>
              <div className={styles.boxWrapper}>
                <div className={styles.formGroup}>
                  <div className={styles.Dropdown_label_plus_input_container}>
                    <label htmlFor="country">{t("app.StepThree.PaysDeLentreprise")}</label>
                    <DropdownList
                      value={values.country.key}
                      data={[{ ...objAucun }, ...(referencialData?.COUNTRY || [])]}
                      dataKey="key"
                      textField="label"
                      id="country"
                      style={{ marginTop: "5px" }}
                      className={`${validCountry && `${styles.errorInput}`} dropdown_customized`}
                      onChange={(value) => {
                        setCountry(value.key);
                        setDunsResponseToNull(value.key);
                        setSiretResponseToNull(value.key);
                        setFieldValue("country", value);
                      }}
                    />
                  </div>
                  {validCountry && <span className={styles.error}>{errors.country}</span>}
                </div>
                <div id="active"></div>
                {values.country?.key === "FR" ? (
                  <>
                    <div className={`${styles.formGroup} input-like-rw`}>
                      <label htmlFor="siret">{t("app.StepThree.SiretEntreprise")}</label>
                      <input
                        type="text"
                        name="siret"
                        id="siret"
                        value={values.siret}
                        className={
                          `${validSiret && `${styles.errorInput}`}` || `${siretNotFound && `${styles.errorInput}`}`
                        }
                        onChange={(e) => {
                          setFieldValue("siret", removeEverythingExceptNumbers(e.target.value));
                        }}
                      />
                      {validSiret ? (
                        <span className={styles.error}>{errors.siret}</span>
                      ) : siretResponse?.length === 0 && firstCall ? (
                        <span className={styles.error}>{`${t("app.errorMessages.siretNotFound")}`}</span>
                      ) : (
                        <span className={styles.error}>{errors.siret}</span>
                      )}
                      {!companyActive && (
                        <span className={styles.error}>{`${t("app.errorMessages.companyInactiv")}`}</span>
                      )}
                    </div>
                    {errorCode !== "503" || firstCall ? (
                      <>
                        {siretResponse?.length > 0 && !validSiret && (
                          <div className={styles.listInfo}>
                            <span className={styles.listInfo__name}>{t("app.autoInscription.raisonSociale")}</span>
                            <span className={styles.info}>
                              {siretResponse !== undefined && siretResponse?.length === 1 && companyActive
                                ? siretResponse?.map((e) => e?.name).join()
                                : ""}
                            </span>
                          </div>
                        )}
                        {siretResponse?.length > 0 && !validSiret && (
                          <div className={styles.listInfo}>
                            <span className={styles.listInfo__name}>{t("app.autoInscription.rue")}</span>
                            <span className={styles.info}>
                              {siretResponse !== undefined && siretResponse?.length === 1 && companyActive
                                ? siretResponse?.map((e) => e?.addresses?.headquarters?.street).join()
                                : ""}
                            </span>
                          </div>
                        )}
                        {siretResponse?.length > 0 && (
                          <div className={styles.listInfo}>
                            <span className={styles.listInfo__name}>{t("app.StepThree.CodePostal")}</span>
                            <span className={styles.info}>
                              {siretResponse !== undefined && siretResponse?.length === 1 && companyActive
                                ? siretResponse?.map((e) => e?.addresses?.headquarters?.zipCode).join()
                                : ""}
                            </span>
                          </div>
                        )}
                        {siretResponse?.length > 0 && (
                          <div className={styles.listInfo}>
                            <span className={styles.listInfo__name}>{t("app.StepThree.Ville")}</span>
                            <span className={styles.info}>
                              {siretResponse !== undefined && siretResponse?.length === 1 && companyActive
                                ? siretResponse?.map((e) => e?.addresses?.headquarters?.city).join()
                                : ""}
                            </span>
                          </div>
                        )}
                        {siretResponse?.length > 0 && (
                          <div className={styles.listInfo}>
                            <span className={styles.listInfo__name}>{t("app.StepThree.categorieJuridique")}</span>
                            <span className={styles.info}>
                              {siretResponse !== undefined && siretResponse?.length === 1 && companyActive
                                ? siretResponse?.map((e) => e?.legalForm?.code).join()
                                : ""}
                            </span>
                          </div>
                        )}
                        {siretResponse?.length > 0 && (
                          <div className={styles.listInfo}>
                            <span className={styles.listInfo__name}>{t("app.StepThree.CodeNaf")}</span>
                            <span className={styles.info}>
                              {siretResponse !== undefined &&
                              siretResponse?.length === 1 &&
                              companyActive &&
                              siretResponse?.map((e) => e?.activities?.map((a) => a?.code).join()).join()
                                ? siretResponse?.map((e) => e?.activities?.map((a) => a?.code).join()).join()
                                : ""}
                            </span>
                          </div>
                        )}
                        {siretResponse?.length > 0 && (
                          <div className={styles.listInfo}>
                            <span className={styles.listInfo__name}>{t("app.StepThree.descriptionNaf")}</span>
                            <span className={styles.info}>
                              {siretResponse !== undefined &&
                              siretResponse?.length === 1 &&
                              companyActive &&
                              siretResponse?.map((e) => e?.activities?.map((a) => a?.code).join()).join()
                                ? siretResponse?.map((e) => e?.activities?.map((a) => a?.label).join()).join()
                                : ""}
                            </span>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="companyFrName">{t("app.autoInscription.raisonSociale")}</label>
                          <input
                            type="text"
                            name="companyFrName"
                            id="companyFrName"
                            value={values.companyFrName}
                            className={`${errors.companyFrName && touched.companyFrName && `${styles.errorInput}`}`}
                            onChange={(e) => {
                              setFieldValue("companyFrName", e.target.value);
                            }}
                          />
                          {validCompanyFrName && <span className={styles.error}>{errors.companyFrName}</span>}
                        </div>
                        <div className={styles.formGroup}>
                          <div className={styles.Dropdown_label_plus_input_container}>
                            <label htmlFor="typeOrganisation">{t("app.StepThree.typeOrganisation")}</label>
                            <DropdownList
                              value={values.typeOrganisation.key}
                              data={[{ ...objAucun }, ...(referencialData?.COMPANY_TYPE || [])]}
                              dataKey="key"
                              textField="label"
                              id="typeOrganisation"
                              style={{ marginTop: "5px" }}
                              className={`${
                                errors.typeOrganisation && touched.typeOrganisation && `${styles.errorInput}`
                              } dropdown_customized`}
                              onChange={(value) => {
                                setFieldValue("typeOrganisation", value);
                              }}
                            />
                          </div>
                          {validOrganisation && <span className={styles.error}>{errors.typeOrganisation}</span>}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="siret">{t("app.autoInscription.rue")}</label>
                          <input
                            type="text"
                            name="companyFrAdresse"
                            id="companyFrAdresse"
                            value={values.companyFrAdresse}
                            className={`${
                              errors.companyFrAdresse && touched.companyFrAdresse && `${styles.errorInput}`
                            }`}
                            onChange={(e) => {
                              setFieldValue("companyFrAdresse", e.target.value);
                            }}
                          />
                          {validcompanyFrAdresse && <span className={styles.error}>{errors.companyFrAdresse}</span>}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="companyFrZipCode">{t("app.StepThree.CodePostal")}</label>
                          <input
                            type="text"
                            name="companyFrZipCode"
                            id="companyFrZipCode"
                            value={values.companyFrZipCode}
                            className={`${
                              errors.companyFrZipCode && touched.companyFrZipCode && `${styles.errorInput}`
                            }`}
                            onChange={(e) => {
                              setFieldValue("companyFrZipCode", e.target.value);
                            }}
                          />
                          {validcompanyFrZipCode && <span className={styles.error}>{errors.companyFrZipCode}</span>}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="companyFrCity">{t("app.StepThree.Ville")}</label>
                          <input
                            type="text"
                            name="companyFrCity"
                            id="companyFrCity"
                            value={values.companyFrCity}
                            className={`${errors.companyFrCity && touched.companyFrCity && `${styles.errorInput}`}`}
                            onChange={(e) => {
                              setFieldValue("companyFrCity", e.target.value);
                            }}
                          />
                          {validcompanyFrCity && <span className={styles.error}>{errors.companyFrCity}</span>}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="catJuridique">{t("app.StepThree.categorieJuridique")}</label>
                          <div
                            className={`${styles.label_plus_input_container} input-like-rw ${styles.search_container}`}
                          >
                            <input
                              className={`${errors.catJuridique && touched.catJuridique && `${styles.errorInput}`}`}
                              type="text"
                              value={inputValue}
                              onChange={handleInputChange}
                            />
                            <Pencil className={styles.search_icon} />
                          </div>
                          <div className={apiResponse.length > 0 ? styles.output_container : undefined}>
                            <ul>
                              {apiResponse.map((result) => (
                                <div>
                                  <Pencil className={styles.search_icon} />
                                  <div key={nanoid()} className={styles.output_plus_image_Container}>
                                    <img src={codeNafSvg} style={{ width: "1.8rem" }} alt="test"></img>
                                    <div
                                      className={styles.outputKeyLabel}
                                      onKeyDown={(e) => e.key === "Enter" && handleOptionSelect(result)}
                                      tabIndex={0}
                                      role="button"
                                      onClick={() => handleOptionSelect(result)}
                                    >
                                      <li>{result.code}</li>
                                      <li> {result.label}</li>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </ul>
                          </div>
                          {validcatJuridique && <span className={styles.error}>{errors.catJuridique}</span>}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="codeNaf">{t("app.StepThree.CodeNaf")}</label>
                          <div
                            className={`${styles.label_plus_input_container} input-like-rw ${styles.search_container}`}
                          >
                            <input
                              className={`${errors.codeNaf && touched.codeNaf && `${styles.errorInput}`}`}
                              type="text"
                              value={inputValue3}
                              onChange={HandleInput3Change}
                            />
                            <Pencil className={styles.search_icon} />
                          </div>
                          <div className={apiResponse3.length > 0 ? styles.output_container : undefined}>
                            <ul>
                              {apiResponse3.map((result) => (
                                <div>
                                  <Pencil className={styles.search_icon} />
                                  <div key={nanoid()} className={styles.output_plus_image_Container}>
                                    <img src={codeNafSvg} style={{ width: "1.8rem" }} alt="test"></img>
                                    <div
                                      className={styles.outputKeyLabel}
                                      onKeyDown={(e) => e.key === "Enter" && handleOption3Select(result)}
                                      tabIndex={0}
                                      role="button"
                                      onClick={() => handleOption3Select(result)}
                                    >
                                      <li>{result.code}</li>
                                      <li> {result.label}</li>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </ul>
                          </div>
                          {validcodeNaf && <span className={styles.error}>{errors.codeNaf}</span>}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="descriptionNaf">{t("app.StepThree.descriptionNaf")}</label>
                          <input
                            type="text"
                            name="descriptionNaf"
                            id="descriptionNaf"
                            value={values.descriptionNaf}
                            className={`${validDescriptionNaf && `${styles.errorInput}`}`}
                            onChange={(e) => {
                              setFieldValue("descriptionNaf", e.target.value);
                            }}
                          />
                          {validDescriptionNaf && <span className={styles.error}>{errors.descriptionNaf}</span>}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="codeIdcc">{t("app.StepThree.CodeIDCC")}</label>
                          <div
                            className={`${styles.label_plus_input_container} input-like-rw ${styles.search_container}`}
                          >
                            <input
                              className={`${validcodeIdcc && `${styles.errorInput}`}`}
                              type="text"
                              value={inputValue2}
                              onChange={HandleInput2Change}
                            />
                            <Pencil className={styles.search_icon} />
                          </div>
                          <div className={apiResponse2.length > 0 ? styles.output_container : undefined}>
                            <ul>
                              {apiResponse2.map((result) => (
                                <div>
                                  <Pencil className={styles.search_icon} />
                                  <div key={nanoid()} className={styles.output_plus_image_Container}>
                                    <img src={codeNafSvg} style={{ width: "1.8rem" }} alt="test"></img>
                                    <div
                                      className={styles.outputKeyLabel}
                                      onClick={() => handleOption2Select(result)}
                                      onKeyDown={(e) => e.key === "Enter" && handleOption2Select(result)}
                                      tabIndex={0}
                                      role="button"
                                    >
                                      <li>{result.code}</li>
                                      <li> {result.label}</li>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </ul>
                          </div>
                          {validcodeIdcc && <span className={styles.error}>{errors.codeIdcc}</span>}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {dunsResponse?.length < 1 && secondCall ? (
                      <>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="duns">{t("app.autoInscription.DUNS")}</label>
                          <input
                            type="text"
                            name="duns"
                            id="duns"
                            value={values.duns}
                            className={`${validDuns && `${styles.errorInput}`}`}
                            onChange={(e) => {
                              setFieldValue("duns", removeEverythingExceptNumbers(e.target.value));
                            }}
                          />
                          {validDuns && <span className={styles.error}>{errors.duns}</span>}
                          {!companyActiveDuns && !errors.duns && (
                            <span className={styles.error}>{`${t("app.errorMessages.companyInactiv")}`}</span>
                          )}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="companyName">{t("app.autoInscription.raisonSociale")}</label>
                          <input
                            type="text"
                            name="companyName"
                            id="companyName"
                            maxLength={255}
                            value={values.companyName}
                            className={`${validCompanyName && `${styles.errorInput}`}`}
                            onChange={(e) => {
                              setFieldValue("companyName", e.target.value.trimStart());
                            }}
                          />
                          {validCompanyName && <span className={styles.error}>{errors.companyName}</span>}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="companyAdress">{t("app.autoInscription.rue")}</label>
                          <input
                            type="text"
                            name="companyAdress"
                            id="companyAdress"
                            maxLength={255}
                            value={values.companyAdress}
                            className={`${validCompanyAdress && `${styles.errorInput}`}`}
                            onChange={(e) => {
                              setFieldValue("companyAdress", e.target.value.trimStart());
                            }}
                          />
                          {validCompanyAdress && <span className={styles.error}>{errors.companyAdress}</span>}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="companyZipCode">{t("app.StepThree.CodePostal")}</label>
                          <input
                            type="text"
                            name="companyZipCode"
                            id="companyZipCode"
                            maxLength={255}
                            value={values.companyZipCode}
                            className={`${validCompanyZipCode && `${styles.errorInput}`}`}
                            onChange={(e) => {
                              setFieldValue("companyZipCode", e.target.value.trimStart());
                            }}
                          />
                          {validCompanyZipCode && <span className={styles.error}>{errors.companyZipCode}</span>}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="companyCity">{t("app.StepThree.Ville")}</label>
                          <input
                            type="text"
                            name="companyCity"
                            id="companyCity"
                            maxLength={255}
                            value={values.companyCity}
                            className={`${validCompanyCity && `${styles.errorInput}`}`}
                            onChange={(e) => {
                              setFieldValue("companyCity", e.target.value.trimStart());
                            }}
                          />
                          {validCompanyCity && <span className={styles.error}>{errors.companyCity}</span>}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="duns">{t("app.autoInscription.DUNS")}</label>
                          <input
                            type="text"
                            name="duns"
                            id="duns"
                            value={values.duns}
                            className={`${validDuns && `${styles.errorInput}`}`}
                            onBlur={(e) => {
                              handleBlur(e);
                            }}
                            onChange={(e) => {
                              setFieldValue("duns", removeEverythingExceptNumbers(e.target.value));
                            }}
                          />
                          {validDuns && <span className={styles.error}>{errors.duns}</span>}
                          {!companyActiveDuns && !errors.duns && (
                            <span className={styles.error}>{`${t("app.errorMessages.companyInactiv")}`}</span>
                          )}
                        </div>
                        {dunsResponse?.length > 0 && (
                          <div className={styles.listInfo}>
                            <span className={styles.listInfo__name}>{t("app.autoInscription.raisonSociale")}</span>
                            <span className={styles.info}>
                              {dunsResponse !== undefined && dunsResponse?.length === 1
                                ? dunsResponse?.map((e) => e?.name).join()
                                : ""}
                            </span>
                          </div>
                        )}
                        {dunsResponse?.length > 0 && (
                          <div className={styles.listInfo}>
                            <span className={styles.listInfo__name}>{t("app.autoInscription.rue")}</span>
                            <span className={styles.info}>
                              {dunsResponse !== undefined && dunsResponse?.length === 1
                                ? dunsResponse?.map((e) => e?.addresses?.headquarters?.street).join()
                                : ""}
                            </span>
                          </div>
                        )}
                        {dunsResponse?.length > 0 && (
                          <div className={styles.listInfo}>
                            <span className={styles.listInfo__name}>{t("app.StepThree.CodePostal")}</span>
                            <span className={styles.info}>
                              {dunsResponse !== undefined && dunsResponse?.length === 1
                                ? dunsResponse?.map((e) => e?.addresses?.headquarters?.zipCode).join()
                                : ""}
                            </span>
                          </div>
                        )}
                        {dunsResponse?.length > 0 && (
                          <div className={styles.listInfo}>
                            <span className={styles.listInfo__name}>{t("app.StepThree.Ville")}</span>
                            <span className={styles.info}>
                              {dunsResponse !== undefined && dunsResponse?.length === 1
                                ? dunsResponse?.map((e) => e?.addresses?.headquarters?.city).join()
                                : ""}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
              <div className={styles.header}>
                <h2 className={styles.headerTitle}>{t("app.autoInscription.InformationSurVotreContact")}</h2>
              </div>
              <div className={styles.boxWrapper}>
                <div className={`${styles.formGroup} input-like-rw`}>
                  <label htmlFor="email">{t("app.autoInscription.email")}</label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={values.email}
                    onBlur={(e) => {
                      handleEmployeesCalls(e.target.value);
                      setFieldValue("email", e.target.value);
                      handleBlur(e);
                    }}
                    className={`${validEmail && `${styles.errorInput}`}`}
                    onChange={(e) => {
                      handleEmployeesCalls(e.target.value);
                      setFieldValue("email", e.target.value);
                    }}
                  />
                  {validEmail && <span className={styles.error}>{errors.email}</span>}
                </div>
                {companyEmployees !== null && companyEmployees?.length === 1 && touched.email && (
                  <>
                    {companyEmployees?.map((row) => (
                      <>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="lastname">{t("app.autoInscription.prenom")}</label>
                          <input
                            key={nanoid()}
                            id="lastname"
                            name="lastname"
                            type="text"
                            disabled
                            value={row?.firstname}
                          />
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="name">{t("app.autoInscription.nom")}</label>
                          <input key={nanoid()} id="name" name="name" type="text" disabled value={row?.name} />
                        </div>
                      </>
                    ))}
                  </>
                )}
                {companyEmployees !== null && companyEmployees?.length > 1 && touched.email && (
                  <>
                    <div className={`${styles.formGroup} input-like-rw`}>
                      <label htmlFor="lastname">{t("app.autoInscription.prenom")}</label>
                      <input
                        key={nanoid()}
                        id="lastname"
                        name="lastname"
                        type="text"
                        disabled
                        value={companyEmployees[0]?.firstname}
                      />
                    </div>
                    <div className={`${styles.formGroup} input-like-rw`}>
                      <label htmlFor="name">{t("app.autoInscription.nom")}</label>
                      <input
                        key={nanoid()}
                        id="name"
                        name="name"
                        type="text"
                        disabled
                        value={companyEmployees[0]?.name}
                      />
                    </div>
                  </>
                )}
                {companyEmployees !== null && !companyEmployees?.length && touched.email && (
                  <>
                    <div className={`${styles.formGroup} input-like-rw`}>
                      <label htmlFor="lastname">{t("app.autoInscription.civility")}</label>
                      <DropdownList
                        value={values.civility.key}
                        data={[{ ...objAucun }, ...(referencialData?.CIVILITY || [])]}
                        dataKey="key"
                        textField="label"
                        id="civility"
                        style={{ marginTop: "5px" }}
                        className={`${
                          errors.civility && touched.civility && `${styles.errorInput}`
                        } dropdown_customized`}
                        onChange={(value) => {
                          setFieldValue("civility", value);
                        }}
                      />
                      {validCivility && <span className={styles.error}>{errors.civility}</span>}
                    </div>
                    <div className={`${styles.formGroup} input-like-rw`}>
                      <label htmlFor="lastname">{t("app.autoInscription.prenom")}</label>
                      <input
                        id="lastname"
                        name="lastname"
                        type="text"
                        value={values.lastname}
                        onBlur={handleBlur}
                        onChange={(e) => setFieldValue("lastname", e.target.value.trimStart())}
                        className={`${validLastname && `${styles.errorInput}`}`}
                      />
                      {validLastname && <span className={styles.error}>{errors.lastname}</span>}
                    </div>
                    <div className={`${styles.formGroup} input-like-rw`}>
                      <label htmlFor="name">{t("app.autoInscription.nom")}</label>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        value={values.name}
                        onBlur={handleBlur}
                        onChange={(e) => setFieldValue("name", e.target.value.trimStart())}
                        className={`${validName && `${styles.errorInput}`}`}
                      />
                      {validName && <span className={styles.error}>{errors.name}</span>}
                    </div>
                    <div className={`${styles.formGroup} input-like-rw`}>
                      <label htmlFor="phone">{t("app.autoInscription.telephone")}</label>
                      <PhoneInput
                        type="text"
                        name="phone"
                        id="phone"
                        countries={[country]}
                        addInternationalOption={false}
                        defaultCountry={country}
                        value={values.phone}
                        onChange={(e) => {
                          setFieldValue("phone", e);
                        }}
                        className={`${validPhone && `${styles.errorInput}`}`}
                      />
                      {/* <input
                                                    type="text"
                                                    name="phone"
                                                    id="phone"
                                                    value={values.phone}
                                                    onBlur={handleBlur}
                                                    onChange={(e) =>
                                                        setFieldValue("phone", e.target.value.trimStart())
                                                    }
                                                    className={`${validPhone && `${styles.errorInput}`}`}
                                                /> */}
                      {validPhone && <span className={styles.error}>{errors.phone}</span>}
                    </div>
                  </>
                )}
                <div className={styles.action}>
                  <ButtonSubmit
                    text={t("app.autoInscription.ValidezPassezEtapeSuivante")}
                    handleSubmit={handleSubmit}
                    pending={pending}
                    pendingPostCompany={pendingPostCompany}
                    pendingPostEmployee={pendingPostEmployee}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  );
};
export default RegisterENTNotAuthenticated;
