import { useSelector, useDispatch } from "react-redux";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DropdownList } from "react-widgets/cjs";
import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";

import { changeOuiOrNonToBoolean } from "utils/tools";
import { createBooleanReferential, createObjAucun } from "utils/localReferentials";

import { toastWarning } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";
import {
  postStep1Thunk,
  patchStepThunk,
  getDeclareARecruitmentPending,
  getDeclareARecruitmentPreContractualizationInformations,
  getDeclareARecruitmentStudentInformations,
  selectDeclareARecruitmentAllStudentInformations,
  selectDeclareARecruitmentPersonInformations,
} from "app/slices/declareARecruitment/declareARecruitment.slice";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";
import FileModal from "./component/FileModal/FileModal";
import HeaderContract from "../../components/HeaderContract/HeaderContract";

import styles from "./Step1.module.scss";

const Step1 = ({ axiosCancelToken }) => {
  const dispatch = useDispatch();

  const YesNo = createBooleanReferential();
  const objAucun = createObjAucun();
  const personInformations = useSelector(selectDeclareARecruitmentPersonInformations);
  const preContractualizationInformations = useSelector(getDeclareARecruitmentPreContractualizationInformations);
  const allStudentInformations = useSelector(selectDeclareARecruitmentAllStudentInformations);
  const studentInformations = useSelector(getDeclareARecruitmentStudentInformations);
  const referencialData = useSelector(getReferentialsData);
  const pending = useSelector(getDeclareARecruitmentPending);

  const [enabledPrograms, setEnabledPrograms] = useState([]);

  const [file, setFile] = useState(null);

  const [show, setShow] = useState(false);
  const [pathIndicator, setPathIndicator] = useState("STEP1");

  // appStudient is for the case where the student is in an APP or CPRO program
  const [appStudient, setAppStudient] = useState(false);
  const getActualSchoolProgram = (schoolProgram) => {
    const displayedProgram = {
      id: schoolProgram.id,
      label: `${schoolProgram.apprenticeshipProject?.schoolProgramEnrollment?.schoolYear} - ${schoolProgram.apprenticeshipProject?.schoolProgramEnrollment?.product?.trainingSchool?.name}`,
      value: schoolProgram,
    };
    return displayedProgram;
  };

  const generateDisplayedYears = () => {
    let displayedShoolProgram = [];
    const programList = allStudentInformations.map((student) => student);
    programList.forEach((value) => {
      if (value.status !== "ANCIEN") {
        displayedShoolProgram.push({
          id: value.id,
          label: `${value.programEnrollment?.schoolYear} - ${value.programEnrollment?.product?.trainingSchool?.name}`,
          value,
        });
      }
    });
    displayedShoolProgram = displayedShoolProgram?.reverse();
    setEnabledPrograms(displayedShoolProgram);
  };

  const handleOutEuRight = () => {
    if (personInformations?.nationalities?.[0]?.isEuropean) return true;
    if (values.residencePermit?.key === "Oui") {
      return true;
    }
    return false;
  };

  const onSubmit = async (values) => {
    const valuesCopy = structuredClone(values);
    const studentId = valuesCopy.universityYear?.value?.id
      ? valuesCopy.universityYear?.value?.id
      : studentInformations.id;
    changeOuiOrNonToBoolean(valuesCopy);
    let localAppStudient = false;
    const programEnrollement =
      valuesCopy?.universityYear?.value?.apprenticeshipProject?.schoolProgramEnrollment ||
      valuesCopy?.universityYear?.value?.apprenticeshipProject?.programEnrollment ||
      valuesCopy?.universityYear?.value?.programEnrollment;
    const trainingMode = programEnrollement?.trainingMode;
    const dataToSend = {
      stage: "Etape2",
      contractType: valuesCopy.contractType?.key,
      student: {
        id: studentId,
        person: {
          rightToWorkFullTimeInFrance: handleOutEuRight(),
          socialSecurityNumber: personInformations.socialSecurityNumber,
        },
        apprenticeshipProject: {
          schoolProgramEnrollment: {
            product: {
              id: valuesCopy.universityYear?.id,
            },
          },
          freeToBeRecruited: valuesCopy.engagementCheckbox,
        },
      },
    };
    // Checks if the student can declare a recruitment other than an internship.
    if (
      trainingMode?.search("Classique - CL") > -1 ||
      (trainingMode?.search("Passage CAN-CLA") > -1 && valuesCopy.contractType?.key !== "Formation continue")
    ) {
      toastWarning(
        <ToastMessageStructure
          firstMessage={t("app.toastMessages.Attention")}
          secondMessage={t("app.toastMessages.DeclarerUnSeulRecrutementStage")}
        />,
      );
      return;
    }
    // if (!personInformations?.nationalities[0]?.isEuropean && !valuesCopy.residencePermit.key) {
    //   console.log("WARNING POPUP");
    //   toastWarning(
    //     <ToastMessageStructure
    //       firstMessage={t("app.toastMessages.Attention")}
    //       secondMessage={t("app.toastMessages.MerciVousRapprocherServiceRelationsEntreprises")}
    //     />,
    //   );
    //   return;
    // }
    if (trainingMode?.search("APP") !== -1 || trainingMode?.search("CPRO") !== -1) {
      setAppStudient(true);
      localAppStudient = true;
    }
    if (!preContractualizationInformations && !pending && localAppStudient) {
      try {
        dataToSend.stage = "Etape1";
        await dispatch(
          postStep1Thunk({
            dataToSend,
            axiosCancelToken,
          }),
        ).unwrap();
        console.log("post success !");
      } catch (error) {
        console.log("post fail !", error.message);
      }
      console.log("false pré contra next");
    } else if (!preContractualizationInformations && !pending) {
      console.log("false pré contra");
      try {
        await dispatch(
          postStep1Thunk({
            dataToSend,
            axiosCancelToken,
          }),
        ).unwrap();
        console.log("post success !");
      } catch (error) {
        console.log("post fail !", error.message);
      }
      console.log("false pré contra next");
      return;
    }

    if (!localAppStudient || pathIndicator === "MODAL") {
      if (!pending) {
        try {
          await dispatch(
            patchStepThunk({
              dataToSend,
              id: preContractualizationInformations.id,
              axiosCancelToken,
            }),
          ).unwrap();
          console.log("patch success !");
        } catch (error) {
          console.log("patch fail !", error.message);
        }
      }
      setAppStudient(false);
      setPathIndicator("STEP1");
    } else {
      setShow(true);
    }
  };
  const hasRightToWorkInFrance = () => {
    if (preContractualizationInformations?.student?.person?.rightToWorkFullTimeInFrance) {
      return YesNo[1];
    }
    if (!preContractualizationInformations?.student?.person?.rightToWorkFullTimeInFrance) {
      return YesNo[2];
    }
    return YesNo[0];
  };
  const informationSchema = Yup.object().shape({
    contractType: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    universityYear: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    engagementCheckbox: Yup.boolean().test(false, t("app.errorMessages.freeToBeRecruited"), (value) => value !== false),
    residencePermit: Yup.object().when([], {
      is: () => !personInformations?.nationalities?.[0]?.isEuropean,
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
  });
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      contractType: preContractualizationInformations?.contractType
        ? preContractualizationInformations?.contractType
        : { ...objAucun },
      universityYear: preContractualizationInformations?.student?.apprenticeshipProject?.schoolProgramEnrollment
        ? getActualSchoolProgram(preContractualizationInformations?.student)
        : { ...objAucun },
      nationalities:
        preContractualizationInformations?.student?.person?.nationalities?.[0] || personInformations?.nationalities?.[0],
      engagementCheckbox: preContractualizationInformations?.student?.apprenticeshipProject?.freeToBeRecruited
        ? preContractualizationInformations?.student?.apprenticeshipProject?.freeToBeRecruited
        : false,
      residencePermit: hasRightToWorkInFrance(),
    },
    validationSchema: informationSchema,
    onSubmit,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    generateDisplayedYears();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {appStudient && (
        <FileModal
          onClose={() => {
            setPathIndicator("STEP1");
            setShow(false);
            setAppStudient(false);
          }}
          show={show}
          setShow={setShow}
          summit={handleSubmit}
          values={values}
          setFile={setFile}
          actualFile={file}
          appStudient={appStudient}
          pathIndicator={pathIndicator}
          setPathIndicator={setPathIndicator}
          axiosCancelToken={axiosCancelToken}
        />
      )}

      <HeaderContract />

      {referencialData && (
        <section className={styles.main_container}>
          <div className={styles.title_container}>
            <h2>{t("app.declarezUnRecrutement.Informations")}</h2>
            <div>{t("app.declarezUnRecrutement.Etape1Sur7")}</div>
          </div>
          <div className={styles.form_container}>
            <form className={styles.your_need_form} onSubmit={handleSubmit}>
              <div className={styles.inputs_container}>
                <div className={styles.label_plus_input_container}>
                  <div className={styles.label_plus_info_bulle}>
                    <label htmlFor="contractType">
                      {t("app.declarezUnRecrutement.TypeDeContratEnvisageParEntreprise")}
                    </label>
                    <div className={styles.infoBulle}>
                      <svg
                        className="slds-button__icon"
                        focusable="false"
                        data-key="info"
                        aria-hidden="true"
                        viewBox="0 0 52 52"
                        part="icon"
                      >
                        <g>
                          <path d="M26 2C12.7 2 2 12.7 2 26s10.7 24 24 24 24-10.7 24-24S39.3 2 26 2zm0 12.1c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm5 21c0 .5-.4.9-1 .9h-8c-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1.5 0 1-.3 1-.9v-4c0-.5-.4-1.1-1-1.1-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1h6c.5 0 1 .5 1 1.1v8c0 .5.4.9 1 .9.5 0 1 .5 1 1.1v2z"></path>
                        </g>
                      </svg>
                      <div className={styles.infoBulleInfo}>
                        {t("app.declarezUnRecrutement.TypeDeContratInfo")}
                        <a href="https://www.service-public.fr/particuliers/vosdroits/N11240"> Alternance</a>
                      </div>
                    </div>
                  </div>

                  <DropdownList
                    style={
                      errors.contractType &&
                      touched.contractType && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="contractType"
                    dataKey="key"
                    textField="label"
                    data={[{ ...objAucun }, ...referencialData.APPRENTICESHIP_CONTRACT_TYPE]}
                    value={values.contractType}
                    onChange={(newValue) => setFieldValue("contractType", newValue)}
                  />
                  <InputErrorMessage message={errors.contractType} touched={touched.contractType} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <div className={styles.label_plus_info_bulle}>
                    <label htmlFor="contractType">{t("app.declarezUnRecrutement.AnneeUniversitaireConcernee")}</label>
                    <div className={styles.infoBulle}>
                      <svg
                        className="slds-button__icon"
                        focusable="false"
                        data-key="info"
                        aria-hidden="true"
                        viewBox="0 0 52 52"
                        part="icon"
                      >
                        <g>
                          <path d="M26 2C12.7 2 2 12.7 2 26s10.7 24 24 24 24-10.7 24-24S39.3 2 26 2zm0 12.1c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm5 21c0 .5-.4.9-1 .9h-8c-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1.5 0 1-.3 1-.9v-4c0-.5-.4-1.1-1-1.1-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1h6c.5 0 1 .5 1 1.1v8c0 .5.4.9 1 .9.5 0 1 .5 1 1.1v2z"></path>
                        </g>
                      </svg>
                      <div className={styles.infoBulleInfo}>
                        {t("app.declarezUnRecrutement.AnnéeUniversitaireInfo")}
                      </div>
                    </div>
                  </div>
                  <DropdownList
                    style={
                      errors.universityYear &&
                      touched.universityYear && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="universityYear"
                    dataKey="id"
                    textField="label"
                    data={enabledPrograms}
                    value={values.universityYear}
                    onChange={(newValue) => {
                      setFieldValue("universityYear", newValue);
                    }}
                  />
                  <InputErrorMessage message={errors.universityYear} touched={touched.universityYear} />
                </div>
                <div className={styles.label_plus_input_container}>
                  <label htmlFor="nationalities">{t("app.declarezUnRecrutement.Nationalite")}</label>
                  <DropdownList
                    style={
                      errors.nationalities &&
                      touched.nationalities && {
                        border: "2px solid #EA001E",
                        borderRadius: "5px",
                      }
                    }
                    id="nationalities"
                    dataKey="key"
                    textField="label"
                    data={studentInformations.nationalities}
                    disabled
                    defaultValue={personInformations?.nationalities?.[0]?.label}
                    value={values.nationalities}
                  />
                  <InputErrorMessage message={errors.nationalities} touched={touched.nationalities} />
                </div>
                {!personInformations?.nationalities?.[0]?.isEuropean && (
                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="residencePermit">{t("app.declarezUnRecrutement.pas-dans-exel-4")}</label>
                    <DropdownList
                      style={
                        errors.residencePermit &&
                        touched.residencePermit && {
                          border: "2px solid #EA001E",
                          borderRadius: "5px",
                        }
                      }
                      id="residencePermit"
                      dataKey="key"
                      textField="label"
                      data={[...YesNo]}
                      defaultValue={personInformations?.nationalities?.[0]}
                      value={values.residencePermit}
                      onChange={(newValue) => {
                        setFieldValue("residencePermit", newValue);
                      }}
                    />
                    <InputErrorMessage message={errors.residencePermit} touched={touched.residencePermit} />
                  </div>
                )}
                <div className={styles.label_plus_input_container}>
                  <div className={styles.label_plus_info_bulle}>
                    <label htmlFor="contractType">{t("app.declarezUnRecrutement.Engagement")}</label>
                    <div className={styles.infoBulle}>
                      <svg
                        className="slds-button__icon"
                        focusable="false"
                        data-key="info"
                        aria-hidden="true"
                        viewBox="0 0 52 52"
                        part="icon"
                      >
                        <g>
                          <path d="M26 2C12.7 2 2 12.7 2 26s10.7 24 24 24 24-10.7 24-24S39.3 2 26 2zm0 12.1c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm5 21c0 .5-.4.9-1 .9h-8c-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1.5 0 1-.3 1-.9v-4c0-.5-.4-1.1-1-1.1-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1h6c.5 0 1 .5 1 1.1v8c0 .5.4.9 1 .9.5 0 1 .5 1 1.1v2z"></path>
                        </g>
                      </svg>
                      <div className={styles.infoBulleInfo}>{t("app.declarezUnRecrutement.engagementInfo")}</div>
                    </div>
                  </div>
                  <div className={styles.checkbox_container}>
                    <div>
                      <input
                        style={
                          errors.engagementCheckbox &&
                          touched.engagementCheckbox && {
                            border: "2px solid #EA001E",
                          }
                        }
                        id="engagementCheckbox"
                        type="checkbox"
                        checked={values.engagementCheckbox}
                        onChange={(e) => setFieldValue("engagementCheckbox", e.target.checked)}
                      />
                    </div>
                    <div className={styles.checkbox_text}>
                      {t("app.declarezUnRecrutement.EngagementContractuelText")}
                    </div>
                  </div>
                  <InputErrorMessage message={errors.engagementCheckbox} touched={touched.engagementCheckbox} />
                </div>
              </div>
              <SubmitFormBtn text={t("app.declarezUnRecrutement.ValidezEtapeSuivante")} />
            </form>
          </div>
        </section>
      )}
    </>
  );
};

export default Step1;
