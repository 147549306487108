import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { getMeData } from "app/slices/userConnexion/userConnexion.slice";

import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";

import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";

import {
  patchContractDataThunk,
  selectCompanyEditRightsFYC,
  selectContractDataFYC,
  selectLocalCompanyIdFYC,
} from "app/slices/followYourContracts/followYourContracts.slice";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";
import {
  getAllCompanies,
  patchCompanyThunk,
  selectPostAndPatchCompanyIsPending,
} from "app/slices/checkDetailsCompany/getAllCompanies.slice";

import { toastError, toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";

import {
  getEmployeesThunk,
  getCompanyDetailsIsPending,
} from "app/slices/checkDetailsCompany/checkDetailsCompany.slice";
import FormInformationField from "pages/ENT/FollowYourContracts/components/formFields/FormInformationField/FormInformationField";
import { FormDropdownField } from "pages/ENT/FollowYourContracts/components/formFields/FormDropdownField/FormDropdownField";
import SubTitleInForm from "components/typography/SubTitleInForm/SubTitleInForm";
import { FormInputField } from "pages/ENT/FollowYourContracts/components/formFields/FormInputField/FormInputField";
import { FormPhoneField } from "pages/ENT/FollowYourContracts/components/formFields/FormPhoneField/FormPhoneField";
import InformationTextInForm from "components/typography/InformationTextInForm/InformationTextInForm";
import { createBooleanReferential, createObjAucun } from "../../../../../../../../utils/localReferentials";

import styles from "./Form9.module.scss";

const Form9 = ({
  axiosCancelToken,
  submittingAllSections,
  submitNextSectionIfSubmitAllTrue,
  cancelSubmitAllSections,
  currentSectionBeingSubmitted,
  setFormIsValid,
  isOpen,
}) => {
  const dispatch = useDispatch();
  const isEmployeeDataPending = useSelector(getCompanyDetailsIsPending);
  const localCompanyId = useSelector(selectLocalCompanyIdFYC);

  const YesNo = createBooleanReferential();
  const objAucun = createObjAucun();

  const TAKE_INFO_FROM = "INFO_FROM";
  const INFO_ORDER_BILL = "ORDER_BILL";

  const phoneRegExp = "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$";

  const contractData = useSelector(selectContractDataFYC);
  const referencialData = useSelector(getReferentialsData);
  const allSiretInfo = useSelector(getAllCompanies);
  const companyEditRights = useSelector(selectCompanyEditRightsFYC);
  const postAndPatchCompanyIsPending = useSelector(selectPostAndPatchCompanyIsPending);
  console.log("allSiretInfo amar", allSiretInfo);

  const myInfo = useSelector(getMeData);
  console.log("GetMe", myInfo);
  const [EmployeeId, setEmployeeId] = useState(null);
  const [fromTakeInfo, setFromTakeInfo] = useState(false);
  const [mailInfoFrom, setMailInfoFrom] = useState("");
  const [sameAsHeadquartersValue, setSameAsHeadquartersValue] = useState(null);
  const timedOutRef = useRef(null);

  const [enableCivilityIfEmpty, setEnableCivilityIfEmpty] = useState(false);
  const [enableLastnameIfEmpty, setEnableLastnameIfEmpty] = useState(false);
  const [enableFirstnameIfEmpty, setEnableFirstnameIfEmpty] = useState(false);
  const [enableFixPhoneIfEmpty, setEnableFixPhoneIfEmpty] = useState(false);
  const [enablePhoneIfEmpty, setEnablePhoneIfEmpty] = useState(false);
  const [enableFunctionIfEmpty, setEnableFunctionIfEmpty] = useState(false);
  const [enableCivilityOrderBillIfEmpty, setEnableCivilityOrderBillIfEmpty] = useState(false);
  const [enableLastnameOrderBillIfEmpty, setEnableLastnameOrderBillIfEmpty] = useState(false);
  const [enableFirstnameOrderBillIfEmpty, setEnableFirstnameOrderBillIfEmpty] = useState(false);
  const [enableFixPhoneOrderBillIfEmpty, setEnableFixPhoneOrderBillIfEmpty] = useState(false);
  const [enablePhoneOrderBillIfEmpty, setEnablePhoneOrderBillIfEmpty] = useState(false);
  const [enableFunctionOrderBillIfEmpty, setEnableFunctionOrderBillIfEmpty] = useState(false);

  const handleResetStatesIfEmptyValue = () => {
    setEnableCivilityIfEmpty(false);
    setEnableLastnameIfEmpty(false);
    setEnableFirstnameIfEmpty(false);
    setEnableFixPhoneIfEmpty(false);
    setEnablePhoneIfEmpty(false);
    setEnableFunctionIfEmpty(false);
  };

  const handleResetStatesOrderBillIfEmptyValue = () => {
    setEnableCivilityOrderBillIfEmpty(false);
    setEnableLastnameOrderBillIfEmpty(false);
    setEnableFirstnameOrderBillIfEmpty(false);
    setEnableFixPhoneOrderBillIfEmpty(false);
    setEnablePhoneOrderBillIfEmpty(false);
    setEnableFunctionOrderBillIfEmpty(false);
  };

  // Yup validation schema
  const Form9Schema = Yup.object().shape({
    isDifferentAddress: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    address: Yup.string()
      .typeError("Remplissez ce champ.")
      .when(["isDifferentAddress"], {
        is: (isDifferentAddress) => isDifferentAddress?.key === "Oui",
        then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
      }),
    zipCode: Yup.string()
      .typeError("Remplissez ce champ.")
      .when(["isDifferentAddress"], {
        is: (isDifferentAddress) => isDifferentAddress?.key === "Oui",
        then: (schema) => schema
          .matches(/^[0-9]+$/, `${t("app.errorMessages.numberFormat")}`)
          .min(5, t("app.errorMessages.NombreItemRequis"))
          .max(5, t("app.errorMessages.NombreItemRequis"))
          .required(t("app.errorMessages.RemplirChamp")),
      }),
    city: Yup.string()
      .typeError("Remplissez ce champ.")
      .when(["isDifferentAddress"], {
        is: (isDifferentAddress) => isDifferentAddress?.key === "Oui",
        then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
      }),
    takeInfoFrom: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    email: Yup.string().when(["takeInfoFrom"], {
      is: (takeInfoFrom) => takeInfoFrom?.key !== "-Aucun-",
      then: (schema) =>
        schema.email(t("app.errorMessages.FormatEmailInvalide")).required(t("app.errorMessages.RemplirChamp")),
    }),
    civility: Yup.object().when(["takeInfoFrom"], {
      is: (takeInfoFrom) => takeInfoFrom?.key !== "-Aucun-",
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value?.key !== "-Aucun-"),
    }),
    lastName: Yup.string().when(["takeInfoFrom"], {
      is: (takeInfoFrom) => takeInfoFrom?.key !== "-Aucun-",
      then: (schema) => schema.min(2).required(t("app.errorMessages.RemplirChamp")),
    }),
    firstName: Yup.string().when(["takeInfoFrom"], {
      is: (takeInfoFrom) => takeInfoFrom?.key !== "-Aucun-",
      then: (schema) => schema.min(2).required(t("app.errorMessages.RemplirChamp")),
    }),
    function: Yup.string().when(["takeInfoFrom"], {
      is: (takeInfoFrom) => takeInfoFrom?.key !== "-Aucun-",
      then: (schema) =>
        schema
          .min(2, `${t("app.errorMessages.Min2Chiffres")}`)
          .required(t("app.errorMessages.RemplirChamp"))
          .max(40, `${t("app.errorMessages.Max40")}`),
    }),
    fixPhone: Yup.string().when(["takeInfoFrom"], {
      is: (takeInfoFrom) => takeInfoFrom?.key !== "-Aucun-",
      then: (schema) =>
        schema
          .test(
            "if-is-aucun",
            t("app.errorMessages.PhoneFormat"),
            (value) => value !== undefined && isValidPhoneNumber(value) !== false,
          )
          .required(t("app.errorMessages.RemplirChamp")),
    }),
    phone: Yup.string().matches(phoneRegExp, t("app.errorMessages.PhoneFormat")),
    orderInfoOnBill: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    emailOrderBill: Yup.string()
      .typeError(t("app.errorMessages.RemplirChamp"))
      .when(["orderInfoOnBill"], {
        is: (orderInfoOnBill) => orderInfoOnBill?.key !== "Non",
        then: (schema) =>
          schema
            .email(`${t("app.errorMessages.FormatEmailInvalide")}`)
            .required(`${t("app.errorMessages.RemplirChamp")}`),
      }),
    civilityOrderBill: Yup.object()
      .typeError(t("app.errorMessages.RemplirChamp"))
      .when(["orderInfoOnBill", "emailOrderBill"], {
        is: (orderInfoOnBill, emailOrderBill) =>
          orderInfoOnBill?.key !== "Non" && emailOrderBill && emailOrderBill.length > 0,
        then: (schema) =>
          schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
      }),
    lastNameOrderBill: Yup.string()
      .typeError(t("app.errorMessages.RemplirChamp"))
      .when(["orderInfoOnBill", "emailOrderBill"], {
        is: (orderInfoOnBill, emailOrderBill) =>
          orderInfoOnBill?.key !== "Non" && emailOrderBill && emailOrderBill.length > 0,
        then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
      }),
    firstNameOrderBill: Yup.string()
      .typeError(t("app.errorMessages.RemplirChamp"))
      .when(["orderInfoOnBill", "emailOrderBill"], {
        is: (orderInfoOnBill, emailOrderBill) =>
          orderInfoOnBill?.key !== "Non" && emailOrderBill && emailOrderBill.length > 0,
        then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
      }),
    fixPhoneOrderBill: Yup.string().when(["orderInfoOnBill", "emailOrderBill"], {
      is: (orderInfoOnBill, emailOrderBill) =>
        orderInfoOnBill?.key !== "Non" && emailOrderBill && emailOrderBill.length > 0,
      then: (schema) =>
        schema
          .test(
            "if-is-aucun",
            t("app.errorMessages.PhoneFormat"),
            (value) => value !== undefined && isValidPhoneNumber(value) !== false,
          )
          .required(t("app.errorMessages.RemplirChamp")),
    }),
    phoneOrderBill: Yup.string().when(["orderInfoOnBill", "emailOrderBill"], {
      is: (orderInfoOnBill, emailOrderBill) =>
        orderInfoOnBill?.key !== "Non" && emailOrderBill && emailOrderBill.length > 0,
      then: Yup.string().matches(phoneRegExp, t("app.errorMessages.PhoneFormat")),
    }),
    functionOrderBill: Yup.string().when(["orderInfoOnBill", "emailOrderBill"], {
      is: (orderInfoOnBill, emailOrderBill) => orderInfoOnBill?.key === "Oui" && emailOrderBill,
      then: (schema) =>
        schema
          .min(2, `${t("app.errorMessages.Min2Chiffres")}`)
          .required(t("app.errorMessages.RemplirChamp"))
          .max(40, `${t("app.errorMessages.Max40")}`),
    }),
  });

  const onSubmit = async (values) => {
    console.log("CONTRACT VALUE TO SEND 9", errors);

    const valuesCopy = structuredClone(values);

    // These functions change the value of certain keys depending on the swagger, before dataToSend initialization
    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);

    const contactPart = {
      billing: {
        copyFrom: valuesCopy?.takeInfoFrom?.key,
        email: valuesCopy.email,
        civility: valuesCopy?.civility?.key,
        name: valuesCopy.lastName,
        firstname: valuesCopy.firstName,
        phones: {
          office: valuesCopy.fixPhone,
          cellular: valuesCopy.phone,
        },
        function: valuesCopy.function,
      },
    };

    const dataToSendWithoutPurchase = {
      status: "En cours",
      company: {
        id: contractData?.company?.id,
        contacts: {
          ...contactPart,
        },
      },
      contract: {
        apprenticeshipType: contractData?.contract?.apprenticeshipType?.key,
        financing: {
          purchaseOrderMandatory: valuesCopy?.orderInfoOnBill?.key,
        },
      },
    };

    const dataToSend = {
      status: "En cours",
      company: {
        id: contractData?.company?.id,
        contacts: {
          ...contactPart,
          purchase: {
            id: EmployeeId,
            email: valuesCopy.emailOrderBill,
            civility: valuesCopy?.civilityOrderBill?.key,
            name: valuesCopy.lastNameOrderBill,
            firstname: valuesCopy.firstNameOrderBill,
            phones: {
              office: valuesCopy.fixPhoneOrderBill,
              cellular: valuesCopy.phoneOrderBill,
            },
            function: valuesCopy.functionOrderBill,
          },
        },
      },
      contract: {
        apprenticeshipType: contractData?.contract?.apprenticeshipType?.key,
        financing: {
          purchaseOrderMandatory: valuesCopy?.orderInfoOnBill?.key,
        },
      },
    };

    if (companyEditRights) {
      let dataToSendCompany = null;
      if (sameAsHeadquartersValue?.key === "Oui") {
        dataToSendCompany = {
          addresses: {
            billing: {
              sameAsHeadquarters: false,
              street: valuesCopy.address,
              additionalAddress: valuesCopy.addressMoreInfo,
              zipCode: valuesCopy.zipCode,
              city: valuesCopy.city,
            },
          },
        };
      } else {
        dataToSendCompany = {
          addresses: {
            billing: {
              sameAsHeadquarters: true,
            },
          },
        };
      }

      await dispatch(
        patchCompanyThunk({
          dataToSend: dataToSendCompany,
          id: localCompanyId,
          axiosCancelToken,
        }),
      ).unwrap();
    }

    try {
      await dispatch(
        patchContractDataThunk({
          dataToSend: values.orderInfoOnBill?.key === "Oui" ? dataToSend : dataToSendWithoutPurchase,
          id: contractData.id,
          axiosCancelToken,
        }),
      ).unwrap();

      submitNextSectionIfSubmitAllTrue(10);

      if (!submittingAllSections) {
        toastSuccess(<ToastMessageStructure secondMessage={t("app.toastMessages.ModifFinancementContratEnt")} />);
      }
      console.log("Form9 patchContractDataThunk success !", dataToSend);
    } catch (error) {
      cancelSubmitAllSections();
      console.log("Form9 patchContractDataThunk fail !", error);
      toastError(
        <ToastMessageStructure
          firstMessage={t("app.toastMessages.PreContraEntSectionInformationsFacturationSubmitError")}
          secondMessage={error.message}
        />,
      );
    }
  };

  const handleTakeInfoFromMount = () => {
    if (values?.takeInfoFrom?.key === "Aucun") {
      setFromTakeInfo(false);
    } else if (values?.takeInfoFrom?.key !== "Aucun" && values?.takeInfoFrom?.key !== "-Aucun-") {
      setFromTakeInfo(true);
    }
  };

  const handleYesNoOnMount = (value) => {
    switch (value) {
      case true:
        return { key: "Oui", label: t("app.referentials.Oui") };
      case false:
        return { key: "Non", label: t("app.referentials.Non") };
      default:
        return { ...objAucun };
    }
  };

  const handleSameAsHeadquartersValue = () => {
    let value = { ...objAucun };

    if (allSiretInfo?.[0]?.addresses?.billing?.sameAsHeadquarters === true) {
      value = { key: "Non", label: t("app.referentials.Non") };
    } else if (allSiretInfo?.[0]?.addresses?.billing?.sameAsHeadquarters === false) {
      value = { key: "Oui", label: t("app.referentials.Oui") };
    }

    return value;
  };

  const { values, errors, touched, setFieldValue, handleSubmit, isValid, validateForm, submitCount } = useFormik({
    initialValues: {
      isDifferentAddress: sameAsHeadquartersValue,
      address: sameAsHeadquartersValue?.key === "Oui" ? allSiretInfo?.[0]?.addresses?.billing?.street : "",
      addressMoreInfo:
        sameAsHeadquartersValue?.key === "Oui" ? allSiretInfo?.[0]?.addresses?.billing?.additionalAddress : "",
      zipCode: sameAsHeadquartersValue?.key === "Oui" ? allSiretInfo?.[0]?.addresses?.billing?.zipCode : "",
      city: sameAsHeadquartersValue?.key === "Oui" ? allSiretInfo?.[0]?.addresses?.billing?.city : "",
      takeInfoFrom: contractData?.company?.contacts?.billing?.copyFrom || { ...objAucun },
      email: contractData?.company?.contacts?.billing?.email || "",
      civility: contractData?.company?.contacts?.billing?.civility || "",
      lastName: contractData?.company?.contacts?.billing?.name || "",
      firstName: contractData?.company?.contacts?.billing?.firstname || "",
      function: contractData?.company?.contacts?.billing?.function || "",
      fixPhone: contractData?.company?.contacts?.billing?.phones?.office || "",
      phone: contractData?.company?.contacts?.billing?.phones?.cellular || "",
      orderInfoOnBill: handleYesNoOnMount(contractData?.contract?.financing?.purchaseOrderMandatory),
      emailOrderBill: contractData?.company?.contacts?.purchase?.email || "",
      civilityOrderBill: contractData?.company?.contacts?.purchase?.civility || { ...objAucun },
      lastNameOrderBill: contractData?.company?.contacts?.purchase?.name || "",
      firstNameOrderBill: contractData?.company?.contacts?.purchase?.firstname || "",
      fixPhoneOrderBill: contractData?.company?.contacts?.purchase?.phones?.office || "",
      phoneOrderBill: contractData?.company?.contacts?.purchase?.phones?.cellular || "",
      functionOrderBill: contractData?.company?.contacts?.purchase?.function || "",
    },
    validationSchema: Form9Schema,
    enableReinitialize: true,
    onSubmit,
  });

  const setInfoFrom = (value, update) => {
    console.log("SET INFO", value);
    switch (value?.key) {
      case "Contact signataire":
        setFieldValue(
          "email",
          contractData?.company?.contacts?.signatory?.email ? contractData?.company?.contacts?.signatory?.email : "",
        );
        setFieldValue(
          "civility",
          contractData?.company?.contacts?.signatory?.civility
            ? contractData?.company?.contacts?.signatory?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName",
          contractData?.company?.contacts?.signatory?.name ? contractData?.company?.contacts?.signatory?.name : "",
        );
        setFieldValue(
          "firstName",
          contractData?.company?.contacts?.signatory?.firstname
            ? contractData?.company?.contacts?.signatory?.firstname
            : "",
        );
        setFieldValue(
          "phone",
          contractData?.company?.contacts?.signatory?.phones?.cellular
            ? contractData?.company?.contacts?.signatory?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone",
          contractData?.company?.contacts?.signatory?.phones?.office
            ? contractData?.company?.contacts?.signatory?.phones?.office
            : "",
        );
        setFieldValue(
          "function",
          contractData?.company?.contacts?.signatory?.function
            ? contractData?.company?.contacts?.signatory?.function
            : "",
        );
        setFromTakeInfo(true);
        break;
      case "Contact établissement du contrat":
        setFieldValue(
          "email",
          contractData?.company?.contacts?.contractDrafter?.email
            ? contractData?.company?.contacts?.contractDrafter?.email
            : "",
        );
        setFieldValue(
          "civility",
          contractData?.company?.contacts?.contractDrafter?.civility
            ? contractData?.company?.contacts?.contractDrafter?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName",
          contractData?.company?.contacts?.contractDrafter?.name
            ? contractData?.company?.contacts?.contractDrafter?.name
            : "",
        );
        setFieldValue(
          "firstName",
          contractData?.company?.contacts?.contractDrafter?.firstname
            ? contractData?.company?.contacts?.contractDrafter?.firstname
            : "",
        );
        setFieldValue(
          "phone",
          contractData?.company?.contacts?.contractDrafter?.phones?.cellular
            ? contractData?.company?.contacts?.contractDrafter?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone",
          contractData?.company?.contacts?.contractDrafter?.phones?.office
            ? contractData?.company?.contacts?.contractDrafter?.phones?.office
            : "",
        );
        setFieldValue(
          "function",
          contractData?.company?.contacts?.contractDrafter?.function
            ? contractData?.company?.contacts?.contractDrafter?.function
            : "",
        );
        setFromTakeInfo(true);
        break;
      case "Contact administratif":
        setFieldValue(
          "email",
          contractData?.company?.contacts?.administrative?.email
            ? contractData?.company?.contacts?.administrative?.email
            : "",
        );
        setFieldValue(
          "civility",
          contractData?.company?.contacts?.administrative?.civility
            ? contractData?.company?.contacts?.administrative?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName",
          contractData?.company?.contacts?.administrative?.name
            ? contractData?.company?.contacts?.administrative?.name
            : "",
        );
        setFieldValue(
          "firstName",
          contractData?.company?.contacts?.administrative?.firstname
            ? contractData?.company?.contacts?.administrative?.firstname
            : "",
        );
        setFieldValue(
          "phone",
          contractData?.company?.contacts?.administrative?.phones?.cellular
            ? contractData?.company?.contacts?.administrative?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone",
          contractData?.company?.contacts?.administrative?.phones?.office
            ? contractData?.company?.contacts?.administrative?.phones?.office
            : "",
        );
        setFieldValue(
          "function",
          contractData?.company?.contacts?.administrative?.function
            ? contractData?.company?.contacts?.administrative?.function
            : "",
        );
        setFromTakeInfo(true);
        break;
      case "Contact facturation":
        setFieldValue(
          "email",
          contractData?.company?.contacts?.billing?.email ? contractData?.company?.contacts?.billing?.email : "",
        );
        setFieldValue(
          "civility",
          contractData?.company?.contacts?.billing?.civility
            ? contractData?.company?.contacts?.billing?.civility
            : { ...objAucun },
        );
        setFieldValue(
          "lastName",
          contractData?.company?.contacts?.billing?.name ? contractData?.company?.contacts?.billing?.name : "",
        );
        setFieldValue(
          "firstName",
          contractData?.company?.contacts?.billing?.firstname
            ? contractData?.company?.contacts?.billing?.firstname
            : "",
        );
        setFieldValue(
          "phone",
          contractData?.company?.contacts?.billing?.phones?.cellular
            ? contractData?.company?.contacts?.billing?.phones?.cellular
            : "",
        );
        setFieldValue(
          "fixPhone",
          contractData?.company?.contacts?.billing?.phones?.office
            ? contractData?.company?.contacts?.billing?.phones?.office
            : "",
        );
        setFieldValue(
          "function",
          contractData?.company?.contacts?.billing?.function ? contractData?.company?.contacts?.billing?.function : "",
        );
        setFromTakeInfo(true);
        break;
      case "Aucun":
        if (!update) {
          setFieldValue("email", "");
          setFieldValue("civility", { ...objAucun });
          setFieldValue("lastName", "");
          setFieldValue("firstName", "");
          setFieldValue("phone", "");
          setFieldValue("fixPhone", "");
          setFieldValue("function", "");
          setFromTakeInfo(false);
        } else {
          setFieldValue(
            "email",
            contractData?.company?.contacts?.billing?.email ? contractData?.company?.contacts?.billing?.email : "",
          );
          setFieldValue(
            "civility",
            contractData?.company?.contacts?.billing?.civility
              ? contractData?.company?.contacts?.billing?.civility
              : { ...objAucun },
          );
          setFieldValue(
            "lastName",
            contractData?.company?.contacts?.billing?.name ? contractData?.company?.contacts?.billing?.name : "",
          );
          setFieldValue(
            "firstName",
            contractData?.company?.contacts?.billing?.firstname
              ? contractData?.company?.contacts?.billing?.firstname
              : "",
          );
          setFieldValue(
            "phone",
            contractData?.company?.contacts?.billing?.phones?.cellular
              ? contractData?.company?.contacts?.billing?.phones?.cellular
              : "",
          );
          setFieldValue(
            "fixPhone",
            contractData?.company?.contacts?.billing?.phones?.office
              ? contractData?.company?.contacts?.billing?.phones?.office
              : "",
          );
          setFieldValue(
            "function",
            contractData?.company?.contacts?.billing?.function
              ? contractData?.company?.contacts?.billing?.function
              : "",
          );
          const tmpFromTake = fromTakeInfo;
          setFromTakeInfo(tmpFromTake);
        }
        break;
      case "-Aucun-":
        setFieldValue("email", "");
        setFieldValue("civility", { ...objAucun });
        setFieldValue("lastName", "");
        setFieldValue("firstName", "");
        setFieldValue("phone", "");
        setFieldValue("fixPhone", "");
        setFieldValue("function", "");
        setFromTakeInfo(false);
        break;
      default:
        break;
    }
  };

  const displayUserData = (employeePayload) => {
    if (mailInfoFrom === TAKE_INFO_FROM) {
      setFieldValue("lastName", employeePayload[0]?.name ? employeePayload[0]?.name : "");
      setFieldValue("firstName", employeePayload[0]?.firstname ? employeePayload[0]?.firstname : "");
      setFieldValue("civility", employeePayload[0]?.civility ? employeePayload[0]?.civility : { ...objAucun });
      setFieldValue("fixPhone", employeePayload[0]?.phones?.office ? employeePayload[0]?.phones?.office : "");
      setFieldValue("phone", employeePayload[0]?.phones?.cellular ? employeePayload[0]?.phones?.cellular : "");
      setFieldValue("function", employeePayload[0]?.function ? employeePayload[0]?.function : "");
      setMailInfoFrom("");
    } else if (mailInfoFrom === INFO_ORDER_BILL) {
      setFieldValue("lastNameOrderBill", employeePayload[0]?.name ? employeePayload[0]?.name : "");
      setFieldValue("firstNameOrderBill", employeePayload[0]?.firstname ? employeePayload[0]?.firstname : "");
      setFieldValue("civilityOrderBill", employeePayload[0]?.civility ? employeePayload[0]?.civility : { ...objAucun });
      setFieldValue("fixPhoneOrderBill", employeePayload[0]?.phones?.office ? employeePayload[0]?.phones?.office : "");
      setFieldValue("phoneOrderBill", employeePayload[0]?.phones?.cellular);
      setFieldValue("functionOrderBill", employeePayload[0]?.function ? employeePayload[0]?.function : "");
      setMailInfoFrom("");
      setEmployeeId(employeePayload[0]?.id);
    }
  };

  const displayDefaultData = () => {
    if (mailInfoFrom === TAKE_INFO_FROM) {
      setFieldValue("civility", { ...objAucun });
      setFieldValue("lastName", "");
      setFieldValue("firstName", "");
      setFieldValue("fixPhone", "");
      setFieldValue("phone", "");
      setFieldValue("function", "");
      setMailInfoFrom("");
    } else if (mailInfoFrom === INFO_ORDER_BILL) {
      setFieldValue("lastNameOrderBill", "");
      setFieldValue("firstNameOrderBill", "");
      setFieldValue("civilityOrderBill", { ...objAucun });
      setFieldValue("fixPhoneOrderBill", "");
      setFieldValue("phoneOrderBill", "");
      setFieldValue("functionOrderBill", "");
      setMailInfoFrom("");
      setEmployeeId(null);
    }
  };

  const setInfoOrderBill = () => {
    setFieldValue("emailOrderBill", "");
    setFieldValue("civilityOrderBill", { ...objAucun });
    setFieldValue("lastNameOrderBill", "");
    setFieldValue("firstNameOrderBill", "");
    setFieldValue("phoneOrderBill", "");
    setFieldValue("fixPhoneOrderBill", "");
    setFieldValue("functionOrderBill", "");
  };

  useEffect(() => {
    if (values) {
      console.log("INITIZAL VALUE", errors);
      handleTakeInfoFromMount(contractData?.company?.contacts?.billing);
    }
    console.log("MY VALUES", values.orderInfoOnBill);
  }, []);

  useEffect(() => {
    setInfoFrom(values.takeInfoFrom, true);
  }, [contractData]);

  useEffect(() => {
    if (values.takeInfoFrom.key === "Aucun") {
      if (!errors.email && values.email.length > 1) {
        if (timedOutRef.current) clearTimeout(timedOutRef.current);
        timedOutRef.current = setTimeout(() => {
          (async () => {
            try {
              await dispatch(
                getEmployeesThunk({
                  email: values.email,
                  axiosCancelToken,
                }),
              )
                .unwrap()
                .then((payload) => {
                  if (payload.length > 0) {
                    displayUserData(payload);
                  } else {
                    displayDefaultData();
                  }
                });

              handleResetStatesIfEmptyValue();
              handleResetStatesOrderBillIfEmptyValue();
              console.log("get success Employees !");
            } catch (error) {
              handleResetStatesIfEmptyValue();
              handleResetStatesOrderBillIfEmptyValue();
              if (isOpen) {
                console.log("get fail Employees !", error.message);
                toastError(
                  <ToastMessageStructure
                    firstMessage={t("app.toastMessages.PreContraEntGetEmployeeError")}
                    secondMessage={error.message}
                  />,
                );
              }
            }
          })();
        }, "500");
      } else {
        displayDefaultData();
      }
    }
  }, [errors.email, values.email]);

  useEffect(() => {
    if (values.emailOrderBill.length > 0) {
      if (!errors.emailOrderBill) {
        if (timedOutRef.current) clearTimeout(timedOutRef.current);
        timedOutRef.current = setTimeout(() => {
          (async () => {
            try {
              await dispatch(
                getEmployeesThunk({
                  email: values.emailOrderBill,
                  axiosCancelToken,
                }),
              )
                .unwrap()
                .then((payload) => {
                  if (payload.length > 0) {
                    displayUserData(payload);
                  } else {
                    displayDefaultData();
                  }
                });

              handleResetStatesIfEmptyValue();
              handleResetStatesOrderBillIfEmptyValue();
              console.log("get success Employees !");
            } catch (error) {
              handleResetStatesIfEmptyValue();
              handleResetStatesOrderBillIfEmptyValue();
              console.log("get fail Employees !", error.message);
              if (isOpen) {
                toastError(
                  <ToastMessageStructure
                    firstMessage={t("app.toastMessages.PreContraEntGetEmployeeError")}
                    secondMessage={error.message}
                  />,
                );
              }
            }
          })();
        }, "500");
      }
    } else {
      displayDefaultData();
    }
  }, [values.emailOrderBill, errors.emailOrderBill]);

  // useLayoutEffect to force Formik's isValid check, the first time the component is mounted
  useLayoutEffect(() => {
    validateForm();
  }, [values.fixPhoneOrderBill, values.fixPhone]);

  // useLayoutEffect to control the color of the DropdownSection circle.
  useLayoutEffect(() => {
    if (isValid) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [isValid]);

  // useLayoutEffect to manage the trigger at the right time for sending this form, during global submission.
  useLayoutEffect(() => {
    const thisFormNumber = 9;

    if (isValid && submittingAllSections && currentSectionBeingSubmitted === thisFormNumber) {
      handleSubmit();
    } else if (!isValid && submittingAllSections && currentSectionBeingSubmitted === thisFormNumber) {
      handleSubmit();
      cancelSubmitAllSections();
      toastError(
        <ToastMessageStructure
          firstMessage={t("app.toastMessages.PreContraEntSectionInformationsFacturationNonRemplieError")}
        />,
      );
    }
  }, [isValid, submittingAllSections, currentSectionBeingSubmitted]);

  const isDisabled = !(values?.takeInfoFrom?.key === "Aucun" && values?.email && values.email.length > 0);
  const isOrderBillDisabled = !(
    values?.orderInfoOnBill?.key === "Oui" &&
    values.emailOrderBill &&
    values.emailOrderBill.length > 0
  );

  const isEmailDisabled = values?.takeInfoFrom?.key !== "Aucun";
  const isOrderBillEmailDisabled = !(values?.orderInfoOnBill?.key === "Oui");

  useEffect(() => {
    if (values?.takeInfoFrom?.key !== "-Aucun-" && values.email !== "") {
      if (values?.civility?.key === "-Aucun-") {
        setEnableCivilityIfEmpty(true);
      }
      if (!values?.lastName) {
        setEnableLastnameIfEmpty(true);
      }
      if (!values?.firstName) {
        setEnableFirstnameIfEmpty(true);
      }
      if (!values?.fixPhone) {
        setEnableFixPhoneIfEmpty(true);
      }
      if (!values?.phone) {
        setEnablePhoneIfEmpty(true);
      }
      if (!values?.function) {
        setEnableFunctionIfEmpty(true);
      }
    } else {
      handleResetStatesIfEmptyValue();
    }

    if (
      values?.orderInfoOnBill?.key !== "-Aucun-" &&
      values?.orderInfoOnBill?.key !== "Non" &&
      values.emailOrderBill !== ""
    ) {
      if (values?.civilityOrderBill?.key === "-Aucun-") {
        setEnableCivilityOrderBillIfEmpty(true);
      }
      if (!values?.lastNameOrderBill) {
        setEnableLastnameOrderBillIfEmpty(true);
      }
      if (!values?.firstNameOrderBill) {
        setEnableFirstnameOrderBillIfEmpty(true);
      }
      if (!values?.fixPhoneOrderBill) {
        setEnableFixPhoneOrderBillIfEmpty(true);
      }
      if (!values?.phoneOrderBill) {
        setEnablePhoneOrderBillIfEmpty(true);
      }
      if (!values?.functionOrderBill) {
        setEnableFunctionOrderBillIfEmpty(true);
      }
    } else {
      handleResetStatesOrderBillIfEmptyValue();
    }
  }, [values]);

  useLayoutEffect(() => {
    if (allSiretInfo) {
      setSameAsHeadquartersValue(handleSameAsHeadquartersValue());
    }
  }, [allSiretInfo]);
  console.log("sameAsHeadquartersValue", sameAsHeadquartersValue);

  return (
    <form className={styles.form_container} onSubmit={handleSubmit}>
      <FormInformationField
        label={t("app.suivezVosContratsPartner.NomDeLaSociete")}
        information={allSiretInfo?.[0]?.name}
      />
      <SubTitleInForm additionalClassNames={styles.subtitle}>
        {t("app.suivezVosContratsPartner.AdresseDeFacturation")}
      </SubTitleInForm>
      <div className={styles.row_container}>
        <FormDropdownField
          id="isDifferentAddress"
          label={t("app.suivezVosContratsPartner.AdresseDifferente")}
          touched={touched.isDifferentAddress}
          values={values.isDifferentAddress}
          errors={errors.isDifferentAddress}
          disabled={!companyEditRights}
          data={[...YesNo]}
          additionalClassNames={styles.dropdown_list}
          onChange={(e) => {
            setFieldValue("isDifferentAddress", e);
            setFieldValue("address", e?.key === "Oui" ? allSiretInfo?.[0]?.addresses?.billing?.street : "");
            setFieldValue(
              "addressMoreInfo",
              e?.key === "Oui" ? allSiretInfo?.[0]?.addresses?.billing?.additionalAddress : "",
            );
            setFieldValue("zipCode", e?.key === "Oui" ? allSiretInfo?.[0]?.addresses?.billing?.zipCode : "");
            setFieldValue("city", e?.key === "Oui" ? allSiretInfo?.[0]?.addresses?.billing?.city : "");
            setSameAsHeadquartersValue(e);
          }}
        />
      </div>
      <div className={styles.row_container}>
        <FormInputField
          id="address"
          label={t("app.suivezVosContratsPartner.Adresse")}
          touched={touched.address}
          values={values.address}
          errors={errors.address}
          disabled={sameAsHeadquartersValue?.key !== "Oui" || !companyEditRights}
          additionalClassNames={styles.input_field}
          onChange={(e) => setFieldValue("address", e.target.value)}
        />
        <FormInputField
          id="addressMoreInfo"
          label={t("app.suivezVosContratsPartner.ComplementDadresse")}
          touched={touched.addressMoreInfo}
          values={values.addressMoreInfo}
          errors={errors.addressMoreInfo}
          disabled={sameAsHeadquartersValue?.key !== "Oui" || !companyEditRights}
          additionalClassNames={styles.input_field}
          onChange={(e) => setFieldValue("addressMoreInfo", e.target.value)}
        />
        <FormInputField
          id="zipCode"
          type="number"
          label={t("app.suivezVosContratsPartner.CodePostal2")}
          touched={touched.zipCode}
          values={values.zipCode}
          errors={errors.zipCode}
          disabled={sameAsHeadquartersValue?.key !== "Oui" || !companyEditRights}
          additionalClassNames={styles.input_field}
          onChange={(e) => setFieldValue("zipCode", e.target.value)}
        />
        <FormInputField
          id="city"
          label={t("app.suivezVosContratsPartner.Ville")}
          touched={touched.city}
          values={values.city}
          errors={errors.city}
          additionalClassNames={styles.input_field}
          disabled={sameAsHeadquartersValue?.key !== "Oui" || !companyEditRights}
          onChange={(e) => setFieldValue("city", e.target.value)}
        />
      </div>
      {!companyEditRights && (
        <InformationTextInForm>
          {t("app.suivezVosContratsPartner.ModificationInterditeEntreprise.part1")}
          <span style={{ fontWeight: "bold" }}>
            {t("app.suivezVosContratsPartner.ModificationInterditeEntreprise.part2")}
          </span>
        </InformationTextInForm>
      )}
      <SubTitleInForm additionalClassNames={styles.subtitle}>
        {t("app.suivezVosContratsPartner.ContactDeFacturation")}
      </SubTitleInForm>
      <div className={styles.row_container}>
        <FormDropdownField
          id="takeInfoFrom"
          label={t("app.suivezVosContratsPartner.ReprendreInfo")}
          touched={touched.takeInfoFrom}
          values={values.takeInfoFrom}
          errors={errors.takeInfoFrom}
          additionalClassNames={styles.dropdown_list}
          data={[
            {
              ...objAucun,
            },
            ...(referencialData?.COMPANY_CONTACT_TYPE_TO_COPY_FOR_BILLING_CONTACT || []),
          ]}
          onChange={(e) => {
            setInfoFrom(e, false);
            setFieldValue("takeInfoFrom", e);
          }}
        />
      </div>
      <SubTitleInForm additionalClassNames={styles.sub_subtitle}>
        {t("app.suivezVosContratsPartner.CoordonneesTitle")}
      </SubTitleInForm>
      <div className={styles.row_container}>
        <FormInputField
          id="email"
          label={t("app.suivezVosContratsPartner.EmailDuContact")}
          touched={touched.email}
          values={values.email}
          errors={errors.email}
          loading={isEmployeeDataPending}
          disabled={isEmailDisabled}
          additionalClassNames={styles.dropdown_list}
          onChange={(e) => {
            setFieldValue("email", e.target.value);
            setMailInfoFrom(TAKE_INFO_FROM);
          }}
        >
          {values.takeInfoFrom.key === "Aucun" && <p>{t("app.suivezVosContratsPartner.EmailInfo")}</p>}
        </FormInputField>
        <FormPhoneField
          id="fixPhone"
          label={t("app.suivezVosContratsPartner.Telephone")}
          touched={submitCount > 0}
          values={values.fixPhone}
          errors={errors.fixPhone}
          disabled={isDisabled && !enableFixPhoneIfEmpty && true}
          additionalClassNames={styles.dropdown_list}
          onChange={(e) => {
            setFieldValue("fixPhone", e);
          }}
        />
        <FormInputField
          id="phone"
          label={t("app.suivezVosContratsPartner.Portable")}
          touched={touched.phone}
          values={values.phone}
          errors={errors.phone}
          disabled={isDisabled && !enablePhoneIfEmpty && true}
          additionalClassNames={styles.dropdown_list}
          onChange={(e) => setFieldValue("phone", e.target.value)}
        />
      </div>
      <SubTitleInForm additionalClassNames={styles.sub_subtitle}>
        {t("app.suivezVosContratsPartner.IdentiteTitle")}
      </SubTitleInForm>
      <div className={styles.row_container}>
        <FormDropdownField
          id="civility"
          label={t("app.suivezVosContratsPartner.Civilite")}
          touched={touched.civility}
          values={values.civility}
          errors={errors.civility}
          data={[{ ...objAucun }, ...(referencialData?.CIVILITY || [])]}
          disabled={isDisabled && !enableCivilityIfEmpty && true}
          additionalClassNames={styles.dropdown_list}
          onChange={(newValue) => setFieldValue("civility", newValue)}
        />
        <FormInputField
          id="lastName"
          label={t("app.suivezVosContratsPartner.Nom")}
          touched={touched.lastName}
          values={values.lastName}
          errors={errors.lastName}
          disabled={isDisabled && !enableLastnameIfEmpty && true}
          additionalClassNames={styles.dropdown_list}
          onChange={(e) => setFieldValue("lastName", e.target.value)}
        />
        <FormInputField
          id="firstName"
          label={t("app.suivezVosContratsPartner.Prenom")}
          touched={touched.firstName}
          values={values.firstName}
          errors={errors.firstName}
          disabled={isDisabled && !enableFirstnameIfEmpty && true}
          additionalClassNames={styles.dropdown_list}
          onChange={(e) => setFieldValue("firstName", e.target.value)}
        />
      </div>
      <div className={styles.row_container_double}>
        <FormInputField
          id="function"
          label={t("app.suivezVosContratsPartner.Fonction")}
          touched={touched.function}
          values={values.function}
          errors={errors.function}
          disabled={isDisabled && !enableFunctionIfEmpty && true}
          onChange={(e) => setFieldValue("function", e.target.value)}
        />
      </div>
      <SubTitleInForm additionalClassNames={styles.subtitle}>
        {t("app.suivezVosContratsPartner.BonCommandeFacture")}
      </SubTitleInForm>
      <div className={styles.row_container}>
        <FormDropdownField
          id="orderInfoOnBill"
          label="Souhaitez-vous un bon de commande sur facture ?"
          touched={touched.orderInfoOnBill}
          values={values.orderInfoOnBill}
          errors={errors.orderInfoOnBill}
          data={[...YesNo]}
          additionalClassNames={styles.dropdown_list}
          onChange={(e) => {
            setFieldValue("orderInfoOnBill", e);
            setInfoOrderBill(e);
          }}
        />
      </div>
      {values.orderInfoOnBill.key === "Oui" && (
        <>
          <SubTitleInForm additionalClassNames={styles.sub_subtitle}>
            {t("app.suivezVosContratsPartner.CoordonneesTitle")}
          </SubTitleInForm>
          <div className={styles.row_container}>
            <FormInputField
              id="emailOrderBill"
              label={t("app.suivezVosContratsPartner.EmailDuContact")}
              touched={touched.emailOrderBill}
              values={values.emailOrderBill}
              errors={errors.emailOrderBill}
              loading={isEmployeeDataPending}
              disabled={isOrderBillEmailDisabled}
              additionalClassNames={styles.dropdown_list}
              onChange={(e) => {
                setMailInfoFrom(INFO_ORDER_BILL);
                setFieldValue("emailOrderBill", e.target.value);
              }}
            >
              <p>{t("app.suivezVosContratsPartner.EmailInfo")}</p>
            </FormInputField>
            <FormPhoneField
              id="fixPhoneOrderBill"
              label={t("app.suivezVosContratsPartner.Telephone")}
              touched={submitCount > 0}
              values={values.fixPhoneOrderBill}
              errors={errors.fixPhoneOrderBill}
              disabled={isOrderBillDisabled && !enableFixPhoneOrderBillIfEmpty && true}
              additionalClassNames={styles.dropdown_list}
              onChange={(e) => {
                setFieldValue("fixPhoneOrderBill", e);
              }}
            />
            <FormInputField
              id="phoneOrderBill"
              label={t("app.suivezVosContratsPartner.Portable")}
              touched={touched.phoneOrderBill}
              values={values.phoneOrderBill}
              errors={errors.phoneOrderBill}
              disabled={isOrderBillDisabled && !enablePhoneOrderBillIfEmpty && true}
              additionalClassNames={styles.dropdown_list}
              onChange={(e) => setFieldValue("phoneOrderBill", e.target.value)}
            />
          </div>
          <SubTitleInForm additionalClassNames={styles.sub_subtitle}>
            {t("app.suivezVosContratsPartner.IdentiteTitle")}
          </SubTitleInForm>
          <div className={styles.row_container}>
            <FormDropdownField
              id="civilityOrderBill"
              label={t("app.suivezVosContratsPartner.Civilite")}
              errors={errors.civilityOrderBill}
              touched={touched.civilityOrderBill}
              values={values.civilityOrderBill}
              data={[{ ...objAucun }, ...(referencialData?.CIVILITY || [])]}
              disabled={isOrderBillDisabled && !enableCivilityOrderBillIfEmpty && true}
              additionalClassNames={styles.dropdown_list}
              onChange={(newValue) => setFieldValue("civilityOrderBill", newValue)}
            />
            <FormInputField
              id="lastNameOrderBill"
              label={t("app.suivezVosContratsPartner.Nom")}
              touched={touched.lastNameOrderBill}
              values={values.lastNameOrderBill}
              errors={errors.lastNameOrderBill}
              disabled={isOrderBillDisabled && !enableLastnameOrderBillIfEmpty && true}
              additionalClassNames={styles.dropdown_list}
              onChange={(e) => setFieldValue("lastNameOrderBill", e.target.value)}
            />
            <FormInputField
              id="firstNameOrderBill"
              label={t("app.suivezVosContratsPartner.Prenom")}
              touched={touched.firstNameOrderBill}
              values={values.firstNameOrderBill}
              errors={errors.firstNameOrderBill}
              disabled={isOrderBillDisabled && !enableFirstnameOrderBillIfEmpty && true}
              additionalClassNames={styles.dropdown_list}
              onChange={(e) => setFieldValue("firstNameOrderBill", e.target.value)}
            />
          </div>
          <div className={styles.row_container_double}>
            <FormInputField
              id="functionOrderBill"
              label={t("app.suivezVosContratsPartner.Fonction")}
              values={values.functionOrderBill}
              errors={errors.functionOrderBill}
              touched={touched.functionOrderBill}
              disabled={isOrderBillDisabled && !enableFunctionOrderBillIfEmpty && true}
              onChange={(e) => setFieldValue("functionOrderBill", e.target.value)}
            />
          </div>
        </>
      )}

      <div className={styles.submit_btn_to_right}>
        <SubmitFormBtn
          disabled={postAndPatchCompanyIsPending}
          text={t("app.visualisationOffre.ValidezLesModifications")}
        />
      </div>
    </form>
  );
};

export default Form9;
