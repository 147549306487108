import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";
import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";
import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";
import SubTitleInForm from "components/typography/SubTitleInForm/SubTitleInForm";
import {
  getEmployeesThunk,
  getEmployeeDetails,
  getCompanyDetailsIsPending,
} from "app/slices/checkDetailsCompany/checkDetailsCompany.slice";
import {
  patchContractDataThunk,
  selectContractDataFYC,
} from "app/slices/followYourContracts/followYourContracts.slice";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";
import { toastError, toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import { FormPhoneField } from "pages/ENT/FollowYourContracts/components/formFields/FormPhoneField/FormPhoneField";
import { FormInputField } from "pages/ENT/FollowYourContracts/components/formFields/FormInputField/FormInputField";
import { FormDropdownField } from "pages/ENT/FollowYourContracts/components/formFields/FormDropdownField/FormDropdownField";
import FormCustomCheckBoxField from "pages/ENT/FollowYourContracts/components/formFields/FormCustomCheckBoxField/FormCustomCheckBoxField";
import { createObjAucun } from "../../../../../../../../utils/localReferentials";

import styles from "./Form2.module.scss";

const Form2 = ({
  axiosCancelToken,
  submittingAllSections,
  submitNextSectionIfSubmitAllTrue,
  cancelSubmitAllSections,
  currentSectionBeingSubmitted,
  setFormIsValid,
  isOpen,
}) => {
  const dispatch = useDispatch();
  const isEmployeeDataPending = useSelector(getCompanyDetailsIsPending);
  const FORM = "FORM_2";
  const employeeDetailsForm2 = useSelector(getEmployeeDetails);
  const contractData = useSelector(selectContractDataFYC);
  const [formStep, setFormStep] = useState("");
  const referencialData = useSelector(getReferentialsData);

  const timedOutRef = useRef(null);

  const objAucun = createObjAucun();
  const phoneRegExp = "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$";

  useEffect(() => {
    if (employeeDetailsForm2) {
      if (employeeDetailsForm2.length > 0 && formStep === FORM) {
        displayUserData();
      } else displayDefaultData();
    }
  }, [employeeDetailsForm2]);

  // Yup validation schema
  const Form1Schema = Yup.object().shape({
    signatoryEmailForm2: Yup.string()
      .email(`${t("app.errorMessages.FormatEmailInvalide")}`)
      .required(`${t("app.errorMessages.RemplirChamp")}`),
    phone: Yup.string().when("signatoryEmailForm2", {
      is: (e) => e && e.length > 0,
      then: (schema) =>
        schema.matches(phoneRegExp, t("app.errorMessages.PhoneFormat")).required(t("app.errorMessages.PhoneFormat")),
    }),
    fixPhone: Yup.string().when("signatoryEmailForm2", {
      is: (e) => e && e.length > 0,
      then: (schema) =>
        schema.test(
          "if-is-notFrench",
          t("app.errorMessages.PhoneFormat"),
          (value) => value !== undefined && isValidPhoneNumber(value) !== false,
        ),
    }),
    lastNameForm2: Yup.string().when("signatoryEmailForm2", {
      is: (e) => e && e.length > 0,
      then: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    firstNameForm2: Yup.string().when("signatoryEmailForm2", {
      is: (e) => e && e.length > 0,
      then: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    functionForm2: Yup.string().when("signatoryEmailForm2", {
      is: (e) => e && e.length > 0,
      then: Yup.string()
        .required(`${t("app.errorMessages.RemplirChamp")}`)
        .max(40, `${t("app.errorMessages.Max40")}`),
    }),
    civilityForm2: Yup.object().when("signatoryEmailForm2", {
      is: (e) => e && e.length > 0,
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    habilitationForm2: Yup.boolean().oneOf([true], t("app.errorMessages.RemplirChamp")),
  });

  const onSubmit = async (values) => {
    const valuesCopy = structuredClone(values);

    // These functionForm2s change the value of certain keys depending on the swagger, before dataToSend initialization
    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);

    const dataToSend = {
      status: "En cours",
      company: {
        id: contractData?.company?.id,
        contacts: {
          signatory: {
            email: valuesCopy?.signatoryEmailForm2,
            civility: valuesCopy?.civilityForm2?.key,
            name: valuesCopy?.lastNameForm2,
            firstname: valuesCopy?.firstNameForm2,
            phones: {
              office: valuesCopy?.fixPhone,
              cellular: valuesCopy?.phone,
            },
            function: valuesCopy?.functionForm2,
            empowered: valuesCopy?.habilitationForm2,
          },
        },
      },
    };

    try {
      await dispatch(
        patchContractDataThunk({
          dataToSend,
          id: contractData.id,
          axiosCancelToken,
        }),
      ).unwrap();

      submitNextSectionIfSubmitAllTrue(4);

      if (!submittingAllSections) {
        toastSuccess(<ToastMessageStructure secondMessage={t("app.toastMessages.ModifContactSignataireConvention")} />);
      }
    } catch (error) {
      cancelSubmitAllSections();
      toastError(
        <ToastMessageStructure firstMessage={t("app.toastMessages.PreContraEntSectionSignataireConventionSubmitError")} secondMessage={error.message} />,
      );
    }
  };

  const { values, errors, touched, setFieldValue, handleSubmit, validateForm, isValid, submitCount } = useFormik({
    initialValues: {
      signatoryEmailForm2: contractData?.company?.contacts?.signatory?.email || "",
      lastNameForm2: contractData?.company?.contacts?.signatory?.name || "",
      firstNameForm2: contractData?.company?.contacts?.signatory?.firstname || "",
      fixPhone: contractData?.company?.contacts?.signatory?.phones?.office || "",
      phone: contractData?.company?.contacts?.signatory?.phones?.cellular || "",
      functionForm2: contractData?.company?.contacts?.signatory?.function || "",
      civilityForm2: contractData?.company?.contacts?.signatory?.civility || { ...objAucun },
      habilitationForm2: contractData?.company?.contacts?.signatory?.empowered || false,
    },
    validationSchema: Form1Schema,
    onSubmit,
  });

  useLayoutEffect(() => {
    if (!errors.signatoryEmailForm2 && values.signatoryEmailForm2.length > 1) {
      if (timedOutRef.current) clearTimeout(timedOutRef.current);
      timedOutRef.current = setTimeout(() => {
        (async () => {
          try {
            await dispatch(
              getEmployeesThunk({
                email: values.signatoryEmailForm2,
                axiosCancelToken,
              }),
            ).unwrap();
          } catch (error) {
            if (isOpen) {
              toastError(
                <ToastMessageStructure
                  firstMessage={t("app.toastMessages.PreContraEntGetEmployeeError")}
                  secondMessage={error.message}
                />,
              );
            }
          }
        })();
      }, "500");
    } else {
      setFieldValue("civilityForm2", { ...objAucun });
      setFieldValue("lastNameForm2", "");
      setFieldValue("firstNameForm2", "");
      setFieldValue("fixPhone", "");
      setFieldValue("phone", "");
      setFieldValue("functionForm2", "");
      setFieldValue("habilitationForm2", false);
    }
  }, [errors.signatoryEmailForm2, values.signatoryEmailForm2]);

  const displayUserData = () => {
    if (formStep === FORM) {
      setFieldValue("lastNameForm2", employeeDetailsForm2[0]?.name ? employeeDetailsForm2[0]?.name : "");
      setFieldValue("firstNameForm2", employeeDetailsForm2[0]?.firstname ? employeeDetailsForm2[0]?.firstname : "");
      setFieldValue(
        "civilityForm2",
        employeeDetailsForm2[0]?.civility ? employeeDetailsForm2[0]?.civility : { ...objAucun },
      );
      setFieldValue("fixPhone", employeeDetailsForm2[0]?.phones?.office ? employeeDetailsForm2[0]?.phones?.office : "");
      setFieldValue(
        "phone",
        employeeDetailsForm2[0]?.phones?.cellular ? employeeDetailsForm2[0]?.phones?.cellular : "",
      );
      setFieldValue("functionForm2", employeeDetailsForm2[0]?.function ? employeeDetailsForm2[0]?.function : "");
      setFieldValue(
        "habilitationForm2",
        employeeDetailsForm2[0]?.empowered ? employeeDetailsForm2[0]?.empowered : false,
      );

      setFormStep("");
    }
  };

  const displayDefaultData = () => {
    if (formStep === FORM) {
      setFieldValue("civilityForm2", { ...objAucun });
      setFieldValue("lastNameForm2", "");
      setFieldValue("firstNameForm2", "");
      setFieldValue("fixPhone", "");
      setFieldValue("phone", "");
      setFieldValue("functionForm2", "");
      setFieldValue("habilitationForm2", false);
      setFormStep("");
    }
  };

  // useLayoutEffect to force Formik's isValid check, the first time the component is mounted
  useLayoutEffect(() => {
    validateForm();
  }, [values.lastNameForm2]);

  // useLayoutEffect to control the color of the DropdownSection circle.
  useLayoutEffect(() => {
    if (isValid) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [isValid]);

  // useLayoutEffect to manage the trigger at the right time for sending this form, during global submission.
  useLayoutEffect(() => {
    const thisFormNumber = 2;

    if (isValid && submittingAllSections && currentSectionBeingSubmitted === thisFormNumber) {
      handleSubmit();
    } else if (!isValid && submittingAllSections && currentSectionBeingSubmitted === thisFormNumber) {
      handleSubmit();
      cancelSubmitAllSections();
      toastError(<ToastMessageStructure firstMessage={t("app.toastMessages.PreContraEntSectionSignataireConventionNonRemplieError")} />);
    }
  }, [isValid, submittingAllSections, currentSectionBeingSubmitted]);
  const isDisabled = !(values.signatoryEmailForm2 && values.signatoryEmailForm2.length > 0);

  return (
    referencialData &&
    contractData && (
      <div className={styles.container}>
        <form onSubmit={handleSubmit}>
          <SubTitleInForm additionalClassNames={styles.subtitle_in_form}>
            {t("app.suivezVosContratsPartner.CoordonneesTitle")}
          </SubTitleInForm>
          <div className={styles.wrapper}>
            <FormInputField
              id="signatoryEmailForm2"
              label={t("app.suivezVosContratsPartner.EmailDuContact")}
              errors={errors.signatoryEmailForm2}
              values={values.signatoryEmailForm2}
              touched={touched.signatoryEmailForm2}
              loading={isEmployeeDataPending}
              onChange={(e) => {
                setFormStep(FORM);
                setFieldValue("signatoryEmailForm2", e.target.value);
              }}
            >
              <p>{t("app.suivezVosContratsPartner.EmailInfo")}</p>
            </FormInputField>
            <FormPhoneField
              type="text"
              name="fixPhone"
              id="fixPhone"
              country="FR"
              label={t("app.suivezVosContratsPartner.Telephone")}
              disabled={isDisabled}
              values={values.fixPhone}
              onChange={(e) => {
                setFieldValue("fixPhone", e);
              }}
              errors={errors.fixPhone}
              touched={submitCount > 0}
            />
            <FormPhoneField
              type="text"
              name="phone"
              id="phone"
              country="FR"
              label={t("app.suivezVosContratsPartner.Portable")}
              disabled={isDisabled}
              values={values.phone}
              onChange={(e) => {
                setFieldValue("phone", e);
              }}
              errors={errors.phone}
              touched={submitCount > 0}
            />
          </div>
          <SubTitleInForm additionalClassNames={styles.subtitle_in_form}>
            {t("app.suivezVosContratsPartner.IdentiteTitle")}
          </SubTitleInForm>
          <div className={styles.wrapper}>
            <FormInputField
              id="lastNameForm2"
              label={t("app.suivezVosContratsPartner.Nom")}
              errors={errors.lastNameForm2}
              values={values.lastNameForm2}
              touched={touched.lastNameForm2}
              disabled={isDisabled}
              onChange={(e) => setFieldValue("lastNameForm2", e.target.value)}
            />
            <FormInputField
              id="firstNameForm2"
              label={t("app.suivezVosContratsPartner.Prenom")}
              errors={errors.firstNameForm2}
              values={values.firstNameForm2}
              touched={touched.firstNameForm2}
              disabled={isDisabled}
              onChange={(e) => setFieldValue("firstNameForm2", e.target.value)}
            />
            <FormDropdownField
              id="civilityForm2"
              label={t("app.suivezVosContratsPartner.Civilite")}
              dataKey="key"
              textField="label"
              data={[{ ...objAucun }, ...(referencialData?.CIVILITY || [])]}
              disabled={isDisabled}
              errors={errors.civilityForm2}
              touched={touched.civilityForm2}
              values={values.civilityForm2}
              onChange={(newValue) => setFieldValue("civilityForm2", newValue)}
            />
          </div>
          <div className={styles.wrapper_double}>
            <FormInputField
              id="functionForm2"
              label={t("app.suivezVosContratsPartner.Fonction")}
              errors={errors.functionForm2}
              values={values.functionForm2}
              touched={touched.functionForm2}
              disabled={isDisabled}
              onChange={(e) => setFieldValue("functionForm2", e.target.value)}
            />
          </div>

          <div className={styles.checkbox_container}>
            <label htmlFor="habilitationForm2">{t("app.suivezVosContratsPartner.Habilitation")}</label>
            <FormCustomCheckBoxField
              text={t("app.suivezVosContratsPartner.PersonneHabilitee")}
              id="habilitationForm2"
              checkboxValue={values.habilitationForm2}
              handleChange={(e) => setFieldValue("habilitationForm2", e)}
              error={errors.habilitationForm2}
              touched={touched.habilitationForm2}
            />
          </div>
          <div className={styles.submit_btn_to_right}>
            <SubmitFormBtn text={t("app.visualisationOffre.ValidezLesModifications")} />
          </div>
        </form>
      </div>
    )
  );
};

export default Form2;
