import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";

import SubTitleInForm from "components/typography/SubTitleInForm/SubTitleInForm";
import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";

import {
  getEmployeesThunk,
  getEmployeeDetails,
  getCompanyDetailsIsPending,
} from "app/slices/checkDetailsCompany/checkDetailsCompany.slice";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";
import {
  patchContractDataThunk,
  selectContractDataFYC,
} from "app/slices/followYourContracts/followYourContracts.slice";
import { toastError, toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import { FormInputField } from "pages/ENT/FollowYourContracts/components/formFields/FormInputField/FormInputField";
import { FormDropdownField } from "pages/ENT/FollowYourContracts/components/formFields/FormDropdownField/FormDropdownField";
import { FormPhoneField } from "pages/ENT/FollowYourContracts/components/formFields/FormPhoneField/FormPhoneField";
import { createObjAucun } from "../../../../../../../../utils/localReferentials";

import styles from "./Form4.module.scss";

const Form4 = ({
  axiosCancelToken,
  submittingAllSections,
  submitNextSectionIfSubmitAllTrue,
  cancelSubmitAllSections,
  currentSectionBeingSubmitted,
  setFormIsValid,
  isOpen,
}) => {
  const dispatch = useDispatch();
  const isEmployeeDataPending = useSelector(getCompanyDetailsIsPending);
  const FORM = "FORM_4";

  const objAucun = createObjAucun();

  const phoneRegExp = "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$";
  const referencialData = useSelector(getReferentialsData);
  const employeeDetails = useSelector(getEmployeeDetails);
  const contractData = useSelector(selectContractDataFYC);
  const [formStep, setFormStep] = useState("");

  const timedOutRef = useRef(null);

  const [enableCivilityIfEmpty, setEnableCivilityIfEmpty] = useState(false);
  const [enableLastnameIfEmpty, setEnableLastnameIfEmpty] = useState(false);
  const [enableFirstnameIfEmpty, setEnableFirstnameIfEmpty] = useState(false);
  const [enableFixPhoneIfEmpty, setEnableFixPhoneIfEmpty] = useState(false);
  const [enablePhoneIfEmpty, setEnablePhoneIfEmpty] = useState(false);
  const [enableFunctionIfEmpty, setEnableFunctionIfEmpty] = useState(false);

  const handleResetStatesIfEmptyValue = () => {
    setEnableCivilityIfEmpty(false);
    setEnableLastnameIfEmpty(false);
    setEnableFirstnameIfEmpty(false);
    setEnableFixPhoneIfEmpty(false);
    setEnablePhoneIfEmpty(false);
    setEnableFunctionIfEmpty(false);
  };

  // Yup validation schema
  const Form4Schema = Yup.object().shape({
    takeInfoFrom: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    email: Yup.string().when(["takeInfoFrom"], {
      is: (takeInfoFrom) => takeInfoFrom?.key !== "-Aucun-",
      then: (schema) => schema.email(t("app.errorMessages.FormatEmailInvalide")).required(t("app.errorMessages.RemplirChamp")),
    }),

    fixPhone: Yup.string().when(["takeInfoFrom"], {
      is: (takeInfoFrom) => takeInfoFrom?.key !== "-Aucun-",
      then: (schema) => schema
        .test(
          "if-is-aucun",
          t("app.errorMessages.PhoneFormat"),
          (value) => value !== undefined && isValidPhoneNumber(value) !== false,
        )
        .required(t("app.errorMessages.RemplirChamp")),
    }),
    phone: Yup.string().matches(phoneRegExp, t("app.errorMessages.PhoneFormat")),
    lastName: Yup.string().when(["takeInfoFrom"], {
      is: (takeInfoFrom) => takeInfoFrom?.key !== "-Aucun-",
      then: (schema) => schema.min(2).required(t("app.errorMessages.RemplirChamp")),
    }),
    firstName: Yup.string().when(["takeInfoFrom"], {
      is: (takeInfoFrom) => takeInfoFrom?.key !== "-Aucun-",
      then: (schema) => schema.min(2).required(t("app.errorMessages.RemplirChamp")),
    }),
    function: Yup.string().when(["takeInfoFrom"], {
      is: (takeInfoFrom) => takeInfoFrom?.key !== "-Aucun-",
      then: (schema) => schema.min(2).required(t("app.errorMessages.RemplirChamp")).max(40, `${t("app.errorMessages.Max40")}`),
    }),

    civility: Yup.object().when(["takeInfoFrom"], {
      is: (takeInfoFrom) => takeInfoFrom?.key !== "-Aucun-",
      then: (schema) => schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
  });

  const onSubmit = async (values) => {
    const valuesCopy = structuredClone(values);
    // These functions change the value of certain keys depending on the swagger, before dataToSend initialization
    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);

    const dataToSend = {
      status: "En cours",
      company: {
        id: contractData?.company?.id,
        contacts: {
          administrative: {
            copyFrom: valuesCopy?.takeInfoFrom?.key,
            email: valuesCopy.email,
            civility: valuesCopy?.civility?.key,
            name: valuesCopy.lastName,
            firstname: valuesCopy.firstName,
            phones: {
              office: valuesCopy.fixPhone,
              cellular: valuesCopy.phone,
            },
            function: valuesCopy.function,
          },
        },
      },
    };

    try {
      await dispatch(
        patchContractDataThunk({
          dataToSend,
          id: contractData.id,
          axiosCancelToken,
        }),
      ).unwrap();

      submitNextSectionIfSubmitAllTrue(6);

      if (!submittingAllSections) {
        toastSuccess(<ToastMessageStructure secondMessage={t("app.toastMessages.ModifContactAdminFormation")} />);
      }
    } catch (error) {
      cancelSubmitAllSections();
      toastError(
        <ToastMessageStructure firstMessage={t("app.toastMessages.PreContraEntSectionReferentRHSubmitError")} secondMessage={error.message} />,
      );
    }
  };

  const { values, errors, touched, setFieldValue, handleSubmit, validateForm, isValid, submitCount } = useFormik({
    initialValues: {
      takeInfoFrom: contractData?.company?.contacts?.administrative?.copyFrom || { ...objAucun },
      email: contractData?.company?.contacts?.administrative?.email || "",
      civility: contractData?.company?.contacts?.administrative?.civility || { ...objAucun },
      lastName: contractData?.company?.contacts?.administrative?.name || "",
      firstName: contractData?.company?.contacts?.administrative?.firstname || "",
      phone: contractData?.company?.contacts?.administrative?.phones?.cellular || "",
      fixPhone: contractData?.company?.contacts?.administrative?.phones?.office || "",
      function: contractData?.company?.contacts?.administrative?.function || "",
    },
    validationSchema: Form4Schema,
    onSubmit,
  });

  const setFormFieldsValues = (value) => {
    setFieldValue("email", value?.email || "");
    setFieldValue("civility", value?.civility || { ...objAucun });
    setFieldValue("lastName", value?.name || "");
    setFieldValue("firstName", value?.firstname || "");
    setFieldValue("phone", value?.phones?.cellular || "");
    setFieldValue("fixPhone", value?.phones?.office || "");
    setFieldValue("function", value?.function || "");
  };

  const setInfoFrom = (value, update) => {
    switch (value?.key) {
    case "Contact signataire":
      setFormFieldsValues(contractData?.company?.contacts?.signatory);
      break;
    case "Contact établissement du contrat":
      setFormFieldsValues(contractData?.company?.contacts?.contractDrafter);
      break;
    case "Contact facturation":
      setFormFieldsValues(contractData?.company?.contacts?.billing);
      break;
    case "Contact tuteur / maître d’apprentissage":
      setFormFieldsValues(contractData?.company?.contacts?.tutor);
      break;
    case "Contact administratif":
      setFormFieldsValues(contractData?.company?.contacts?.administrative);
      break;
    case "Aucun":
      if (!update) {
        setFormFieldsValues(null);
      } else {
        setFormFieldsValues(contractData?.company?.contacts?.administrative);
      }
      break;
    case "-Aucun-":
      setFormFieldsValues(null);
      break;
    default:
      break;
    }
  };

  const displayUserData = () => {
    if (FORM === formStep) {
      setFieldValue("lastName", employeeDetails[0]?.name ? employeeDetails[0]?.name : "");
      setFieldValue("firstName", employeeDetails[0]?.firstname ? employeeDetails[0]?.firstname : "");
      setFieldValue("civility", employeeDetails[0]?.civility ? employeeDetails[0]?.civility : { ...objAucun });
      setFieldValue("fixPhone", employeeDetails[0]?.phones?.office ? employeeDetails[0]?.phones?.office : "");
      setFieldValue("phone", employeeDetails[0]?.phones?.cellular ? employeeDetails[0]?.phones?.cellular : "");
      setFieldValue("function", employeeDetails[0]?.function ? employeeDetails[0]?.function : "");
      setFormStep("");
    }
  };

  const displayDefaultData = () => {
    if (FORM === formStep) {
      setFieldValue("civility", { ...objAucun });
      setFieldValue("lastName", "");
      setFieldValue("firstName", "");
      setFieldValue("fixPhone", "");
      setFieldValue("phone", "");
      setFieldValue("function", "");
      setFormStep("");
    }
  };

  useEffect(() => {
    setInfoFrom(values.takeInfoFrom, true);
  }, [contractData]);

  useEffect(() => {
    if (values.takeInfoFrom.key === "Aucun") {
      if (!errors.email && values.email.length > 1) {
        if (timedOutRef.current) clearTimeout(timedOutRef.current);
        timedOutRef.current = setTimeout(() => {
          (async () => {
            try {
              await dispatch(
                getEmployeesThunk({
                  email: values.email,
                  axiosCancelToken,
                }),
              ).unwrap();
              handleResetStatesIfEmptyValue();
              validateForm();
            } catch (error) {
              handleResetStatesIfEmptyValue();
              if (isOpen) {
                toastError(
                  <ToastMessageStructure
                    firstMessage={t("app.toastMessages.PreContraEntGetEmployeeError")}
                    secondMessage={error.message}
                  />,
                );
              }
            }
          })();
        }, "500");
      } else {
        setFieldValue("civility", { ...objAucun });
        setFieldValue("lastName", "");
        setFieldValue("firstName", "");
        setFieldValue("fixPhone", "");
        setFieldValue("phone", "");
        setFieldValue("function", "");
      }
    }
  }, [errors.email, values.email]);

  useLayoutEffect(() => {
    if (employeeDetails) {
      if (employeeDetails.length > 0 && formStep === FORM) {
        displayUserData();
      } else displayDefaultData();
    }
  }, [employeeDetails]);

  // useLayoutEffect to force Formik's isValid check, the first time the component is mounted
  useLayoutEffect(() => {
    validateForm();
  }, []);

  // useLayoutEffect to control the color of the DropdownSection circle.
  useLayoutEffect(() => {
    if (isValid) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [isValid]);

  // useLayoutEffect to manage the trigger at the right time for sending this form, during global submission.
  useLayoutEffect(() => {
    const thisFormNumber = 4;

    if (isValid && submittingAllSections && currentSectionBeingSubmitted === thisFormNumber) {
      handleSubmit();
    } else if (!isValid && submittingAllSections && currentSectionBeingSubmitted === thisFormNumber) {
      handleSubmit();
      cancelSubmitAllSections();
      toastError(<ToastMessageStructure firstMessage={t("app.toastMessages.PreContraEntSectionReferentRHNonRemplieError")} />);
    }
  }, [isValid, submittingAllSections, currentSectionBeingSubmitted]);

  const isDisabled = !(values.takeInfoFrom?.key === "Aucun" && values.email && values.email.length > 0);
  const isMailDisabled = !(values.takeInfoFrom?.key === "Aucun");

  useEffect(() => {
    if (values?.takeInfoFrom?.key !== "-Aucun-" && values.email !== "") {
      if (values?.civility?.key === "-Aucun-") {
        setEnableCivilityIfEmpty(true);
      }
      if (!values?.lastName) {
        setEnableLastnameIfEmpty(true);
      }
      if (!values?.firstName) {
        setEnableFirstnameIfEmpty(true);
      }
      if (!values?.fixPhone) {
        setEnableFixPhoneIfEmpty(true);
      }
      if (!values?.phone) {
        setEnablePhoneIfEmpty(true);
      }
      if (!values?.function) {
        setEnableFunctionIfEmpty(true);
      }
    } else {
      handleResetStatesIfEmptyValue();
    }
  }, [values]);

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit}>
        <div className={styles.row_grid}>
          <FormDropdownField
            id="takeInfoFrom"
            label={t("app.suivezVosContratsPartner.ReprendreInfo")}
            values={values.takeInfoFrom}
            touched={touched.takeInfoFrom}
            errors={errors.takeInfoFrom}
            data={[{ ...objAucun }, ...(referencialData?.COMPANY_CONTACT_TYPE_TO_COPY_FOR_ADMIN_CONTACT || [])]}
            value={values.takeInfoFrom}
            onChange={(e) => {
              setInfoFrom(e, false);
              setFieldValue("takeInfoFrom", e);
            }}
          />
        </div>
        <SubTitleInForm additionalClassNames={styles.subtitle_in_form}>
          {t("app.suivezVosContratsPartner.CoordonneesTitle")}
        </SubTitleInForm>
        <div className={styles.row_grid}>
          <FormInputField
            id="email"
            label={t("app.suivezVosContratsPartner.EmailDuContact")}
            errors={errors.email}
            values={values.email}
            touched={touched.email}
            loading={isEmployeeDataPending}
            disabled={isMailDisabled}
            additionalClassNames={styles.formGroup}
            onChange={(e) => {
              setFormStep(FORM);
              setFieldValue("email", e.target.value);
            }}
          >
            <p>{t("app.suivezVosContratsPartner.EmailInfo")}</p>
          </FormInputField>
          <FormPhoneField
            id="fixPhone"
            label={t("app.suivezVosContratsPartner.Telephone")}
            errors={errors.fixPhone}
            values={values.fixPhone}
            touched={submitCount > 0}
            disabled={isDisabled && !enableFixPhoneIfEmpty && true}
            onChange={(e) => {
              setFieldValue("fixPhone", e);
            }}
          />
          <FormInputField
            id="phone"
            label={t("app.suivezVosContratsPartner.Portable")}
            errors={errors.phone}
            values={values.phone}
            touched={touched.phone}
            disabled={isDisabled && !enablePhoneIfEmpty && true}
            onChange={(e) => setFieldValue("phone", e.target.value)}
          />
        </div>
        <SubTitleInForm additionalClassNames={styles.subtitle_in_form}>
          {t("app.suivezVosContratsPartner.IdentiteTitle")}
        </SubTitleInForm>
        <div className={styles.row_grid}>
          <FormInputField
            id="lastName"
            label={t("app.suivezVosContratsPartner.Nom")}
            errors={errors.lastName}
            values={values.lastName}
            touched={touched.lastName}
            disabled={isDisabled && !enableLastnameIfEmpty && true}
            onChange={(e) => setFieldValue("lastName", e.target.value)}
          />
          <FormInputField
            id="firstName"
            label={t("app.suivezVosContratsPartner.Prenom")}
            errors={errors.firstName}
            values={values.firstName}
            touched={touched.firstName}
            disabled={isDisabled && !enableFirstnameIfEmpty && true}
            onChange={(e) => setFieldValue("firstName", e.target.value)}
          />
          <FormDropdownField
            id="civility"
            label={t("app.suivezVosContratsPartner.Civilite")}
            errors={errors.civility}
            values={values.civility}
            touched={touched.civility}
            data={[{ ...objAucun }, ...(referencialData?.CIVILITY || [])]}
            disabled={isDisabled && !enableCivilityIfEmpty && true}
            value={values.civility}
            onChange={(newValue) => setFieldValue("civility", newValue)}
          />
        </div>
        <div className={styles.row_grid_double}>
          <FormInputField
            id="function"
            label={t("app.suivezVosContratsPartner.Fonction")}
            errors={errors.function}
            values={values.function}
            touched={touched.function}
            disabled={isDisabled && !enableFunctionIfEmpty && true}
            onChange={(e) => setFieldValue("function", e.target.value)}
          />
        </div>
        <div className={styles.submit_btn_to_right}>
          <SubmitFormBtn text={t("app.visualisationOffre.ValidezLesModifications")} />
        </div>
      </form>
    </div>
  );
};

export default Form4;
