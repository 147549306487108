import { t } from "i18next";
import axios from "axios";

import { useState, useLayoutEffect } from "react";
import { Link } from "react-router-dom";

import { nanoid } from "@reduxjs/toolkit";

import { useDispatch, useSelector } from "react-redux";
import { getUserToken } from "app/slices/userConnexion/userConnexion.slice";
import {
  getContractsListThunk,
  nextPaginationContractsListThunk,
  previousPaginationContractsListThunk,
  resetContractsListState,
  selectContractsListFYC,
  selectCurrentPaginationFYC,
  selectMaxPaginationFYC,
  selectPendingFYC,
} from "app/slices/followYourContracts/followYourContracts.slice";

import { toastError } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import Loader from "components/Loader/Loader";
import Header from "components/Header/Header";

import arrow from "../assets/icon-arrow-right.svg";
import arrowPagination from "../assets/icon-arrow-pagination.svg";
import bgContractImg from "../assets/bg-consul-offre.png";

import styles from "./ContractsListView.module.scss";

const ContractsListView = () => {
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const dispatch = useDispatch();

  const contractsList = useSelector(selectContractsListFYC);
  console.log("contractsList", contractsList);
  const currentPagination = useSelector(selectCurrentPaginationFYC);
  const maxPagination = useSelector(selectMaxPaginationFYC);
  const pending = useSelector(selectPendingFYC);

  const userToken = useSelector(getUserToken);

  const [firstLoad, setFirstLoad] = useState(false);

  // useLayoutEffect to get list of contracts
  useLayoutEffect(() => {
    if (!pending && userToken && !firstLoad) {
      (async () => {
        try {
          await dispatch(getContractsListThunk({ axiosCancelToken })).unwrap();
          setFirstLoad(true);
          console.log("useLayoutEffect success ! getContractsListThunk");
        } catch (error) {
          if (error.message !== "canceled") {
            toastError(<ToastMessageStructure secondMessage={error.message} />);
          }

          console.log("useLayoutEffect fail ! getContractsListThunk", error.message);
        }
      })();
    }
  }, [userToken]);

  // For cleanup function only
  useLayoutEffect(
    () => () => {
      axiosCancelToken.cancel();
      dispatch(resetContractsListState());
      console.log("RESET : resetContractsListState");
    },
    [],
  );

  return (
    <>
      {pending && <Loader />}
      {contractsList && (
        <>
          <Header h1={t("app.SuivezVosContrats.SuivezContrats")} bgImg={bgContractImg} />
          <div className={styles.containerResult}>
            <div className={styles.containerResult__head}>
              <div className={styles.containerResult__head__title}>{t("app.SuivezVosContrats.NomEtudiant")}</div>
              <div className={styles.containerResult__head__title}>{t("app.SuivezVosContrats.EcoleFormation")}</div>
              <div className={styles.containerResult__head__title}>{t("app.SuivezVosContrats.DateDebut")}</div>
              <div className={styles.containerResult__head__title}>{t("app.SuivezVosContrats.Programme")}</div>
              <div className={styles.containerResult__head__title}>{t("app.SuivezVosContrats.Statut")}</div>
            </div>
            <div className={styles.allResults}>
              {contractsList?.map((item) => (
                <div className={styles.result} key={nanoid()}>
                  <div className={styles.result__body__id}>
                    <span>{item?.student?.person?.name}</span>
                    <span> {item?.student?.person?.firstname}</span>
                  </div>
                  <div className={styles.result__body}>
                    <span className={styles.hideDesktop}>{t("app.SuivezVosContrats.EcoleFormation")}</span>
                    <span>{item?.student?.schoolProgramEnrollment?.product?.trainingSchool?.name}</span>
                  </div>
                  <div className={styles.result__body}>
                    <span className={styles.hideDesktop}>{t("app.SuivezVosContrats.DateDebut")}</span>
                    <span className={styles.date}>{item?.student?.schoolProgramEnrollment?.startDate}</span>
                  </div>
                  <div className={styles.result__body}>
                    <span className={styles.hideDesktop}>{t("app.SuivezVosContrats.Programme")}</span>
                    <span>{item?.student?.schoolProgramEnrollment?.product?.name}</span>
                  </div>
                  <div className={styles.result__body}>
                    <span className={styles.hideDesktop}>{t("app.SuivezVosContrats.Statut")}</span>
                    <span className={styles.status}>{item?.statusForCustomerDisplay?.label}</span>
                  </div>
                  <div className={styles.result__body__link}>
                    <Link to={`/suivez-vos-contrats/${item?.id?.toString()}`}>
                      <div className={styles.btn_seeMore} role="button" tabIndex={0}>
                        <span>{t("app.SuivezVosContrats.Consulter")}</span>
                        <img src={arrow} alt={t("app.allOffers.btnDetails")} />
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.arrowPagination}>
            <button
              disabled={currentPagination <= 1}
              onClick={() => {
                if (!pending) {
                  dispatch(
                    previousPaginationContractsListThunk({
                      axiosCancelToken,
                    }),
                  );
                }
              }}
              className={styles.arrowLeft}
              type="button"
            >
              <img src={arrowPagination} alt="prev" />
            </button>
            <span className={styles.totalPage}>PAGE</span>
            {currentPagination}/{maxPagination}
            <button
              disabled={currentPagination >= maxPagination}
              onClick={() => {
                if (!pending) {
                  dispatch(
                    nextPaginationContractsListThunk({
                      axiosCancelToken,
                    }),
                  );
                }
              }}
              type="button"
            >
              <img src={arrowPagination} alt="prev" />
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default ContractsListView;
