import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";
import { InputLoader } from "components/InputLoader/InputLoader";
import { useState } from "react";
import styles from "./FormInputFieldWithIcon.module.scss";

export const FormInputFieldWithIcon = ({
  id,
  label,
  errors,
  values,
  touched,
  onChange,
  min = 0,
  type = "text",
  disabled = false,
  additionalClassNames = "",
  children,
  icon,
  loading = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };
  return (
    <div
      className={`${styles.formGroup} input-like-rw ${additionalClassNames}`}
    >
      <label htmlFor={id}>{label}</label>
      <div className={styles.input_container}>
        <input
          id={id}
          type={type}
          className={errors && touched && styles.error_input}
          min={type === "number" ? min : undefined}
          value={values}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
        />
        {icon}
        {loading && isFocused &&
        <InputLoader additionalClassNames={styles.loader} />}
      </div>
      {children}
      <InputErrorMessage message={errors} touched={touched} />
    </div>
  );
};
