import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";
import { InputLoader } from "components/InputLoader/InputLoader";
import { useState } from "react";
import styles from "./FormInputField.module.scss";

export const FormInputField = ({
  id,
  label,
  errors,
  values,
  touched,
  onChange,
  min = 0,
  type = "text",
  disabled = false,
  additionalClassNames = "",
  loading = false,
  children,
  step,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className={`${styles.formGroup} input-like-rw  ${additionalClassNames}`}>
      <label htmlFor={id}>{label}</label>
      <div className={styles.input_container}>
        <input
          id={id}
          type={type}
          className={errors && touched && styles.error_input}
          min={type === "number" ? min : undefined}
          value={values}
          onChange={onChange}
          disabled={disabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
          step={type === "number" ? step : undefined}
        />
        {loading && isFocused && <InputLoader additionalClassNames={styles.loader} />}
      </div>
      {children}
      <InputErrorMessage message={errors} touched={touched} />
    </div>
  );
};
