import { useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { axiosCompany } from "app/axiosInstances/axiosInstances";
import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  changeAucunToNull,
  changeOuiOrNonToBoolean,
  changeEmptyStringToNull,
  removeEverythingExceptNumbers,
} from "utils/tools";
import { createObjAucun } from "utils/localReferentials";
import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";
import {
  patchContractDataThunk,
  selectCompanyEditRightsFYC,
  selectContactAffiliatedCompanyFYC,
  selectContractDataFYC,
  selectLocalCompanyIdFYC,
  setCompanyEditRights,
  setContactAffiliatedCompany,
  setLocalCompanyId,
} from "app/slices/followYourContracts/followYourContracts.slice";
import {
  getSiretThunk,
  getAllCompanies,
  postCompanyThunk,
  getAllCompaniesIsPending,
  patchCompanyThunk,
  selectPostAndPatchCompanyIsPending,
} from "app/slices/checkDetailsCompany/getAllCompanies.slice";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";

import { toastError, toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";

import { FormInputField } from "pages/ENT/FollowYourContracts/components/formFields/FormInputField/FormInputField";
import { FormDropdownField } from "pages/ENT/FollowYourContracts/components/formFields/FormDropdownField/FormDropdownField";
import FormCustomCheckBoxField from "pages/ENT/FollowYourContracts/components/formFields/FormCustomCheckBoxField/FormCustomCheckBoxField";
import { FormInputFieldWithIcon } from "pages/ENT/FollowYourContracts/components/formFields/FormInputFieldWithIcon/FormInputFieldWithIcon";
import InformationTextInForm from "components/typography/InformationTextInForm/InformationTextInForm";
import { getMeDataV2 } from "app/slices/userConnexion/userConnexion.slice";
import { InfoBulle } from "./components/InfoBulle/InfoBulle";
import { ReactComponent as Pencil } from "./assets/search.svg";
import { ReactComponent as BackArrow } from "./assets/backArrow.svg";
import codeNafSvg from "../../../../../assets/codeNaf.svg";
import handGlass from "../../../../../assets/hand_glass.svg";
import legalForms from "../../../../../assets/legal_forms.svg";

import styles from "./Form1.module.scss";

const Form1 = ({
  axiosCancelToken,
  submittingAllSections,
  submitNextSectionIfSubmitAllTrue,
  cancelSubmitAllSections,
  currentSectionBeingSubmitted,
  setFormIsValid,
  isOpen,
}) => {
  const dispatch = useDispatch();
  const isSiretDataPending = useSelector(getAllCompaniesIsPending);
  const postAndPatchCompanyIsPending = useSelector(selectPostAndPatchCompanyIsPending);

  const objAucun = createObjAucun();

  const referencialData = useSelector(getReferentialsData);
  const contractData = useSelector(selectContractDataFYC);
  const localCompanyId = useSelector(selectLocalCompanyIdFYC);

  const [codeIdccValue, setCodeIdccValue] = useState(contractData?.company?.collectiveAgreement?.code || "");
  const [apiResponse2, setApiResponse2] = useState([]);
  const allSiretInfo = useSelector(getAllCompanies);
  const employeeInfo = useSelector(getMeDataV2);
  const [idccCallLoading, setIdccCallLoading] = useState(false);

  const companyEditRights = useSelector(selectCompanyEditRightsFYC);
  const contactAffiliatedCompany = useSelector(selectContactAffiliatedCompanyFYC);

  const Form1Schema = Yup.object().shape({
    companyName: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    siret: Yup.string()
      .matches(/^[0-9]+$/, `${t("app.errorMessages.siretFormatNumber")}`)
      .min(14, `${t("app.errorMessages.siretFormatMinimum")}`)
      .max(14, `${t("app.errorMessages.siretFormatMaximum")}`)
      .required(`${t("app.errorMessages.RemplirChamp")}`),
    codeNaf: Yup.object({
      code: Yup.string(),
      label: Yup.string(),
    }).test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.code),
    codeIdcc: Yup.object({
      code: Yup.string(),
      label: Yup.string(),
    }).test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.code),
    legalStatus: Yup.object({
      code: Yup.string(),
      label: Yup.string(),
    }).test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.code),
    employerType: Yup.object({
      key: Yup.string(),
      label: Yup.string(),
    }).test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    VAT: Yup.string()
      .matches(/^(?:((FR)?[0-9A-Z]{2}[0-9]{9}))$/, `${t("app.errorMessages.TVAFormat")}`)
      .required(`${t("app.errorMessages.RemplirChamp")}`),
    pensionFund: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
    workForce: Yup.string()
      .matches(/^[0-9]+$/, `${t("app.errorMessages.numberFormat")}`)
      .required(`${t("app.errorMessages.RemplirChamp")}`),
    socialRegime: Yup.object({
      key: Yup.string(),
      label: Yup.string(),
    }).test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    address: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
    zipCode: Yup.string()
      .matches(/^[0-9]+$/, `${t("app.errorMessages.numberFormat")}`)
      .min(5, `${t("app.errorMessages.NombreItemRequis")}`)
      .max(5, `${t("app.errorMessages.NombreItemRequis")}`)
      .required(`${t("app.errorMessages.RemplirChamp")}`),
    city: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
    certifiedIdentifierCodeByCompany: Yup.boolean().oneOf([true], t("app.errorMessages.CertifiedCompanyData")),
  });

  const onSubmit = async (values) => {
    if (localCompanyId) {
      const valuesCopy = structuredClone(values);

      changeAucunToNull(valuesCopy);
      changeOuiOrNonToBoolean(valuesCopy);
      changeEmptyStringToNull(valuesCopy);

      try {
        const dataToSend = {
          status: "En cours",
          company: {
            id: localCompanyId,
            certifiedIdentifierCodeByCompany: values?.certifiedIdentifierCodeByCompany,
          },
        };

        if (contactAffiliatedCompany) {
          const dataToSendCompany = {
            activities:
              allSiretInfo?.[0]?.activities?.[0]?.code !== "" ? [allSiretInfo?.[0]?.activities?.[0]?.code] : null,
            legalForm: allSiretInfo?.[0]?.legalForm?.code,
            collectiveAgreement: values?.codeIdcc?.code,
            employerType: values?.employerType?.key,
            vatNumber: values?.VAT,
            pensionFund: values?.pensionFund,
            workforce: {
              number: parseInt(values.workForce, 10),
            },
            socialSecuritySystem: values?.socialRegime?.key,
            providentFund: values?.providentOrganization,
            addresses: {
              headquarters: {
                street: values?.address,
                additionalAddress: values?.additionalAddress || null,
                zipCode: values?.zipCode,
                city: values?.city,
              },
            },
          };

          await dispatch(
            patchCompanyThunk({
              dataToSend: dataToSendCompany,
              id: localCompanyId,
              axiosCancelToken,
            }),
          ).unwrap();
        }

        await dispatch(
          patchContractDataThunk({
            dataToSend,
            id: contractData.id,
            axiosCancelToken,
          }),
        ).unwrap();

        submitNextSectionIfSubmitAllTrue(2);

        if (!submittingAllSections) {
          toastSuccess(<ToastMessageStructure secondMessage={t("app.toastMessages.ModifInformationsEntreprise")} />);
        }
      } catch (error) {
        cancelSubmitAllSections();
        toastError(
          <ToastMessageStructure
            firstMessage={t("app.toastMessages.PreContraEntSectionInformationsSurEntrepriseSubmitError")}
            secondMessage={error.message}
          />,
        );
      }
    } else if (!localCompanyId) {
      let newIdToPatch = null;

      try {
        await dispatch(
          postCompanyThunk({
            dataToSend: {
              siret: allSiretInfo?.[0]?.siret,
              name: allSiretInfo?.[0]?.name,
              addresses: {
                headquarters: {
                  street: values.address,
                  additionalAddress: values?.additionalAddress || null,
                  zipCode: values.zipCode,
                  city: values.city,
                },
              },
              activities:
                allSiretInfo?.[0]?.activities?.[0]?.code !== "" ? [allSiretInfo?.[0]?.activities?.[0]?.code] : null,
              legalForm: allSiretInfo?.[0]?.legalForm?.code,
              active: allSiretInfo?.[0]?.active || false,
              employerType: values.employerType?.key,
              vatNumber: values.VAT !== "" ? values.VAT : null,
              workforce: { number: parseInt(values.workForce, 10) },
              socialSecuritySystem: values?.socialRegime?.key,
              pensionFund: values.pensionFund,
              collectiveAgreement: values?.codeIdcc?.code,
              providentFund: values.providentOrganization !== "" ? values.providentOrganization : null,
            },
            axiosCancelToken,
          }),
        )
          .unwrap()
          .then((res) => {
            newIdToPatch = res.id;
            dispatch(setLocalCompanyId(res.id));
          });

        const dataToSendContractData = {
          status: "En cours",
          company: {
            id: newIdToPatch,
            certifiedIdentifierCodeByCompany: values?.certifiedIdentifierCodeByCompany,
          },
        };

        try {
          await dispatch(
            patchContractDataThunk({
              dataToSend: dataToSendContractData,
              id: contractData.id,
              axiosCancelToken,
            }),
          ).unwrap();
          submitNextSectionIfSubmitAllTrue(2);

          if (!submittingAllSections) {
            toastSuccess(<ToastMessageStructure secondMessage={t("app.toastMessages.ModifInformationsEntreprise")} />);
          }
        } catch (error) {
          cancelSubmitAllSections();
          toastError(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.PreContraEntSectionInformationsSurEntrepriseSubmitError")}
              secondMessage={error.message}
            />,
          );
        }
      } catch (err) {
        toastError(
          <ToastMessageStructure
            firstMessage={t("app.toastMessages.PreContraEntSectionInformationsSurEntrepriseNewCompanyError")}
            secondMessage={err.message}
          />,
        );
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    isValid,
    validateForm,
    setFieldError,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      companyName: "",
      siret: contractData?.company?.siret || "",
      codeNaf: { code: "", label: "" },
      legalStatus: { code: "", label: "" },
      descriptionNaf: "",
      codeIdcc: { code: "", label: "" },
      employerType: { ...objAucun },
      VAT: "",
      pensionFund: "",
      workForce: "",
      socialRegime: { ...objAucun },
      providentOrganization: "",
      address: "",
      city: "",
      additionalAddress: "",
      zipCode: "",
      certifiedIdentifierCodeByCompany: contractData?.company?.certifiedIdentifierCodeByCompany || false,
    },
    validationSchema: Form1Schema,
    onSubmit,
  });

  const handleSiretCalls = (siret) => {
    (async () => {
      // const isSiret = true;

      try {
        await dispatch(
          getSiretThunk({
            siret,
            axiosCancelToken,
          }),
        )
          .unwrap()
          .then((res) => {
            if (res.length > 0) {
              const firstRes = res?.[0];

              setFieldValue("companyName", firstRes?.name || "");
              setFieldValue("codeIdcc", {
                code: firstRes?.collectiveAgreement?.code || "",
                label: firstRes?.collectiveAgreement?.label || "",
              });
              setCodeIdccValue(firstRes?.collectiveAgreement?.code || "");
              setFieldValue(
                "codeNaf",
                firstRes?.activities?.length > 0
                  ? {
                      code: firstRes?.activities[0]?.code || "",
                      label: firstRes?.activities[0]?.label || "",
                    }
                  : {
                      code: "",
                      label: "",
                    },
              );
              setFieldValue("legalStatus", {
                code: firstRes?.legalForm?.code || "",
                label: firstRes?.legalForm?.label || "",
              });
              setFieldValue("siret", siret);
              setFieldValue("descriptionNaf", firstRes?.activities?.[0]?.label || "");
              setFieldValue("address", firstRes?.addresses?.headquarters?.street || "");
              setFieldValue("city", firstRes?.addresses?.headquarters?.city || "");
              setFieldValue("zipCode", firstRes?.addresses?.headquarters?.zipCode || "");
              setFieldValue("additionalAddress", firstRes?.addresses?.headquarters?.additionalAddress || "");
              setFieldValue("employerType", firstRes?.employerType || { ...objAucun });
              setFieldValue("legalStatus", firstRes?.legalForm || { code: "", label: "" });
              setFieldValue("VAT", firstRes?.vatNumber || "");
              setFieldValue("socialRegime", firstRes?.socialSecuritySystem || { ...objAucun });
              setFieldValue("providentOrganization", firstRes?.providentFund || "");
              setFieldValue("workForce", firstRes?.workforce?.number || "");
              setFieldValue("pensionFund", firstRes?.pensionFund || "");
            } else {
              setFieldError("siret", t("app.errorMessages.siretNotFound"));
              setFieldTouched("siret", true, false);
            }

            if (res?.[0]?.id) {
              dispatch(setLocalCompanyId(res?.[0]?.id));
            } else {
              dispatch(setLocalCompanyId(undefined));
            }
          });
      } catch (error) {
        if (isOpen) {
          toastError(
            <ToastMessageStructure firstMessage={t("app.PDFSection.ErreurSiret")} secondMessage={error.message} />,
          );
        }
      }
    })();
  };

  const deleteFieldValueWithoutSiret = () => {
    setFieldValue("companyName", "");
    setFieldValue("codeNaf", { code: "", label: "" });
    setFieldValue("codeIdcc", { code: "", label: "" });
    setFieldValue("descriptionNaf", "");
    setFieldValue("employerType", { ...objAucun });
    setFieldValue("legalStatus", { code: "", label: "" });
    setFieldValue("VAT", "");
    setFieldValue("pensionFund", "");
    setFieldValue("workForce", "");
    setFieldValue("socialRegime", { key: "-Aucun-", label: "- Sélectionner -" });
    setFieldValue("providentOrganization", "");
    setFieldValue("address", "");
    setFieldValue("additionalAddress", "");
    setFieldValue("city", "");
    setFieldValue("zipCode", "");
    setFieldValue("certifiedIdentifierCodeByCompany", false);
    setCodeIdccValue("");
  };

  const handleCodeIdccInputChange = async (event) => {
    const { value } = event.target;
    setCodeIdccValue(value);
    if (value !== values.codeIdcc?.code) {
      setFieldValue("codeIdcc", { code: "", label: "" });
    }
    if (value.length > 1) {
      setIdccCallLoading(true);
      const response = await axiosCompany().get(`/v1/companies/collective-agreements?code=*${value}*`);
      setApiResponse2(response.data);
      setIdccCallLoading(false);
    } else {
      setApiResponse2([]);
    }
  };

  const handleSelectCodeIdcc = (option) => {
    setFieldValue("codeIdcc", option);
    setCodeIdccValue(option.code);
    setApiResponse2([]);
  };

  // useLayoutEffect to force Formik's isValid check, the first time the component is mounted + when values are changed (descriptionNaf as an indicator )
  useLayoutEffect(() => {
    validateForm();
  }, [values.descriptionNaf]);

  // useLayoutEffect to control the color of the DropdownSection circle.
  useLayoutEffect(() => {
    if (isValid) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [isValid]);

  // useLayoutEffect to manage the trigger at the right time for sending this form, during global submission.
  useLayoutEffect(() => {
    const thisFormNumber = 1;

    if (isValid && submittingAllSections && currentSectionBeingSubmitted === thisFormNumber) {
      handleSubmit();
    } else if (!isValid && submittingAllSections && currentSectionBeingSubmitted === thisFormNumber) {
      handleSubmit();
      cancelSubmitAllSections();
      toastError(
        <ToastMessageStructure
          firstMessage={t("app.toastMessages.PreContraEntSectionInformationsSurEntrepriseNonRemplieError")}
        />,
      );
    }
  }, [isValid, submittingAllSections, currentSectionBeingSubmitted]);

  useLayoutEffect(() => {
    handleSiretCalls(removeEverythingExceptNumbers(contractData?.company?.siret));
  }, []);

  useLayoutEffect(() => {
    if (
      employeeInfo?.companyId === localCompanyId ||
      employeeInfo?.affiliatedCompanyIds?.includes(localCompanyId) ||
      !localCompanyId
    ) {
      dispatch(setCompanyEditRights(true));
    } else {
      dispatch(setCompanyEditRights(false));
    }

    if (employeeInfo?.companyId === localCompanyId || employeeInfo?.affiliatedCompanyIds?.includes(localCompanyId)) {
      dispatch(setContactAffiliatedCompany(true));
    } else {
      dispatch(setContactAffiliatedCompany(false));
    }
  }, [employeeInfo, localCompanyId]);

  console.log("values amar", values);

  return (
    referencialData &&
    contractData && (
      <div className={styles.container}>
        <form onSubmit={handleSubmit}>
          <div className={styles.input_group_line}>
            <FormInputFieldWithIcon
              label={t("app.suivezVosContratsPartner.siretLabel")}
              id="siret"
              name="siret"
              values={values.siret}
              touched={touched.siret}
              errors={errors.siret}
              additionalClassNames={styles.siret_container}
              loading={isSiretDataPending}
              onChange={(e) => {
                if (!isSiretDataPending) {
                  deleteFieldValueWithoutSiret();
                  setFieldTouched("siret", true, false);
                  const siretWithoutSpace = removeEverythingExceptNumbers(e.target.value);
                  setFieldValue("siret", removeEverythingExceptNumbers(e.target.value));
                  if (siretWithoutSpace.length === 14) {
                    handleSiretCalls(siretWithoutSpace);
                  }
                }
              }}
              icon={(
                <div className={styles.back_arrow_icon}>
                  <BackArrow
                    onClick={() => {
                      setFieldValue("certifiedIdentifierCodeByCompany", false);
                      handleSiretCalls(removeEverythingExceptNumbers(contractData?.company?.siret));
                    }}
                  />
                  <div className={styles.tooltip}>{t("app.suivezVosContratsPartner.siretTooltip")}</div>
                </div>
              )}
            >
              <p className={styles.siret_text}>{t("app.suivezVosContratsPartner.SiretInfo")}</p>
            </FormInputFieldWithIcon>
            <FormInputField
              disabled
              values={values.companyName}
              errors={errors.companyName}
              label={t("app.suivezVosContratsPartner.NomDeLentreprise")}
              id="companyName"
            />
          </div>
          <div className={styles.input_group_line}>
            <FormDropdownField
              label={t("app.suivezVosContratsPartner.CodeNaf")}
              id="codeNaf"
              errors={errors.codeNaf}
              values={values.codeNaf}
              selectIcon={<img src={handGlass} alt="handGlass" style={{ width: "1.2rem" }}></img>}
              onChange={(value) => {
                setFieldValue("codeNaf", value);
              }}
              renderValue={() => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0",
                  }}
                >
                  <img src={codeNafSvg} style={{ width: "1.2rem" }} alt="test"></img>
                  <div
                    style={{
                      paddingLeft: "2rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {values.codeNaf?.code}
                  </div>
                </div>
              )}
              disabled
            />
            <FormInputField
              disabled
              id="descriptionNaf"
              label={t("app.suivezVosContratsPartner.CodeNafDescription")}
              values={values.descriptionNaf}
              errors={errors.descriptionNaf}
            />
            <div className={styles.idcc_group}>
              <FormInputFieldWithIcon
                id="codeIdcc"
                label={t("app.suivezVosContratsPartner.CodeIdcc")}
                values={codeIdccValue}
                errors={errors.codeIdcc}
                touched={touched.codeIdcc}
                icon={(
                  <>
                    <img className={styles.idcc_icon} src={codeNafSvg} alt="test"></img>
                    <Pencil className={styles.search_icon} />
                  </>
                )}
                onChange={handleCodeIdccInputChange}
                additionalClassNames={`${styles.input_margin} ${styles.input_padding}`}
                disabled={!companyEditRights}
                loading={idccCallLoading}
              />
              <div className={apiResponse2.length > 0 ? styles.output_container : undefined}>
                <ul>
                  {apiResponse2.map((result) => (
                    <div>
                      <div key={result.code} className={styles.output_plus_image_container}>
                        <img src={codeNafSvg} style={{ width: "1.8rem" }} alt="test"></img>
                        <div
                          className={styles.outputKeyLabel}
                          onClick={() => handleSelectCodeIdcc(result)}
                          onKeyDown={(e) => e.key === "Enter" && handleSelectCodeIdcc(result)}
                          role="button"
                          tabIndex={0}
                        >
                          <li>{result.code}</li>
                          <li> {result.label}</li>
                        </div>
                      </div>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
            <FormDropdownField
              label={t("app.suivezVosContratsPartner.TypeEmployeur")}
              disabled={!companyEditRights}
              values={values.employerType}
              id="employerType"
              touched={touched.employerType}
              errors={errors.employerType}
              dataKey="key"
              textField="label"
              data={[{ ...objAucun }, ...(referencialData?.COMPANY_EMPLOYER_TYPE || [])]}
              onChange={(newValue) => setFieldValue("employerType", newValue)}
            />
            <FormDropdownField
              label={t("app.suivezVosContratsPartner.FormeJuridique")}
              selectIcon={<img src={handGlass} alt="handGlass" style={{ width: "1.2rem" }}></img>}
              disabled
              id="legalStatus"
              errors={errors.legalStatus}
              values={values.legalStatus}
              renderValue={() => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0",
                  }}
                >
                  <img src={legalForms} style={{ width: "1.2rem" }} alt="test"></img>
                  <div
                    style={{
                      paddingLeft: "2rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {values.legalStatus?.code}
                  </div>
                </div>
              )}
              onChange={(value) => {
                setFieldValue("legalStatus", value);
              }}
            />
            <FormInputField
              label={t("app.suivezVosContratsPartner.Tva")}
              disabled={!companyEditRights}
              id="VAT"
              values={values.VAT}
              errors={errors.VAT}
              touched={touched.VAT}
              onChange={(e) => setFieldValue("VAT", e.target.value.trimStart())}
            />
            <FormInputField
              label={t("app.suivezVosContratsPartner.CaisseDeRetraite")}
              disabled={!companyEditRights}
              id="pensionFund"
              values={values.pensionFund}
              errors={errors.pensionFund}
              touched={touched.pensionFund}
              onChange={(e) => setFieldValue("pensionFund", e.target.value.trimStart())}
            />
            <FormInputField
              label={t("app.suivezVosContratsPartner.Effectif")}
              disabled={!companyEditRights}
              id="workForce"
              type="number"
              values={values.workForce}
              errors={errors.workForce}
              touched={touched.workForce}
              onChange={(e) => setFieldValue("workForce", e.target.value.trimStart())}
            />
          </div>
          <div className={styles.wrapper}>
            <FormDropdownField
              label={t("app.suivezVosContratsPartner.RegimeSocial")}
              disabled={!companyEditRights}
              id="socialRegime"
              values={values.socialRegime}
              data={[{ ...objAucun }, ...(referencialData?.SOCIAL_SECURITY_SYSTEM || [])]}
              errors={errors.socialRegime}
              touched={touched.socialRegime}
              onChange={(newValue) => setFieldValue("socialRegime", newValue)}
            />
            <FormInputField
              label={<InfoBulle />}
              disabled={!companyEditRights}
              id="providentOrganization"
              values={values.providentOrganization}
              errors={errors.providentOrganization}
              touched={touched.providentOrganization}
              onChange={(e) => setFieldValue("providentOrganization", e.target.value.trimStart())}
            />
          </div>
          <div className={styles.input_group_line}>
            <FormInputField
              label={t("app.suivezVosContratsPartner.Adresse")}
              disabled={!companyEditRights}
              id="address"
              values={values.address}
              errors={errors.address}
              touched={touched.address}
              onChange={(e) => setFieldValue("address", e.target.value.trimStart())}
            />
            <FormInputField
              label={t("app.suivezVosContratsPartner.ComplementDadresse")}
              disabled={!companyEditRights}
              id="additionalAddress"
              values={values.additionalAddress}
              errors={errors.additionalAddress}
              touched={touched.additionalAddress}
              onChange={(e) => setFieldValue("additionalAddress", e.target.value.trimStart())}
            />
            <FormInputField
              label={t("app.suivezVosContratsPartner.CodePostal2")}
              disabled={!companyEditRights}
              id="zipCode"
              type="number"
              values={values.zipCode}
              errors={errors.zipCode}
              touched={touched.zipCode}
              onChange={(e) => setFieldValue("zipCode", e.target.value.trimStart())}
            />
            <FormInputField
              label={t("app.suivezVosContratsPartner.Ville")}
              disabled={!companyEditRights}
              id="city"
              values={values.city}
              errors={errors.city}
              touched={touched.city}
              onChange={(e) => setFieldValue("city", e.target.value.trimStart())}
            />
          </div>
          <FormCustomCheckBoxField
            text={`${t("app.suivezVosContratsPartner.ValidSiret.part1")} ${values?.siret} ${t(
              "app.suivezVosContratsPartner.ValidSiret.part2",
            )}`}
            id="certifiedIdentifierCodeByCompany"
            touched={touched.certifiedIdentifierCodeByCompany}
            error={errors.certifiedIdentifierCodeByCompany}
            checkboxValue={values.certifiedIdentifierCodeByCompany}
            additionalClassNames={styles.checkbox_container}
            handleChange={(e) => setFieldValue("certifiedIdentifierCodeByCompany", e)}
          />
          {!companyEditRights && (
            <InformationTextInForm>
              {t("app.suivezVosContratsPartner.ModificationInterditeEntreprise.part1")}
              <span style={{ fontWeight: "bold" }}>
                {t("app.suivezVosContratsPartner.ModificationInterditeEntreprise.part2")}
              </span>
            </InformationTextInForm>
          )}
          <div className={styles.submit_btn_to_right}>
            <SubmitFormBtn
              disabled={postAndPatchCompanyIsPending}
              text={t("app.visualisationOffre.ValidezLesModifications")}
            />
          </div>
        </form>
      </div>
    )
  );
};

export default Form1;
