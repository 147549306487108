import { t } from "i18next";

import { useEffect } from "react";

import { useSelector } from "react-redux";
import { getDeclareARecruitmentPreContractualizationInformations } from "app/slices/declareARecruitment/declareARecruitment.slice";

import HeaderContract from "../../components/HeaderContract/HeaderContract";
import SummaryCard from "../../components/SummaryCard/SummaryCard";
import MainForm from "./forms/MainForm";

import styles from "./Step5.module.scss";

const Step5 = ({ axiosCancelToken }) => {
  const preContractualizationInformations = useSelector(getDeclareARecruitmentPreContractualizationInformations);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.wrapper}>
      <HeaderContract />
      <section className={styles.main_container}>
        <div className={styles.form_container}>
          <div className={styles.form_and_card_container}>
            <div className={styles.title_container}>
              <h2>{t("app.declarezUnRecrutement.Situation")}</h2>
              <div className={styles.step_indicator}>{t("app.declarezUnRecrutement.Etape5Sur7")}</div>
            </div>
            <MainForm axiosCancelToken={axiosCancelToken} />
          </div>
          <SummaryCard
            data={[
              {
                title: t("app.declarezUnRecrutement.EcoleDeFormation"),
                text: preContractualizationInformations.student?.apprenticeshipProject?.schoolProgramEnrollment
                  ?.product?.trainingSchool?.name,
              },
              {
                title: t("app.declarezUnRecrutement.AnneeUniversitaire"),
                text: preContractualizationInformations.student?.apprenticeshipProject?.schoolProgramEnrollment
                  ?.schoolYear,
              },
              {
                title: t("app.declarezUnRecrutement.Promotion"),
                text: preContractualizationInformations.student?.apprenticeshipProject?.schoolProgramEnrollment
                  ?.promotion,
              },
              {
                title: t("app.declarezUnRecrutement.SousPromotion"),
                text: preContractualizationInformations.student?.apprenticeshipProject?.schoolProgramEnrollment
                  ?.subPromotion,
              },
            ]}
          />
        </div>
      </section>
    </div>
  );
};

export default Step5;
