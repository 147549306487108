import { t } from "i18next";
import React, { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { selectContractDataFYC } from "app/slices/followYourContracts/followYourContracts.slice";
import { FormInputField } from "pages/ENT/FollowYourContracts/components/formFields/FormInputField/FormInputField";
import { FormDateField } from "pages/ENT/FollowYourContracts/components/formFields/FormDateField/FormDateField";
import { FormDropdownField } from "pages/ENT/FollowYourContracts/components/formFields/FormDropdownField/FormDropdownField";
import SubTitleInForm from "components/typography/SubTitleInForm/SubTitleInForm";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";
import { createObjAucun } from "utils/localReferentials";

import styles from "../Form8.module.scss";

function is21stOr26thBirthdayBetween(birthdateString, contractPeriod) {
  const birthDate = new Date(birthdateString);
  const startDate = new Date(contractPeriod?.startDate);
  const endDate = new Date(contractPeriod?.endDate);

  const birthMonth = birthDate.getMonth();
  const endDateMonth = endDate.getMonth();
  // Condition pour éviter la logique si le mois de naissance est le même que le mois de fin de contrat (demande métier)
  const isBirthMonthOnEndDateMonth = birthMonth === endDateMonth;

  const twentyFirstBirthday = new Date(birthDate.getFullYear() + 21, birthDate.getMonth(), birthDate.getDate());
  const twentySixBirthday = new Date(birthDate.getFullYear() + 26, birthDate.getMonth(), birthDate.getDate());
  if (twentyFirstBirthday >= startDate && twentyFirstBirthday <= endDate && !isBirthMonthOnEndDateMonth) {
    return 21;
  }
  if (twentySixBirthday >= startDate && twentySixBirthday <= endDate && !isBirthMonthOnEndDateMonth) {
    return 26;
  }
  return null;
}

export function YearFields({
  index,
  errors,
  values,
  touched,
  setFieldValue,
  contractPeriod,
  setYearOfChange,
}) {
  const contractData = useSelector(selectContractDataFYC);
  const referentialsData = useSelector(getReferentialsData);
  const objAucun = createObjAucun();
  const birthdate = contractData?.student?.person?.birthdate;

  useLayoutEffect(() => {
    if (
      is21stOr26thBirthdayBetween(birthdate, contractPeriod)
    ) {
      const birthDateObject = new Date(birthdate);
      const getAfterBirthMonth = new Date(
        birthDateObject.getFullYear() + is21stOr26thBirthdayBetween(birthdate, contractPeriod),
        birthDateObject.getMonth() + 1,
        1,
      );
      const getLastDayBirthMonth = new Date(
        getAfterBirthMonth.getFullYear(),
        getAfterBirthMonth.getMonth(),
        getAfterBirthMonth.getDate() - 1,
      );
      setFieldValue(`Year${index}After`, getAfterBirthMonth);
      setFieldValue(`Year${index}Before`, getLastDayBirthMonth);
      setYearOfChange(index);
    }
    if (is21stOr26thBirthdayBetween(birthdate, contractPeriod)) {
      setYearOfChange(index);
    }
  }, [contractData, contractPeriod, birthdate]);

  return (
    <div className={styles.year_container}>
      <SubTitleInForm additionalClassNames={styles.subtitle_in_form}>
        {t("app.suivezVosContratsPartner.Annee")} {index}
      </SubTitleInForm>
      {is21stOr26thBirthdayBetween(birthdate, contractPeriod) && (
        <p className={styles.form_text_description}>{t("app.suivezVosContratsPartner.changementAge")}</p>
      )}
      <div className={styles.row_container}>
        <FormDateField
          // disabled={index === 1}
          disabled
          label={t(`app.suivezVosContratsPartner.Annee${index}Debut`)}
          id={`Year${index}Start`}
          errors={errors[`Year${index}Start`]}
          values={values[`Year${index}Start`]}
          touched={touched[`Year${index}Start`]}
          // onChange={(newValue) => {
          //   setFieldValue(`Year${index}Start`, newValue);
          // }}
          additionalClassNames={styles.input_form8}
        />
        {is21stOr26thBirthdayBetween(birthdate, contractPeriod) !== null ? (
          <FormDateField
            disabled
            label={`${t(`app.suivezVosContratsPartner.Annee${index}Avant`)} ${is21stOr26thBirthdayBetween(
              birthdate,
              contractPeriod,
            )} ${t("app.suivezVosContratsPartner.Ans")}`}
            id={`Year${index}Before`}
            errors={errors[`Year${index}Before`]}
            values={values[`Year${index}Before`]}
            touched={touched[`Year${index}Before`]}
            // onChange={(newValue) => {
            //   setFieldValue(`Year${index}Before`, newValue);
            // }}
            additionalClassNames={styles.input_form8}
          />
        ) : (
          <FormDateField
            disabled
            // disabled={index === numberOfYears}
            label={t(`app.suivezVosContratsPartner.Annee${index}Fin`)}
            id={`Year${index}End`}
            errors={errors[`Year${index}End`]}
            values={values[`Year${index}End`]}
            touched={touched[`Year${index}End`]}
            // onChange={(newValue) => {
            //   setFieldValue(`Year${index}End`, newValue);
            // }}
            additionalClassNames={styles.input_form8}
          />
        )}

        <FormDropdownField
          label={t("app.suivezVosContratsPartner.TypeDeSalaire")}
          id={`salaryType${index}`}
          errors={errors[`salaryType${index}`]}
          values={values[`salaryType${index}`]}
          data={[{ ...objAucun }, ...referentialsData.COMPANY_SALARY_TYPE]}
          touched={touched[`salaryType${index}`]}
          onChange={(newValue) => {
            setFieldValue(`salaryType${index}`, newValue);
          }}
          additionalClassNames={styles.input_form8}
        />
        <FormInputField
          label={t("app.suivezVosContratsPartner.SalaireEnPourcent")}
          id={`salaryPercentage${index}`}
          errors={errors[`salaryPercentage${index}`]}
          values={values[`salaryPercentage${index}`]}
          type="number"
          touched={touched[`salaryPercentage${index}`]}
          onChange={(e) => setFieldValue(`salaryPercentage${index}`, e.target.value)}
          additionalClassNames={styles.input_form8}
        />
      </div>
      {is21stOr26thBirthdayBetween(birthdate, contractPeriod) !== null && (
        <div className={styles.row_container}>
          <FormDateField
            disabled
            label={`${t(`app.suivezVosContratsPartner.Annee${index}Apres`)} ${is21stOr26thBirthdayBetween(
              birthdate,
              contractPeriod,
            )} ${t("app.suivezVosContratsPartner.Ans")}`}
            id={`Year${index}After`}
            errors={errors[`Year${index}After`]}
            values={values[`Year${index}After`]}
            touched={touched[`Year${index}After`]}
            // onChange={(newValue) => {
            //   setFieldValue(`Year${index}After`, newValue);
            // }}
            additionalClassNames={styles.input_form8}
          />
          <FormDateField
            // disabled={index === numberOfYears}
            disabled
            label={t(`app.suivezVosContratsPartner.Annee${index}Fin`)}
            id={`Year${index}End`}
            errors={errors[`Year${index}End`]}
            values={values[`Year${index}End`]}
            touched={touched[`Year${index}End`]}
            // onChange={(newValue) => {
            //   setFieldValue(`Year${index}End`, newValue);
            // }}
            additionalClassNames={styles.input_form8}
          />
          <FormDropdownField
            label={t("app.suivezVosContratsPartner.TypeDeSalaire")}
            id={`salaryType${index}Bonus`}
            errors={errors[`salaryType${index}Bonus`]}
            values={values[`salaryType${index}Bonus`]}
            data={[{ ...objAucun }, ...referentialsData.COMPANY_SALARY_TYPE]}
            touched={touched[`salaryType${index}Bonus`]}
            onChange={(newValue) => {
              setFieldValue(`salaryType${index}Bonus`, newValue);
            }}
            additionalClassNames={styles.input_form8}
          />
          <FormInputField
            label={t("app.suivezVosContratsPartner.SalaireEnPourcent")}
            id={`salaryPercentage${index}Bonus`}
            errors={errors[`salaryPercentage${index}Bonus`]}
            values={values[`salaryPercentage${index}Bonus`]}
            type="number"
            touched={touched[`salaryPercentage${index}Bonus`]}
            onChange={(e) => setFieldValue(`salaryPercentage${index}Bonus`, e.target.value)}
            additionalClassNames={styles.input_form8}
          />
        </div>
      )}
    </div>
  );
}
