import { t } from "i18next";

import { nanoid } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { selectContractDataFYC } from "app/slices/followYourContracts/followYourContracts.slice";

import { formatDateFrench } from "utils/formatDate";
import { changeBooleanToOuiNon } from "utils/tools";

import Header from "components/Header/Header";

import { useEffect, useState } from "react";
import bgContractImg from "../../assets/bg-consul-offre.png";

import styles from "./Recapitulative.module.scss";

const ListDetail = ({ label, value }) => (
  <div className={styles.listDetails} key={nanoid()}>
    <h6>{label}</h6>
    <p>{value}</p>
  </div>
);

const CheckBoxBloc = (checked) => <input type="checkbox" name="checkbox" disabled defaultChecked={checked} />;

const Recapitulative = () => {
  const contractData = useSelector(selectContractDataFYC);
  const [contractPeriods, setContractPeriods] = useState([]);
  const isChecked = contractData?.contract?.dangerousMachineryUse === true;
  const isPublicSector = contractData?.contract?.publicSector === true;
  const missions = contractData?.contract?.missions;
  const remunerations = contractData?.contract?.remuneration?.periods;

  const studentDetails = [
    {
      label: t("app.suivezVosContratsPartner.DateDeNaissance"),
      values: formatDateFrench(contractData?.student?.person?.birthdate),
    },

    {
      label: t("app.suivezVosContratsPartner.EcoleDeFormation"),
      values: contractData?.student?.schoolProgramEnrollment?.product?.trainingSchool?.name,
    },
    {
      label: t("app.suivezVosContratsPartner.Uai"),
      values: contractData?.student?.schoolProgramEnrollment?.educationalEstablishmentRegistrationNumber,
    },
    {
      label: t("app.suivezVosContratsPartner.AnneeUniversitaire"),
      values: contractData?.student?.schoolProgramEnrollment?.schoolYear,
    },

    {
      label: t("app.suivezVosContratsPartner.Session"),
      values: contractData?.student?.schoolProgramEnrollment?.session?.label,
    },
    {
      label: t("app.suivezVosContratsPartner.Annee"),
      values: contractData?.student?.schoolProgramEnrollment?.year,
    },
    {
      label: t("app.suivezVosContratsPartner.DateDeDebutDeFormation"),
      values: formatDateFrench(contractData?.student?.schoolProgramEnrollment?.startDate),
    },

    {
      label: t("app.suivezVosContratsPartner.Promotion"),
      values: contractData?.student?.schoolProgramEnrollment?.promotion,
    },
    {
      label: t("app.suivezVosContratsPartner.SousPromotion"),
      values: contractData?.student?.schoolProgramEnrollment?.subPromotion,
    },
    {
      label: t("app.suivezVosContratsPartner.SousPromotionEnvisagee"),
      values: contractData?.student?.schoolProgramEnrollment?.wantedSubPromotion,
    },
    {
      label: t("app.suivezVosContratsPartner.NomDiplome"),
      values: contractData?.student?.schoolProgramEnrollment?.diploma?.name,
    },
    {
      label: t("app.suivezVosContratsPartner.CodeDiplome"),
      values: contractData?.student?.schoolProgramEnrollment?.diploma?.code,
    },
  ];

  const generalInformation = [
    {
      label: t("app.suivezVosContratsPartner.NomDeLentreprise"),
      values: contractData?.company?.name,
    },
    {
      label: t("app.suivezVosContratsPartner.siretLabel"),
      values: contractData?.company?.siret,
    },
    {
      label: t("app.suivezVosContratsPartner.CodeNaf"),
      values: contractData?.company?.activities?.map((CodeNaf) => CodeNaf?.code),
    },
    {
      label: t("app.suivezVosContratsPartner.CodeNafDescription"),
      values: contractData?.company?.activities?.map((CodeNaf) => CodeNaf?.label),
    },
    {
      label: t("app.suivezVosContratsPartner.CodeIdcc"),
      values: contractData?.company?.collectiveAgreement?.code,
    },
    {
      label: t("app.suivezVosContratsPartner.TypeEmployeur"),
      values: contractData?.company?.employerType?.label,
    },
    {
      label: t("app.suivezVosContratsPartner.FormeJuridique"),
      values: contractData?.company?.legalForm?.code,
    },
    {
      label: t("app.suivezVosContratsPartner.Tva"),
      values: contractData?.company?.vatNumber,
    },
    {
      label: t("app.suivezVosContratsPartner.CaisseDeRetraite"),
      values: contractData?.company?.pensionFund,
    },
    {
      label: t("app.suivezVosContratsPartner.Effectif"),
      values: contractData?.company?.workforce?.number,
    },
    {
      label: t("app.suivezVosContratsPartner.RegimeSocial"),
      values: contractData?.company?.socialSecuritySystem?.label,
    },
    {
      label: t("app.suivezVosContratsPartner.OrganismePrevoyance"),
      values: contractData?.company?.providentFund,
    },
    {
      label: t("app.suivezVosContratsPartner.Adresse"),
      values: contractData?.company?.addresses?.headquarters?.street,
    },
    {
      label: t("app.suivezVosContratsPartner.ComplementDadresse"),
      values: contractData?.company?.addresses?.headquarters?.complement,
    },
    {
      label: t("app.suivezVosContratsPartner.CodePostal2"),
      values: contractData?.company?.addresses?.headquarters?.zipCode,
    },
    {
      label: t("app.suivezVosContratsPartner.Ville"),
      values: contractData?.company?.addresses?.headquarters?.city,
    },
  ];
  const signatoryAgreement = [
    {
      label: t("app.suivezVosContratsPartner.Civilite"),
      values: contractData?.company?.contacts?.signatory?.civility?.label,
    },
    {
      label: t("app.suivezVosContratsPartner.Nom"),
      values: contractData?.company?.contacts?.signatory?.name,
    },
    {
      label: t("app.suivezVosContratsPartner.Prenom"),
      values: contractData?.company?.contacts?.signatory?.firstname,
    },
    {
      label: t("app.suivezVosContratsPartner.Telephone"),
      values: contractData?.company?.contacts?.signatory?.phones?.office,
    },
    {
      label: t("app.suivezVosContratsPartner.Portable"),
      values: contractData?.company?.contacts?.signatory?.phones?.cellular,
    },
    {
      label: t("app.suivezVosContratsPartner.EmailDuContact"),
      values: contractData?.company?.contacts?.signatory?.email,
    },
    {
      label: t("app.suivezVosContratsPartner.Fonction"),
      values: contractData?.company?.contacts?.signatory?.function,
    },
  ];

  const administrativeContactTraining = [
    {
      label: t("app.suivezVosContratsPartner.ReprendreInfo"),
      values: contractData?.company?.contacts?.administrative?.copyFrom?.label,
    },
    {
      label: t("app.suivezVosContratsPartner.Civilite"),
      values: contractData?.company?.contacts?.administrative?.civility?.label,
    },

    {
      label: t("app.suivezVosContratsPartner.Nom"),
      values: contractData?.company?.contacts?.administrative?.name,
    },
    {
      label: t("app.suivezVosContratsPartner.Prenom"),
      values: contractData?.company?.contacts?.administrative?.firstname,
    },
    {
      label: t("app.suivezVosContratsPartner.Telephone"),
      values: contractData?.company?.contacts?.administrative?.phones?.office,
    },
    {
      label: t("app.suivezVosContratsPartner.Portable"),
      values: contractData?.company?.contacts?.administrative?.phones?.cellular,
    },
    {
      label: t("app.suivezVosContratsPartner.EmailDuContact"),
      values: contractData?.company?.contacts?.administrative?.email,
    },

    {
      label: t("app.suivezVosContratsPartner.Fonction"),
      values: contractData?.company?.contacts?.administrative?.function,
    },
  ];

  const tutorApprenticeshipMaster = [
    {
      label: t("app.suivezVosContratsPartner.Civilite"),
      values: contractData?.company?.contacts?.tutors[0]?.civility?.label,
    },

    {
      label: t("app.suivezVosContratsPartner.Nom"),
      values: contractData?.company?.contacts?.tutors[0]?.name,
    },
    {
      label: t("app.suivezVosContratsPartner.Prenom"),
      values: contractData?.company?.contacts?.tutors[0]?.firstname,
    },
    {
      label: t("app.suivezVosContratsPartner.DateDeNaissance"),
      values: contractData?.company?.contacts?.tutors[0]?.birthdate,
    },
    // {
    //   label: t("app.suivezVosContratsPartner.SocialSecurityNumber"),
    //   values: contractData?.company?.contacts?.tutors[0]?.socialSecurityNumber,
    // },
    {
      label: t("app.suivezVosContratsPartner.Telephone"),
      values: contractData?.company?.contacts?.tutors[0]?.phones?.office,
    },
    {
      label: t("app.suivezVosContratsPartner.Portable"),
      values: contractData?.company?.contacts?.tutors[0]?.phones?.cellular,
    },
    {
      label: t("app.suivezVosContratsPartner.EmailDuContact"),
      values: contractData?.company?.contacts?.tutors[0]?.email,
    },
    {
      label: t("app.suivezVosContratsPartner.Fonction"),
      values: contractData?.company?.contacts?.tutors[0]?.function,
    },
    {
      label: t("app.suivezVosContratsPartner.HighestDegree"),
      values: contractData?.company?.contacts?.tutors[0]?.maximalObtainedDiploma?.label,
    },
  ];
  const tutorApprenticeshipMaster2 = [
    {
      label: t("app.suivezVosContratsPartner.Civilite"),
      values: contractData?.company?.contacts?.tutors[1]?.civility?.label,
    },

    {
      label: t("app.suivezVosContratsPartner.Nom"),
      values: contractData?.company?.contacts?.tutors[1]?.name,
    },
    {
      label: t("app.suivezVosContratsPartner.Prenom"),
      values: contractData?.company?.contacts?.tutors[1]?.firstname,
    },
    {
      label: t("app.suivezVosContratsPartner.DateDeNaissance"),
      values: contractData?.company?.contacts?.tutors[1]?.birthdate,
    },
    // {
    //   label: t("app.suivezVosContratsPartner.SocialSecurityNumber"),
    //   values: contractData?.company?.contacts?.tutors[1]?.socialSecurityNumber,
    // },
    {
      label: t("app.suivezVosContratsPartner.Telephone"),
      values: contractData?.company?.contacts?.tutors[1]?.phones?.office,
    },
    {
      label: t("app.suivezVosContratsPartner.Portable"),
      values: contractData?.company?.contacts?.tutors[1]?.phones?.cellular,
    },
    {
      label: t("app.suivezVosContratsPartner.EmailDuContact"),
      values: contractData?.company?.contacts?.tutors[1]?.email,
    },

    {
      label: t("app.suivezVosContratsPartner.Fonction"),
      values: contractData?.company?.contacts?.tutors[1]?.function,
    },
    {
      label: t("app.suivezVosContratsPartner.HighestDegree"),
      values: contractData?.company?.contacts?.tutors[1]?.maximalObtainedDiploma?.label,
    },
  ];
  const contractGeneralInformation = [
    {
      label: t("app.suivezVosContratsPartner.apprenticeshipType"),
      values: contractData?.contract?.apprenticeshipType?.label,
    },
    {
      label: t("app.suivezVosContratsPartner.jobTitle"),
      values: contractData?.contract?.jobTitle,
    },
    {
      label: t("app.suivezVosContratsPartner.contractType"),
      values: contractData?.contract?.type?.label,
    },
    {
      label: t("app.suivezVosContratsPartner.startDate"),
      values: contractData?.contract?.startDate,
    },
    {
      label: t("app.suivezVosContratsPartner.endDate"),
      values: contractData?.contract?.endDate,
    },
    {
      label: t("app.suivezVosContratsPartner.weeklyWorkingHours"),
      values: contractData?.contract?.weeklyWorkingHours,
    },
    {
      label: t("app.suivezVosContratsPartner.grossSalary"),
      values: contractData?.contract?.remuneration?.grossSalary,
    },
  ];

  const businessContractFinancing = [
    {
      label: t("app.suivezVosContratsPartner.AdresseDifferente"),
      values: contractData?.company?.addresses?.billing?.sameAsHeadquarters
        ? changeBooleanToOuiNon(false)?.label
        : changeBooleanToOuiNon(true)?.label,
    },
    {
      label: t("app.suivezVosContratsPartner.Adresse"),
      values: contractData?.company?.addresses?.billing?.street,
    },

    {
      label: t("app.suivezVosContratsPartner.ComplementDadresse"),
      values: contractData?.company?.addresses?.billing?.complement,
    },
    {
      label: t("app.suivezVosContratsPartner.CodePostal2"),
      values: contractData?.company?.addresses?.billing?.zipCode,
    },

    {
      label: t("app.suivezVosContratsPartner.Ville"),
      values: contractData?.company?.addresses?.billing?.city,
    },

    {
      label: t("app.suivezVosContratsPartner.ReprendreInfo"),
      values: contractData?.company?.contacts?.billing?.copyFrom?.label,
    },
    {
      label: t("app.suivezVosContratsPartner.EmailDuContact"),
      values: contractData?.company?.contacts?.billing?.email,
    },
    {
      label: t("app.suivezVosContratsPartner.Civilite"),
      values: contractData?.company?.contacts?.billing?.civility?.label,
    },

    {
      label: t("app.suivezVosContratsPartner.Nom"),
      values: contractData?.company?.contacts?.billing?.name,
    },
    {
      label: t("app.suivezVosContratsPartner.Prenom"),
      values: contractData?.company?.contacts?.billing?.firstname,
    },
    {
      label: t("app.suivezVosContratsPartner.Fonction"),
      values: contractData?.company?.contacts?.billing?.function,
    },

    {
      label: t("app.suivezVosContratsPartner.Telephone"),
      values: contractData?.company?.contacts?.billing?.phones?.office,
    },
    {
      label: t("app.suivezVosContratsPartner.Portable"),
      values: contractData?.company?.contacts?.billing?.phones?.cellular,
    },
    {
      label: t("app.suivezVosContratsPartner.BonCommandeFactureObligatoire"),
      values: changeBooleanToOuiNon(contractData?.contract?.financing?.purchaseOrderMandatory)?.label,
    },

    {
      label: t("app.suivezVosContratsPartner.EmailDuContact"),
      values: contractData?.company?.contacts?.purchase?.email,
    },
    {
      label: t("app.suivezVosContratsPartner.Civilite"),
      values: contractData?.company?.contacts?.purchase?.civility?.label,
    },

    {
      label: t("app.suivezVosContratsPartner.Nom"),
      values: contractData?.company?.contacts?.purchase?.name,
    },
    {
      label: t("app.suivezVosContratsPartner.Prenom"),
      values: contractData?.company?.contacts?.purchase?.firstname,
    },
    {
      label: t("app.suivezVosContratsPartner.Telephone"),
      values: contractData?.company?.contacts?.purchase?.phones?.office,
    },
    {
      label: t("app.suivezVosContratsPartner.Portable"),
      values: contractData?.company?.contacts?.purchase?.phones?.cellular,
    },
    {
      label: t("app.suivezVosContratsPartner.Fonction"),
      values: contractData?.company?.contacts?.purchase?.function,
    },
  ];

  const contractFinancing = [
    {
      label: t("app.Step10ContractEnt.FromatAmount"),
      values: contractData?.contract?.financing?.trainingAmount,
    },
    {
      label: t("app.Step10ContractEnt.NumAdh"),
      values: contractData?.contract?.financing?.skillsOperator?.membershipNumber,
    },
    {
      label: t("app.Step10ContractEnt.NomOpco"),
      values: contractData?.contract?.financing?.skillsOperator?.name,
    },
    {
      label: t("app.Step10ContractEnt.lvlCharge"),
      values: contractData?.contract?.financing?.skillsOperator?.coveredAmount,
    },
    // {
    //   label: t("app.Step10ContractEnt.ComOpco"),
    //   values: changeBooleanToOuiNon(contractData?.contract?.financing?.skillsOperator?.hasContact)?.label,
    // },
    {
      label: t("app.Step10ContractEnt.FinancerRAC"),
      values: contractData?.contract?.financing?.agreementToPayRemainingCost
        ? t("app.referentials.Oui")
        : t("app.referentials.Non"),
    },
    {
      label: t("app.Step10ContractEnt.CertifieInformations"),
      values: contractData?.contract?.financing?.certifiedData ? t("app.referentials.Oui") : t("app.referentials.Non"),
    },
  ];

  useEffect(() => {
    const periodCodes = ["YEAR1", "YEAR2", "YEAR3"];
    const periods = periodCodes
      .map((code) => contractData?.contract?.remuneration?.periods?.find((remuneration) => remuneration?.code === code))
      .filter((period) => period !== undefined);

    setContractPeriods(periods);
  }, [contractData]);

  return (
    <div className={styles.container}>
      <Header h1={t("app.suivezVosContratsPartner.SuivezContrats")} bgImg={bgContractImg} />
      <div className={styles.containerBox}>
        <h3 className={styles.name}>
          {contractData?.student?.person?.name} {contractData?.student?.person?.firstname}
        </h3>
        <div className={styles.box}>
          {studentDetails?.map((elem) => (
            <ListDetail key={nanoid()} label={elem?.label} value={elem?.values} />
          ))}
        </div>
      </div>
      <div className={styles.containerBox}>
        <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.InformationsEntreprise")}</h6>
        <div className={styles.box}>
          {generalInformation?.map((item) => (
            <ListDetail key={nanoid()} label={item?.label} value={item?.values} />
          ))}
        </div>
      </div>
      <div className={styles.containerBox}>
        <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.SignataireDeLaConvention")}</h6>
        <div className={styles.box}>
          {signatoryAgreement?.map((item) => (
            <ListDetail key={nanoid()} label={item?.label} value={item?.values} />
          ))}
        </div>
      </div>
      <div className={styles.containerBox}>
        <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.ContactAdministratifDurantLaFormation")}</h6>
        <div className={styles.box}>
          {administrativeContactTraining?.map((item) => (
            <ListDetail key={nanoid()} label={item?.label} value={item?.values} />
          ))}
        </div>
      </div>
      <div className={styles.containerBox}>
        <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.TuteurEtMaitreDapprentissage1")}</h6>
        <div className={styles.box}>
          {tutorApprenticeshipMaster?.map((item) => (
            <ListDetail key={nanoid()} label={item?.label} value={item?.values} />
          ))}
        </div>
        {contractData?.company?.contacts?.tutors[1] && (
          <>
            <h6 className={styles.subtitle} style={{ paddingTop: "25px" }}>
              {t("app.suivezVosContratsPartner.TuteurEtMaitreDapprentissage2")}
            </h6>
            <div className={styles.box}>
              {tutorApprenticeshipMaster2?.map((item) => (
                <ListDetail key={nanoid()} label={item?.label} value={item?.values} />
              ))}
            </div>
          </>
        )}
      </div>
      <div className={styles.containerBox}>
        <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.ContratRenseignementsGeneraux")}</h6>
        <div className={styles.box}>
          {contractGeneralInformation?.map((item) => (
            <ListDetail key={nanoid()} label={item?.label} value={item?.values} />
          ))}
        </div>
        <div className={styles.box}>
          <div className={styles.listMission}>
            <h6> {t("app.suivezVosContratsPartner.dangerousMachineryUse")}</h6>
            <div className={styles.flexBox}>
              <p>{t("app.suivezVosContratsPartner.dangerousMachineryUseTitle")}</p>
              {isChecked ? CheckBoxBloc(true) : CheckBoxBloc(false)}
            </div>
          </div>
          <div className={styles.listMission}>
            <h6>{t("app.suivezVosContratsPartner.publicSector")}</h6>
            <div className={styles.flexBox}>
              <p>{t("app.suivezVosContratsPartner.publicSectorLabel")}</p>
              {isPublicSector ? CheckBoxBloc(true) : CheckBoxBloc(false)}
            </div>
          </div>
        </div>

        {missions &&
          missions?.map((lang, index) => (
            <div className={styles.listMission} key={nanoid()}>
              <h6>
                {t("app.suivezVosContratsPartner.mission")}
                {index + 1}
              </h6>
              <p> {lang}</p>
            </div>
          ))}
      </div>
      <div className={styles.containerBox}>
        <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.RemunerationContratDapprentissage")}</h6>

        <div>
          <div className={styles.listMission}>
            <h6>{t("app.suivezVosContratsPartner.SalaireBrutParMois")}</h6>
            <p>{contractData?.contract?.remuneration?.grossSalary}</p>
          </div>
          {remunerations &&
            contractPeriods?.map((period, index) => {
              const changeAgePeriod = remunerations?.find(
                (remuneration) => remuneration?.code === `${period?.code}_CHGT_AGE`,
              );
              const getAgeInTheYearOfChange = (birthdate, yearOfChange) => {
                const birthYear = new Date(birthdate).getFullYear();
                const changeYear = new Date(yearOfChange).getFullYear();
                return changeYear - birthYear;
              };
              const age =
                changeAgePeriod &&
                getAgeInTheYearOfChange(contractData?.student?.person?.birthdate, changeAgePeriod?.endDate);

              return (
                <div key={nanoid()} className={`${styles.box}  ${styles.flex}`}>
                  <div className={styles.listDetails}>
                    <h6>{t(`app.suivezVosContratsPartner.Annee${index + 1}Debut`)}</h6>
                    <p>{period?.startDate}</p>
                  </div>
                  {changeAgePeriod !== undefined ? (
                    <div className={styles.listDetails}>
                      <h6>
                        {`${t(`app.suivezVosContratsPartner.Annee${index + 1}Avant`)} ${age} ${t(
                          "app.suivezVosContratsPartner.Ans",
                        )}`}
                      </h6>
                      <p>{changeAgePeriod?.startDate}</p>
                    </div>
                  ) : (
                    <div className={styles.listDetails}>
                      <h6>{t(`app.suivezVosContratsPartner.Annee${index + 1}Fin`)}</h6>
                      <p>{period?.endDate}</p>
                    </div>
                  )}
                  <div className={styles.listDetails}>
                    <h6>{t("app.suivezVosContratsPartner.SalaireEnPourcent")}</h6>
                    <p>{period?.grossSalaryPercent}</p>
                  </div>
                  <div className={styles.listDetails}>
                    <h6>{t("app.suivezVosContratsPartner.TypeDeSalaire")}</h6>
                    <p>{period?.salaryType?.label}</p>
                  </div>
                  {changeAgePeriod !== undefined && (
                    <>
                      <div className={styles.listDetails}>
                        <h6>
                          {`${t(`app.suivezVosContratsPartner.Annee${index + 1}Apres`)} ${age} ${t(
                            "app.suivezVosContratsPartner.Ans",
                          )}`}
                        </h6>
                        <p>{changeAgePeriod?.endDate}</p>
                      </div>
                      <div className={styles.listDetails}>
                        <h6>{t(`app.suivezVosContratsPartner.Annee${index + 1}Fin`)}</h6>
                        <p>{period?.endDate}</p>
                      </div>
                      <div className={styles.listDetails}>
                        <h6>{t("app.suivezVosContratsPartner.SalaireEnPourcent")}</h6>
                        <p>{changeAgePeriod?.grossSalaryPercent}</p>
                      </div>
                      <div className={styles.listDetails}>
                        <h6>{t("app.suivezVosContratsPartner.TypeDeSalaire")}</h6>
                        <p>{changeAgePeriod?.salaryType?.label}</p>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <div className={styles.containerBox}>
        <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.FinancementDuContrat")}</h6>
        <div className={styles.box}>
          {businessContractFinancing?.map((item) => (
            <ListDetail key={nanoid()} label={item?.label} value={item?.values} />
          ))}
        </div>
      </div>
      <div className={styles.containerBox}>
        <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.FinancementDuContratOpco")}</h6>
        <div className={styles.box}>
          {contractFinancing?.map((item) => (
            <ListDetail key={nanoid()} label={item?.label} value={item?.values} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Recapitulative;
