// fonction qui permet de formater une date

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export function formatDate(date) {
  const convertToDate = new Date(date);
  return [
    convertToDate.getFullYear(),
    padTo2Digits(convertToDate.getMonth() + 1),
    padTo2Digits(convertToDate.getDate()),
  ].join("-");
}

export function formatDateFrench(date) {
  const convertToDate = new Date(date);
  // Vérifie si la date convertie est NaN en utilisant getTime()
  if (Number.isNaN(convertToDate.getTime())) {
    return "///////"; // Ou retournez toute autre chaîne/indicateur de votre choix
  }
  return [
    padTo2Digits(convertToDate.getDate()),
    padTo2Digits(convertToDate.getMonth() + 1),
    convertToDate.getFullYear(),
  ].join("-");
}

export function getAgeFromDateOfBirth(dateOfBirth) {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  // Vérifie si la date convertie est NaN en utilisant getTime()
  if (Number.isNaN(birthDate.getTime())) {
    return "///////"; // Ou retournez toute autre chaîne/indicateur de votre choix
  }
  let age = today.getFullYear() - birthDate.getFullYear();
  const isBirthdayPassedThisYear =
    today.getMonth() > birthDate.getMonth() ||
    (today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate());

  if (!isBirthdayPassedThisYear) {
    age -= 1;
  }

  return age;
}
