import { t } from "i18next";
import { nanoid } from "@reduxjs/toolkit";

import Header from "components/Header/Header";
import React, { useLayoutEffect, useState } from "react";
import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";
import { TitleInForm } from "components/typography/TitleInForm/TitleInForm";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import { toastError, toastSuccess } from "utils/toasts";
import {
  getAllCompaniesIsPending,
  getCompanyThunk,
  getOneCompany,
  patchCompanyThunk,
  selectPostAndPatchCompanyIsPending,
  resetSliceInformations,
} from "app/slices/checkDetailsCompany/getAllCompanies.slice";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getMeDataV2, getUserToken } from "app/slices/userConnexion/userConnexion.slice";
import axios from "axios";
import { useFormik } from "formik";
import { createBooleanReferential, createObjAucun, createObjCodeAucun } from "utils/localReferentials";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";
import { axiosCompany } from "app/axiosInstances/axiosInstances";
import Loader from "components/Loader/Loader";
import bgContractImg from "./assets/bg-consul-offre.png";
import { FormInputFieldWithIcon } from "../FollowYourContracts/components/formFields/FormInputFieldWithIcon/FormInputFieldWithIcon";
import styles from "./CompanyProfile.module.scss";
import { FormInputField } from "../FollowYourContracts/components/formFields/FormInputField/FormInputField";
import { FormDropdownField } from "../FollowYourContracts/components/formFields/FormDropdownField/FormDropdownField";
import { InfoBulle } from "../FollowYourContracts/SelectedContract/ModifiableContract/ManualEntrySection/forms/Form1/components/InfoBulle/InfoBulle";
import legalForms from "./assets/legal_forms.svg";
import { ReactComponent as Pencil } from "./assets/search.svg";
import codeNafSvg from "./assets/codeNaf.svg";
import IdccDropdownItem from "./components/IdccDropdownItem";

export default function CompanyProfile() {
  const [axiosCancelToken] = useState(axios.CancelToken.source());
  const dispatch = useDispatch();

  const referencialData = useSelector(getReferentialsData);
  const userToken = useSelector(getUserToken);
  const pending = useSelector(getAllCompaniesIsPending);
  const pendingPatchCompany = useSelector(selectPostAndPatchCompanyIsPending);
  console.log("pendingPatchCompany", pendingPatchCompany);
  const getMeData = useSelector(getMeDataV2);
  const companyData = useSelector(getOneCompany);

  const [idccCallLoading, setIdccCallLoading] = useState(false);
  const [idccApiResponse, setIdccApiResponse] = useState([]);

  const objAucun = createObjAucun();
  const objCodeAucun = createObjCodeAucun();
  const objBoolean = createBooleanReferential();

  const formSchema = Yup.object().shape({
    codeIdcc: Yup.object({
      code: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
      label: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    employerType: Yup.object({
      key: Yup.string(),
      label: Yup.string(),
    }).test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    VAT: Yup.string()
      .matches(/^(?:((FR)?[0-9A-Z]{2}[0-9]{9}))$/, `${t("app.errorMessages.TVAFormat")}`)
      .required(`${t("app.errorMessages.RemplirChamp")}`),
    pensionFund: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
    workForce: Yup.string()
      .matches(/^[0-9]+$/, `${t("app.errorMessages.numberFormat")}`)
      .required(`${t("app.errorMessages.RemplirChamp")}`),
    socialRegime: Yup.object({
      key: Yup.string(),
      label: Yup.string(),
    }).test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    address: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
    zipCode: Yup.string()
      .matches(/^[0-9]+$/, `${t("app.errorMessages.numberFormat")}`)
      .min(5, t("app.errorMessages.NombreItemRequis"))
      .max(5, t("app.errorMessages.NombreItemRequis"))
      .required(`${t("app.errorMessages.RemplirChamp")}`),
    city: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
    billingSameAdress: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    billingStreet: Yup.string()
      .typeError("Remplissez ce champ.")
      .when(["billingSameAdress"], {
        is: (billingSameAdress) => billingSameAdress?.key === "Oui",
        then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
      }),
    billingZipCode: Yup.string()
      .typeError("Remplissez ce champ.")
      .when(["billingSameAdress"], {
        is: (billingSameAdress) => billingSameAdress?.key === "Oui",
        then: (schema) => schema
          .matches(/^[0-9]+$/, `${t("app.errorMessages.numberFormat")}`)
          .min(5, t("app.errorMessages.NombreItemRequis"))
          .max(5, t("app.errorMessages.NombreItemRequis"))
          .required(t("app.errorMessages.RemplirChamp")),
      }),
    billingCity: Yup.string()
      .typeError("Remplissez ce champ.")
      .when(["billingSameAdress"], {
        is: (billingSameAdress) => billingSameAdress?.key === "Oui",
        then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
      }),
  });

  const handleCodeIdccInputChange = async (event) => {
    const { value } = event.target;
    console.log("value idcc", value);
    setFieldValue("codeIdcc", {
      code: value,
      label: "",
    });
    if (value.length > 1) {
      setIdccCallLoading(true);
      try {
        const response = await axiosCompany().get(`/v1/companies/collective-agreements?code=*${value}*`);
        setIdccApiResponse(response.data);
        setIdccCallLoading(false);
      } catch (err) {
        setIdccApiResponse([]);
        setIdccCallLoading(false);
        toastError(
          <ToastMessageStructure firstMessage={t("app.toastMessages.ErrorRecuperationListeIdcc")} secondMessage={err.message} />,
        );
      }
    } else {
      setIdccApiResponse([]);
    }
  };

  const handleSelectCodeIdcc = (option) => {
    setFieldValue("codeIdcc", option);
    setIdccApiResponse([]);
  };

  const handleYesNoOnMountForSameAdress = (value) => {
    switch (value) {
    case true:
      return { key: "Non", label: t("app.referentials.Non") };
    case false:
      return { key: "Oui", label: t("app.referentials.Oui") };
    default:
      return { ...objAucun };
    }
  };

  const setBillingInfo = (value) => {
    if (value?.key === "Non") {
      setFieldValue("billingStreet", companyData?.addresses?.headquarters?.street || "");
      setFieldValue("billingAdditionalAddress", companyData?.addresses?.headquarters?.additionalAddress || "");
      setFieldValue("billingZipCode", companyData?.addresses?.headquarters?.zipCode || "");
      setFieldValue("billingCity", companyData?.addresses?.headquarters?.city || "");
    } else {
      setFieldValue("billingStreet", companyData?.addresses?.billing?.street || "");
      setFieldValue("billingAdditionalAddress", companyData?.addresses?.billing?.additionalAddress || "");
      setFieldValue("billingZipCode", companyData?.addresses?.billing?.zipCode || "");
      setFieldValue("billingCity", companyData?.addresses?.billing?.city || "");
    }
  };

  const onSubmit = async (values) => {
    try {
      const dataToSend = {
        collectiveAgreement: values?.codeIdcc?.code,
        employerType: values?.employerType?.key,
        vatNumber: values?.VAT,
        pensionFund: values?.pensionFund,
        workforce: {
          number: parseInt(values.workForce, 10),
        },
        socialSecuritySystem: values?.socialRegime?.key,
        providentFund: values?.providentFund,
        addresses: {
          headquarters: {
            street: values?.address,
            additionalAddress: values?.additionalAddress ? values?.additionalAddress : null,
            zipCode: values?.zipCode,
            city: values?.city,
          },
          billing: {
            ...values?.billingSameAdress?.key === "Oui"
              ? {
                sameAsHeadquarters: false,
                street: values?.billingStreet,
                additionalAddress: values?.billingAdditionalAddress ? values?.billingAdditionalAddress : null,
                zipCode: values?.billingZipCode,
                city: values?.billingCity,
              }
              : {
                sameAsHeadquarters: true,
                street: null,
                additionalAddress: null,
                zipCode: null,
                city: null,
              },
          },
        },
      };

      await dispatch(patchCompanyThunk({ id: companyData?.id, dataToSend, axiosCancelToken })).unwrap();
      toastSuccess(<ToastMessageStructure secondMessage={t("app.toastMessages.ConfirmationModifInformationsEntreprise")} />);
    } catch (err) {
      toastError(
        <ToastMessageStructure firstMessage={t("app.toastMessages.ErrorModifInformationsEntreprise")} secondMessage={err.message} />,
      );
    }
  };

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      companyName: companyData?.name || "",
      siret: companyData?.siret || "",
      codeNaf: companyData?.activities?.[0]?.code || "",
      descriptionNaf: companyData?.activities?.[0]?.label || "",
      codeIdcc:
        companyData?.collectiveAgreement
          ? {
            code: companyData?.collectiveAgreement?.code,
            label: companyData?.collectiveAgreement?.label,
          }
          : {
            code: "",
            label: "",
          },
      employerType: companyData?.employerType || { ...objAucun },
      legalForm:
        companyData?.legalForm
          ? {
            code: companyData?.legalForm?.code,
            label: companyData?.legalForm?.label,
          }
          : { ...objCodeAucun },
      VAT: companyData?.vatNumber || "",
      pensionFund: companyData?.pensionFund || "",
      workForce: companyData?.workforce?.number || "",
      socialRegime: companyData?.socialSecuritySystem ? companyData?.socialSecuritySystem : { ...objAucun },
      providentFund: companyData?.providentFund || "",
      address: companyData?.addresses?.headquarters?.street || "",
      city: companyData?.addresses?.headquarters?.city || "",
      additionalAddress: companyData?.addresses?.headquarters?.additionalAddress || "",
      zipCode: companyData?.addresses?.headquarters?.zipCode || "",
      billingSameAdress: handleYesNoOnMountForSameAdress(companyData?.addresses?.billing?.sameAsHeadquarters),
      billingStreet: companyData?.addresses?.billing?.street || "",
      billingAdditionalAddress: companyData?.addresses?.billing?.additionalAddress || "",
      billingZipCode: companyData?.addresses?.billing?.zipCode || "",
      billingCity: companyData?.addresses?.billing?.city || "",
    },
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit,
  });

  useLayoutEffect(() => {
    if (!pending && userToken) {
      (async () => {
        try {
          await dispatch(getCompanyThunk({ companyId: getMeData.companyId, axiosCancelToken })).unwrap();
        } catch (error) {
          toastError(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.companyProfileError")}
              secondMessage={error.message}
            />,
          );
        }
      })();
    }
  }, [userToken]);

  // For cleanup function only
  useLayoutEffect(
    () => () => {
      axiosCancelToken.cancel();
      dispatch(resetSliceInformations());
      console.log("RESET : resetSliceInformations");
    },
    [],
  );
  console.log("idcc", values.codeIdcc);

  return (
    <>
      {(pending || pendingPatchCompany) && <Loader />}
      <Header h1={t("app.FicheEntreprise.title")} bgImg={bgContractImg} />
      <div className={styles.container}>
        <form onSubmit={handleSubmit}>
          <TitleInForm>{t("app.suivezVosContratsPartner.InformationsEntreprise")}</TitleInForm>
          <div className={`${styles.input_group_line} ${styles.group_margin}`}>
            <FormInputFieldWithIcon
              label={t("app.suivezVosContratsPartner.siretLabel")}
              id="siret"
              name="siret"
              disabled
              values={values.siret}
              touched={touched.siret}
              errors={errors.siret}
            >
              <p className={styles.siret_text}>{t("app.suivezVosContratsPartner.SiretInfo")}</p>
            </FormInputFieldWithIcon>
            <FormInputField
              disabled
              values={values.companyName}
              errors={errors.companyName}
              label={t("app.suivezVosContratsPartner.NomDeLentreprise")}
              id="companyName"
            />
          </div>
          <div className={styles.input_group_line}>
            <FormInputFieldWithIcon
              label={t("app.suivezVosContratsPartner.CodeNaf")}
              id="codeNaf"
              errors={errors.codeNaf}
              values={values.codeNaf}
              disabled
              icon={(<img src={codeNafSvg} className={styles.idcc_icon} alt="test"></img>)}
              additionalClassNames={styles.input_padding}
            />
            <FormInputField
              disabled
              id="descriptionNaf"
              label={t("app.suivezVosContratsPartner.CodeNafDescription")}
              values={values.descriptionNaf}
              errors={errors.descriptionNaf}
            />
            <div className={styles.idcc_group}>
              <FormInputFieldWithIcon
                id="codeIdcc"
                label={t("app.suivezVosContratsPartner.CodeIdcc")}
                values={values.codeIdcc?.code}
                errors={errors.codeIdcc?.code}
                touched={touched.codeIdcc}
                icon={(
                  <>
                    <img className={styles.idcc_icon} src={codeNafSvg} alt="test"></img>
                    <Pencil className={styles.search_icon} />
                  </>
                )}
                additionalClassNames={`${styles.input_margin} ${styles.input_padding}`}
                onChange={handleCodeIdccInputChange}
                loading={idccCallLoading}
              />
              <div className={idccApiResponse.length > 0 ? styles.output_container : undefined}>
                <ul>
                  {idccApiResponse.map((result) => (
                    <IdccDropdownItem
                      key={nanoid()}
                      result={result}
                      handleClick={() => handleSelectCodeIdcc(result)}
                      handleKeyDown={(e) => e.key === "Enter" && handleSelectCodeIdcc(result)}
                    />
                  ))}
                </ul>
              </div>
            </div>
            <FormDropdownField
              label={t("app.suivezVosContratsPartner.TypeEmployeur")}
              id="employerType"
              values={values.employerType}
              touched={touched.employerType}
              errors={errors.employerType}
              dataKey="key"
              textField="label"
              data={[{ ...objAucun }, ...(referencialData?.COMPANY_EMPLOYER_TYPE || [])]}
              onChange={(newValue) => setFieldValue("employerType", newValue)}
            />
            <FormDropdownField
              label={t("app.suivezVosContratsPartner.FormeJuridique")}
              id="legalForm"
              disabled
              errors={errors.legalForm}
              values={values.legalForm.code}
              renderValue={() => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0",
                  }}
                >
                  <img src={legalForms} style={{ width: "1.2rem" }} alt="test"></img>
                  <div
                    style={{
                      paddingLeft: "2rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {values.legalForm?.code}
                  </div>
                </div>
              )}
              onChange={(value) => {
                setFieldValue("legalForm", value);
              }}
            />
            <FormInputField
              label={t("app.suivezVosContratsPartner.Tva")}
              id="VAT"
              values={values.VAT}
              errors={errors.VAT}
              touched={touched.VAT}
              onChange={(e) => setFieldValue("VAT", e.target.value.trimStart())}
            />
            <FormInputField
              label={t("app.suivezVosContratsPartner.CaisseDeRetraite")}
              id="pensionFund"
              values={values.pensionFund}
              errors={errors.pensionFund}
              touched={touched.pensionFund}
              onChange={(e) => setFieldValue("pensionFund", e.target.value.trimStart())}
            />
            <FormInputField
              label={t("app.suivezVosContratsPartner.Effectif")}
              id="workForce"
              type="number"
              values={values.workForce}
              errors={errors.workForce}
              touched={touched.workForce}
              onChange={(e) => setFieldValue("workForce", e.target.value.trimStart())}
            />
          </div>
          <div className={styles.input_group_line}>
            <FormDropdownField
              label={t("app.suivezVosContratsPartner.RegimeSocial")}
              id="socialRegime"
              values={values.socialRegime}
              data={[{ ...objAucun }, ...(referencialData?.SOCIAL_SECURITY_SYSTEM || [])]}
              errors={errors.socialRegime}
              touched={touched.socialRegime}
              onChange={(newValue) => setFieldValue("socialRegime", newValue)}
            />
            <FormInputField
              label={<InfoBulle />}
              id="providentFund"
              values={values.providentFund}
              errors={errors.providentFund}
              touched={touched.providentFund}
              onChange={(e) => setFieldValue("providentFund", e.target.value.trimStart())}
            />
          </div>
          <div className={styles.input_group_line}>
            <FormInputField
              label={t("app.suivezVosContratsPartner.Adresse")}
              id="address"
              values={values.address}
              errors={errors.address}
              touched={touched.address}
              onChange={(e) => setFieldValue("address", e.target.value.trimStart())}
            />
            <FormInputField
              label={t("app.suivezVosContratsPartner.ComplementDadresse")}
              id="additionalAddress"
              values={values.additionalAddress}
              errors={errors.additionalAddress}
              touched={touched.additionalAddress}
              onChange={(e) => setFieldValue("additionalAddress", e.target.value.trimStart())}
            />
            <FormInputField
              label={t("app.suivezVosContratsPartner.CodePostal2")}
              id="zipCode"
              type="number"
              values={values.zipCode}
              errors={errors.zipCode}
              touched={touched.zipCode}
              onChange={(e) => setFieldValue("zipCode", e.target.value.trimStart())}
            />
            <FormInputField
              label={t("app.suivezVosContratsPartner.Ville")}
              id="city"
              values={values.city}
              errors={errors.city}
              touched={touched.city}
              onChange={(e) => setFieldValue("city", e.target.value.trimStart())}
            />
          </div>
          <TitleInForm>{t("app.suivezVosContratsPartner.FinancementDuContrat")}</TitleInForm>
          <div className={`${styles.input_group_line} ${styles.group_margin}`}>
            <FormDropdownField
              id="billingSameAdress"
              label={t("app.suivezVosContratsPartner.AdresseDifferente")}
              touched={touched.billingSameAdress}
              values={values.billingSameAdress}
              errors={errors.billingSameAdress}
              data={objBoolean}
              onChange={(e) => {
                setBillingInfo(e);
                setFieldValue("billingSameAdress", e);
              }}
            />
          </div>
          {values.billingSameAdress.key === "Oui" && (
            <div className={styles.input_group_line}>
              <FormInputField
                id="billingStreet"
                label={t("app.suivezVosContratsPartner.Adresse")}
                touched={touched.billingStreet}
                values={values.billingStreet}
                errors={errors.billingStreet}
                onChange={(e) => setFieldValue("billingStreet", e.target.value)}
              />
              <FormInputField
                id="billingAdditionalAddress"
                label={t("app.suivezVosContratsPartner.ComplementDadresse")}
                touched={touched.billingAdditionalAddress}
                values={values.billingAdditionalAddress}
                errors={errors.billingAdditionalAddress}
                onChange={(e) => setFieldValue("billingAdditionalAddress", e.target.value)}
              />
              <FormInputField
                id="billingZipCode"
                type="number"
                label={t("app.suivezVosContratsPartner.CodePostal2")}
                touched={touched.billingZipCode}
                values={values.billingZipCode}
                errors={errors.billingZipCode}
                onChange={(e) => setFieldValue("billingZipCode", e.target.value)}
              />
              <FormInputField
                id="billingCity"
                label={t("app.suivezVosContratsPartner.Ville")}
                touched={touched.billingCity}
                values={values.billingCity}
                errors={errors.billingCity}
                onChange={(e) => setFieldValue("billingCity", e.target.value)}
              />
            </div>
          )}
          <div className={styles.submit_btn}>
            <SubmitFormBtn disabled={pendingPatchCompany} text={t("app.visualisationOffre.ValidezLesModifications")} />
          </div>
        </form>
      </div>
    </>
  );
}
