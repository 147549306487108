import styles from "./FormInformationField.module.scss";

const FormInformationField = ({ label, information }) => (
  <div className={styles.user_info_card}>
    <span className={styles.label}>{label}</span>
    <div className={styles.info_container}>{information}</div>
  </div>
);

export default FormInformationField;
