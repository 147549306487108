import { t } from "i18next";

import { useState } from "react";

import { useSelector } from "react-redux";
import { selectContractDataFYC } from "app/slices/followYourContracts/followYourContracts.slice";

import Header from "components/Header/Header";
import DropdownMainSection from "../../components/DropdownMainSection/DropdownMainSection";
import ManualEntrySection from "./ManualEntrySection/ManualEntrySection";
// import PDFSection from "./PDFSection/PDFSection";

import bgContractImg from "../../assets/bg-consul-offre.png";

import styles from "./ModifiableContract.module.scss";

const ModifiableContract = () => {
  const [manualEntrySectionOpen, setManualEntrySectionOpen] = useState(true);
  // const [pdfSectionOpen, setPdfSectionOpen] = useState(false);
  // const isCerfaReturned = true;
  const contractData = useSelector(selectContractDataFYC);
  console.log("contractData", contractData);

  const handleDropdownManualEntrySection = () => {
    setManualEntrySectionOpen((value) => !value);
    // Close other sections
    // setPdfSectionOpen(false);
  };

  // const handleDropdownPdfSection = () => {
  //   setPdfSectionOpen((value) => !value);
  //   // Close other sections
  //   setManualEntrySectionOpen(false);
  // };

  return (
    <>
      <Header
        h1={t("app.suivezVosContratsPartner.SuivezContrats")}
        bgImg={bgContractImg}
        textElement={t("app.suivezVosContratsPartner.Intro2")}
      />

      <div className={styles.main_container}>
        <section className={styles.main_section_container}>
          <DropdownMainSection
            title={t("app.suivezVosContratsPartner.ManualEntrySectionTitle")}
            optional={t("app.suivezVosContratsPartner.ManualEntrySectionOptionalTitle")}
            MainSection={<ManualEntrySection />}
            sectionOpen={manualEntrySectionOpen}
            sectionOpenHandler={handleDropdownManualEntrySection}
          />
        </section>

        {/* {contractData?.company?.strategicAccount && (
          <section className={styles.main_section_container}>
            <DropdownMainSection
              title={t("app.suivezVosContratsPartner.PDFSectionTitle")}
              optional={t("app.suivezVosContratsPartner.PDFSectionOptionalTitle")}
              MainSection={<PDFSection isCerfaReturned={isCerfaReturned} />}
              isCerfaReturned={isCerfaReturned}
              sectionOpen={pdfSectionOpen}
              sectionOpenHandler={handleDropdownPdfSection}
            />
          </section>
        )} */}
      </div>
    </>
  );
};

export default ModifiableContract;
