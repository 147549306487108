import React, { useState, useEffect, useLayoutEffect } from "react";
import { t } from "i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { DropdownList } from "react-widgets";

import { nanoid } from "@reduxjs/toolkit";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { createObjAucun } from "utils/localReferentials";
import { removeEverythingExceptNumbers } from "utils/tools";

import axios from "axios";
import { axiosCompany } from "app/axiosInstances/axiosInstances";
import {
  getDeclareARecruitmentPreContractualizationInformations,
  patchStepThunk,
  handlePreviousStep,
  getDeclareARecruitmentPending,
} from "app/slices/declareARecruitment/declareARecruitment.slice";

import {
  getAllCompanies,
  getSiretThunk,
  getDunsThunk,
  postCompanyThunk,
  postEmployeeThunk,
  selectPostAndPatchCompanyIsPending,
  getPostEmployeeIsPending,
  getAllCompaniesIsPending,
} from "app/slices/checkDetailsCompany/getAllCompanies.slice";

import {
  getEmployeeDetails,
  getCompanyDetailsIsPending,
  getEmployeesThunk,
} from "app/slices/checkDetailsCompany/checkDetailsCompany.slice";

import { getReferentialsData } from "app/slices/referentials/referentials.slice";

import { formatDate } from "utils/formatDate";
import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";

import Loader from "components/Loader/Loader";
import PreviousFormBtn from "components/PreviousFormBtn/PreviousFormBtn";
import Header from "../../components/HeaderContract/HeaderContract";
import SummaryCard from "../../components/SummaryCard/SummaryCard";
import ButtonSubmit from "./components/ButtonSubmit";

import codeNafSvg from "./assets/codeNaf.svg";
import { ReactComponent as Pencil } from "./assets/search.svg";

import styles from "./StepThree.module.scss";
import ConfirmationWarning from "./components/ConfirmationWarning";

const Step3 = () => {
  const dispatch = useDispatch();
  const objAucun = createObjAucun();
  const referencialData = useSelector(getReferentialsData);
  const companyEmployees = useSelector(getEmployeeDetails);
  const allCompanies = useSelector(getAllCompanies);
  const pending = useSelector(getCompanyDetailsIsPending);
  const preContractualizationInformations = useSelector(getDeclareARecruitmentPreContractualizationInformations);

  const [firstCall, setFirstCall] = useState(false);
  const [secondCall, setSecondCall] = useState(false);
  const [companyActive, setCompanyActive] = useState(true);
  const [companyActiveDuns, setCompanyActiveDuns] = useState(true);
  const [errorCode, setErrorCode] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [inputValue3, setInputValue3] = useState("");
  const [country, setCountry] = useState("FR");

  const [step3Token] = useState(axios.CancelToken.source());
  const [apiResponse, setApiResponse] = useState([]);
  const [apiResponse3, setApiResponse3] = useState([]);
  const [, setSelectedItem] = useState(null);
  const [apiResponse2, setApiResponse2] = useState([]);
  const [, setSelectedItem2] = useState(null);
  const [dunsResponse, setDunsResponse] = useState([]);
  const [siretResponse, setSiretResponse] = useState([]);

  const [companyIdCreated, setCompanyIdCreated] = useState(null);
  const [employeeIdCreated, setEmployeeIdCreated] = useState(null);
  const postCompanyPending = useSelector(selectPostAndPatchCompanyIsPending);
  const postEmployeePending = useSelector(getPostEmployeeIsPending);
  const patchStepThunkPending = useSelector(getDeclareARecruitmentPending);
  const siretOrDunsPending = useSelector(getAllCompaniesIsPending);

  // const phoneRegExp = "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$";

  useEffect(() => {
    setFirstCall(false);
    setSecondCall(false);
    setCountry("FR");

    window.scrollTo(0, 0);
  }, []);

  const HandleInput3Change = async (event) => {
    const { value } = event.target;
    setInputValue3(value);
    if (value.length > 2) {
      const response = await axiosCompany().get(`/v1/companies/activities?code=*${value}*`);
      setApiResponse3(response.data);
      console.log("this is the DAta", response.data);
    } else {
      setApiResponse2([]);
    }
    setSelectedItem2(null);
  };
  const HandleInput2Change = async (event) => {
    const { value } = event.target;
    setInputValue2(value);
    if (value.length > 2) {
      const response = await axiosCompany().get(`/v1/companies/collective-agreements?code=*${value}*`);
      setApiResponse2(response.data);
      console.log("this is the DAta", response.data);
    } else {
      setApiResponse2([]);
    }
    setSelectedItem2(null);
  };
  const handleInputChange = async (event) => {
    const { value } = event.target;
    setInputValue(value);
    if (value.length > 2) {
      const response = await axiosCompany().get(`/v1/companies/legal-forms?code=*${value}*`);
      setApiResponse(response.data);
      console.log("this is the DAta", response.data);
    } else {
      setApiResponse([]);
    }
    setSelectedItem(null);
  };
  const handleOptionSelect = (option) => {
    setFieldValue("catJuridique", option.code);
    setInputValue(option.code);
    console.log(values.catJuridique);
    setApiResponse([]);
  };
  const handleOption2Select = (option) => {
    setFieldValue("codeIdcc", option.code);
    setInputValue2(option.code);
    console.log(values.codeIdcc);
    setApiResponse2([]);
  };
  const handleOption3Select = (option) => {
    setFieldValue("codeNaf", option.code);
    setFieldValue("descriptionNaf", option.label);
    setInputValue3(option.code);
    console.log(values.codeNaf);
    setApiResponse3([]);
  };

  const schemaSiret = Yup.string().when([], {
    is: () => values.country.key === "FR",
    then: (schema) =>
      schema
        .matches(/^[0-9]+$/, `${t("app.errorMessages.siretFormatNumber")}`)
        .min(14, `${t("app.errorMessages.siretFormatMinimum")}`)
        .max(14, `${t("app.errorMessages.siretFormatMaximum")}`)
        .required(`${t("app.errorMessages.RemplirChamp")}`),
  });

  const schemaDuns = Yup.string().when([], {
    is: () => values.country.key !== "FR",
    then: (schema) =>
      schema
        .matches(/^[0-9]+$/, `${t("app.errorMessages.dunsFormatNumber")}`)
        .min(9, `${t("app.errorMessages.dunsFormatMinimum")}`)
        .max(9, `${t("app.errorMessages.dunsFormatMaximum")}`)
        .required(`${t("app.errorMessages.RemplirChamp")}`),
  });

  const contactSchema = Yup.object().shape({
    catJuridique: Yup.string().when("notMandatory", {
      is: errorCode === "503" || true,
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    codeNaf: Yup.string().when("notMandatory", {
      is: errorCode === "503" || true,
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    descriptionNaf: Yup.string().when("notMandatory", {
      is: errorCode === "503" || true,
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    companyFrName: Yup.string().when("notMandatory", {
      is: errorCode === "503" || true,
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    typeOrganisation: Yup.object().when("notMandatory", {
      is: errorCode === "503" || true,
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    companyFrAdresse: Yup.string().when("notMandatory", {
      is: errorCode === "503" || true,
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    companyFrZipCode: Yup.string().when("notMandatory", {
      is: errorCode === "503" || true,
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    companyFrCity: Yup.string().when("notMandatory", {
      is: errorCode === "503" || true,
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    country: Yup.object({
      key: Yup.string(),
      label: Yup.string(),
    }).test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    siret: schemaSiret,
    duns: schemaDuns,
    companyName: Yup.string().when([], {
      is: () => values.country.key !== "FR",
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    companyAdress: Yup.string().when([], {
      is: () => values.country.key !== "FR",
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    companyZipCode: Yup.string().when([], {
      is: () => values.country.key !== "FR",
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    companyCity: Yup.string().when([], {
      is: () => values.country.key !== "FR",
      then: (schema) => schema.max(255).required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    email: Yup.string()
      .email(`${t("app.errorMessages.EmailInvalid")}`)
      .required(`${t("app.errorMessages.RemplirChamp")}`),
    lastname: Yup.string().when([], {
      is: () => companyEmployees?.length < 1,
      then: Yup.string()
        .max(80)
        .required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    name: Yup.string().when([], {
      is: () => companyEmployees?.length < 1,
      then: Yup.string()
        .max(80)
        .required(`${t("app.errorMessages.RemplirChamp")}`),
    }),
    phone: Yup.string().when([], {
      is: () =>
        companyEmployees?.length < 1 &&
        values.phone !== undefined &&
        !isValidPhoneNumber(values.phone) &&
        companyEmployees?.length < 1,
      then: (schema) =>
        schema
          .test(
            "if-is-aucun",
            t("app.errorMessages.PhoneFormat"),
            (value) => value !== undefined && isValidPhoneNumber(value) !== false,
          )
          .required(t("app.errorMessages.RemplirChamp")),
    }),
    startDate: Yup.date().typeError(t("app.errorMessages.FormatDateInvalide")).required(),
    contract: Yup.object({
      key: Yup.string(),
      label: Yup.string(),
    }).test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
  });

  console.log("ALL COMPANIES", allCompanies);
  const onSubmit = (values) => {
    if (!companyActive) return;
    if (!companyActiveDuns) return;
    if (allCompanies?.length === 0) return;

    const DataToPost = {
      siret: values.country?.key === "FR" ? values.siret : null,
      duns: values.country?.key === "FR" ? null : parseInt(values.duns, 10),
      name: values.country?.key === "FR" ? values.companyFrName : values.companyName,
      addresses: {
        headquarters: {
          street: values.country?.key === "FR" ? values.companyFrAdresse : values.companyAdress,
          additionalAddress: null,
          zipCode: values.country?.key === "FR" ? values.companyFrZipCode : values.companyZipCode,
          city: values.country?.key === "FR" ? values.companyFrCity : values.companyCity,
          country: values.country?.key,
        },
        billing: {
          sameAsHeadquarters: true,
        },
      },
      activities: values.country?.key === "FR" ? [values.codeNaf] : errorCode !== "503" ? null : [""],
      collectiveAgreement: values.country?.key === "FR" ? values.codeIdcc : null,
      legalForm: values.country?.key === "FR" ? values.catJuridique : null,
      active: allCompanies?.[0]?.active || false,
    };

    const employeeDataToPost = {
      email: values.email,
      name: values.lastname,
      firstname: values.name,
      phones: {
        office: values.phone,
        cellular: values.phone,
      },
      address: {
        street: values.country?.key === "FR" ? values.companyFrAdresse : values.companyAdress,
        additionalAddress: null,
        zipCode: values.country?.key === "FR" ? values.companyFrZipCode : values.companyZipCode,
        city: values.country?.key === "FR" ? values.companyFrCity : values.companyCity,
        country: values.country?.key,
      },
    };
    if (!pending) {
      (async () => {
        let newCompanyIdForEmployee = companyIdCreated;
        let newEmployeeId = employeeIdCreated;
        console.log("companyIdCreated", companyIdCreated);
        console.log("newEmployeeId", newEmployeeId);

        try {
          if (siretNotFound || !companyActive) return;
          if (values.country.key === "FR" || country === "FR") {
            if (allCompanies?.map((e) => e.id).join("") === "" || errorCode === "503") {
              if (!companyIdCreated && !postCompanyPending) {
                await dispatch(
                  postCompanyThunk({
                    dataToSend: DataToPost,
                    axiosCancelToken: step3Token,
                  }),
                )
                  .unwrap()
                  .then((res) => {
                    newCompanyIdForEmployee = res.id;
                    setCompanyIdCreated(res.id);
                  });
              }
            }
            if (
              companyEmployees?.length === 0 &&
              (allCompanies?.map((e) => e.id).join() !== "" || newCompanyIdForEmployee)
            ) {
              if (!employeeIdCreated && !postEmployeePending) {
                await dispatch(
                  postEmployeeThunk({
                    dataToSend: employeeDataToPost,
                    id: newCompanyIdForEmployee
                      ? newCompanyIdForEmployee
                      : parseInt(
                          allCompanies.map((e) => e.id),
                          10,
                        ),
                    axiosCancelToken: step3Token,
                  }),
                )
                  .unwrap()
                  .then((res) => {
                    newEmployeeId = res.id;
                    setEmployeeIdCreated(res.id);
                  });
              }
            }
            if (newEmployeeId && newCompanyIdForEmployee) {
              if (!patchStepThunkPending) {
                await dispatch(
                  patchStepThunk({
                    dataToSend: {
                      stage: "Etape4",
                      contractType: preContractualizationInformations?.contractType?.key,
                      student: {
                        id: preContractualizationInformations?.student?.id,
                      },
                      company: {
                        id: newCompanyIdForEmployee,
                        contactId: newEmployeeId,
                      },
                      startDate: formatDate(values.startDate),
                      recruitmentOrigin: values.contract?.key,
                    },
                    id: preContractualizationInformations.id,
                    axiosCancelToken: step3Token,
                  }),
                ).unwrap();
              }
            } else if (newEmployeeId && allCompanies?.map((e) => e.id).join("") !== "") {
              if (!patchStepThunkPending) {
                await dispatch(
                  patchStepThunk({
                    dataToSend: {
                      stage: "Etape4",
                      contractType: preContractualizationInformations?.contractType?.key,
                      student: {
                        id: preContractualizationInformations?.student?.id,
                      },
                      company: {
                        id: parseInt(allCompanies.map((e) => e.id).join(), 10),
                        contactId: newEmployeeId,
                      },
                      startDate: formatDate(values.startDate),
                      recruitmentOrigin: values.contract?.key,
                    },
                    id: preContractualizationInformations.id,
                    axiosCancelToken: step3Token,
                  }),
                ).unwrap();
              }
            } else if (newCompanyIdForEmployee && companyEmployees?.map((e) => e.id).join("") !== "") {
              if (!patchStepThunkPending) {
                console.log("companyEmployees 1", companyEmployees);
                await dispatch(
                  patchStepThunk({
                    dataToSend: {
                      stage: "Etape4",
                      contractType: preContractualizationInformations?.contractType?.key,
                      student: {
                        id: preContractualizationInformations?.student?.id,
                      },
                      company: {
                        id: newCompanyIdForEmployee,
                        contactId: companyEmployees[0].id,
                      },
                      startDate: formatDate(values.startDate),
                      recruitmentOrigin: values.contract?.key,
                    },
                    id: preContractualizationInformations.id,
                    axiosCancelToken: step3Token,
                  }),
                ).unwrap();
              }
            } else if (
              allCompanies?.map((e) => e.id).join("") !== "" &&
              companyEmployees?.map((e) => e.id).join("") !== ""
            ) {
              if (!patchStepThunkPending) {
                console.log("companyEmployees 2", companyEmployees);
                await dispatch(
                  patchStepThunk({
                    dataToSend: {
                      stage: "Etape4",
                      contractType: preContractualizationInformations?.contractType?.key,
                      student: {
                        id: preContractualizationInformations?.student?.id,
                      },
                      company: {
                        id: parseInt(allCompanies.map((e) => e.id).join(), 10),
                        contactId: companyEmployees[0].id,
                      },
                      startDate: formatDate(values.startDate),
                      recruitmentOrigin: values.contract?.key,
                    },
                    id: preContractualizationInformations.id,
                    axiosCancelToken: step3Token,
                  }),
                ).unwrap();
              }
            }
          }
          if (values.country.key !== "FR" || country !== "FR") {
            if (dunsResponse?.length === 0) {
              if (!companyIdCreated && !postCompanyPending) {
                await dispatch(
                  postCompanyThunk({
                    dataToSend: DataToPost,
                    axiosCancelToken: step3Token,
                  }),
                )
                  .unwrap()
                  .then((res) => {
                    newCompanyIdForEmployee = res.id;
                    setCompanyIdCreated(res.id);
                  });
              }
            }
            if (
              companyEmployees?.length === 0 &&
              (allCompanies?.map((e) => e.id).join() !== "" || newCompanyIdForEmployee)
            ) {
              if (!employeeIdCreated && !postEmployeePending) {
                await dispatch(
                  postEmployeeThunk({
                    dataToSend: employeeDataToPost,
                    id: newCompanyIdForEmployee
                      ? newCompanyIdForEmployee
                      : parseInt(
                          allCompanies.map((e) => e.id),
                          10,
                        ),
                    axiosCancelToken: step3Token,
                  }),
                )
                  .unwrap()
                  .then((res) => {
                    newEmployeeId = res.id;
                    setEmployeeIdCreated(res.id);
                  });
              }
            }
            if (newEmployeeId && newCompanyIdForEmployee) {
              if (!patchStepThunkPending) {
                await dispatch(
                  patchStepThunk({
                    dataToSend: {
                      stage: "Etape4",
                      contractType: preContractualizationInformations?.contractType?.key,
                      student: {
                        id: preContractualizationInformations?.student?.id,
                      },
                      company: {
                        id: newCompanyIdForEmployee,
                        contactId: newEmployeeId,
                      },
                      startDate: formatDate(values.startDate),
                      recruitmentOrigin: values.contract?.key,
                    },
                    id: preContractualizationInformations.id,
                    axiosCancelToken: step3Token,
                  }),
                ).unwrap();
              }
            } else if (newEmployeeId && allCompanies?.map((e) => e.id).join("") !== "") {
              if (!patchStepThunkPending) {
                await dispatch(
                  patchStepThunk({
                    dataToSend: {
                      stage: "Etape4",
                      contractType: preContractualizationInformations?.contractType?.key,
                      student: {
                        id: preContractualizationInformations?.student?.id,
                      },
                      company: {
                        id: parseInt(allCompanies.map((e) => e.id).join(), 10),
                        contactId: newEmployeeId,
                      },
                      startDate: formatDate(values.startDate),
                      recruitmentOrigin: values.contract?.key,
                    },
                    id: preContractualizationInformations.id,
                    axiosCancelToken: step3Token,
                  }),
                ).unwrap();
              }
            } else if (
              newCompanyIdForEmployee &&
              companyEmployees?.map((e) => e.id !== null || e.id !== undefined).join() === "true"
            ) {
              if (!patchStepThunkPending) {
                console.log("companyEmployees 3", companyEmployees);
                await dispatch(
                  patchStepThunk({
                    dataToSend: {
                      stage: "Etape4",
                      contractType: preContractualizationInformations?.contractType?.key,
                      student: {
                        id: preContractualizationInformations?.student?.id,
                      },
                      company: {
                        id: newCompanyIdForEmployee,
                        contactId: companyEmployees[0].id,
                      },
                      startDate: formatDate(values.startDate),
                      recruitmentOrigin: values.contract?.key,
                    },
                    id: preContractualizationInformations.id,
                    axiosCancelToken: step3Token,
                  }),
                ).unwrap();
              }
            } else if (
              allCompanies?.map((e) => e.id).join("") !== "" &&
              companyEmployees?.map((e) => e.id).join("") !== ""
            ) {
              if (!patchStepThunkPending) {
                console.log("companyEmployees 4", companyEmployees);
                await dispatch(
                  patchStepThunk({
                    dataToSend: {
                      stage: "Etape4",
                      contractType: preContractualizationInformations?.contractType?.key,
                      student: {
                        id: preContractualizationInformations?.student?.id,
                      },
                      company: {
                        id: parseInt(allCompanies.map((e) => e.id).join(), 10),
                        contactId: companyEmployees[0].id,
                      },
                      startDate: formatDate(values.startDate),
                      recruitmentOrigin: values.contract?.key,
                    },
                    id: preContractualizationInformations.id,
                    axiosCancelToken: step3Token,
                  }),
                ).unwrap();
              }
            }
          }
        } catch (err) {
          console.log(err.message);
        }
      })();
    }
  };

  console.log("siretResponse", siretResponse);
  console.log("dunsResponse", dunsResponse);
  console.log("companyEmployees", companyEmployees);

  const setFieldHandlerSiret = (value) => {
    if (value.length > 0) {
      console.log("valueBack", value);
      setFieldValue("catJuridique", value.map((e) => e.legalForm.code).join());
      setFieldValue(
        "codeIdcc",
        value.map((e) => e?.id).join() === "" ? null : value.map((e) => e?.collectiveAgreement?.code).join(),
      );
      setFieldValue(
        "codeNaf",
        value.map((e) => e?.activities?.map((e2) => e2?.code).join()).join()
          ? value.map((e) => e?.activities?.map((e2) => e2?.code).join()).join()
          : null,
      );
      setFieldValue("companyFrAdresse", value.map((e) => e.addresses.headquarters.street).join());
      setFieldValue("companyFrCity", value.map((e) => e.addresses.headquarters.city).join());
      setFieldValue("companyFrName", value.map((e) => e.name).join());
      setFieldValue("companyFrZipCode", value.map((e) => e.addresses.headquarters.zipCode).join());
      setFieldValue("descriptionNaf", value.map((e) => e?.activities?.map((e2) => e2?.label).join()).join());
      setFieldValue("active", value.map((e) => e.active).join());

      setFieldValue("companyName", "");
      setFieldValue("companyAdress", "");
      setFieldValue("companyCity", "");
      setFieldValue("companyZipCode", "");
      setFieldValue("duns", "");
      setFieldValue("notMandatory", false);
    } else {
      setFieldValue("catJuridique", "");
      setFieldValue("codeIdcc", "");
      setFieldValue("codeNaf", "");
      setFieldValue("companyFrAdresse", "");
      setFieldValue("companyFrName", "");
      setFieldValue("companyFrZipCode", "");
      setFieldValue("companyFrCity", "");
      setFieldValue("descriptionNaf", "");

      setFieldValue("companyName", "");
      setFieldValue("companyAdress", "");
      setFieldValue("companyCity", "");
      setFieldValue("companyZipCode", "");
      setFieldValue("duns", "");
      setFieldValue("notMandatory", true);
    }
  };

  const setFieldHandlerDuns = (value) => {
    if (value.length > 0) {
      setFieldValue("companyName", value.map((e) => e.name).join());
      setFieldValue("companyAdress", value.map((e) => e.addresses.headquarters.street).join());
      setFieldValue("companyCity", value.map((e) => e.addresses.headquarters.city).join());
      setFieldValue("companyZipCode", value.map((e) => e.addresses.headquarters.zipCode).join());

      setFieldValue("catJuridique", "");
      setFieldValue("codeNaf", "");
      setFieldValue("companyFrAdresse", "");
      setFieldValue("companyFrName", "");
      setFieldValue("companyFrZipCode", "");
      setFieldValue("descriptionNaf", "");
      setFieldValue("codeIdcc", "");
      setFieldValue("siret", "");
      setFieldValue("notMandatory", false);
    } else {
      setFieldValue("companyName", "");
      setFieldValue("companyAdress", "");
      setFieldValue("companyCity", "");
      setFieldValue("companyZipCode", "");
      setFieldValue("notMandatory", false);

      setFieldValue("catJuridique", "");
      setFieldValue("codeNaf", "");
      setFieldValue("companyFrAdresse", "");
      setFieldValue("companyFrName", "");
      setFieldValue("companyFrCity", "");
      setFieldValue("companyFrZipCode", "");
      setFieldValue("descriptionNaf", "");
      setFieldValue("codeIdcc", "");
      setFieldValue("siret", "");
    }
  };
  const { values, errors, touched, setFieldValue, handleSubmit, handleBlur, setStatus } = useFormik({
    initialValues: {
      country: preContractualizationInformations?.company?.addresses?.billing.country
        ? preContractualizationInformations?.company?.addresses?.billing?.country
        : { key: "FR", label: "France" },
      siret: preContractualizationInformations?.company?.siret || "",
      duns: preContractualizationInformations?.company?.duns || "",
      companyName: preContractualizationInformations?.company?.name || "",
      companyAdress: preContractualizationInformations?.company?.addresses?.billing?.street || "",
      companyZipCode: preContractualizationInformations?.company?.addresses?.billing?.zipCode || "",
      companyCity: preContractualizationInformations?.company?.addresses?.billing?.city || "",
      email: preContractualizationInformations?.company?.contact?.email || "",
      name: preContractualizationInformations?.company?.contact?.firstname || "",
      lastname: preContractualizationInformations?.company?.contact?.name || "",
      phone: preContractualizationInformations?.company?.contact?.phone || "",
      startDate: preContractualizationInformations?.startDate
        ? new Date(preContractualizationInformations?.startDate)
        : null,
      contract: preContractualizationInformations?.recruitmentOrigin || { ...objAucun },
      catJuridique: "",
      codeNaf: "",
      descriptionNaf: "",
      companyFrName: "",
      codeIdcc: "",
      typeOrganisation: { ...objAucun },
      companyFrAdresse: "",
      companyFrZipCode: "",
      companyFrCity: "",
    },
    validationSchema: contactSchema,
    onSubmit,
  });

  const validCountry = touched.country && errors.country;
  const validSiret = touched.siret && errors.siret;
  const siretNotFound = siretResponse?.length === 0 && firstCall;
  const validEmail = touched.email && errors.email;
  const validName = touched.name && errors.name;
  const validLastname = touched.lastname && errors.lastname;
  const validPhone = touched.phone && errors.phone;
  const validDate = touched.startDate && errors.startDate;
  const validContract = touched.contract && errors.contract;
  const validDuns = touched.duns && errors.duns;
  const validCompanyName = touched.companyName && errors.companyName;
  const validCompanyAdress = touched.companyAdress && errors.companyAdress;
  const validCompanyZipCode = touched.companyZipCode && errors.companyZipCode;
  const validCompanyCity = touched.companyCity && errors.companyCity;
  const validOrganisation = touched.typeOrganisation && errors.typeOrganisation;
  const validCompanyFrName = touched.companyFrName && errors.companyFrName;
  const validcompanyFrAdresse = touched.companyFrAdresse && errors.companyFrAdresse;
  const validcompanyFrZipCode = touched.companyFrZipCode && errors.companyFrZipCode;
  const validcompanyFrCity = touched.companyFrCity && errors.companyFrCity;
  const validcatJuridique = touched.catJuridique && errors.catJuridique;
  const validcodeNaf = touched.codeNaf && errors.codeNaf;
  const validDescriptionNaf = touched.descriptionNaf && errors.descriptionNaf;
  const validcodeIdcc = touched.codeIdcc && errors.codeIdcc;

  const handleEmployeesCalls = (email) => {
    (async () => {
      try {
        if (allCompanies !== undefined) {
          await dispatch(
            getEmployeesThunk({
              email,
              axiosCancelToken: step3Token,
            }),
          )
            .unwrap()
            .then((res) => {
              if (res.length > 0) {
                setFieldValue("name", res[0]?.firstname);
                setFieldValue("lastname", res[0]?.lastname);
                setFieldValue("phone", res[0]?.phones?.cellular);
              }
            });
          console.log("get success Employees !");
        }
      } catch (error) {
        console.log("get fail Employees !", error.message);
      }
    })();
  };

  const setDunsResponseToNull = (value) => {
    if (value === "FR") {
      setDunsResponse([]);
    }
  };

  const setSiretResponseToNull = (value) => {
    if (value !== "FR") {
      setSiretResponse([]);
    }
  };

  console.log("erreurs formik", errors);

  const handleSiretCalls = async (siret) => {
    // const isSiret = true;
    let responseForSiretTrue = null;
    setErrorCode(null);

    try {
      await dispatch(
        getSiretThunk({
          siret,
          axiosCancelToken: step3Token,
        }),
      )
        .unwrap()
        .then((res) => {
          responseForSiretTrue = res;
          setCompanyActive(JSON.parse(res.map((e) => e.active).join()));
          setStatus(res.map((e) => e.active).join());
          setFieldValue("active", false);
          setSiretResponse(responseForSiretTrue);
          setFieldHandlerSiret(responseForSiretTrue);
        })
        .catch((err) => {
          setErrorCode(err.message.split(" ")[5]);
          console.log("err amar", err);
        });
      setFirstCall(true);
      if (allCompanies?.length === 0) {
        await dispatch(
          getSiretThunk({
            siret,
            axiosCancelToken: step3Token,
          }),
        )
          .unwrap()
          .then((res) => {
            responseForSiretTrue = res;
            setCompanyActive(JSON.parse(res.map((e) => e.active).join()));
            setStatus(res.map((e) => e.active).join());
            setFieldValue("active", false);
            setSiretResponse(responseForSiretTrue);
            setFieldHandlerSiret(responseForSiretTrue);
          })
          .catch((err) => setErrorCode(err.message.split(" ")[5]));
      }
    } catch (error) {
      console.log(error.message);
      setErrorCode(error.message.split(" ")[5]);
      if (errorCode === "503") {
        try {
          dispatch(
            getSiretThunk({
              siret,
              axiosCancelToken: step3Token,
            }),
          )
            .unwrap()
            .then((res) => setFieldHandlerSiret(res));
          console.log("get success SIRET !");
        } catch (err) {
          console.log("get siret failed ", err.message);
        }
      }
    }
  };

  const handleDunsCalls = async (duns) => {
    let responseDuns = null;
    setCompanyActiveDuns(true);
    setErrorCode(null);

    try {
      await dispatch(
        getDunsThunk({
          duns,
          axiosCancelToken: step3Token,
        }),
      )
        .unwrap()
        .then((res) => {
          responseDuns = res;
          console.log("responseDuns", responseDuns);
          if (!responseDuns?.[0]?.active && responseDuns.length > 0) {
            setCompanyActiveDuns(false);
          }
        });
      setDunsResponse(responseDuns);

      setFieldHandlerDuns(responseDuns);
      if (responseDuns?.length > 0) {
        setSecondCall(false);
      } else {
        setSecondCall(true);
      }
      console.log("get success DUNS !");
    } catch (error) {
      console.log("get fail DUNS !", error.message);
    }
  };

  useLayoutEffect(() => {
    if (values.siret.length === 14 && !errors.siret) {
      handleSiretCalls(values.siret);
    }
  }, [values.siret, errors.siret]);

  useLayoutEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!errors.email && values.email.length > 2) {
        handleEmployeesCalls(values.email);
      }
    }, 800);

    return () => clearTimeout(delayDebounceFn);
  }, [values.email, errors.email]);

  useLayoutEffect(() => {
    if (values.duns.length === 9 && !errors.duns) {
      handleDunsCalls(values.duns);
    }
  }, [values.duns, errors.duns]);

  const handleResetCompanyInformations = () => {
    setFieldValue("companyFrName", "");
    setFieldValue("companyFrAdresse", "");
    setFieldValue("companyFrZipCode", "");
    setFieldValue("companyFrCity", "");
    setFieldValue("catJuridique", "");
    setFieldValue("codeNaf", "");
    setFieldValue("descriptionNaf", "");
    setFieldValue("companyName", "");
    setFieldValue("companyAdress", "");
    setFieldValue("companyZipCode", "");
    setFieldValue("companyCity", "");
  };

  useLayoutEffect(() => {
    if (allCompanies?.length === 0) {
      handleResetCompanyInformations();
    }
    if (!allCompanies) {
      handleResetCompanyInformations();
    }
  }, [allCompanies]);

  console.log("errorCode amar", errorCode);

  return (
    referencialData && (
      <div className={styles.container}>
        {(pending || postCompanyPending || postEmployeePending || patchStepThunkPending || siretOrDunsPending) && (
          <Loader />
        )}
        <Header />
        <div className={styles.wrapper}>
          <div className={styles.wrapper__colLeft}>
            <form onSubmit={handleSubmit}>
              <div className={styles.header}>
                <h2 className={styles.headerTitle}>{t("app.StepThree.VotreEntreprise")}</h2>
                <div className={styles.stepLevel}>{t("app.declarezUnRecrutement.Etape3Sur7")}</div>
              </div>
              <div className={styles.boxWrapper}>
                <div className={styles.formGroup}>
                  <div className={styles.Dropdown_label_plus_input_container}>
                    <label htmlFor="country">{t("app.StepThree.PaysDeLentreprise")}</label>
                    <DropdownList
                      value={values.country.key}
                      data={[{ ...objAucun }, ...(referencialData?.COUNTRY || [])]}
                      dataKey="key"
                      textField="label"
                      id="country"
                      style={{ marginTop: "5px" }}
                      className={`${validCountry && `${styles.errorInput}`} dropdown_customized`}
                      onChange={(value) => {
                        setCountry(value.key);
                        setDunsResponseToNull(value.key);
                        setSiretResponseToNull(value.key);
                        setFieldValue("country", value);
                      }}
                    />
                  </div>
                  {validCountry && <span className={styles.error}>{errors.country}</span>}
                </div>
                <div id="active"></div>
                {values.country?.key === "FR" ? (
                  <>
                    <div className={`${styles.formGroup} input-like-rw`}>
                      <div className={styles.label_plus_info_bulle}>
                        <label htmlFor="contractType">{t("app.StepThree.SiretEntreprise")}</label>
                        <div className={styles.infoBulle}>
                          <svg
                            className="slds-button__icon"
                            focusable="false"
                            data-key="info"
                            aria-hidden="true"
                            viewBox="0 0 52 52"
                            part="icon"
                          >
                            <g>
                              <path d="M26 2C12.7 2 2 12.7 2 26s10.7 24 24 24 24-10.7 24-24S39.3 2 26 2zm0 12.1c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm5 21c0 .5-.4.9-1 .9h-8c-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1.5 0 1-.3 1-.9v-4c0-.5-.4-1.1-1-1.1-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1h6c.5 0 1 .5 1 1.1v8c0 .5.4.9 1 .9.5 0 1 .5 1 1.1v2z"></path>
                            </g>
                          </svg>
                          <div className={styles.infoBulleInfo}>{t("app.declarezUnRecrutement.SiretInfo")}</div>
                        </div>
                      </div>
                      <input
                        type="text"
                        name="siret"
                        id="siret"
                        value={values.siret}
                        className={
                          (validSiret || siretNotFound || (errorCode === "404" && values.siret.length === 14) || (allCompanies?.length === 0 && values.siret.length === 14)) &&
                          styles.errorInput
                        }
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        onChange={(e) => {
                          if (!siretOrDunsPending) {
                            setFieldValue("siret", removeEverythingExceptNumbers(e.target.value));
                          }
                        }}
                      />
                      {validSiret && <span className={styles.error}>{errors.siret}</span>}
                      {errorCode === "404" && values.siret.length === 14 && (
                        <span className={styles.error}>{`${t("app.errorMessages.siretNotFound")}`}</span>
                      )}
                      {!companyActive && (
                        <span className={styles.error}>{`${t("app.errorMessages.companyInactiv")}`}</span>
                      )}
                    </div>
                    <h4 className={styles.subTitle}>{t("app.StepThree.InformationEntreprise")}</h4>
                    {errorCode !== "503" || firstCall ? (
                      <>
                        <div className={styles.listInfo}>
                          <span className={styles.listInfo__name}>
                            {t("app.StepThree.NomDeLentrepriseEtAssociation")}
                          </span>
                          <span className={styles.info}>{values.companyFrName}</span>
                        </div>
                        <div className={styles.listInfo}>
                          <span className={styles.listInfo__name}>{t("app.StepThree.AdresseRue")}</span>
                          <span className={styles.info}>{values.companyFrAdresse}</span>
                        </div>
                        <div className={styles.listInfo}>
                          <span className={styles.listInfo__name}>{t("app.StepThree.CodePostal")}</span>
                          <span className={styles.info}>{values.companyFrZipCode}</span>
                        </div>
                        <div className={styles.listInfo}>
                          <span className={styles.listInfo__name}>{t("app.StepThree.Ville")}</span>
                          <span className={styles.info}>{values.companyFrCity}</span>
                        </div>
                        <div className={styles.listInfo}>
                          <span className={styles.listInfo__name}>{t("app.StepThree.categorieJuridique")}</span>
                          <span className={styles.info}>{values.catJuridique}</span>
                        </div>
                        <div className={styles.listInfo}>
                          <span className={styles.listInfo__name}>{t("app.StepThree.CodeNaf")}</span>
                          <span className={styles.info}>{values.codeNaf}</span>
                        </div>
                        <div className={styles.listInfo}>
                          <span className={styles.listInfo__name}>{t("app.StepThree.descriptionNaf")}</span>
                          <span className={styles.info}>{values.descriptionNaf}</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="companyFrName">{t("app.StepThree.NomDeLentrepriseEtAssociation")}</label>
                          <input
                            type="text"
                            name="companyFrName"
                            id="companyFrName"
                            value={values.companyFrName}
                            className={`${errors.companyFrName && touched.companyFrName && `${styles.errorInput}`}`}
                            onChange={(e) => {
                              setFieldValue("companyFrName", e.target.value);
                            }}
                          />
                          {validCompanyFrName && <span className={styles.error}>{errors.companyFrName}</span>}
                        </div>
                        <div className={styles.formGroup}>
                          <div className={styles.Dropdown_label_plus_input_container}>
                            <label htmlFor="typeOrganisation">{t("app.StepThree.typeOrganisation")}</label>
                            <DropdownList
                              value={values.typeOrganisation.key}
                              data={[{ ...objAucun }, ...(referencialData?.COMPANY_TYPE || [])]}
                              dataKey="key"
                              textField="label"
                              id="typeOrganisation"
                              style={{ marginTop: "5px" }}
                              className={`${
                                errors.typeOrganisation && touched.typeOrganisation && `${styles.errorInput}`
                              } dropdown_customized`}
                              onChange={(value) => {
                                setFieldValue("typeOrganisation", value);
                              }}
                            />
                          </div>
                          {validOrganisation && <span className={styles.error}>{errors.typeOrganisation}</span>}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="siret">{t("app.StepThree.AdresseRue")}</label>
                          <input
                            type="text"
                            name="companyFrAdresse"
                            id="companyFrAdresse"
                            value={values.companyFrAdresse}
                            className={`${
                              errors.companyFrAdresse && touched.companyFrAdresse && `${styles.errorInput}`
                            }`}
                            onChange={(e) => {
                              setFieldValue("companyFrAdresse", e.target.value);
                            }}
                          />
                          {validcompanyFrAdresse && <span className={styles.error}>{errors.companyFrAdresse}</span>}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="companyFrZipCode">{t("app.StepThree.CodePostal")}</label>
                          <input
                            type="text"
                            name="companyFrZipCode"
                            id="companyFrZipCode"
                            value={values.companyFrZipCode}
                            className={`${
                              errors.companyFrZipCode && touched.companyFrZipCode && `${styles.errorInput}`
                            }`}
                            onChange={(e) => {
                              setFieldValue("companyFrZipCode", e.target.value);
                            }}
                          />
                          {validcompanyFrZipCode && <span className={styles.error}>{errors.companyFrZipCode}</span>}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="companyFrCity">{t("app.StepThree.Ville")}</label>
                          <input
                            type="text"
                            name="companyFrCity"
                            id="companyFrCity"
                            value={values.companyFrCity}
                            className={`${errors.companyFrCity && touched.companyFrCity && `${styles.errorInput}`}`}
                            onChange={(e) => {
                              setFieldValue("companyFrCity", e.target.value);
                            }}
                          />

                          {validcompanyFrCity && <span className={styles.error}>{errors.companyFrCity}</span>}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="catJuridique">{t("app.StepThree.categorieJuridique")}</label>
                          <div
                            className={`${styles.label_plus_input_container} input-like-rw ${styles.search_container}`}
                          >
                            <input
                              className={`${errors.catJuridique && touched.catJuridique && `${styles.errorInput}`}`}
                              type="text"
                              value={inputValue}
                              onChange={handleInputChange}
                            />
                            <Pencil className={styles.search_icon} />
                          </div>
                          <div className={apiResponse.length > 0 ? styles.output_container : undefined}>
                            <ul>
                              {apiResponse.map((result) => (
                                <div>
                                  <Pencil className={styles.search_icon} />

                                  <div key={nanoid()} className={styles.output_plus_image_Container}>
                                    <img src={codeNafSvg} style={{ width: "1.8rem" }} alt="test"></img>
                                    <div
                                      className={styles.outputKeyLabel}
                                      onClick={() => handleOptionSelect(result)}
                                      onKeyDown={(e) => e.key === "Enter" && handleOptionSelect(result)}
                                      role="button"
                                      tabIndex={0}
                                    >
                                      <li>{result.code}</li>
                                      <li> {result.label}</li>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </ul>
                          </div>
                          {validcatJuridique && <span className={styles.error}>{errors.catJuridique}</span>}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="codeNaf">{t("app.StepThree.CodeNaf")}</label>
                          <div
                            className={`${styles.label_plus_input_container} input-like-rw ${styles.search_container}`}
                          >
                            <input
                              className={`${errors.codeNaf && touched.codeNaf && `${styles.errorInput}`}`}
                              type="text"
                              value={inputValue3}
                              onChange={HandleInput3Change}
                            />
                            <Pencil className={styles.search_icon} />
                          </div>
                          <div className={apiResponse3.length > 0 ? styles.output_container : undefined}>
                            <ul>
                              {apiResponse3.map((result) => (
                                <div>
                                  <Pencil className={styles.search_icon} />

                                  <div key={nanoid()} className={styles.output_plus_image_Container}>
                                    <img src={codeNafSvg} style={{ width: "1.8rem" }} alt="test"></img>
                                    <div
                                      className={styles.outputKeyLabel}
                                      onClick={() => handleOption3Select(result)}
                                      onKeyDown={(e) => e.key === "Enter" && handleOption3Select(result)}
                                      role="button"
                                      tabIndex={0}
                                    >
                                      <li>{result.code}</li>
                                      <li> {result.label}</li>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </ul>
                          </div>
                          {validcodeNaf && <span className={styles.error}>{errors.codeNaf}</span>}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="descriptionNaf">{t("app.StepThree.descriptionNaf")}</label>
                          <input
                            type="text"
                            name="descriptionNaf"
                            id="descriptionNaf"
                            value={values.descriptionNaf}
                            className={`${validDescriptionNaf && `${styles.errorInput}`}`}
                            onChange={(e) => {
                              setFieldValue("descriptionNaf", e.target.value);
                            }}
                          />
                          {validDescriptionNaf && <span className={styles.error}>{errors.descriptionNaf}</span>}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="codeIdcc">{t("app.StepThree.CodeIDCC")}</label>
                          <div
                            className={`${styles.label_plus_input_container} input-like-rw ${styles.search_container}`}
                          >
                            <input
                              className={`${validcodeIdcc && `${styles.errorInput}`}`}
                              type="text"
                              value={inputValue2}
                              onChange={HandleInput2Change}
                            />
                            <Pencil className={styles.search_icon} />
                          </div>
                          <div className={apiResponse2.length > 0 ? styles.output_container : undefined}>
                            <ul>
                              {apiResponse2.map((result) => (
                                <div>
                                  <Pencil className={styles.search_icon} />

                                  <div key={result.code} className={styles.output_plus_image_Container}>
                                    <img src={codeNafSvg} style={{ width: "1.8rem" }} alt="test"></img>
                                    <div
                                      className={styles.outputKeyLabel}
                                      onClick={() => handleOption2Select(result)}
                                      onKeyDown={(e) => e.key === "Enter" && handleOption2Select(result)}
                                      role="button"
                                      tabIndex={0}
                                    >
                                      <li>{result.code}</li>
                                      <li> {result.label}</li>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </ul>
                          </div>
                          {validcodeIdcc && <span className={styles.error}>{errors.codeIdcc}</span>}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {dunsResponse?.length < 1 && secondCall ? (
                      <>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="duns">{t("app.StepThree.Duns")}</label>
                          <input
                            type="text"
                            name="duns"
                            id="duns"
                            value={values.duns}
                            className={`${validDuns && `${styles.errorInput}`}`}
                            onBlur={(e) => {
                              handleBlur(e);
                            }}
                            onChange={(e) => {
                              if (!siretOrDunsPending) {
                                setFieldValue("duns", removeEverythingExceptNumbers(e.target.value));
                              }
                            }}
                          />
                          {validDuns ? (
                            <span className={styles.error}>{errors.duns}</span>
                          ) : dunsResponse?.length === 0 && secondCall ? (
                            <span className={styles.error}>{`${t("app.errorMessages.dunsNotFound")}`}</span>
                          ) : (
                            <span className={styles.error}>{errors.duns}</span>
                          )}
                          {!companyActiveDuns && !errors.duns && (
                            <span className={styles.error}>{`${t("app.errorMessages.companyInactiv")}`}</span>
                          )}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="companyName">{t("app.StepThree.NomDeLentrepriseEtAssociation")}</label>
                          <input
                            type="text"
                            name="companyName"
                            id="companyName"
                            maxLength={255}
                            value={values.companyName}
                            className={`${validCompanyName && `${styles.errorInput}`}`}
                            onChange={(e) => {
                              setFieldValue("companyName", e.target.value.trimStart());
                            }}
                          />
                          {validCompanyName && <span className={styles.error}>{errors.companyName}</span>}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="companyAdress">{t("app.StepThree.AdresseRue")}</label>
                          <input
                            type="text"
                            name="companyAdress"
                            id="companyAdress"
                            maxLength={255}
                            value={values.companyAdress}
                            className={`${validCompanyAdress && `${styles.errorInput}`}`}
                            onChange={(e) => {
                              setFieldValue("companyAdress", e.target.value.trimStart());
                            }}
                          />
                          {validCompanyAdress && <span className={styles.error}>{errors.companyAdress}</span>}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="companyZipCode">{t("app.StepThree.CodePostal")}</label>
                          <input
                            type="text"
                            name="companyZipCode"
                            id="companyZipCode"
                            maxLength={255}
                            value={values.companyZipCode}
                            className={`${validCompanyZipCode && `${styles.errorInput}`}`}
                            onChange={(e) => {
                              setFieldValue("companyZipCode", e.target.value.trimStart());
                            }}
                          />
                          {validCompanyZipCode && <span className={styles.error}>{errors.companyZipCode}</span>}
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="companyCity">{t("app.StepThree.Ville")}</label>
                          <input
                            type="text"
                            name="companyCity"
                            id="companyCity"
                            maxLength={255}
                            value={values.companyCity}
                            className={`${validCompanyCity && `${styles.errorInput}`}`}
                            onChange={(e) => {
                              setFieldValue("companyCity", e.target.value.trimStart());
                            }}
                          />
                          {validCompanyCity && <span className={styles.error}>{errors.companyCity}</span>}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="duns">{t("app.StepThree.Duns")}</label>
                          <input
                            type="text"
                            name="duns"
                            id="duns"
                            value={values.duns}
                            className={`${validDuns && `${styles.errorInput}`}`}
                            onBlur={(e) => {
                              handleBlur(e);
                            }}
                            onChange={(e) => {
                              if (!siretOrDunsPending) {
                                setFieldValue("duns", removeEverythingExceptNumbers(e.target.value));
                              }
                            }}
                          />
                          {validDuns && <span className={styles.error}>{errors.duns}</span>}
                          {!companyActiveDuns && !errors.duns && (
                            <span className={styles.error}>{`${t("app.errorMessages.companyInactiv")}`}</span>
                          )}
                        </div>
                        <div className={styles.listInfo}>
                          <span className={styles.listInfo__name}>
                            {t("app.StepThree.NomDeLentrepriseEtAssociation")}
                          </span>
                          <span className={styles.info}>{values.companyName}</span>
                        </div>
                        <div className={styles.listInfo}>
                          <span className={styles.listInfo__name}>{t("app.StepThree.AdresseRue")}</span>
                          <span className={styles.info}>{values.companyAdress}</span>
                        </div>
                        <div className={styles.listInfo}>
                          <span className={styles.listInfo__name}>{t("app.StepThree.CodePostal")}</span>
                          <span className={styles.info}>{values.companyZipCode}</span>
                        </div>
                        <div className={styles.listInfo}>
                          <span className={styles.listInfo__name}>{t("app.StepThree.Ville")}</span>
                          <span className={styles.info}>{values.companyCity}</span>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
              <div className={styles.header}>
                <h2 className={styles.headerTitle}>{t("app.StepThree.InformationsContact")}</h2>
              </div>
              <div className={styles.boxWrapper}>
                <div className={`${styles.formGroup} input-like-rw`}>
                  <div className={styles.label_plus_info_bulle}>
                    <label htmlFor="email">{t("app.StepThree.AdresseMailDuContact")}</label>
                    <div className={styles.infoBulle}>
                      <svg
                        className="slds-button__icon"
                        focusable="false"
                        data-key="info"
                        aria-hidden="true"
                        viewBox="0 0 52 52"
                        part="icon"
                      >
                        <g>
                          <path d="M26 2C12.7 2 2 12.7 2 26s10.7 24 24 24 24-10.7 24-24S39.3 2 26 2zm0 12.1c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm5 21c0 .5-.4.9-1 .9h-8c-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1.5 0 1-.3 1-.9v-4c0-.5-.4-1.1-1-1.1-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1h6c.5 0 1 .5 1 1.1v8c0 .5.4.9 1 .9.5 0 1 .5 1 1.1v2z"></path>
                        </g>
                      </svg>
                      <div className={styles.infoBulleInfo}>{t("app.declarezUnRecrutement.emailContactInfo")}</div>
                    </div>
                  </div>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={values.email}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                    className={`${validEmail && `${styles.errorInput}`}`}
                    onChange={(e) => {
                      setFieldValue("email", e.target.value);
                      // On reset les champs seulement si le precedent email avait été validé par le call API
                      if (companyEmployees.length >= 1) {
                        setFieldValue("name", "");
                        setFieldValue("lastname", "");
                        setFieldValue("phone", "");
                      }
                    }}
                  />
                  {validEmail && <span className={styles.error}>{errors.email}</span>}
                </div>
                {companyEmployees !== null && companyEmployees?.length === 1 && (
                  <>
                    {companyEmployees?.map((row) => (
                      <React.Fragment key={nanoid()}>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="contactName">{t("app.StepThree.PrenomDuContact")}</label>
                          <input id="contactName" name="contactName" type="text" disabled value={row?.firstname} />
                        </div>
                        <div className={`${styles.formGroup} input-like-rw`}>
                          <label htmlFor="contactLastname">{t("app.StepThree.NomDuContact")}</label>
                          <input
                            key={nanoid()}
                            id="contactLastname"
                            name="contactLastname"
                            type="text"
                            disabled
                            value={row?.name}
                          />
                        </div>
                      </React.Fragment>
                    ))}
                  </>
                )}
                {companyEmployees !== null && !companyEmployees?.length && values.email && (
                  <>
                    <div className={`${styles.formGroup} input-like-rw`}>
                      <label htmlFor="name">{t("app.StepThree.PrenomDuContact")}</label>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        value={values.name}
                        onBlur={handleBlur}
                        onChange={(e) => setFieldValue("name", e.target.value.trimStart())}
                        className={`${validName && `${styles.errorInput}`}`}
                      />
                      {validName && <span className={styles.error}>{errors.name}</span>}
                    </div>
                    <div className={`${styles.formGroup} input-like-rw`}>
                      <label htmlFor="lastname">{t("app.StepThree.NomDuContact")}</label>
                      <input
                        id="lastname"
                        name="lastname"
                        type="text"
                        value={values.lastname}
                        onBlur={handleBlur}
                        onChange={(e) => setFieldValue("lastname", e.target.value.trimStart())}
                        className={`${validLastname && `${styles.errorInput}`}`}
                      />
                      {validLastname && <span className={styles.error}>{errors.lastname}</span>}
                    </div>
                    <div className={`${styles.formGroup} input-like-rw`}>
                      <label htmlFor="phone">{t("app.StepThree.NumeroDeTelephoneDuContact")}</label>
                      <PhoneInput
                        type="text"
                        name="phone"
                        id="phone"
                        countries={[country]}
                        addInternationalOption={false}
                        defaultCountry={country}
                        value={values.phone}
                        onChange={(e) => {
                          setFieldValue("phone", e);
                        }}
                        className={`${validPhone && `${styles.errorInput}`}`}
                      />
                      {validPhone && <span className={styles.error}>{errors.phone}</span>}
                    </div>
                  </>
                )}
                <div className={styles.formGroup}>
                  <div className={styles.label_plus_info_bulle}>
                    <label htmlFor="startDate">{t("app.StepThree.DateDeDebutSouhaiteeParLentreprise")}</label>
                    <div className={styles.infoBulle}>
                      <svg
                        className="slds-button__icon"
                        focusable="false"
                        data-key="info"
                        aria-hidden="true"
                        viewBox="0 0 52 52"
                        part="icon"
                      >
                        <g>
                          <path d="M26 2C12.7 2 2 12.7 2 26s10.7 24 24 24 24-10.7 24-24S39.3 2 26 2zm0 12.1c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm5 21c0 .5-.4.9-1 .9h-8c-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1.5 0 1-.3 1-.9v-4c0-.5-.4-1.1-1-1.1-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1h6c.5 0 1 .5 1 1.1v8c0 .5.4.9 1 .9.5 0 1 .5 1 1.1v2z"></path>
                        </g>
                      </svg>
                      <div className={styles.infoBulleInfo}>{t("app.declarezUnRecrutement.StartDateInfo")}</div>
                    </div>
                  </div>

                  <DatePickerCustom
                    error={errors.startDate}
                    touched={touched.startDate}
                    id="startDate"
                    value={values.startDate}
                    onChange={(newValue) => setFieldValue("startDate", newValue)}
                  />
                  {validDate && <span className={styles.error}>{errors.startDate}</span>}
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="contract">{t("app.StepThree.CommentAvezVousTrouveVotreContrat")}</label>
                  <DropdownList
                    data={[{ ...objAucun }, ...(referencialData?.APPRENTICESHIP_RECRUITMENT_ORIGIN_CONTRACT || [])]}
                    value={values.contract}
                    dataKey="key"
                    textField="label"
                    // onBlur={handleBlur}
                    id="contract"
                    className={`${validContract && `${styles.errorInput}`} dropdown_customized`}
                    onChange={(newValue) => setFieldValue("contract", newValue)}
                  />
                  {validContract && <span className={styles.error}>{errors.contract}</span>}
                </div>
                <ConfirmationWarning />
                <div className={styles.action}>
                  <ButtonSubmit
                    text={t("app.StepThree.ValidezEtSoumettezVotreEntreprise")}
                    handleSubmit={handleSubmit}
                    pending={postCompanyPending || postEmployeePending || patchStepThunkPending}
                  />
                  <PreviousFormBtn
                    text={t("app.StepThree.EtapePrecedente")}
                    handlePreviousStep={() => dispatch(handlePreviousStep("Etape2"))}
                  />
                </div>
              </div>
            </form>
          </div>
          <SummaryCard
            data={[
              {
                title: t("app.declarezUnRecrutement.ContratSouhaite"),
                text: preContractualizationInformations.contractType?.label,
              },
              {
                title: t("app.declarezUnRecrutement.Nationalite"),
                text: preContractualizationInformations.student?.person?.nationalities
                  ?.map((obj) => obj.key)
                  ?.join("; "),
              },
            ]}
          />
        </div>
      </div>
    )
  );
};
export default Step3;
