import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";
import PhoneInput from "react-phone-number-input/input";
import styles from "./FormPhoneField.module.scss";

// Lors de l'utilisation de ce composant, il faut utiliser la propriété submitCount de Formik pour la props touched={ submitCount >0 }

export const FormPhoneField = ({
  id,
  label,
  errors,
  values,
  touched,
  onChange,
  disabled = false,
  additionalClassNames = "",
  children,
  handleBlur,
}) => (
  <div
    className={`${styles.formGroup} input-like-rw ${additionalClassNames}`}
  >
    <label htmlFor={id}>{label}</label>
    <PhoneInput
      className={errors && touched ? styles.errorInput : null}
      id={id}
      type="text"
      country="FR"
      onBlur={handleBlur}
      value={values}
      onChange={onChange}
      disabled={disabled}
    />
    {children}
    <InputErrorMessage message={errors} touched={touched} />
  </div>
);
