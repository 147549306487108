import { Routes, Route } from "react-router-dom";

import { useSelector } from "react-redux";
import { getMeData, getMeDataV2, getUserConnexionErrorMessage } from "app/slices/userConnexion/userConnexion.slice";

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import { LOCAL_COMPANY_BYPASS } from "utils/globalConstantsAndURL";

import Loader from "components/Loader/Loader";

// import CompanySheet from "pages/ENT/CompanySheet/CompanySheet";
import ContactUsENT from "pages/ENT/ContactUs/ContactUsENT";
import Mission from "pages/ENT/LearningBooklets/SelectedBooklet/forms/Missions/Mission";
import ConfirmationBooklet from "pages/ENT/LearningBooklets/ConfirmationBooklet/ConfirmationBooklet";
import SelectedBooklet from "pages/ENT/LearningBooklets/SelectedBooklet/SelectedBooklet";
import NewContactConfirmation from "pages/ENT/Register/components/NewContactConfirmation/NewContactConfirmation";
import ExistingContactConfirmation from "pages/ENT/Register/components/ExistingContactConfirmation/ExistingContactConfirmation";
import RegisterENTNotAuthenticated from "pages/ENT/Register/Register";
import RelationEnt from "pages/ENT/RelationEnt/RelationEnt";
import LearningBooklets from "pages/ENT/LearningBooklets/LearningBooklets";
import Faq from "pages/ENT/Faq/Faq";
import CvsById from "pages/ENT/CvById/CvById";
import OfferDetails from "pages/ENT/FollowOffers/OfferDetails/OfferDetails";
import HomepageNotAuthenticated from "pages/ENT/Homepage/HomepageNotAuthenticated/HomepageNotAuthenticated";
import ContractsListView from "pages/ENT/FollowYourContracts/ContractsListView/ContractsListView";
import SelectedContract from "pages/ENT/FollowYourContracts/SelectedContract/SelectedContract";
import CompanyProfile from "pages/ENT/CompanyProfile/CompanyProfile";
import Homepage from "../../pages/ENT/Homepage/Homepage";
import PublishAnOffer from "../../pages/ENT/PublishAnOffer/PublishAnOffer";
import FollowOffers from "../../pages/ENT/FollowOffers/FollowOffers";
import Page404 from "../../pages/global/Page404/Page404";
import ListOfCV from "../../pages/ENT/ListOfCV/ListOfCV";

const RoutesENT = () => {
  const getMe = useSelector(getMeData);
  const getMeV2 = useSelector(getMeDataV2);
  const userConnexionErrorMessage = useSelector(getUserConnexionErrorMessage);

  const RoutesUnauthenticatedStructure = (
    <Routes>
      <Route path="/" element={<HomepageNotAuthenticated />} />
      <Route path="/inscription-entreprises" element={<RegisterENTNotAuthenticated />} />
      <Route path="/inscription-confirmation" element={<NewContactConfirmation />} />
      <Route path="/inscription-dejainscrit" element={<ExistingContactConfirmation />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/check" element={<>dfn-health-check-ok</>} />
      <Route path="/contactez-nous" element={<ContactUsENT />} />
      <Route path="/*" element={<Page404 />} />
    </Routes>
  );

  const RoutesAuthenticatedStructure = (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/deposer-une-offre" element={<PublishAnOffer />} />
      <Route path="/mes-offres" element={<FollowOffers />} />
      <Route path="/liste-des-cvs" element={<ListOfCV />} />
      <Route path="/details/:id" element={<CvsById />} />
      <Route path="/mes-offres/:id" element={<OfferDetails />} />
      <Route path="/suivez-vos-contrats" element={<ContractsListView />} />
      <Route path="/suivez-vos-contrats/:id" element={<SelectedContract />} />
      <Route path="/suivez-vos-alternants" element={<LearningBooklets />} />
      <Route path="/suivez-vos-alternants/:id" element={<SelectedBooklet />} />
      <Route path="/suivez-vos-alternants/confirmation" element={<ConfirmationBooklet />} />
      <Route path="/suivez-vos-alternants/:id/missions" element={<Mission />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/contactez-nous" element={<ContactUsENT />} />
      <Route path="/relation-entreprise" element={<RelationEnt />} />
      <Route path="/fiche-entreprise" element={<CompanyProfile />} />
      <Route path="/*" element={<Page404 />} />
    </Routes>
  );

  if (LOCAL_COMPANY_BYPASS) {
    return (
      <>
        {(!getMe || !getMeV2) && !userConnexionErrorMessage && <Loader />}
        {getMe && getMeV2 && RoutesAuthenticatedStructure}
      </>
    );
  }

  return (
    <>
      <UnauthenticatedTemplate>{RoutesUnauthenticatedStructure}</UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        {(!getMe || !getMeV2) && !userConnexionErrorMessage && <Loader />}
        {getMe && getMeV2 && RoutesAuthenticatedStructure}
      </AuthenticatedTemplate>
    </>
  );
};

export default RoutesENT;
