import { t } from "i18next";
import styles from "../../Form1.module.scss";

export const InfoBulle = () => (
  <div className={styles.info_label}>
    <span>{t("app.suivezVosContratsPartner.OrganismePrevoyance")}</span>
    <div className={styles.infoBulle}>
      <svg
        className="slds-button__icon"
        focusable="false"
        data-key="info"
        aria-hidden="true"
        viewBox="0 0 52 52"
        part="icon"
      >
        <g>
          <path d="M26 2C12.7 2 2 12.7 2 26s10.7 24 24 24 24-10.7 24-24S39.3 2 26 2zm0 12.1c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm5 21c0 .5-.4.9-1 .9h-8c-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1.5 0 1-.3 1-.9v-4c0-.5-.4-1.1-1-1.1-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1h6c.5 0 1 .5 1 1.1v8c0 .5.4.9 1 .9.5 0 1 .5 1 1.1v2z"></path>
        </g>
      </svg>
      <div className={styles.infoBulleInfo}>{t("app.suivezVosContratsPartner.OrganismePrevoyanceHelpText")}</div>
    </div>
  </div>
);
