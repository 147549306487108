import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";
import DatePicker, { getDefaultLocale } from "react-datepicker";
import styles from "./FormDateField.module.scss";

export const FormDateField = ({
  id,
  label,
  errors,
  values,
  touched,
  onChange,
  additionalClassNames,
  children,
  dateFormat = "dd/MM/yyyy",
  disabled = false,
}) => (
  <div
    className={`${styles.formGroup} input-like-rw ${additionalClassNames}`}
  >
    <label htmlFor={id}>{label}</label>
    <DatePicker
      className={errors && touched && styles.errorInput}
      disabled={disabled}
      id={id}
      dataKey="key"
      touched={touched}
      error={errors}
      onChange={onChange}
      dateFormat={dateFormat}
      selected={values}
      locale={getDefaultLocale()}
    />
    {children}
    <InputErrorMessage message={errors} touched={touched} />
  </div>
);
