import { t } from "i18next";

import axios from "axios";

import { useEffect, useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import {
  patchStepThunk,
  handlePreviousStep,
  getDeclareARecruitmentPreContractualizationInformations,
  getDeclareARecruitmentPending,
} from "app/slices/declareARecruitment/declareARecruitment.slice";
import { postFileThunk, getUploadFilePending, getUploadFIleErrorMessage } from "app/slices/uploadFile/uploadFile.slice";
import { getCitiesThunk, getCitiesData } from "app/slices/getCities/getCities.slice";

import { createObjAucun, createObjOui, createObjNon, createBooleanReferential } from "utils/localReferentials";
import { changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";
import { formatDate } from "utils/formatDate";

import { DropdownList } from "react-widgets";

import SubmitContractBtn from "pages/ETU/DeclareARecruitment/components/SubmitContractBtn/SubmitContractBtn";
import PreviousFormBtn from "components/PreviousFormBtn/PreviousFormBtn";
import LoadingModal from "pages/ETU/DeclareARecruitment/components/LoadingModal/LoadingModal";
import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";

import DatePickerCustom from "components/DatePickerCustom/DatePickerCustom";

import { toastSuccess, toastError } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import UploadOrganism from "../../../components/UploadOrganism/UploadOrganism";

import styles from "../Step5.module.scss";

const MainForm = ({ axiosCancelToken }) => {
  const [file, setFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [spanErrorMessage, setSpanErrorMessage] = useState(false);
  const [citiesAxiosCancelToken] = useState(axios.CancelToken.source());
  const objAucun = createObjAucun();
  const objOui = createObjOui();
  const objNon = createObjNon();
  const booleanReferential = createBooleanReferential();

  const dispatch = useDispatch();

  const preContractualizationInformations = useSelector(getDeclareARecruitmentPreContractualizationInformations);
  const pending = useSelector(getDeclareARecruitmentPending);

  const [isChecked, setIsChecked] = useState(preContractualizationInformations?.student?.person?.birthplace?.abroad);
  const filePending = useSelector(getUploadFilePending);
  const fileErrorMessage = useSelector(getUploadFIleErrorMessage);
  const citiesData = useSelector(getCitiesData);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setFieldValue("birthPostcode", "");
    setFieldValue("placeOfBirth", { nom: null, code: null });
    setFieldValue("placeOfBirthEtranger", "");
  };

  // useEffect onChange for UploadOrganism
  useEffect(() => {
    if (file) {
      (async () => {
        try {
          await dispatch(
            postFileThunk({
              file,
              endpoint: `/v1/companies/recruitments/apprenticeships/pre-contractualizations/${preContractualizationInformations.id}/supporting-documents/EMPLOYMENT_CENTER_REGISTRATION`,
              axiosCancelToken,
            }),
          ).unwrap();
          setFileUploaded((value) => value + 1);
          setSpanErrorMessage(false);
          toastSuccess(
            <ToastMessageStructure firstMessage={t("app.toastMessages.genericSuccess")} secondMessage={file.name} />,
          );
          console.log("upload success TestUploadFile !");
        } catch (error) {
          console.log("upload fail TestUploadFile !", error.message);
          toastError(
            <ToastMessageStructure firstMessage={t("app.toastMessages.genericError")} secondMessage={file.name} />,
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  // useEffect to trigger cities api call if birthplace.zipCode is set and setDisabled
  useEffect(() => {
    if (preContractualizationInformations.student?.birthplace?.zipCode) {
      handleCitiesCalls(preContractualizationInformations.student?.birthplace?.zipCode);
    }

    if (preContractualizationInformations.student?.employmentCenterRegistration?.jobSeeker) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This useEffect is only for cleanup function !!!
  useEffect(
    () => () => {
      citiesAxiosCancelToken.cancel();
      console.log("Step6 unmount");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Function to handle getCitiesThunk call
  const handleCitiesCalls = (postcode) => {
    console.log("handeCitiesCalls");
    if (postcode.length === 5) {
      console.log("handeCitiesCalls length 5");
      (async () => {
        try {
          await dispatch(
            getCitiesThunk({
              postalCode: postcode,
              axiosCancelToken: citiesAxiosCancelToken,
            }),
          ).unwrap();
          console.log("get success Cities !");
        } catch (error) {
          console.log("get fail Cities !", error.message);
        }
      })();
    }
  };

  // Yup validation schema
  const mainFormSchema = Yup.object().shape(
    {
      areYouRegistredEmployment: Yup.object().test(
        "if-is-aucun",
        t("app.errorMessages.RemplirChamp"),
        (value) => value.key !== "-Aucun-",
      ),
      socialNumber: Yup.string().when("socialNumber", {
        is: (exists) => !!exists,
        then: Yup.string()
          .matches(/^[12][0-9]{5}([0-9]|[A-Za-z])[0-9]{8}$/, `${t("app.errorMessages.startsWithOneOrTwo")}`)
          .test("nir-control-test", t("app.errorMessages.invalidNIR"), (value) => {
            if (value?.length < 15) return false;
            if (value?.length === 15) {
              const temp = value?.split("");
              const key = parseInt(temp[13] + temp[14], 10);
              if ((temp[6] === "B" || temp[6] === "b") && temp[5] === "2") {
                temp[6] = "8";
                temp[5] = "1";
                const slicedArray = parseInt(temp.slice(0, 13).join(""), 10);
                if (key === 97 - (slicedArray % 97)) return true;
                return false;
              }
              if ((temp[6] === "A" || temp[6] === "a") && temp[5] === "2") {
                temp[6] = "9";
                temp[5] = "1";
                const slicedArray = parseInt(temp.slice(0, 13).join(""), 10);
                if (key === 97 - (slicedArray % 97)) return true;
                return false;
              }
              const slicedArray = parseInt(temp.slice(0, 13).join(""), 10);
              if (key === 97 - (slicedArray % 97)) return true;
              return false;
            }
            return false;
          })
          .typeError(t("app.errorMessages.RemplirChamp"))
          .min(15, t("app.errorMessages.ChiffresRequis15"))
          .max(15, t("app.errorMessages.ChiffresRequis15"))
          .required(t("app.errorMessages.RemplirChamp")),
      }),
      birthPostcode: Yup.string().when([], {
        is: () => !isChecked,
        then: (schema) =>
          schema
            .typeError(t("app.errorMessages.RemplirChamp"))
            .min(5, t("app.errorMessages.ChiffresRequis5"))
            .max(5, t("app.errorMessages.ChiffresRequis5"))
            .required(t("app.errorMessages.RemplirChamp")),
      }),
      placeOfBirth: Yup.object().when([], {
        is: () => values.birthPostcode && values.birthPostcode.length === 5 && !isChecked,
        then: (schema) =>
          schema.test("if-is-placeOfBirth", t("app.errorMessages.RemplirChamp"), (value) => value.nom !== null),
      }),
      placeOfBirthEtranger: Yup.string().when([], {
        is: () => isChecked,
        then: (schema) => schema.required(t("app.errorMessages.RemplirChamp")),
      }),
      registrationNumber: Yup.string()
        .typeError(t("app.errorMessages.RemplirChamp"))
        .when(["areYouRegistredEmployment"], {
          is: (areYouRegistredEmployment) => areYouRegistredEmployment.key === "Oui",
          then: (schema) =>
            schema
              .min(1, t("app.errorMessages.ChiffresRequis12"))
              .max(12, t("app.errorMessages.ChiffresRequis12"))
              .required(t("app.errorMessages.RemplirChamp")),
        }),
      dateOfRegistration: Yup.date()
        .typeError(t("app.errorMessages.FormatDateInvalide"))
        .when(["areYouRegistredEmployment"], {
          is: (areYouRegistredEmployment) => areYouRegistredEmployment.key === "Oui",
          then: (schema) => schema.required(),
        }),
      businessCreationProject: Yup.object().test(
        "if-is-aucun",
        t("app.errorMessages.RemplirChamp"),
        (value) => value.key !== "-Aucun-",
      ),
    },
    [["socialNumber", "socialNumber"]],
  );

  // onSubmit form function
  const onSubmit = (values) => {
    if (!disabled && fileUploaded === 0) {
      setSpanErrorMessage(true);
      return;
    }
    setSpanErrorMessage(false);

    // These functions change the value of certain keys depending on the swagger, before dataToSend initialization
    const valuesCopy = structuredClone(values);

    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);

    // Data to send object initialization
    const dataToSend = {
      stage: "Etape6",
      contractType: preContractualizationInformations?.contractType?.key,
      student: {
        id: preContractualizationInformations?.student?.id,
        employmentCenterRegistration: {
          jobSeeker: valuesCopy?.areYouRegistredEmployment.key,
          number: valuesCopy?.registrationNumber || null,
          registrationDate:
            valuesCopy?.areYouRegistredEmployment?.key === true ? formatDate(valuesCopy?.dateOfRegistration) : null,
        },
        person: {
          socialSecurityNumber: valuesCopy?.socialNumber,
          birthplace: isChecked
            ? {
                city: valuesCopy?.placeOfBirthEtranger,
                abroad: true,
              }
            : {
                zipCode: valuesCopy.birthPostcode,
                city: valuesCopy?.placeOfBirth?.nom,
                abroad: false,
              },
          companyProject: valuesCopy?.businessCreationProject?.key,
        },
      },
    };
    console.log("dataToSend : ", dataToSend);

    (async () => {
      if (!pending) {
        (async () => {
          try {
            await dispatch(
              patchStepThunk({
                dataToSend,
                id: preContractualizationInformations.id,
                axiosCancelToken,
              }),
            ).unwrap();
            console.log("patch success !");
          } catch (error) {
            console.log("patch fail !", error.message);
          }
        })();
      }
    })();
  };

  // useFormik from Formik
  const { values, errors, touched, setFieldValue, handleSubmit, validateForm } = useFormik({
    initialValues: {
      areYouRegistredEmployment:
        preContractualizationInformations.student?.employmentCenterRegistration?.jobSeeker === true
          ? { ...objOui }
          : preContractualizationInformations.student?.employmentCenterRegistration?.jobSeeker === false
            ? { ...objNon }
            : { ...objAucun },
      socialNumber: preContractualizationInformations.student?.person?.socialSecurityNumber || "",
      birthPostcode: preContractualizationInformations.student?.person?.birthplace?.zipCode || "",
      placeOfBirth: preContractualizationInformations?.student?.person?.birthplace?.abroad
        ? { nom: null, code: null }
        : {
            nom: preContractualizationInformations?.student?.person?.birthplace?.city,
            code: preContractualizationInformations?.student?.person?.birthplace?.zipCode,
          },
      placeOfBirthEtranger: preContractualizationInformations?.student?.person?.birthplace?.abroad
        ? preContractualizationInformations?.student?.person?.birthplace?.city
        : "",
      registrationNumber: preContractualizationInformations.student?.employmentCenterRegistration?.number || "",
      dateOfRegistration:
        preContractualizationInformations.student?.employmentCenterRegistration?.jobSeeker === true &&
        preContractualizationInformations.student?.employmentCenterRegistration?.registrationDate
          ? new Date(preContractualizationInformations.student?.employmentCenterRegistration?.registrationDate)
          : preContractualizationInformations.student?.employmentCenterRegistration?.jobSeeker === true &&
              !preContractualizationInformations.student?.employmentCenterRegistration?.registrationDate
            ? null
            : new Date(),
      businessCreationProject:
        preContractualizationInformations.student?.person?.companyProject === true
          ? { ...objOui }
          : preContractualizationInformations.student?.person?.companyProject === false
            ? { ...objNon }
            : { ...objAucun },
    },
    validationSchema: mainFormSchema,
    onSubmit,
  });

  useEffect(() => {
    validateForm();
  }, [isChecked]);

  return (
    <>
      <LoadingModal
        setShow={setDisplayModal}
        show={displayModal}
        file={{
          name: file?.name,
          type: file?.type,
          size: file?.size,
        }}
        pending={filePending}
        error={fileErrorMessage}
        handleClose={() => setDisplayModal(false)}
      />
      <form onSubmit={handleSubmit}>
        <div className={styles.inputs_container}>
          <div className={`${styles.label_plus_input_container} input-like-rw`}>
            <label htmlFor="socialNumber">{t("app.declarezUnRecrutement.NumSecuriteSociale")}</label>
            <input
              disabled={preContractualizationInformations.student?.person?.socialSecurityNumber ? true : false}
              style={
                errors.socialNumber &&
                touched.socialNumber && {
                  border: "2px solid #EA001E",
                }
              }
              id="socialNumber"
              type="text"
              min="0"
              value={values.socialNumber}
              onChange={(e) => setFieldValue("socialNumber", e.target.value)}
            />
            <InputErrorMessage message={errors.socialNumber} touched={touched.socialNumber} />
          </div>
          <div className={`${styles.label_plus_input_container} input-like-rw`}>
            <label htmlFor="birthPostcode">{t("app.declarezUnRecrutement.CodePostal")}</label>
            <input
              style={
                errors.birthPostcode &&
                touched.birthPostcode && {
                  border: "2px solid #EA001E",
                }
              }
              id="birthPostcode"
              type="number"
              min="0"
              value={values.birthPostcode}
              onChange={(e) => {
                handleCitiesCalls(e.target.value);
                setFieldValue("placeOfBirth", { nom: null, code: null });
                setFieldValue("birthPostcode", e.target.value);
              }}
              disabled={isChecked}
            />
            <InputErrorMessage message={errors.birthPostcode} touched={touched.birthPostcode} />
          </div>
          <div className={styles.checkbox_container}>
            <input id="etrange" type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
            <label htmlFor="etrange">{t("app.declarezUnRecrutement.bornAbroad")}</label>
          </div>
          {isChecked && (
            <div className={`${styles.label_plus_input_container} input-like-rw`}>
              <label htmlFor="placeOfBirthEtranger">{t("app.declarezUnRecrutement.CommuneNaissance")}</label>
              <input
                style={
                  errors.placeOfBirthEtranger &&
                  touched.placeOfBirthEtranger && {
                    border: "2px solid #EA001E",
                  }
                }
                id="placeOfBirthEtranger"
                type="text"
                value={values.placeOfBirthEtranger}
                onChange={(e) => {
                  setFieldValue("placeOfBirthEtranger", e.target.value);
                }}
              />
              <InputErrorMessage message={errors.placeOfBirthEtranger} touched={touched.placeOfBirthEtranger} />
            </div>
          )}
          {values.birthPostcode.length === 5 && !isChecked && (
            <div className={`${styles.label_plus_input_container} input-like-rw`}>
              <label htmlFor="placeOfBirth">{t("app.declarezUnRecrutement.CommuneNaissance")}</label>
              <DropdownList
                style={
                  errors.placeOfBirth &&
                  touched.placeOfBirth && {
                    border: "2px solid #EA001E",
                    borderRadius: "5px",
                  }
                }
                id="placeOfBirth"
                placeholder="Sélectionnez votre ville de naissance"
                dataKey="nom"
                textField={(item) => (item.nom ? `${item.nom} - ${values.birthPostcode}` : "")}
                data={citiesData}
                value={values.placeOfBirth}
                onChange={(newValue) => {
                  setFieldValue("placeOfBirth", newValue);
                }}
              />
              <InputErrorMessage message={errors.placeOfBirth} touched={touched.placeOfBirth} />
            </div>
          )}
          <div className={styles.label_plus_input_container}>
            <label htmlFor="areYouRegistredEmployment">
              {t("app.declarezUnRecrutement.EtesVousInscritAPoleEmploi")}
            </label>
            <DropdownList
              style={
                errors.areYouRegistredEmployment &&
                touched.areYouRegistredEmployment && {
                  border: "2px solid #EA001E",
                  borderRadius: "5px",
                }
              }
              id="areYouRegistredEmployment"
              dataKey="key"
              textField="label"
              data={[...booleanReferential]}
              value={values.areYouRegistredEmployment}
              onChange={(newValue) => {
                if (newValue.key === "Oui") {
                  if (preContractualizationInformations.student?.employmentCenterRegistration?.registrationDate) {
                    setFieldValue(
                      "dateOfRegistration",
                      new Date(preContractualizationInformations.student.employmentCenterRegistration.registrationDate),
                    );
                  } else {
                    setFieldValue("dateOfRegistration", null);
                  }
                  setDisabled(false);
                } else {
                  setFieldValue("dateOfRegistration", new Date());
                  setDisabled(true);
                }
                setFieldValue("areYouRegistredEmployment", newValue);
              }}
            />
            <InputErrorMessage message={errors.areYouRegistredEmployment} touched={touched.areYouRegistredEmployment} />
          </div>
          {values.areYouRegistredEmployment.key === "Oui" && (
            <div className={`${styles.label_plus_input_container} input-like-rw`}>
              <label htmlFor="registrationNumber">{t("app.declarezUnRecrutement.NumInscriptionPoleEmploi")}</label>
              <input
                style={
                  errors.registrationNumber &&
                  touched.registrationNumber && {
                    border: "2px solid #EA001E",
                  }
                }
                id="registrationNumber"
                type="text"
                min="0"
                value={values.registrationNumber}
                onChange={(e) => {
                  setFieldValue("registrationNumber", e.target.value);
                }}
              />
              <InputErrorMessage message={errors.registrationNumber} touched={touched.registrationNumber} />
            </div>
          )}
          {values.areYouRegistredEmployment.key === "Oui" && (
            <div className={styles.label_plus_input_container}>
              <label htmlFor="dateOfRegistration">{t("app.declarezUnRecrutement.DateInscriptionPoleEmploi")}</label>
              <DatePickerCustom
                error={errors.dateOfRegistration}
                touched={touched.dateOfRegistration}
                value={values.dateOfRegistration}
                onChange={(value) => setFieldValue("dateOfRegistration", value)}
              />
              <InputErrorMessage message={errors.dateOfRegistration} touched={touched.dateOfRegistration} />
            </div>
          )}
          {values.areYouRegistredEmployment.key === "Oui" && (
            <UploadOrganism
              labelText={t("app.declarezUnRecrutement.TelechargezUneAttestationDeDroits")}
              disabled={disabled}
              setFile={setFile}
              setDisplayModal={setDisplayModal}
              spanErrorMessage={spanErrorMessage}
            />
          )}
          <div className={styles.label_plus_input_container}>
            <label htmlFor="businessCreationProject">{t("app.declarezUnRecrutement.BusinessCreationProject")}</label>
            <DropdownList
              style={
                errors.businessCreationProject &&
                touched.businessCreationProject && {
                  border: "2px solid #EA001E",
                  borderRadius: "5px",
                }
              }
              id="businessCreationProject"
              dataKey="key"
              textField="label"
              data={[...booleanReferential]}
              value={values.businessCreationProject}
              onChange={(newValue) => {
                setFieldValue("businessCreationProject", newValue);
              }}
            />
            <InputErrorMessage message={errors.businessCreationProject} touched={touched.businessCreationProject} />
          </div>
        </div>
      </form>
      <div className={styles.btns_container}>
        <SubmitContractBtn text={t("app.declarezUnRecrutement.ValidezEtapeSuivante")} handleNextStep={handleSubmit} />
        <PreviousFormBtn
          text={t("app.declarezUnRecrutement.EtapePrecedente")}
          handlePreviousStep={() => dispatch(handlePreviousStep("Etape4"))}
        />
      </div>
    </>
  );
};

export default MainForm;
