import { useState, cloneElement } from "react";

import { useSelector } from "react-redux";
import { selectPendingContractDataFYC } from "app/slices/followYourContracts/followYourContracts.slice";

import { TitleInForm } from "components/typography/TitleInForm/TitleInForm";
import InformationTextInForm from "components/typography/InformationTextInForm/InformationTextInForm";
import upArrow from "../../assets/icon-arrowDropDownUp.svg";

import styles from "./DropdownSection.module.scss";

const DropdownSection = ({ title, formIsValid, isSubTitle, subTitle, Form }) => {
  const pending = useSelector(selectPendingContractDataFYC);

  const [formOpen, setFormOpen] = useState(false);

  return (
    <div className={styles.main_container}>
      <div className={styles.top_section}>
        <div
          className={styles.section_title}
          onClick={() => setFormOpen(!formOpen)}
          onKeyDown={(e) => e.key === "Enter" && setFormOpen(!formOpen)}
          role="button"
          tabIndex={0}
        >
          <TitleInForm>{title}</TitleInForm>
          <div>
            {pending && <div className={styles.pin_status_pending} />}
            {formIsValid && !pending && <div className={styles.pin_status_success} />}
            {!formIsValid && !pending && <div className={styles.pin_status_error} />}
          </div>
          <div className={styles.dropdown_icon}>
            {!formOpen && <img src={upArrow} alt="flèche" />}
            {formOpen && <img src={upArrow} alt="flèche" className={styles.arrowUp} />}
          </div>
        </div>

        {isSubTitle === true && (
          <InformationTextInForm additionalClassNames={styles.optional}>{subTitle}</InformationTextInForm>
        )}
      </div>
      <div style={{ display: `${formOpen ? "block" : "none"}` }}>{cloneElement(Form, { isOpen: formOpen })}</div>
    </div>
  );
};

export default DropdownSection;
