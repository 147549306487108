import DatePicker, { getDefaultLocale } from "react-datepicker";

const DatePickerCustom = ({
  id,
  error,
  touched,
  disabled,
  dateFormat = "dd/MM/yyyy",
  value,
  onChange,
  //   clicked,
}) => (
  <div
    style={
      error && touched
        ? {
            border: "2px solid #EA001E",
            borderRadius: "5px",
          }
        : null
    }
  >
    <DatePicker
      id={id}
      disabled={disabled}
      dateFormat={dateFormat}
      selected={value}
      locale={getDefaultLocale()}
      onChange={onChange}
    />
  </div>
);

export default DatePickerCustom;
