import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";
import styles from "./FormTextAreaField.module.scss";

export const FormTextAreaField = ({ id, label, errors, values, touched, onChange, disabled = false, className }) => (
  <div className={`${styles.formGroup} input-like-rw ${className}`}>
    <label htmlFor={id}>{label}</label>
    <textarea
      className={errors && touched && styles.error_input}
      id={id}
      value={values}
      onChange={onChange}
      disabled={disabled}
    />
    <InputErrorMessage message={errors} touched={touched} />
  </div>
);
