import InformationTextInForm from "components/typography/InformationTextInForm/InformationTextInForm";
import { TitleInForm } from "components/typography/TitleInForm/TitleInForm";
import { t } from "i18next";
import upArrow from "../../assets/icon-arrowDropDownUp.svg";

import styles from "./DropdownMainSection.module.scss";

const DropdownMainSection = ({
  title,
  optional,
  isSubTitle,
  subTitle,
  MainSection,
  sectionOpen,
  sectionOpenHandler,
  isCerfaReturned,
}) => (
  <div className={styles.main_container}>
    <div className={styles.top_section}>
      <div
        className={styles.section_title}
        onClick={() => sectionOpenHandler()}
        onKeyDown={(e) => e.key === "Enter" && sectionOpenHandler()}
        role="button"
        tabIndex={0}
      >
        <div className={styles.title_and_optional_container}>
          <TitleInForm>{title}</TitleInForm>
          <InformationTextInForm>{optional}</InformationTextInForm>
          {isCerfaReturned && (
            <>
              <div className={styles.info_bulle}>
                <svg
                  className="slds-button__icon"
                  focusable="false"
                  data-key="info"
                  aria-hidden="true"
                  viewBox="0 0 52 52"
                  part="icon"
                >
                  <g>
                    <path d="M26 2C12.7 2 2 12.7 2 26s10.7 24 24 24 24-10.7 24-24S39.3 2 26 2zm0 12.1c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm5 21c0 .5-.4.9-1 .9h-8c-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1.5 0 1-.3 1-.9v-4c0-.5-.4-1.1-1-1.1-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1h6c.5 0 1 .5 1 1.1v8c0 .5.4.9 1 .9.5 0 1 .5 1 1.1v2z"></path>
                  </g>
                </svg>
                <span className={styles.info_Text}>{t("app.PDFSection.ModifsAttendues")}</span>
              </div>
            </>
          )}
        </div>
        <div className={styles.dropdown_icon}>
          {!sectionOpen && <img src={upArrow} alt="flèche" />}
          {sectionOpen && <img src={upArrow} alt="flèche" className={styles.arrowUp} />}
        </div>
      </div>

      {isSubTitle === true && <span className={styles.subTitle}>{subTitle}</span>}
    </div>
    <div>{sectionOpen && MainSection}</div>
  </div>
);

export default DropdownMainSection;
