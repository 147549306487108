import styles from "../Step2.module.scss";

/* eslint-disable */
const CheckboxOrganism = ({ disabled, checkboxId, label, text, infoBubbleContent, values, setFieldValue }) => (
  <div className={styles.label_plus_input_container}>
    <label>{label}</label>
    <div className={styles.checkbox_svg_text_container}>
      <div className={styles.checkbox_and_svg_container}>
        <div className={styles.checkbox_container}>
          <input
            disabled={disabled}
            id={checkboxId}
            type="checkbox"
            checked={values[checkboxId]}
            onChange={(e) => setFieldValue(checkboxId, e.target.checked)}
          />
        </div>
      </div>
      <div className={styles.text}>{text}</div>
    </div>
  </div>
);

export default CheckboxOrganism;
