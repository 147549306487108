export const referentialsParamsETU = [
  "LANGUAGE",
  "LANGUAGE_LEVEL",
  "STUDENT_RESUME_STATUS",
  "STUDENT_EDUCATION_LEVEL",
  "CONTRACT_TYPE",
  "COMPANY_JOB_OFFER_SUBSECTOR",
  "CONTRACT_DURATION",
  "COMPANY_JOB_OFFER_SECTOR",
  "STUDENT_RESUME_COMPUTER_SKILL",
  "REGION_CH",
  "REGION_FR",
  "COUNTRY",
  "STUDENT_RESUME_LANG",
  "STUDENT_LANG_LEVEL",
  "STUDENT_RESUME_SOFT_SKILL",
  "CURRENCY",
  "COMPANY_JOB_OFFER_TRAINING_SCHOOL",
  "REMOTE_WORK_FREQUENCY",
  "MOBILITY_LEVEL",
  "INTERNSHIP_DURATION",
  "COMPANY_JOB_CONTRACT_DURATION",
  "COMPANY_JOB_OFFER_APPLICATION_CHANNEL",
  "STUDENT_RESUME_SPECIALITY",
  "STUDENT_RESUME_SUBSPECIALITY_Ingéniérie",
  "STUDENT_RESUME_SUBSPECIALITY_Sciences_Politiques_et_Relations_Internationales",
  "STUDENT_RESUME_SUBSPECIALITY_Finance",
  "STUDENT_RESUME_SUBSPECIALITY_Management",
  "COMPANY_JOB_OFFER_STATUS",
  "REGION_FR",
  "REGION_CH",
  "APPRENTICESHIP_CONTRACT_TYPE",
  "STUDENT_RESUME_LANGUAGE",
  "STUDENT_GRADUATION_STATUS",
  "STUDENT_RESUME_EXP_PRO_CONTRACT_TYPE",
  "APPRENTICESHIP_TRAINING_PATH",
  "APPRENTICESHIP_STUDENT_SITUATION",
  "APPRENTICESHIP_STUDENT_DIPLOMA",
  "APPRENTICESHIP_COURSE",
  "APPRENTICESHIP_PARTICULAR_SITUATION",
  "COMPANY_TYPE",
  "COMPANY_SALARY_TYPE",
  "APPRENTICESHIP_RECRUITMENT_ORIGIN_CONTRACT",
  "COMPANY_CONTACT_TYPE_TO_COPY_FOR_TAXES_CONTACT",
  "COMPANY_CONTACT_TYPE_TO_COPY_FOR_ADMIN_CONTACT",
  "COMPANY_CONTACT_TYPE_TO_COPY_FOR_CONTRACT_CONTACT",
  "COMPANY_CONTACT_TYPE_TO_COPY_FOR_TUTOR_CONTACT",
  "COMPANY_CONTACT_TYPE_TO_COPY_FOR_BILLING_CONTACT",
  "CIVILITY",
  "APPRENTICESHIP_TYPE",
  "APPRENTICESHIP_CONTRACT_NATURE",
  "APPRENTICESHIP_SKILLS_EVALUATION",
  "APPRENTICESHIP_EVALUATION",
  "APPRENTICESHIP_STUDENT_LAST_YEAR_SITUATION",
];
