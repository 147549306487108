import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-phone-number-input/style.css";

import { useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";
import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";
import { nanoid } from "@reduxjs/toolkit";
import { toastError, toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import { axiosCompany } from "app/axiosInstances/axiosInstances";
import {
  patchContractDataThunk,
  selectContractDataFYC,
} from "app/slices/followYourContracts/followYourContracts.slice";
import FormCustomCheckBoxField from "pages/ENT/FollowYourContracts/components/formFields/FormCustomCheckBoxField/FormCustomCheckBoxField";
import { FormInputField } from "pages/ENT/FollowYourContracts/components/formFields/FormInputField/FormInputField";
import FormInformationField from "pages/ENT/FollowYourContracts/components/formFields/FormInformationField/FormInformationField";
import { FormInputFieldWithIcon } from "pages/ENT/FollowYourContracts/components/formFields/FormInputFieldWithIcon/FormInputFieldWithIcon";
import { ReactComponent as Pencil } from "./assets/search.svg";
import codeNafSvg from "./assets/codeNaf.svg";

import styles from "./Form10.module.scss";

const Form10 = ({
  axiosCancelToken,
  submittingAllSections,
  submitNextSectionIfSubmitAllTrue,
  cancelSubmitAllSections,
  currentSectionBeingSubmitted,
  setFormIsValid,
}) => {
  const dispatch = useDispatch();

  const [apiResponse, setApiResponse] = useState([]);
  const [opcoApiCallLoading, setOpcoApiCallLoading] = useState(false);
  const [typeOfContract, setTypeOfcontract] = useState("");
  const contractData = useSelector(selectContractDataFYC);

  // Yup validation schema
  const Form1OSchema = Yup.object().shape({
    membershipNumber: Yup.string().max(255, t("app.errorMessages.Max255")),
    coveredAmount: Yup.string().required(t("app.errorMessages.RemplirChamp")).max(255, t("app.errorMessages.Max255")),
    teletransmission: Yup.bool().oneOf([true], t("app.errorMessages.RemplirChamp")),
    agreementToPayRemainingCost: Yup.bool().oneOf([true], t("app.errorMessages.RemplirChamp")),
    IdOpco: Yup.string().required(t("app.errorMessages.RemplirChamp")).min(1, t("app.errorMessages.RemplirChamp")),
    teletransmissionCheckbox: Yup.boolean(),
  });
  const defineApprenticeshipTypeSkillOp = () => {
    if (contractData?.contract?.apprenticeshipType) {
      if (contractData?.contract?.apprenticeshipType?.key === "Contrat_dapprentissage") {
        setTypeOfcontract("APPRENTICESHIP_CONTRACT");
      } else {
        setTypeOfcontract("PROFESSIONALIZATION_CONTRACT");
      }
    }
  };

  const handleInputChange = async (event) => {
    const { value } = event.target;
    setFieldValue("NameOpco", value);
    setFieldValue("IdOpco", "");
    if (value.length >= 3) {
      setOpcoApiCallLoading(true);
      try {
        const response = await axiosCompany().get(
          `/v2/companies/skill-operators?name=*${value.toUpperCase()}*&compatibleApprenticeshipType[]=${typeOfContract}`,
        );
        setApiResponse(response.data);
        setOpcoApiCallLoading(false);
      } catch (err) {
        toastError(
          <ToastMessageStructure firstMessage={t("app.toastMessages.PreContraEntGetEmployeeError")} secondMessage={err.message} />,
        );
      }
    } else {
      setApiResponse([]);
    }
  };

  const handleOptionSelect = (option) => {
    setFieldValue("IdOpco", option.id);
    setFieldValue("NameOpco", option.name);
    setApiResponse([]);
  };

  const onSubmit = async (values) => {
    const valuesCopy = structuredClone(values);

    // These functions change the value of certain keys depending on the swagger, before dataToSend initialization
    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);
    const dataToSend = {
      status: "En cours",

      contract: {
        apprenticeshipType: contractData?.contract?.apprenticeshipType?.key,
        financing: {
          trainingAmount: valuesCopy.trainingAmount,
          skillsOperator: {
            id: parseInt(valuesCopy.IdOpco, 10),
            membershipNumber: valuesCopy.membershipNumber,
            coveredAmount: parseFloat(valuesCopy.coveredAmount),
            teletransmission: valuesCopy.teletransmissionCheckbox,
          },
          agreementToPayRemainingCost: valuesCopy.agreementToPayRemainingCost,
        },
      },
    };
    try {
      await dispatch(
        patchContractDataThunk({
          dataToSend,
          id: contractData.id,
          axiosCancelToken,
        }),
      ).unwrap();
      submitNextSectionIfSubmitAllTrue(11);

      if (!submittingAllSections) {
        toastSuccess(<ToastMessageStructure secondMessage={t("app.toastMessages.ModifFinancementContratOPCO")} />);
      }
    } catch (error) {
      cancelSubmitAllSections();
      toastError(
        <ToastMessageStructure firstMessage={t("app.toastMessages.PreContraEntSectionOPCOSubmitError")} secondMessage={error.message} />,
      );
    }
  };

  const { values, errors, touched, setFieldValue, handleSubmit, validateForm, isValid } = useFormik({
    initialValues: {
      membershipNumber: contractData?.contract?.financing?.skillsOperator?.membershipNumber || "",
      trainingAmount: contractData?.contract?.financing?.trainingAmount || "",
      coveredAmount: contractData?.contract?.financing?.skillsOperator?.coveredAmount || "",
      agreementToPayRemainingCost: contractData?.contract?.financing?.agreementToPayRemainingCost || false,
      IdOpco: contractData?.contract?.financing?.skillsOperator?.id || "",
      NameOpco: contractData?.contract?.financing?.skillsOperator?.name || "",
      teletransmissionCheckbox: contractData?.contract?.financing?.skillsOperator?.teletransmission || false,
    },
    validationSchema: Form1OSchema,
    onSubmit,
  });

  // useLayoutEffect to force Formik's isValid check, the first time the component is mounted
  useLayoutEffect(() => {
    validateForm();
  }, []);

  // useLayoutEffect to control the color of the DropdownSection circle.
  useLayoutEffect(() => {
    if (isValid) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [isValid]);

  // useLayoutEffect to manage the trigger at the right time for sending this form, during global submission.
  useLayoutEffect(() => {
    const thisFormNumber = 10;

    if (isValid && submittingAllSections && currentSectionBeingSubmitted === thisFormNumber) {
      handleSubmit();
    } else if (!isValid && submittingAllSections && currentSectionBeingSubmitted === thisFormNumber) {
      handleSubmit();
      cancelSubmitAllSections();
      toastError(<ToastMessageStructure firstMessage={t("app.toastMessages.PreContraEntSectionOPCONonRemplieError")} />);
    }
  }, [isValid, submittingAllSections, currentSectionBeingSubmitted]);

  useLayoutEffect(() => {
    defineApprenticeshipTypeSkillOp();
  }, [contractData]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={`${styles.wrapper} input-like-rw`}>
          <FormInformationField
            label={t("app.Step10ContractEnt.FromatAmount")}
            information={`${values.trainingAmount} €`}
          />
          <FormInputField
            label={t("app.Step10ContractEnt.NumAdh")}
            values={values.membershipNumber}
            errors={errors.membershipNumber}
            touched={touched.membershipNumber}
            additionalClassNames={styles.additionalClassNames}
            onChange={(e) => setFieldValue("membershipNumber", e.target.value.trim())}
          />
          <div className={styles.opco_group}>
            <FormInputFieldWithIcon
              id="NameOpco"
              label={t("app.Step10ContractEnt.NomOpco")}
              values={values.NameOpco}
              errors={errors.IdOpco}
              touched={touched.IdOpco}
              icon={<Pencil className={styles.search_icon} />}
              onChange={handleInputChange}
              additionalClassNames={styles.input_margin}
              loading={opcoApiCallLoading}
            />
            <div className={apiResponse.length > 0 ? styles.output_container : undefined}>
              <ul>
                {apiResponse.map((result) => (
                  <div key={nanoid()}>
                    <div
                      className={styles.output_plus_image_Container}
                      onKeyDown={(e) => e.key === "Enter" && handleOptionSelect(result)}
                      onClick={() => handleOptionSelect(result)}
                      role="button"
                      tabIndex={0}
                    >
                      <img src={codeNafSvg} style={{ width: "1.8rem" }} alt="test"></img>
                      <div className={styles.outputKeyLabel}>
                        <li key={result?.key}> {result?.number}</li>
                        <li key={result?.key}>{result?.name}</li>
                      </div>
                    </div>
                  </div>
                ))}
              </ul>
            </div>
          </div>
          <FormInputField
            label={t("app.Step10ContractEnt.lvlCharge")}
            values={values.coveredAmount}
            errors={errors.coveredAmount}
            touched={touched.coveredAmount}
            type="number"
            onChange={(e) => setFieldValue("coveredAmount", e.target.value.trim())}
          />
        </div>
        <div>
          <FormCustomCheckBoxField
            id="agreementToPayRemainingCost"
            text={t("app.suivezVosContratsPartner.checkbox1Opco")}
            error={errors.agreementToPayRemainingCost}
            touched={touched.agreementToPayRemainingCost}
            checkboxValue={values.agreementToPayRemainingCost}
            handleChange={(e) => {
              setFieldValue("agreementToPayRemainingCost", e);
            }}
          />
          <FormCustomCheckBoxField
            error={errors.teletransmissionCheckbox}
            touched={touched.teletransmissionCheckbox}
            checkboxValue={values.teletransmissionCheckbox}
            infoBullText={t("app.suivezVosContratsPartner.BulleInfo")}
            text={t("app.suivezVosContratsPartner.Teletransmission")}
            id="teletransmissionCheckbox"
            modalText={t("app.suivezVosContratsPartner.OpcoModal")}
            modalAcceptButtonText={t("app.suivezVosContratsPartner.AcceptOCPO")}
            modalDeclineButtonText={t("app.suivezVosContratsPartner.DeclineOPCO")}
            handleChange={(e) => {
              setFieldValue("teletransmissionCheckbox", e);
            }}
          />
        </div>
        <div className={styles.submit_btn_to_right}>
          <SubmitFormBtn text={t("app.visualisationOffre.ValidezLesModifications")} />
        </div>
      </form>
    </>
  );
};

export default Form10;
