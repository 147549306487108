import { useFormik } from "formik";
import { t } from "i18next";
import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";

import {
  patchContractDataThunk,
  selectContractDataFYC,
} from "app/slices/followYourContracts/followYourContracts.slice";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import InformationTextInForm from "components/typography/InformationTextInForm/InformationTextInForm";
import { formatDate } from "utils/formatDate";
import { createObjAucun, createBooleanReferential } from "utils/localReferentials";
import { FormDropdownField } from "pages/ENT/FollowYourContracts/components/formFields/FormDropdownField/FormDropdownField";
import { FormInputField } from "pages/ENT/FollowYourContracts/components/formFields/FormInputField/FormInputField";
import { FormDateField } from "pages/ENT/FollowYourContracts/components/formFields/FormDateField/FormDateField";
import { toastError, toastSuccess } from "utils/toasts";
import { FormTextAreaField } from "pages/ENT/FollowYourContracts/components/formFields/FormTextAreaField/FormTextAreaField";
import FormCustomCheckBoxField from "pages/ENT/FollowYourContracts/components/formFields/FormCustomCheckBoxField/FormCustomCheckBoxField";
import { changeAucunToNull, changeEmptyStringToNull, changeOuiOrNonToBoolean } from "utils/tools";

import styles from "./Form7.module.scss";

const apprenticeshipType = {
  key: "apprenticeshipType",
};
const contractType = {
  key: "contractType",
};

const addMonths = (date, months) => {
  const result = new Date(date);
  result.setMonth(result.getMonth() + months);
  result.setDate(result.getDate() - 1);
  return result;
};

const Form7 = ({
  axiosCancelToken,
  submittingAllSections,
  submitNextSectionIfSubmitAllTrue,
  cancelSubmitAllSections,
  currentSectionBeingSubmitted,
  setFormIsValid,
}) => {
  const dispatch = useDispatch();
  const contractData = useSelector(selectContractDataFYC);
  const referencialData = useSelector(getReferentialsData);
  const objAucun = createObjAucun();
  const booleanReferential = createBooleanReferential();

  const today = new Date();
  const todayPlus7 = new Date(new Date(today).setDate(today.getDate() + 7));
  // Yup validation schema

  const Form7Schema = Yup.object().shape({
    apprenticeshipType: Yup.object({
      key: Yup.string(),
      label: Yup.string(),
    })
      .test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value?.key !== "-Aucun-")
      .nullable(),
    jobTitle: Yup.string().required(t("app.errorMessages.RemplirChamp")).max(40, `${t("app.errorMessages.Max40")}`),
    contractType: Yup.object({ key: Yup.string() })
      .test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value?.key !== "-Aucun-")
      .nullable(),
    startDate: Yup.date()
      .min(formatDate(todayPlus7), t("app.errorMessages.dateEndPlus"))
      .typeError(t("app.errorMessages.FormatDateInvalide")),
    endDate: Yup.date().required(t("app.errorMessages.RemplirChamp"))
      .min(
        Yup.ref("startDate"),
        t("app.errorMessages.dateEndAfterStart"),
      )
      .test("max", t("app.errorMessages.dateEndUnder"), (value, context) => {
        const { startDate } = context.parent;
        if (!startDate || !value) {
          return false;
        }
        const maxEndDate = addMonths(startDate, 36);
        if (new Date(value) > maxEndDate) {
          return false;
        }
        return true;
      })
      .typeError(t("app.errorMessages.FormatDateInvalide")),
    weeklyWorkingHours: Yup.number()
      .min(0.0, t("app.errorMessages.numberGreaterThan0.0"))
      .max(99.99, t("app.errorMessages.numberLesserThan99.99"))
      .required(t("app.errorMessages.RemplirChamp"))
      .nullable(),
    publicSector: Yup.boolean(),
    dangerousMachineryUse: Yup.boolean(),
    mission1: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    mission2: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    mission3: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    address: Yup.string().when("isSamePlaceOfExecution.key", {
      is: "Non",
      then: Yup.string().max(255, t("app.errorMessages.Max255")).required(t("app.errorMessages.RemplirChamp")),
    }),
    additionalAddress: Yup.string().when("isSamePlaceOfExecution.key", {
      is: "Non",
      then: Yup.string().max(255, t("app.errorMessages.Max255")),
    }),
    zipCode: Yup.string().when("isSamePlaceOfExecution.key", {
      is: "Non",
      then: Yup.string()
        .matches(/^[0-9]+$/, t("app.errorMessages.numberFormat"))
        .min(5, t("app.errorMessages.NombreItemRequis"))
        .max(5, t("app.errorMessages.NombreItemRequis"))
        .required(t("app.errorMessages.RemplirChamp")),
    }),
    city: Yup.string().when("isSamePlaceOfExecution.key", {
      is: "Non",
      then: Yup.string().max(255, t("app.errorMessages.Max255")).required(t("app.errorMessages.RemplirChamp")),
    }),
    isSamePlaceOfExecution: Yup.object({
      key: Yup.string(),
    }).test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value?.key !== "-Aucun-"),
  });

  const onSubmit = async (values) => {
    const valuesCopy = structuredClone(values);

    // These functions change the value of certain keys depending on the swagger, before dataToSend initialization
    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);

    const dataToSend = {
      status: "En cours",
      contract: {
        apprenticeshipType: valuesCopy?.apprenticeshipType?.key,
        jobTitle: valuesCopy.jobTitle,
        type: valuesCopy?.contractType?.key,
        startDate: formatDate(valuesCopy.startDate),
        endDate: formatDate(valuesCopy.endDate),
        weeklyWorkingHours: parseFloat(valuesCopy.weeklyWorkingHours),
        dangerousMachineryUse: valuesCopy.dangerousMachineryUse,
        publicSector: valuesCopy.publicSector,
        missions: [valuesCopy.mission1, valuesCopy.mission2, valuesCopy.mission3],
        executionAddress: {
          street: null,
          additionalAddress: null,
          zipCode: null,
          city: null,
        },
      },
    };

    if (values.isSamePlaceOfExecution.key === "Non") {
      dataToSend.contract.executionAddress = {
        street: valuesCopy.address,
        additionalAddress: valuesCopy.additionalAddress,
        zipCode: valuesCopy.zipCode,
        city: valuesCopy.city,
      };
    }

    try {
      await dispatch(
        patchContractDataThunk({
          dataToSend,
          id: contractData.id,
          axiosCancelToken,
        }),
      ).unwrap();

      submitNextSectionIfSubmitAllTrue(8);

      if (!submittingAllSections) {
        toastSuccess(<ToastMessageStructure secondMessage={t("app.toastMessages.ModifContratRensGeneraux")} />);
      }
      console.log("Form7 patchContractDataThunk success !", dataToSend);
    } catch (error) {
      cancelSubmitAllSections();
      toastError(
        <ToastMessageStructure firstMessage={t("app.toastMessages.PreContraEntSectionModalitesDuContratSubmitError")} secondMessage={error.message} />,
      );
      console.log("Form7 patchContractDataThunk fail !", error);
    }
  };

  const { values, errors, touched, setFieldValue, handleSubmit, validateForm, isValid } = useFormik({
    initialValues: {
      apprenticeshipType: contractData?.contract?.apprenticeshipType || { ...objAucun },
      jobTitle: contractData?.contract?.jobTitle || "",
      contractType: contractData?.contract?.type || { ...objAucun },
      startDate: contractData?.contract?.startDate ? new Date(contractData?.contract?.startDate) : null,
      endDate: contractData?.contract?.endDate ? new Date(contractData?.contract?.endDate) : null,
      weeklyWorkingHours: contractData?.contract?.weeklyWorkingHours || "",
      dangerousMachineryUse: contractData?.contract?.dangerousMachineryUse || false,
      publicSector: contractData?.contract?.publicSector || false,
      mission1: contractData?.contract?.missions[0] || "",
      mission2: contractData?.contract?.missions[1] || "",
      mission3: contractData?.contract?.missions[2] || "",
      address: contractData?.contract?.executionAddress?.street || "",
      additionalAddress: contractData?.contract?.executionAddress?.additionalAddress || "",
      zipCode: contractData?.contract?.executionAddress?.zipCode || "",
      city: contractData?.contract?.executionAddress?.city || "",
      isSamePlaceOfExecution:
        contractData?.contract?.executionAddress?.street ||
        contractData?.contract?.executionAddress?.zipCode ||
        contractData?.contract?.executionAddress?.city
          ? booleanReferential[2]
          : booleanReferential[1],
    },
    validationSchema: Form7Schema,
    onSubmit,
  });

  // useLayoutEffect to force Formik's isValid check, the first time the component is mounted
  useLayoutEffect(() => {
    validateForm();
  }, []);

  // useLayoutEffect to control the color of the DropdownSection circle.
  useLayoutEffect(() => {
    if (isValid) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [isValid]);

  // useLayoutEffect to manage the trigger at the right time for sending this form, during global submission.
  useLayoutEffect(() => {
    const thisFormNumber = 7;

    if (isValid && submittingAllSections && currentSectionBeingSubmitted === thisFormNumber) {
      handleSubmit();
    } else if (!isValid && submittingAllSections && currentSectionBeingSubmitted === thisFormNumber) {
      handleSubmit();
      cancelSubmitAllSections();
      toastError(<ToastMessageStructure firstMessage={t("app.toastMessages.PreContraEntSectionModalitesDuContratNonRemplieError")} />);
    }
  }, [isValid, submittingAllSections, currentSectionBeingSubmitted]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={styles.wrapper}>
          <FormDropdownField
            id="apprenticeshipType"
            label={t("app.suivezVosContratsPartner.apprenticeshipType")}
            dataKey="key"
            textField="label"
            values={values.apprenticeshipType}
            data={[{ ...objAucun }, ...(referencialData?.APPRENTICESHIP_TYPE || [])]}
            onChange={(newValue) => {
              setFieldValue(apprenticeshipType?.key, newValue);
            }}
            errors={errors.apprenticeshipType}
            touched={touched.apprenticeshipType}
          />
          <FormInputField
            label={t("app.suivezVosContratsPartner.jobTitle")}
            id="jobTitle"
            name="jobTitle"
            type="text"
            values={values.jobTitle}
            onChange={(e) => setFieldValue("jobTitle", e.target.value)}
            errors={errors.jobTitle}
            touched={touched.jobTitle}
          />
          <FormDropdownField
            label={t("app.suivezVosContratsPartner.contractType")}
            id="contractType"
            dataKey="key"
            textField="label"
            values={values.contractType}
            data={[{ ...objAucun }, ...(referencialData?.APPRENTICESHIP_CONTRACT_NATURE || [])]}
            onChange={(newValue) => setFieldValue(contractType?.key, newValue)}
            errors={errors.contractType}
            touched={touched.contractType}
          />
          <FormDateField
            label={t("app.suivezVosContratsPartner.startDate")}
            errors={errors.startDate}
            touched={touched.startDate}
            id="startDate"
            values={values.startDate}
            onChange={(value) => setFieldValue("startDate", value)}
          />
          <FormDateField
            label={t("app.suivezVosContratsPartner.endDate")}
            errors={errors.endDate}
            touched={touched.endDate}
            id="endDate"
            values={values.endDate}
            onChange={(value) => setFieldValue("endDate", value)}
          />
          <FormInputField
            label={t("app.suivezVosContratsPartner.weeklyWorkingHours")}
            id="weeklyWorkingHours"
            name="weeklyWorkingHours"
            type="number"
            min="0.00"
            step="0.01"
            values={values.weeklyWorkingHours}
            onChange={(e) => setFieldValue("weeklyWorkingHours", e.target.value)}
            errors={errors.weeklyWorkingHours}
            touched={touched.weeklyWorkingHours}
          />
        </div>
        <div className={styles.fullContainer}>
          <div className={styles.formGroup}>
            <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.dangerousMachineryUse")}</h6>
            <FormCustomCheckBoxField
              additionalClassNames={styles.checkboxInform}
              error={errors.dangerousMachineryUse}
              touched={touched.dangerousMachineryUse}
              checkboxValue={values.dangerousMachineryUse}
              text={t("app.suivezVosContratsPartner.dangerousMachineryUseLabel")}
              id="dangerousMachineryUse"
              handleChange={(e) => {
                setFieldValue("dangerousMachineryUse", e);
              }}
            />
          </div>
          <div className={styles.formGroup}>
            <h6 className={styles.subtitle}>{t("app.suivezVosContratsPartner.publicSector")}</h6>
            <FormCustomCheckBoxField
              additionalClassNames={styles.checkboxInform}
              error={errors.publicSector}
              touched={touched.publicSector}
              checkboxValue={values.publicSector}
              text={t("app.suivezVosContratsPartner.publicSectorLabel")}
              id="publicSector"
              handleChange={(e) => {
                setFieldValue("publicSector", e);
              }}
            />
          </div>
          <div className={styles.explication}>
            <InformationTextInForm>{t("app.suivezVosContratsPartner.explication1")}</InformationTextInForm>
            <InformationTextInForm>{t("app.suivezVosContratsPartner.explication2")}</InformationTextInForm>
          </div>
          <div className={styles.box}>
            <FormTextAreaField
              label={`${t("app.suivezVosContratsPartner.mission")} 1`}
              id="mission1"
              values={values.mission1}
              onChange={(e) => setFieldValue("mission1", e.target.value)}
              errors={errors.mission1}
              touched={touched.mission1}
            />
            <FormTextAreaField
              label={`${t("app.suivezVosContratsPartner.mission")} 2`}
              id="mission2"
              values={values.mission2}
              onChange={(e) => setFieldValue("mission2", e.target.value)}
              errors={errors.mission2}
              touched={touched.mission2}
            />
            <FormTextAreaField
              label={`${t("app.suivezVosContratsPartner.mission")} 3`}
              id="mission3"
              values={values.mission3}
              onChange={(e) => setFieldValue("mission3", e.target.value)}
              errors={errors.mission3}
              touched={touched.mission3}
            />
          </div>

          <div className={styles.wrapper}>
            <FormDropdownField
              label={t("app.suivezVosContratsPartner.OtherPlaceOfExecutionFieldName")}
              id="isSamePlaceOfExecution"
              dataKey="key"
              textField="label"
              values={values.isSamePlaceOfExecution}
              data={booleanReferential}
              onChange={(e) => setFieldValue("isSamePlaceOfExecution", e)}
              errors={errors.isSamePlaceOfExecution}
              touched={touched.contractType}
            />
          </div>
          {values.isSamePlaceOfExecution.key === "Non" && (
            <div className={styles.explication}>
              <InformationTextInForm>
                {t("app.suivezVosContratsPartner.OtherPlaceOfExecutionHelpText")}
              </InformationTextInForm>
            </div>
          )}
          {values.isSamePlaceOfExecution.key === "Non" && (
            <div className={styles.wrapper}>
              <FormInputField
                label={t("app.suivezVosContratsPartner.Adresse")}
                id="address"
                name="address"
                type="text"
                values={values.address}
                onChange={(e) => setFieldValue("address", e.target.value)}
                errors={errors.address}
                touched={touched.address}
              />
              <FormInputField
                label={t("app.suivezVosContratsPartner.ComplementDadresse")}
                id="additionalAddress"
                name="additionalAddress"
                type="text"
                values={values.additionalAddress}
                onChange={(e) => setFieldValue("additionalAddress", e.target.value)}
                errors={errors.additionalAddress}
                touched={touched.additionalAddress}
              />
              <FormInputField
                label={t("app.suivezVosContratsPartner.CodePostal2")}
                id="zipCode"
                name="zipCode"
                type="text"
                values={values.zipCode}
                onChange={(e) => setFieldValue("zipCode", e.target.value)}
                errors={errors.zipCode}
                touched={touched.zipCode}
              />
              <FormInputField
                label={t("app.suivezVosContratsPartner.Ville")}
                id="city"
                name="city"
                type="text"
                values={values.city}
                onChange={(e) => setFieldValue("city", e.target.value)}
                errors={errors.city}
                touched={touched.city}
              />
            </div>
          )}
        </div>
        <div className={styles.submit_btn_to_right}>
          <SubmitFormBtn
            onClick={() => {
              if (errors.startDate) {
                toastError(<ToastMessageStructure secondMessage={t("app.toastMessages.ErrorDateSupASeptJours")} />);
              }
              if (
                errors.startDate === "startDate is a required field" ||
                errors.endDate === "endDate is a required field"
              ) {
                toastError(
                  <ToastMessageStructure
                    secondMessage={t("app.toastMessages.ErrorDateDebutDateFinContratUndefined")}
                  />,
                );
              }
            }}
            text={t("app.visualisationOffre.ValidezLesModifications")}
          />
        </div>
      </form>
    </>
  );
};

export default Form7;
