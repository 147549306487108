import { t } from "i18next";

import { useState } from "react";

import UploadOrganism from "pages/ETU/DeclareARecruitment/components/UploadOrganism/UploadOrganism";
import { getDeclareARecruitmentPreContractualizationInformations } from "app/slices/declareARecruitment/declareARecruitment.slice";

import { postFileThunk, getUploadFilePending, getUploadFIleErrorMessage } from "app/slices/uploadFile/uploadFile.slice";
import { useSelector, useDispatch } from "react-redux";
import { toastSuccess, toastError } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import LoadingModal from "../../../../components/LoadingModal/LoadingModal";

import closeIcon from "../../../../assets/close-svg.svg";

import styles from "./FileModal.module.scss";

const FileModal = ({
  setFile,
  actualFile,
  axiosCancelToken,
  setPathIndicator,
  setShow,
  summit,
  fileValue,
  show,
  onClose,
  appStudient,
}) => {
  // const [uploadStatus, setUploadStatus] = useState("");
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const dispatch = useDispatch();
  const preContractualizationInformations = useSelector(getDeclareARecruitmentPreContractualizationInformations);

  const filePending = useSelector(getUploadFilePending);
  const fileError = useSelector(getUploadFIleErrorMessage);

  const [fileLoadModal, setFileLoadModal] = useState({});
  console.log("appStudient", appStudient);
  const setFileFunction = (file) => {
    setFile(file);
    setFileLoadModal(file);
  };
  const setUselessShow = () => null;
  const handleSubmitFile = (endpoint) => {
    if (actualFile) {
      console.log("YES FILE MODAL", actualFile);
      // setNoFileHandler(false);
      setShowLoadingModal(true);

      if (!filePending) {
        (async () => {
          try {
            await dispatch(
              postFileThunk({
                file: actualFile,
                endpoint,
                axiosCancelToken,
              }),
            ).unwrap();
            console.log("upload success TestUploadFile !");
            toastSuccess(
              <ToastMessageStructure
                firstMessage={t("app.toastMessages.genericSuccess")}
                secondMessage={actualFile.name}
              />,
            );
          } catch (error) {
            toastError(
              <ToastMessageStructure
                firstMessage={t("app.toastMessages.genericError")}
                secondMessage={actualFile.name}
              />,
            );

            console.log("upload fail TestUploadFile !", error.message);
          }
        })();

        setPathIndicator("MODAL");
      }
    }
  };

  const handleCloseLoadingModal = () => {
    setShow(false);
    summit(fileValue);
    setShowLoadingModal(false);
  };
  if (show) {
    return (
      <>
        <div className={styles.modal}>
          <LoadingModal
            setShow={setShowLoadingModal}
            show={showLoadingModal}
            file={{
              name: fileLoadModal?.name,
              type: fileLoadModal?.type,
              size: fileLoadModal?.size,
            }}
            pending={filePending}
            error={fileError}
            handleClose={handleCloseLoadingModal}
          />
          <div className={styles.modal_content}>
            <button type="button" className={styles.modal_close_button} onClick={onClose}>
              <img className={styles.closeIcon_size} src={closeIcon} alt="closeIcon" />
            </button>
            <h2 className={styles.modal_title}>{t("app.declarezUnRecrutement.DeclarerUnRecrutement")}</h2>
            <div className={styles.separator_top} />
            {appStudient && (
              <>
                <p className={styles.text_area}>{t("app.declarezUnRecrutement.ChargerFormulaireRupture")}</p>
                <p className={styles.text_area}>{t("app.declarezUnRecrutement.WarningFormulaireRupture")}</p>
              </>
            )}
            <div className={styles.modal_contentBox}>
              <div>
                {appStudient && (
                  <p className={styles.text_file_area}>{t("app.declarezUnRecrutement.DeposezFormulaireRupture")}</p>
                )}
                <UploadOrganism disabled={false} setFile={setFileFunction} setDisplayModal={setUselessShow} />
              </div>
            </div>
            <div className={styles.footer_modal}>
              <button
                className={styles.close_btn}
                type="button"
                onClick={() => handleSubmitFile(
                  `${process.env.REACT_APP_MIDDLEWARE_COMPANY}/v1/companies/recruitments/apprenticeships/pre-contractualizations/${preContractualizationInformations.id}/supporting-documents/${"BREACH_CONTRACT"}`,
                )}
              >
                {t("app.declarezUnRecrutement.ValidezEtPassezAEtapeSuivante")}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
  return null;
};

export default FileModal;
