import { t } from "i18next";

import { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { getDeclareARecruitmentPreContractualizationInformations } from "app/slices/declareARecruitment/declareARecruitment.slice";

import HeaderContract from "../../components/HeaderContract/HeaderContract";
import SummaryCard from "../../components/SummaryCard/SummaryCard";
import ApprenticeshipForm from "./forms/ApprenticeshipForm";
import ProfessionalForm from "./forms/ProfessionalForm";
import ContinuingEducationForm from "./forms/ContinuingEducationForm";

import styles from "./Step2.module.scss";

const Step2 = ({ axiosCancelToken }) => {
  const [studentAge, setStudentAge] = useState(null);

  const preContractualizationInformations = useSelector(getDeclareARecruitmentPreContractualizationInformations);

  useEffect(() => {
    const studentBirthYear = new Date(preContractualizationInformations?.student?.person?.birthdate)
      .toISOString()
      .split("T")[0]
      .split("-")[0];

    const actualYear = new Date().toISOString().split("T")[0].split("-")[0];

    setStudentAge(actualYear - studentBirthYear);
  }, [preContractualizationInformations]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.wrapper}>
      <HeaderContract />
      <section className={styles.main_container}>
        <div className={styles.form_container}>
          <div className={styles.form_and_card_container}>
            <div className={styles.title_container}>
              <h2>{t("app.declarezUnRecrutement.CasDerogatoiresEtape2Et3")}</h2>
              <div className={styles.step_indicator}>{t("app.declarezUnRecrutement.Etape2Sur7")}</div>
            </div>
            {studentAge && preContractualizationInformations.contractType?.key === "Contrat d'apprentissage" && (
              <ApprenticeshipForm studentAge={studentAge} axiosCancelToken={axiosCancelToken} />
            )}
            {studentAge &&
              preContractualizationInformations.contractType?.key === "Contrat de professionnalisation" && (
                <ProfessionalForm studentAge={studentAge} axiosCancelToken={axiosCancelToken} />
              )}
            {studentAge && preContractualizationInformations.contractType?.key === "Formation continue" && (
              <ContinuingEducationForm studentAge={studentAge} axiosCancelToken={axiosCancelToken} />
            )}
          </div>
          <SummaryCard
            data={[
              {
                title: t("app.declarezUnRecrutement.ContratSouhaite"),
                text: preContractualizationInformations.contractType?.label,
              },
              {
                title: t("app.declarezUnRecrutement.Nationalite"),
                text: preContractualizationInformations.student?.person?.nationalities?.[0]?.key,
              },
            ]}
          />
        </div>
      </section>
    </div>
  );
};

export default Step2;
