import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCompany } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  companyId: null,
  pending: false,
  errorMessage: null,
  data: null,
  allEmployee: [],
  companyEmployeeId: null,
};

// THUNKS SECTION

export const PutThunkOneEmployee = createAsyncThunk(
  "detailsCompany/putThunkOneEmployee",
  async ({ dataToSend, companyId, companyEmployeeId, axiosCancelToken }) => {
    const response = await axiosCompany().put(`/v1/companies/${companyId}/employees/${companyEmployeeId}`, dataToSend, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

export const getCompanyThunk = createAsyncThunk(
  "detailsCompany/getCompanyThunk",
  async ({ companyId, axiosCancelToken }) => {
    const response = await axiosCompany().get(`/v2/companies/${companyId}`, {
      cancelToken: axiosCancelToken.token,
    });

    return response.data;
  },
);
export const getCompanyEmployeesThunk = createAsyncThunk(
  "detailsCompany/getCompanyEmployeesThunk",
  async ({ companyId, axiosCancelToken }) => {
    const response = await axiosCompany().get(`/v1/companies/${companyId}/employees`, {
      cancelToken: axiosCancelToken.token,
    });

    return response.data;
  },
);

export const getOneEmployeesThunk = createAsyncThunk(
  "detailsCompany/getOneEmployeesThunk",
  async ({ companyId, companyEmployeeId, axiosCancelToken }) => {
    const response = await axiosCompany().get(`/v1/companies/${companyId}/employees/${companyEmployeeId}`, {
      cancelToken: axiosCancelToken.token,
    });

    return response.data;
  },
);

// CREATESLICE SECTION

const getCompanySlice = createSlice({
  name: "detailsCompany",
  initialState,
  reducers: {
    handleReset: (state) => {
      console.log("reset");
      state.companyId = null;
      state.companyEmployeeId = null;
      state.pending = false;
      state.errorMessage = "";
      state.allEmployee = [];
    },
  },
  extraReducers: {
    [getCompanyThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [getCompanyThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pending = false;
      state.data = action.payload;
    },
    [getCompanyThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [getCompanyEmployeesThunk.pending]: (state) => {
      console.log("pending !");
      state.errorMessage = "";
      state.pending = true;
    },
    [getCompanyEmployeesThunk.fulfilled]: (state, action) => {
      console.log("fulfillfed !", action.payload);
      state.allEmployee = action.payload;
      state.pending = false;
    },
    [getCompanyEmployeesThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [getOneEmployeesThunk.pending]: (state) => {
      console.log("pending !");
      state.errorMessage = "";
      state.pending = true;
    },
    [getOneEmployeesThunk.fulfilled]: (state, action) => {
      console.log("fulfillfed !", action.payload);
      state.companyEmployeeId = action.payload;
      state.pending = false;
    },
    [getOneEmployeesThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [PutThunkOneEmployee.pending]: (state) => {
      state.errorMessage = "";
      state.pending = true;
    },
    [PutThunkOneEmployee.fulfilled]: (state, action) => {
      console.log("babababab !", action.payload);
      state.pending = false;
    },
    [PutThunkOneEmployee.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION

export const getDetailsCompany = (state) => state?.detailsCompany?.data;
export const getDetailsCompanyPending = (state) => state?.detailsCompany?.pending;
export const getDetailsCompanyErrorMessage = (state) => state.detailsCompany.errorMessage;
export const getAllEmployee = (state) => state?.detailsCompany?.allEmployee;
export const getAllEmployeePending = (state) => state?.detailsCompany?.pending;
export const getAllEmployeeErrorMessage = (state) => state?.detailsCompany?.errorMessage;

export const getOneEmployees = (state) => state?.detailsCompany?.companyEmployeeId;
export const getOneEmployeesPending = (state) => state?.detailsCompany?.pending;
export const getOneEmployeesErrorMessage = (state) => state?.detailsCompany?.errorMessage;
// ACTIONS CREATOR EXPORTS SECTION
export const { handleReset } = getCompanySlice.actions;

// REDUCER DEFAULT EXPORT SECTION

export default getCompanySlice.reducer;
