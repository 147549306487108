import { t } from "i18next";
import { useSelector } from "react-redux";
import { selectContractDataFYC } from "app/slices/followYourContracts/followYourContracts.slice";
import { formatDateFrench, getAgeFromDateOfBirth } from "utils/formatDate";
import FormInformationField from "pages/ENT/FollowYourContracts/components/formFields/FormInformationField/FormInformationField";
import SubForm8Apprenticeship from "./subForms/SubForm8Apprenticeship";
import SubForm8Other from "./subForms/SubForm8Other";

const Form8 = ({
  axiosCancelToken,
  submittingAllSections,
  submitNextSectionIfSubmitAllTrue,
  cancelSubmitAllSections,
  currentSectionBeingSubmitted,
  setFormIsValid,
}) => {
  const contractData = useSelector(selectContractDataFYC);
  const isStudentApprentice = contractData?.contract?.apprenticeshipType?.key === "Contrat_dapprentissage";
  const birthdateInfo = `${formatDateFrench(contractData?.student?.person?.birthdate)} / ${getAgeFromDateOfBirth(
    contractData?.student?.person?.birthdate,
  )} ${t("app.suivezVosContratsPartner.Age")}`;

  return (
    contractData && (
      <>
        <FormInformationField
          label={t("app.suivezVosContratsPartner.DateDeNaissanceDeLalternant")}
          information={birthdateInfo}
        />
        {isStudentApprentice ? (
          <SubForm8Apprenticeship
            axiosCancelToken={axiosCancelToken}
            submittingAllSections={submittingAllSections}
            submitNextSectionIfSubmitAllTrue={submitNextSectionIfSubmitAllTrue}
            cancelSubmitAllSections={cancelSubmitAllSections}
            currentSectionBeingSubmitted={currentSectionBeingSubmitted}
            setFormIsValid={setFormIsValid}
          />
        ) : (
          <SubForm8Other
            axiosCancelToken={axiosCancelToken}
            submittingAllSections={submittingAllSections}
            submitNextSectionIfSubmitAllTrue={submitNextSectionIfSubmitAllTrue}
            cancelSubmitAllSections={cancelSubmitAllSections}
            currentSectionBeingSubmitted={currentSectionBeingSubmitted}
            setFormIsValid={setFormIsValid}
          />
        )}
      </>
    )
  );
};

export default Form8;
