import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCompany } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  contractsList: [],
  contractData: null,
  companyEditRights: null,
  contactAffiliatedCompany: null,
  localCompanyId: undefined,
  numberOfContracts: 0,
  currentPagination: 1,
  maxPagination: undefined,
  offset: 0,
  endIndex: 20,
  pending: false,
  pendingContractData: false,
  errorMessage: null,
  errorMessageContractData: null,
};

// THUNKS SECTION

export const getContractsListThunk = createAsyncThunk(
  "followYourContracts/getContractsListThunk",
  async ({ axiosCancelToken }, { getState }) => {
    const { offset } = getState().followYourContracts;
    const { endIndex } = getState().followYourContracts;

    console.log("off", offset, endIndex);

    const response = await axiosCompany().get("/v3/companies/apprenticeships/pre-contractualizations", {
      headers: {
        Range: `${offset}-${endIndex}`,
      },
      cancelToken: axiosCancelToken.token,
    });

    console.log(typeof response.headers["content-range"]?.split("/")[1]);
    console.log("RESPONSE CONTRACTS", response);

    const numberOfContracts = parseInt(response.headers["content-range"]?.split("/")[1]?.trim(), 10);
    console.log("numberOfContracts", numberOfContracts);

    return {
      data: response.data,
      numberOfContracts,
    };
  },
);

export const getOneContractThunk = createAsyncThunk(
  "followYourContracts/getOneContractThunk",
  async ({ id, axiosCancelToken }) => {
    const response = await axiosCompany().get(`/v3/companies/apprenticeships/pre-contractualizations/${id}`, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

export const nextPaginationContractsListThunk = createAsyncThunk(
  "followYourContracts/nextPaginationContractsListThunk",
  async ({ axiosCancelToken }, { getState }) => {
    const offset = getState().followYourContracts.offset + 20;
    const endIndex = getState().followYourContracts.endIndex + 20;

    console.log("off", offset, endIndex);

    const response = await axiosCompany().get("/v3/companies/apprenticeships/pre-contractualizations", {
      headers: {
        Range: `${offset}-${endIndex}`,
      },
      cancelToken: axiosCancelToken.token,
    });

    console.log(typeof response.headers["content-range"]?.split("/")[1]);
    console.log("RESPONSE CONTRACTS", response);

    const numberOfContracts = parseInt(response.headers["content-range"]?.split("/")[1]?.trim(), 10);
    console.log("numberOfContracts", numberOfContracts);

    return {
      data: response.data,
      numberOfContracts,
    };
  },
);

export const previousPaginationContractsListThunk = createAsyncThunk(
  "followYourContracts/previousPaginationContractsListThunk",
  async ({ axiosCancelToken }, { getState }) => {
    const offset = getState().followYourContracts.offset - 20;
    const endIndex = getState().followYourContracts.endIndex - 20;

    console.log("off", offset, endIndex);

    const response = await axiosCompany().get("/v3/companies/apprenticeships/pre-contractualizations", {
      headers: {
        Range: `${offset}-${endIndex}`,
      },
      cancelToken: axiosCancelToken.token,
    });

    console.log(typeof response.headers["content-range"]?.split("/")[1]);
    console.log("RESPONSE CONTRACTS", response);

    const numberOfContracts = parseInt(response.headers["content-range"]?.split("/")[1]?.trim(), 10);
    console.log("numberOfContracts", numberOfContracts);

    return {
      data: response.data,
      numberOfContracts,
    };
  },
);

export const patchContractDataThunk = createAsyncThunk(
  "followYourContracts/patchContractDataThunk",
  async ({ dataToSend, id, axiosCancelToken }) => {
    const response = await axiosCompany().patch(
      `/v3/companies/apprenticeships/pre-contractualizations/${id}`,
      dataToSend,
      {
        cancelToken: axiosCancelToken.token,
      },
    );
    return response.data;
  },
);

// CREATESLICE SECTION

const followYourContractsSlice = createSlice({
  name: "followYourContracts",
  initialState,
  reducers: {
    resetContractsListState: (state) => {
      state.contractsList = [];
      state.numberOfContracts = 0;
      state.currentPagination = 1;
      state.maxPagination = undefined;
      state.offset = 0;
      state.endIndex = 20;
      state.pending = false;
      state.errorMessage = null;
    },
    resetContractDataState: (state) => {
      state.contractData = null;
      state.companyEditRights = null;
      state.contactAffiliatedCompany = null;
      state.localCompanyId = undefined;
      state.pendingContractData = false;
      state.errorMessageContractData = null;
    },
    setCompanyEditRights: (state, action) => {
      state.companyEditRights = action.payload;
    },
    setContactAffiliatedCompany: (state, action) => {
      state.contactAffiliatedCompany = action.payload;
    },
    setLocalCompanyId: (state, action) => {
      state.localCompanyId = action.payload;
    },
  },
  extraReducers: {
    [getContractsListThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [getContractsListThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload.data);
      state.contractsList = action.payload.data;
      state.numberOfContracts = action.payload.numberOfContracts;
      state.currentPagination = 1;
      state.maxPagination =
        action.payload.numberOfContracts % 20 === 0
          ? action.payload.numberOfContracts / 20
          : Math.floor(action.payload.numberOfContracts / 20) + 1;
      state.pending = false;
      console.log("Range", action.payload.numberOfContracts);
    },
    [getContractsListThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [getOneContractThunk.pending]: (state) => {
      state.errorMessage = "";
      state.pendingContractData = true;
    },
    [getOneContractThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pendingContractData = false;
      state.contractData = action.payload;
    },
    [getOneContractThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pendingContractData = false;
      state.errorMessage = action.error.message;
    },
    [nextPaginationContractsListThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [nextPaginationContractsListThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload.data);
      state.contractsList = action.payload.data;
      state.numberOfContracts = action.payload.numberOfContracts;
      state.currentPagination += 1;
      state.maxPagination =
        action.payload.numberOfContracts % 20 === 0
          ? action.payload.numberOfContracts / 20
          : Math.floor(action.payload.numberOfContracts / 20) + 1;
      state.offset += 20;
      state.endIndex += 20;
      state.pending = false;
      console.log("Range", action.payload.numberOfContracts);
    },
    [nextPaginationContractsListThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [previousPaginationContractsListThunk.pending]: (state) => {
      state.errorMessage = null;
      state.pending = true;
    },
    [previousPaginationContractsListThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload.data);
      state.contractsList = action.payload.data;
      state.numberOfContracts = action.payload.numberOfContracts;
      state.currentPagination -= 1;
      state.maxPagination =
        action.payload.numberOfContracts % 20 === 0
          ? action.payload.numberOfContracts / 20
          : Math.floor(action.payload.numberOfContracts / 20) + 1;
      state.offset -= 20;
      state.endIndex -= 20;
      state.pending = false;
      console.log("Range", action.payload.numberOfContracts);
    },
    [previousPaginationContractsListThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [patchContractDataThunk.pending]: (state) => {
      state.errorMessageContractData = "";
      state.pendingContractData = true;
    },
    [patchContractDataThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.pendingContractData = false;
      state.contractData = action.payload;
    },
    [patchContractDataThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pendingContractData = false;
      state.errorMessageContractData = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION
export const selectContractsListFYC = (state) => state.followYourContracts.contractsList;
export const selectContractDataFYC = (state) => state.followYourContracts.contractData;
export const selectPendingFYC = (state) => state.followYourContracts.pending;
export const selectPendingContractDataFYC = (state) => state.followYourContracts.pendingContractData;
export const selectErrorMessageFYC = (state) => state.followYourContracts.errorMessage;
export const selectErrorMessageContractDataFYC = (state) => state.followYourContracts.errorMessageContractData;
export const selectNumberOfContractsFYC = (state) => state.followYourContracts.numberOfContracts;
export const selectCurrentPaginationFYC = (state) => state.followYourContracts.currentPagination;
export const selectMaxPaginationFYC = (state) => state.followYourContracts.maxPagination;
export const selectCompanyEditRightsFYC = (state) => state.followYourContracts.companyEditRights;
export const selectContactAffiliatedCompanyFYC = (state) => state.followYourContracts.contactAffiliatedCompany;
export const selectLocalCompanyIdFYC = (state) => state.followYourContracts.localCompanyId;

// ACTIONS CREATOR EXPORTS SECTION
export const {
  resetContractsListState,
  resetContractDataState,
  setCompanyEditRights,
  setContactAffiliatedCompany,
  setLocalCompanyId,
} = followYourContractsSlice.actions;

// REDUCER DEFAULT EXPORT SECTION

export default followYourContractsSlice.reducer;
