// React tools
import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

// Msal tools
import { useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";

// i18 translation provided to all App please check the last line export default
import { withTranslation } from "react-i18next";
import { t } from "i18next";

// Redux tools
import { useDispatch, useSelector } from "react-redux";
// Slices imports
import { getReferentialsThunk, getTrainingSchoolsThunk } from "app/slices/referentials/referentials.slice";
import { getLanguage } from "app/slices/language/language.slice";

// React Toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// react-datepicker + date-fns
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-GB";

// Subdomain constants
import {
  STUDENT_SUBDOMAIN_NAME,
  COMPANY_SUBDOMAIN_NAME,
  LOCAL_STUDENT_BYPASS,
  LOCAL_COMPANY_BYPASS,
} from "utils/globalConstantsAndURL";

import { toastError } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";

// Routes for each subdomain
import RoutesSubdomain404 from "routesBySubdomain/Subdomain404/RoutesSubdomain404";
import RoutesETU from "./routesBySubdomain/RoutesETU/RoutesETU";
import RoutesENT from "./routesBySubdomain/RoutesENT/RoutesENT";
// import RoutesTutor from "routesBySubdomain/RoutesTutor/RoutesTutor";

// Slices imports
import { changeDeviceWidth } from "./app/slices/deviceWidth/deviceWidth.slice";
import { getSubdomain } from "./app/slices/subdomain/subdomain.slice";
import {
  handleUserToken,
  getMeDataThunk,
  setAcquireTokenRedirectInProgress,
  getMeDataV2Thunk,
} from "./app/slices/userConnexion/userConnexion.slice";

// Global components
import Navbar from "./components/Navbar/Navbar";
import Sidenav from "./components/Sidenav/Sidenav";

// component.module.scss
import styles from "./App.module.scss";

function App() {
  const { instance, inProgress, accounts } = useMsal();

  const language = useSelector(getLanguage);

  const location = useLocation();
  const dispatch = useDispatch();

  const subdomain = useSelector(getSubdomain);

  useLayoutEffect(() => {
    // Listen to the global resize event and update the state of deviceWidth.slice.js
    window.addEventListener("resize", () => {
      dispatch(changeDeviceWidth());
    });

    // Set all languages needed for date-fns
    registerLocale("fr", fr);
    registerLocale("en", en);

    // LOCAL GETME
    if (LOCAL_STUDENT_BYPASS || LOCAL_COMPANY_BYPASS) {
      (async () => {
        try {
          await dispatch(getMeDataThunk()).unwrap();
          console.log("getMeDataThunk in App.js success !");
          await dispatch(getMeDataV2Thunk()).unwrap();
          console.log("getMeDataV2Thunk in App.js success !");
        } catch (error) {
          console.log("getMe or getMeV2 in App.js failed !", error.message);
          toastError(
            <ToastMessageStructure
              firstMessage={t("app.toastMessages.getMeErrorFirst")}
              secondMessage={`${t("app.toastMessages.getMeErrorSecond")} (${error.message})`}
            />,
            "top-center",
            false,
          );
        }
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Scroll to top each time the pathname change
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // Automatic redirect if not logged, when subdomain === STUDENT_SUBDOMAIN_NAME
  useLayoutEffect(() => {
    if (subdomain === STUDENT_SUBDOMAIN_NAME && !LOCAL_STUDENT_BYPASS) {
      // handle auth redired/do all initial setup for msal
      instance
        .handleRedirectPromise()
        .then(() => {
          // Check if user signed in
          const account = instance.getActiveAccount();
          if (!account) {
            // redirect anonymous user to login page
            instance.loginRedirect({
              scopes: ["openid"],
              prompt: "select_account",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get userToken and dispatch in userConnexion.slice.js
  useLayoutEffect(() => {
    const scopes = ["openid"];

    if (instance.getActiveAccount() && inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent({
          scopes,
          account: instance.getActiveAccount(),
          forceRefresh: true,
        })
        .then((accessTokenResponse) => {
          const { idToken } = accessTokenResponse;
          console.log("accessTokenResponse from acquireTokenSilent", accessTokenResponse);
          dispatch(handleUserToken({ userToken: idToken }));
          (async () => {
            try {
              await dispatch(getMeDataThunk()).unwrap();
              console.log("getMeDataThunk in App.js success !");
              await dispatch(getMeDataV2Thunk()).unwrap();
              console.log("getMeDataV2Thunk in App.js success !");
            } catch (error) {
              console.log("getMe or getMeV2 in App.js failed !", error.message);
              toastError(
                <ToastMessageStructure
                  firstMessage={t("app.toastMessages.getMeErrorFirst")}
                  secondMessage={`${t("app.toastMessages.getMeErrorSecond")} (${error.message})`}
                />,
                "top-center",
                false,
              );
            }
          })();
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            (async () => {
              dispatch(setAcquireTokenRedirectInProgress(true));
              await instance.acquireTokenRedirect({
                scopes,
                prompt: "select_account",
              });
              dispatch(setAcquireTokenRedirectInProgress(false));
            })();
          }
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, inProgress]);

  // Get needed referentials on start and when the language change
  useLayoutEffect(() => {
    if (subdomain === STUDENT_SUBDOMAIN_NAME || subdomain === COMPANY_SUBDOMAIN_NAME) {
      dispatch(getReferentialsThunk());
      if (instance.getActiveAccount() || LOCAL_STUDENT_BYPASS || LOCAL_COMPANY_BYPASS) {
        dispatch(getTrainingSchoolsThunk());
      }
    }

    // Set default locale for date-fns
    setDefaultLocale(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, accounts]);

  return (
    <div className={styles.main_container}>
      <div className={styles.sidenav_container}>
        <Sidenav />
      </div>
      <div className={styles.navbar_and_content_container}>
        <Navbar />
        <div className={styles.main_container_content}>
          {subdomain === STUDENT_SUBDOMAIN_NAME && <RoutesETU />}
          {subdomain === COMPANY_SUBDOMAIN_NAME && <RoutesENT />}
          {subdomain !== STUDENT_SUBDOMAIN_NAME && subdomain !== COMPANY_SUBDOMAIN_NAME && <RoutesSubdomain404 />}
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default withTranslation()(App);
