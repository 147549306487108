import { t } from "i18next";

import axios from "axios";

import { useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getUserToken } from "app/slices/userConnexion/userConnexion.slice";
import {
  getOneContractThunk,
  resetContractDataState,
  selectContractDataFYC,
  selectPendingContractDataFYC,
} from "app/slices/followYourContracts/followYourContracts.slice";
import { selectPostAndPatchCompanyIsPending, getAllCompaniesIsPending } from "app/slices/checkDetailsCompany/getAllCompanies.slice";

import { toastError } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";

import Loader from "components/Loader/Loader";
import ModifiableContract from "./ModifiableContract/ModifiableContract";
import Recapitulative from "./Recapitulative/Recapitulative";

const SelectedContract = () => {
  const [axiosCancelToken] = useState(axios.CancelToken.source());

  const { id } = useParams();

  const dispatch = useDispatch();

  const userToken = useSelector(getUserToken);

  const contractData = useSelector(selectContractDataFYC);
  const pending = useSelector(selectPendingContractDataFYC);
  const postAndPatchCompanyIsPending = useSelector(selectPostAndPatchCompanyIsPending);
  const allCompaniesIsPending = useSelector(getAllCompaniesIsPending);

  const [firstLoad, setFirstLoad] = useState(false);

  // useLayoutEffect to get the selected contract
  useLayoutEffect(() => {
    if (!pending && userToken && !firstLoad) {
      (async () => {
        try {
          await dispatch(getOneContractThunk({ id, axiosCancelToken })).unwrap();
          setFirstLoad(true);
          console.log("useLayoutEffect success ! getOneContractThunk");
        } catch (error) {
          if (error.message === "Request failed with status code 404") {
            toastError(
              <ToastMessageStructure
                firstMessage={t("app.toastMessages.ContractDoesNotExist")}
                secondMessage={error.message}
              />,
            );
          } else if (error.message !== "canceled") {
            toastError(<ToastMessageStructure secondMessage={error.message} />);
          }

          console.log("useLayoutEffect fail ! getOneContractThunk", error);
        }
      })();
    }
  }, [userToken]);

  // For cleanup function only
  useLayoutEffect(
    () => () => {
      axiosCancelToken.cancel();
      dispatch(resetContractDataState());
      console.log("RESET : resetContractDataState");
    },
    [],
  );

  return (
    <>
      {(pending || postAndPatchCompanyIsPending || allCompaniesIsPending) && <Loader />}
      {contractData &&
        (contractData?.status?.key === "À compléter" || contractData?.status?.key === "En cours") && (
          <ModifiableContract />
        )}
      {contractData &&
        contractData?.status?.key !== "À compléter" &&
        contractData?.status?.key !== "En cours" && <Recapitulative />}
    </>
  );
};

export default SelectedContract;
