import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";
import { DropdownList } from "react-widgets";
import styles from "./FormDropdownField.module.scss";

export const FormDropdownField = ({
  id,
  label,
  errors,
  values,
  touched,
  data,
  onChange,
  additionalClassNames,
  renderValue,
  selectIcon,
  disabled = false,
}) => (
  <div
    className={`${styles.formGroup} input-like-rw ${additionalClassNames}`}
  >
    <label htmlFor={id}>{label}</label>
    <DropdownList
      className={errors && touched && styles.errorInput}
      id={id}
      dataKey="key"
      textField="label"
      data={data}
      value={values}
      onChange={onChange}
      renderValue={renderValue}
      selectIcon={selectIcon}
      disabled={disabled}
    />
    <InputErrorMessage message={errors} touched={touched} />
  </div>
);
