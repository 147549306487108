import closeIcon from "../../../assets/close-svg.svg";

import styles from "./FormCustomCheckBoxField.module.scss";

const CheckBoxModal = ({
  closeModalAndInverseCheckboxValue,
  show,
  modalText,
  handleCheckBox,
  modalDeclineButtonText,
  modalAcceptButtonText,
}) => (
  <>
    {show && (
      <div className={styles.modal}>
        <div className={styles.modal_content}>
          <button type="button" className={styles.modal_close_button} onClick={closeModalAndInverseCheckboxValue}>
            <img className={styles.closeIcon_size} src={closeIcon} alt="closeIcon" />
          </button>
          <div className={styles.separator_top} />
          <div className={styles.modal_contentBox}>
            <p className={styles.text_area}>{modalText}</p>
          </div>
          <div className={styles.separator_bottom} />

          <div className={styles.button_container}>
            <div
              className={styles.true_btn}
              onClick={() => handleCheckBox(true)}
              onKeyDown={(e) => e.key === "Enter" && handleCheckBox(true)}
              role="button"
              tabIndex={0}
            >
              <p>{modalAcceptButtonText}</p>
            </div>
            <div
              className={styles.false_btn}
              onClick={() => handleCheckBox(false)}
              onKeyDown={(e) => e.key === "Enter" && handleCheckBox(false)}
              role="button"
              tabIndex={0}
            >
              <p>{modalDeclineButtonText}</p>
            </div>
          </div>
        </div>
      </div>
    )}
  </>
);

export default CheckBoxModal;
