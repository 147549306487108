/* eslint-disable arrow-body-style */
import { t } from "i18next";

const Form10SubTitle = () => {
  return (
    <>
      {t("app.suivezVosContratsPartner.subTitle1Opco")}
      {" "}
      <a href="https://quel-est-mon-opco.francecompetences.fr/" target="_blank" rel="noopener noreferrer">
        https://quel-est-mon-opco.francecompetences.fr/
      </a>
      {" "}
      {t("app.suivezVosContratsPartner.subTitle2Opco")}
    </>
  );
};
export default Form10SubTitle;
