import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCompany } from "app/axiosInstances/axiosInstances";

// Initial State declaration
const initialState = {
  company: null,
  pending: false,
  pendingPostAndPatchCompany: false,
  pendingPostEmployee: false,
  errorMessage: null,
  id: null,
  data: null,
  newCompanyId: null,
  newEmployeeId: null,
  patchedCompanyInformations: null,
};

// THUNKS SECTION

export const getSiretThunk = createAsyncThunk(
  "allCompanies/getSiretThunk",
  async ({ siret, axiosCancelToken }) => {
    const response = await axiosCompany().get(`/v2/companies?siret=${siret}`, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

export const getDunsThunk = createAsyncThunk("allCompanies/getDunsThunk", async ({ duns, axiosCancelToken }) => {
  const response = await axiosCompany().get(`/v2/companies?duns=${duns}`, {
    cancelToken: axiosCancelToken.token,
  });
  return response.data;
});

export const getCompanyThunk = createAsyncThunk("allCompanies/getCompanyThunk", async ({ companyId, axiosCancelToken }) => {
  const response = await axiosCompany().get(`/v2/companies/${companyId}`, {
    cancelToken: axiosCancelToken.token,
  });
  return response.data;
});

export const postCompanyThunk = createAsyncThunk(
  "allCompanies/postCompanyThunk",
  async ({ dataToSend, axiosCancelToken }) => {
    const response = await axiosCompany().post("/v2/companies", dataToSend, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

export const patchCompanyThunk = createAsyncThunk(
  "allCompanies/patchCompanyThunk",
  async ({ id, dataToSend, axiosCancelToken }) => {
    const response = await axiosCompany().patch(`/v2/companies/${id}`, dataToSend, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

export const postEmployeeThunk = createAsyncThunk(
  "allCompanies/postEmployeeThunk",
  async ({ dataToSend, id, axiosCancelToken }) => {
    const response = await axiosCompany().post(`/v1/companies/${id}/employees`, dataToSend, {
      cancelToken: axiosCancelToken.token,
    });
    return response.data;
  },
);

const getAllCompaniesSlice = createSlice({
  name: "allCompanies",
  initialState,
  reducers: {
    resetSliceInformations: (state) => {
      state.company = null;
      state.pending = false;
      state.pendingPostAndPatchCompany = false;
      state.pendingPostEmployee = false;
      state.errorMessage = null;
      state.id = null;
      state.data = null;
      state.newCompanyId = null;
      state.newEmployeeId = null;
      state.patchedCompanyInformations = null;
    },
  },
  extraReducers: {
    [getSiretThunk.pending]: (state) => {
      console.log("pending !");
      state.errorMessage = "";
      state.company = null;
      state.pending = true;
    },
    [getSiretThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.company = action.payload;
      state.pending = false;
    },
    [getSiretThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [getDunsThunk.pending]: (state) => {
      console.log("pending !");
      state.errorMessage = "";
      state.company = null;
      state.pending = true;
    },
    [getDunsThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.company = action.payload;
      state.pending = false;
    },
    [getDunsThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [getCompanyThunk.pending]: (state) => {
      console.log("pending !");
      state.errorMessage = "";
      state.pending = true;
    },
    [getCompanyThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.company = action.payload;
      state.pending = false;
    },
    [getCompanyThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pending = false;
      state.errorMessage = action.error.message;
    },
    [postCompanyThunk.pending]: (state) => {
      console.log("pending !");
      state.errorMessage = "";
      state.pendingPostAndPatchCompany = true;
    },
    [postCompanyThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.newCompanyId = action.payload.id;
      state.pendingPostAndPatchCompany = false;
    },
    [postCompanyThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pendingPostAndPatchCompany = false;
      state.errorMessage = action.error.message;
    },
    [patchCompanyThunk.pending]: (state) => {
      console.log("pending !");
      state.errorMessage = "";
      state.pendingPostAndPatchCompany = true;
    },
    [patchCompanyThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.patchedCompanyInformations = action.payload;
      state.pendingPostAndPatchCompany = false;
    },
    [patchCompanyThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pendingPostAndPatchCompany = false;
      state.errorMessage = action.error.message;
    },
    [postEmployeeThunk.pending]: (state) => {
      console.log("pending !");
      state.errorMessage = "";
      state.pendingPostEmployee = true;
    },
    [postEmployeeThunk.fulfilled]: (state, action) => {
      console.log("fulfilled !", action.payload);
      state.newEmployeeId = action.payload.id;
      state.pendingPostEmployee = false;
    },
    [postEmployeeThunk.rejected]: (state, action) => {
      console.log("rejected !", action.error);
      state.pendingPostEmployee = false;
      state.errorMessage = action.error.message;
    },
  },
});

// SELECTORS EXPORTS SECTION
export const getAllCompanies = (state) => state.allCompanies.company;
export const getOneCompany = (state) => state.allCompanies.company;
export const getPostCompanyId = (state) => state.allCompanies.newCompanyId;
export const getPostEmployeeId = (state) => state.allCompanies.newEmployeeId;
export const selectPatchedCompanyInformations = (state) => state.allCompanies.patchedCompanyInformations;
export const getAllCompaniesIsPending = (state) => state.allCompanies.pending;
export const selectPostAndPatchCompanyIsPending = (state) => state.allCompanies.pendingPostAndPatchCompany;
export const getPostEmployeeIsPending = (state) => state.allCompanies.pendingPostEmployee;

// ACTIONS CREATOR EXPORTS SECTION

export const { resetSliceInformations } = getAllCompaniesSlice.actions;

// REDUCER DEFAULT EXPORT SECTION

export default getAllCompaniesSlice.reducer;
