import { t } from "i18next";

import { nanoid } from "@reduxjs/toolkit";

import { useEffect, useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  patchStepThunk,
  handlePreviousStep,
  getDeclareARecruitmentPreContractualizationInformations,
  getDeclareARecruitmentPending,
} from "app/slices/declareARecruitment/declareARecruitment.slice";
import { postFileThunk, getUploadFilePending, getUploadFIleErrorMessage } from "app/slices/uploadFile/uploadFile.slice";
import { createObjAucun } from "utils/localReferentials";

import SubmitContractBtn from "pages/ETU/DeclareARecruitment/components/SubmitContractBtn/SubmitContractBtn";
import PreviousFormBtn from "components/PreviousFormBtn/PreviousFormBtn";
import LoadingModal from "pages/ETU/DeclareARecruitment/components/LoadingModal/LoadingModal";

import { DropdownList } from "react-widgets";

import { axiosCompany } from "app/axiosInstances/axiosInstances";
import { getReferentialsData } from "app/slices/referentials/referentials.slice";

import {
  changeAucunToNull,
  changeEmptyStringToNull,
  changeOuiOrNonToBoolean,
  handleFilteredReferential,
} from "utils/tools";

import { toastSuccess, toastError } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import InputErrorMessage from "../../../../../../components/InputErrorMessage/InputErrorMessage";
import UploadOrganism from "../../../components/UploadOrganism/UploadOrganism";

import { ReactComponent as Pencil } from "../assets/search.svg";
import codeNafSvg from "../assets/codeNaf.svg";

import styles from "../Step6.module.scss";

const ApprenticeshipForm = ({ axiosCancelToken }) => {
  const preContractualizationInformations = useSelector(getDeclareARecruitmentPreContractualizationInformations);
  const [file, setFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [spanErrorMessage, setSpanErrorMessage] = useState(false);
  const objAucun = createObjAucun();
  const dispatch = useDispatch();
  const [apiResponse, setApiResponse] = useState([]);
  const referencialsData = useSelector(getReferentialsData);
  const [trainingCenterInputValue, setTrainingCenterInputValue] = useState(
    preContractualizationInformations?.student?.currentAcademicProfile?.apprenticeshipTrainingCenterRegistration?.center
      ?.label || "",
  );

  const handleTrainingCenterInputChange = async (event) => {
    const { value } = event.target;
    setTrainingCenterInputValue(value);
    if (value.length >= 3) {
      const response = await axiosCompany().get(`/v1/companies/apprentice-training-centers?label=*${value}*&type=CFA`);
      setApiResponse(response.data);
      console.log("this is the DAta", response.data);
    } else {
      setApiResponse([]);
    }
  };

  const handleOptionSelect = (option) => {
    setFieldValue("ListeDesCFAs", option);
    setTrainingCenterInputValue(option.label);
    console.log(values.ListeDesCFAs);
    setApiResponse([]);
  };

  const FILTERED_KEYS_APPRENTICESHIP_STUDENT_SITUATION = [
    "11 - Scolarité type collègue (incluant SEGPA, DIMA, CPA, CLIPA, MFR…)",
    "12 - Autres instituts médico-éducatifs et pédagogiques",
    "21 - Lycée général et technologique",
    "31 - Lycée professionnel (incluant lycée professionnel, MFR…)",
    "41 - Enseignement supérieur (incluant classes préparatoires…)",
    "51 - Contrat de professionnalisation",
    "52 - Stagiaire",
    "53 - En emploi",
    "54 - Demandeur d’emploi, chômage",
    "90 - Autre situation",
    "99 - Inconnue",
  ];

  const pending = useSelector(getDeclareARecruitmentPending);

  const filePending = useSelector(getUploadFilePending);
  const fileErrorMessage = useSelector(getUploadFIleErrorMessage);

  // setDibaled value when the component is mounted, if a file is required
  useEffect(() => {
    if (values.lastYearSituation.key === "Etudiant en apprentissage") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, []);

  // useEffect onChange for UploadOrganism
  useEffect(() => {
    console.log("FILE : ", file);
    if (file) {
      (async () => {
        try {
          await dispatch(
            postFileThunk({
              file,
              endpoint: `/v1/companies/recruitments/apprenticeships/pre-contractualizations/${preContractualizationInformations.id}/supporting-documents/APPRENTICESHIP_TRAINING_REGISTRATION`,
              axiosCancelToken,
            }),
          ).unwrap();
          setFileUploaded((value) => value + 1);
          setSpanErrorMessage(false);
          toastSuccess(
            <ToastMessageStructure firstMessage={t("app.toastMessages.genericSuccess")} secondMessage={file.name} />,
          );
          console.log("upload success TestUploadFile !");
        } catch (error) {
          console.log("upload fail TestUploadFile !", error.message);
          toastError(
            <ToastMessageStructure firstMessage={t("app.toastMessages.genericError")} secondMessage={file.name} />,
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const StepSixSchema = Yup.object().shape({
    lastYearSituation: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    IntitulePrecisDuDernierDiplomeOuTitrePrepare: Yup.string()
      .required(t("app.errorMessages.RemplirChamp"))
      .max(255, t("app.errorMessages.Max255")),
    DernierDiplomeOuTitrePrepare: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    lastDiplomaObtained: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    lastCourse: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),
    situationBeforeApprenticeship: Yup.object().when(["lastYearSituation"], {
      is: (obj) => obj.key === "Etudiant en apprentissage",
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    NumeroDeDepot: Yup.string().when(["lastYearSituation"], {
      is: (obj) => obj.key === "Etudiant en apprentissage",
      then: (schema) =>
        schema
          .required(t("app.errorMessages.RemplirChamp"))
          .matches(/^0\d{14}$/, t("app.errorMessages.Start0And15Total")),
    }),
    ListeDesCFAs: Yup.object().when(["lastYearSituation"], {
      is: (obj) => obj.key === "Etudiant en apprentissage",
      then: (schema) =>
        schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
  });
  // onSubmit form function
  const onSubmit = (values) => {
    if (!disabled && fileUploaded === 0) {
      setSpanErrorMessage(true);
      return;
    }
    setSpanErrorMessage(false);

    // These functions change the value of certain keys depending on the swagger, before dataToSend initialization
    const valuesCopy = structuredClone(values);

    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);

    // Data to send object initialization
    const dataToSend = {
      stage: "Etape7",
      contractType: preContractualizationInformations?.contractType?.key,
      student: {
        id: preContractualizationInformations?.student?.id,
        currentAcademicProfile: {
          lastYearSituation: valuesCopy.lastYearSituation.key,
          lastDiplomaPrepared: {
            name: valuesCopy.IntitulePrecisDuDernierDiplomeOuTitrePrepare,
            type: valuesCopy.DernierDiplomeOuTitrePrepare.key,
          },
          lastDiplomaObtained: {
            type: valuesCopy.lastDiplomaObtained.key,
          },
          situationBeforeApprenticeship: valuesCopy.situationBeforeApprenticeship.key,
          lastCourse: valuesCopy.lastCourse.key,
          ...(!disabled && {
            apprenticeshipTrainingCenterRegistration: {
              centerKey: valuesCopy.ListeDesCFAs.key,
              number: valuesCopy.NumeroDeDepot,
            },
          }),
        },
      },
    };

    (async () => {
      if (!pending) {
        (async () => {
          try {
            await dispatch(
              patchStepThunk({
                dataToSend,
                id: preContractualizationInformations.id,
                axiosCancelToken,
              }),
            ).unwrap();
            console.log("patch success !");
          } catch (error) {
            console.log("patch fail !", error.message);
          }
        })();
      }
    })();
  };
  // useFormik from Formik
  const thisPreContraData = preContractualizationInformations?.student?.currentAcademicProfile;

  const { values, setFieldValue, errors, touched, handleSubmit } = useFormik({
    initialValues: {
      lastYearSituation: thisPreContraData?.lastYearSituation || { ...objAucun },
      IntitulePrecisDuDernierDiplomeOuTitrePrepare: thisPreContraData?.lastDiplomaPrepared?.name || "",
      DernierDiplomeOuTitrePrepare: thisPreContraData?.lastDiplomaPrepared?.type || { ...objAucun },
      lastDiplomaObtained: thisPreContraData?.lastDiplomaObtained?.type || { ...objAucun },
      lastCourse: thisPreContraData?.lastCourse || { ...objAucun },
      NumeroDeDepot: thisPreContraData?.apprenticeshipTrainingCenterRegistration?.number || "",
      ListeDesCFAs: thisPreContraData?.apprenticeshipTrainingCenterRegistration?.center || { ...objAucun },
      situationBeforeApprenticeship: thisPreContraData?.situationBeforeApprenticeship || { ...objAucun },
    },
    validationSchema: StepSixSchema,
    onSubmit,
  });

  const hasValidDiploma = (lastCourse) => lastCourse?.key?.includes("01");

  useEffect(() => {
    const label =
      preContractualizationInformations?.student?.currentAcademicProfile?.apprenticeshipTrainingCenterRegistration
        ?.center?.label || "";
    setTrainingCenterInputValue(label);
  }, [preContractualizationInformations]);

  return (
    <>
      <LoadingModal
        setShow={setDisplayModal}
        show={displayModal}
        file={{
          name: file?.name,
          type: file?.type,
          size: file?.size,
        }}
        pending={filePending}
        error={fileErrorMessage}
        handleClose={() => setDisplayModal(false)}
      />
      {/* {disabled && <BlueBanner />} */}
      {referencialsData && (
        <>
          <form onSubmit={handleSubmit}>
            <div className={styles.inputs_container}>
              <div className={styles.Dropdown_label_plus_input_container}>
                <label htmlFor="lastYearSituation">{t("app.StepSeven.SituationAvantContrat")}</label>
                <DropdownList
                  value={values.lastYearSituation}
                  data={[{ ...objAucun }, ...referencialsData.APPRENTICESHIP_STUDENT_LAST_YEAR_SITUATION]}
                  dataKey="key"
                  textField="label"
                  id="lastYearSituation"
                  onChange={(value) => {
                    setFieldValue("lastYearSituation", value);
                    if (value.key === "Etudiant en apprentissage") {
                      setDisabled(false);
                    } else {
                      setDisabled(true);
                    }
                  }}
                />
                <InputErrorMessage message={errors.lastYearSituation} touched={touched.lastYearSituation} />
              </div>
              {values.lastYearSituation.key === "Etudiant en apprentissage" ? (
                <>
                  <UploadOrganism
                    labelText={t("app.StepSeven.PrecedentCerfa")}
                    disabled={disabled}
                    setFile={setFile}
                    setDisplayModal={setDisplayModal}
                    spanErrorMessage={spanErrorMessage}
                  />
                  <div className={`${styles.label_plus_input_container} input-like-rw`}>
                    <label htmlFor="NumeroDeDepot">
                      {t("app.StepSeven.NumeroDeca")}
                      <br />
                      <span className={styles.special_label}>{t("app.StepSeven.SubNumeroDeca")}</span>
                    </label>

                    <input
                      type="number"
                      name="NumeroDeDepot"
                      id="NumeroDeDepot"
                      value={values.NumeroDeDepot}
                      onChange={(e) => {
                        setFieldValue("NumeroDeDepot", e.target.value);
                      }}
                    />
                    <InputErrorMessage message={errors.NumeroDeDepot} touched={touched.NumeroDeDepot} />
                  </div>
                  <div className={styles.label_plus_input_container}>
                    <label htmlFor="ListeDesCFAs">{t("app.StepSeven.ListCFA")}</label>
                    <div className={`${styles.label_plus_input_container} input-like-rw ${styles.search_container}`}>
                      <input type="text" value={trainingCenterInputValue} onChange={handleTrainingCenterInputChange} />
                      <Pencil className={styles.search_icon} />
                    </div>
                    <div className={apiResponse.length > 0 ? styles.output_container : undefined}>
                      <ul>
                        {apiResponse.map((result) => (
                          <div key={nanoid()}>
                            <Pencil className={styles.search_icon} />

                            <div
                              key={nanoid()}
                              className={styles.output_plus_image_Container}
                              onClick={() => handleOptionSelect(result)}
                              onKeyDown={(e) => e.key === "Enter" && handleOptionSelect(result)}
                              role="button"
                              tabIndex={0}
                            >
                              <img src={codeNafSvg} style={{ width: "1.8rem" }} alt="test"></img>
                              <div className={styles.outputKeyLabel}>
                                <li> {result.key}</li>
                                <li>{result.label}</li>
                              </div>
                            </div>
                          </div>
                        ))}
                      </ul>
                    </div>
                    <InputErrorMessage message={errors.ListeDesCFAs} touched={touched.ListeDesCFAs} />
                  </div>
                  <div className={styles.Dropdown_label_plus_input_container}>
                    <label htmlFor="situationBeforeApprenticeship">
                      {t("app.StepSeven.SituationAvantApprentissage2")}
                    </label>

                    <DropdownList
                      value={values.situationBeforeApprenticeship}
                      data={handleFilteredReferential(
                        [{ ...objAucun }, ...referencialsData.APPRENTICESHIP_STUDENT_SITUATION],
                        FILTERED_KEYS_APPRENTICESHIP_STUDENT_SITUATION,
                      )}
                      dataKey="key"
                      textField="label"
                      id="situationBeforeApprenticeship"
                      onChange={(value) => {
                        setFieldValue("situationBeforeApprenticeship", value);
                      }}
                    />
                    <InputErrorMessage
                      message={errors.situationBeforeApprenticeship}
                      touched={touched.situationBeforeApprenticeship}
                    />
                  </div>
                </>
              ) : null}

              <div className={styles.Dropdown_label_plus_input_container}>
                <label htmlFor="DernierDiplomeOuTitrePrepare">{t("app.StepSeven.DernierDiplomeOuTitrePrepare")}</label>
                <DropdownList
                  value={values.DernierDiplomeOuTitrePrepare}
                  data={[{ ...objAucun }, ...referencialsData.APPRENTICESHIP_STUDENT_DIPLOMA]}
                  dataKey="key"
                  textField="label"
                  id="DernierDiplomeOuTitrePrepare"
                  onChange={(value) => {
                    setFieldValue("DernierDiplomeOuTitrePrepare", value);
                    if (hasValidDiploma(values?.lastCourse)) {
                      setFieldValue("lastDiplomaObtained", value);
                    }
                  }}
                />
                <InputErrorMessage
                  message={errors.DernierDiplomeOuTitrePrepare}
                  touched={touched.DernierDiplomeOuTitrePrepare}
                />
              </div>
              <div className={styles.Dropdown_label_plus_input_container}>
                <label htmlFor="lastCourse">{t("app.StepSeven.DerniereAnneeOuClasseSuivieParLapprenti")}</label>
                <DropdownList
                  value={values.lastCourse}
                  data={[{ ...objAucun }, ...referencialsData.APPRENTICESHIP_COURSE]}
                  dataKey="key"
                  textField="label"
                  id="lastCourse"
                  onChange={(value) => {
                    setFieldValue("lastCourse", value);
                    if (hasValidDiploma(value)) {
                      setFieldValue("lastDiplomaObtained", values.DernierDiplomeOuTitrePrepare);
                    }
                  }}
                />
                <InputErrorMessage message={errors.lastCourse} touched={touched.lastCourse} />
              </div>
              <div className={`${styles.label_plus_input_container} input-like-rw`}>
                <label htmlFor="IntitulePrecisDuDernierDiplomeOuTitrePrepare">
                  {t("app.StepSeven.IntitulePrecisDuDernierDiplomeOuTitrePrepare")}
                </label>
                <input
                  type="text"
                  name="IntitulePrecisDuDernierDiplomeOuTitrePrepare"
                  id="IntitulePrecisDuDernierDiplomeOuTitrePrepare"
                  value={values.IntitulePrecisDuDernierDiplomeOuTitrePrepare}
                  onChange={(e) => {
                    setFieldValue("IntitulePrecisDuDernierDiplomeOuTitrePrepare", e.target.value);
                  }}
                />
                <InputErrorMessage
                  message={errors.IntitulePrecisDuDernierDiplomeOuTitrePrepare}
                  touched={touched.IntitulePrecisDuDernierDiplomeOuTitrePrepare}
                />
              </div>
              <div className={styles.Dropdown_label_plus_input_container}>
                <label htmlFor="lastDiplomaObtained">{t("app.StepSeven.DernierDiplomeObtenu")}</label>
                <DropdownList
                  value={values.lastDiplomaObtained}
                  data={[{ ...objAucun }, ...referencialsData.APPRENTICESHIP_STUDENT_DIPLOMA]}
                  disabled={hasValidDiploma(values?.lastCourse)}
                  dataKey="key"
                  textField="label"
                  id="lastDiplomaObtained"
                  onChange={(value) => {
                    setFieldValue("lastDiplomaObtained", value);
                  }}
                />
                <InputErrorMessage message={errors.lastDiplomaObtained} touched={touched.lastDiplomaObtained} />
              </div>
            </div>
          </form>
          <div className={styles.btns_container}>
            <SubmitContractBtn
              text={t("app.declarezUnRecrutement.ValidezEtapeSuivante")}
              handleNextStep={handleSubmit}
            />
            <PreviousFormBtn
              text={t("app.declarezUnRecrutement.EtapePrecedente")}
              handlePreviousStep={() => dispatch(handlePreviousStep("Etape5"))}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ApprenticeshipForm;
