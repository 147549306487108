import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";
import { useState } from "react";
import styles from "./FormCustomCheckBoxField.module.scss";
import CheckBoxModal from "./CheckBoxModal";

const FormCustomCheckBoxField = ({
  error,
  touched,
  checkboxValue,
  infoBullText,
  text,
  id,
  modalText,
  modalAcceptButtonText,
  modalDeclineButtonText,
  handleChange,
  additionalClassNames = "",
}) => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = (value) => {
    if (value === false) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  };

  const onClose = () => {
    setShowModal(false);
  };

  const closeModalAndInverseCheckboxValue = () => {
    setShowModal(false);
    handleChange(!checkboxValue);
  };

  return (
    <>
      {modalText && (
        <CheckBoxModal
          modalText={modalText}
          show={showModal}
          closeModalAndInverseCheckboxValue={closeModalAndInverseCheckboxValue}
          handleCheckBox={(value) => {
            handleChange(value);
            onClose();
          }}
          modalAcceptButtonText={modalAcceptButtonText}
          modalDeclineButtonText={modalDeclineButtonText}
        />
      )}

      <div className={styles.checkbox_row}>
        <div className={` ${styles.checkbox_container} input-like-rw ${additionalClassNames}`}>
          <div className={styles.checkbow_row}>
            <input
              className={error && touched && styles.error_borders}
              id={id}
              type="checkbox"
              checked={checkboxValue}
              onChange={(e) => {
                handleShowModal(e.target.checked);
                handleChange(e.target.checked);
              }}
            />
            <div className={styles.checkbox_text}>{text}</div>
            {infoBullText && (
              <div className={styles.infoBulle}>
                <svg
                  className="slds-button__icon"
                  focusable="false"
                  data-key="info"
                  aria-hidden="true"
                  viewBox="0 0 52 52"
                  part="icon"
                >
                  <g>
                    <path d="M26 2C12.7 2 2 12.7 2 26s10.7 24 24 24 24-10.7 24-24S39.3 2 26 2zm0 12.1c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm5 21c0 .5-.4.9-1 .9h-8c-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1.5 0 1-.3 1-.9v-4c0-.5-.4-1.1-1-1.1-.5 0-1-.3-1-.9v-2c0-.5.4-1.1 1-1.1h6c.5 0 1 .5 1 1.1v8c0 .5.4.9 1 .9.5 0 1 .5 1 1.1v2z"></path>
                  </g>
                </svg>
                <div className={styles.infoBulleInfo}>{infoBullText}</div>
              </div>
            )}
          </div>
          <InputErrorMessage message={error} touched={touched} />
        </div>
      </div>
    </>
  );
};

export default FormCustomCheckBoxField;
